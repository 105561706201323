//@ts-nocheck
import React from "react";
import {
  Container,
  withStyles,
  Grid
  // Customizable Area Start
  ,Hidden
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withRouter } from "react-router-dom";
import { FirstBgImg3, FirstBgImg1, FirstBgImg2, logo, secondBgImg } from "./assets";
import StepperWeb from "./Stepper.web";
import UserprofileController, { Props } from "./UserprofileController.web";
import { withSnackbar } from "../../../components/src/toastweb";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import {Theme } from "@material-ui/core/styles"
import { mobileLogo } from "../../LanguageSupport/src/assets";
import { goBack } from "../../../components/src/CommonFunction";
import { mobileArrow } from "../../email-account-login/src/assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

 // Customizable Area Start

 // Customizable Area End

export class UserProfile extends UserprofileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
      // Customizable Area Start
    //@ts-ignore
    const { classes } = this.props
    return (
    
      <>
        <Grid container direction="row" alignItems="center" className={classes.formLayout} spacing={2}>
          <Grid item xs={12} sm={5} md={4} lg={3} xl={4} className={classes.bgImg} />
          <Grid item xs={12} sm={1} md={1} lg={2} xl={2} />
          <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
            <Container>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                 
                  <Grid item xs={12}>
                    <img src={logo} className={classes.logoImg} />
                  </Grid>
                  {this.state.activeStep === 0 &&
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                      <Grid item xs={12}>
                        <img className={classes.backIcon} src={mobileArrow}
                          onClick={() => goBack(this.props.navigation)}
                          width={12} height={24} alt="mobile arrow" />
                      </Grid>
                    </Hidden>
                  }

                  {this.state.activeStep !== 0 &&
                    <Grid item xs={1}>
                      <img className={classes.backIcon} src={mobileArrow}
                        onClick={this.handleBackIcon}
                        width={12} height={24} alt="mobile arrow" />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-start" spacing={1}>
                      <StepperWeb
                      data-test-id="stepper"
                        profile={this.state.profile}
                        countryCode={this.state.countryCode}
                        handleCountryCodeChange={this.handleCountryCodeChange}
                        countryCodeInputValue={this.state.countryCodeInputValue}
                        countryCodeInputChange={this.countryCodeInputChange}
                        countryName={this.state.countryName}
                        handleCountryChange={this.handleCountryChange}
                        countryInputValue={this.state.countryInputValue}
                        countryInputChange={this.countryInputChange}
                        bussiness={this.state.bussiness}
                        referbefore={this.state.referbefore}
                        handleProfileChange={this.handleProfileChange}
                        handleBussinessChange={this.handleBussinessChange}
                        getSteps={this.state.getSteps}
                        activeLabel={this.state.activeLabel}
                        activeStep={this.state.activeStep}
                        handleLoginSubmit={this.handleLoginSubmit}
                        isProfileError={this.state.isProfileError}
                        isBussinessError={this.state.isBussinessError}
                        handlebussinessForm={this.handlebussinessForm}
                        handleChangeReferBefore={this.handleChangeReferBefore}
                        SignUp={this.SignUp}
                        progressbarclass={this.progressbarclass}
                        handleBack={this.handleBack}
                        handleSelectFiles={this.handleSelectFiles}
                        handleOnDragOver={this.handleOnDragOver}
                        handleOnDrop={this.handleOnDrop}
                        handleProgressEvent={this.handleProgressEvent}
                        companyLogo={this.state.companyLogo}
                        isHovered={this.state.isHovered}
                        isHovered2={this.state.isHovered2}
                        progress={this.state.progress}
                        handleDeleteUploadedLogo={this.handleDeleteUploadedLogo}
                        completed={this.state.completed}
                        getProfileData={this.getProfileData}
                        handleNext={this.handleNext}
                        extensionIcons={this.state.extensionIcons}
                        signUpLoader={this.state.signUpLoader}
                        showOtherField={this.state.showOtherField}
                        handleOther={this.handleOther}
                        othertext={this.state.othertext}
                        otherField={this.state.otherField}
                        otherOnChange={this.otherOnChange}
                        otherFielsText={this.state.otherFielsText}
                       
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12} sm={1} md={2} lg={3} xl={2} className={classes.secondImageLayout}>
            <img className={classes.secondBgImg} src={secondBgImg} alt="secondBgImg" />
          </Grid>
        </Grid>
        <LoadingBackdrop
          loading={this.state.signUpLoader}
        />
      </>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start

//@ts-ignore
const UserProfileRouter = withRouter(UserProfile)
const UserProfileToast = withSnackbar(UserProfileRouter)
export const UserprofileStyles = (theme: Theme) => ({
  bgImg: {
    backgroundImage: `url(${FirstBgImg1})`,
    height: "100vh",
    backgroundSize: "100% 100%",
    position: "relative",
    transform: "rotateY(180deg)",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${mobileLogo})`,
      maxHeight: '86px',
      transform: "none",
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${FirstBgImg1})`,
    },
    [theme.breakpoints.between("sm", 'md')]: {
      backgroundImage: `url(${FirstBgImg2})`,
    },
    [theme.breakpoints.between('md', "lg")]: {
      backgroundImage: `url(${FirstBgImg3})`,
    },
  },
  logoImg: {
    width: "150px"
  },
  formLayout: {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    },
  },
  secondImageLayout: {
    height: "100%",
    alignItems: "end",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down('xs')]: {
      height: "fit-content",
      marginTop: 40
    }
  },
  backIcon: {
    cursor: "pointer",
  },
  secondBgImg: {
    width: '80px',
    [theme.breakpoints.down('xs')]: {
      width: '47px',
      height: "47px"
    }
  },
  headingText: {
    fontSize: "22px",
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px'
    }
  }
})
const BackIcon: any = withStyles({
  root: {
    width: '100%',
    "&.MuiSvgIcon-root": {
      color: "#1C18AF",
      width: '100px',
      transform: "translate(-40%, -50%)",
    }
  }
})(ArrowBackIcon);
const comp= withStyles(UserprofileStyles)(UserProfileToast)
export default withTranslation()(comp)
// Customizable Area End