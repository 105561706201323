import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
interface Event {
  target: {
    matches: (arg0: string) => Function;
  };
  animationName: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any
  // Customizable Area Start
  location: any
  openToastHandler: Function;
  t:(key: string) => string;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  signUpForm: {
    signUpEmail: string;
    signUpPassword: string;
  }
  visibilityPassword: boolean;
  signUpErrors: any;
  checkBox:any
      isFocused: boolean,
      isLowerCaseValid: boolean,
      isUpperCaseValid: boolean,
      isNumberValid: boolean,
      isSpecialCharacter: boolean
      isLengthValid: boolean
      passwordHasValue:boolean
      isAutoFilled: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RegisterWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  password: string = "";
  validateEmailApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      signUpForm: {
        signUpEmail: this.props.location?.state?.data || "",
        signUpPassword: "",
      },
      signUpErrors: {},
      visibilityPassword: false,
      checkBox:"false",
      isFocused: false,
      isLowerCaseValid: false,
      isUpperCaseValid: false,
      isNumberValid: false,
      isSpecialCharacter: false,
      isLengthValid: false,
      passwordHasValue: false,
      isAutoFilled: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.password = configJSON.passtext;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.validateEmailApiCallId) {
        if (responseJson && responseJson?.message) {
          const data = this.state.signUpForm
          removeStorageData("signupEmail")
          this.props.history.push("/UserProfile", { userDetails: data })
         

        } else {
          removeStorageData("signupEmail")
          this.props.openToastHandler(this.props.t(responseJson.error.message), "error")
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    const checkBox = localStorage.getItem("terms")
    this.setState({checkBox})
    const email = await getStorageData("signupEmail");
    this.retrieveSignupCreds(email)
    
  }
  retrieveSignupCreds = (email: any) => {
    if (email) {
      this.setState({ signUpForm: { ...this.state.signUpForm, signUpEmail:email } })
    }
  }
  storeSignupCreds = async () => {
    await setStorageData("signupEmail", this.state.signUpForm.signUpEmail)
  }
  handleFocus = () => {
    this.setState({ isFocused: true });
  };
  handleChange = (event: any) => {
    const errors: any = {};
    let { name, value } = event.target;
      const password = String(event.target.value);
    if (name === 'signUpPassword') {
      this.validateSignUpPassword(password)
    }
    
     
    this.setState({
      signUpForm: { ...this.state.signUpForm, [event.target.name]: event.target.value },
      signUpErrors: errors,
    });
    this.setState({
      signUpForm: { ...this.state.signUpForm, [event.target.name]: event.target.value }
    });
  };

  validateSignUpPassword = (password: string) => {
    const { atleastOneCapsRegex,
      atleastLowerCaseRegex,
      specialCharRegex,
      digitsRegex } = configJSON.passwordValidators;

      const lowerCaseLetters = atleastLowerCaseRegex;
      this.setState({ isLowerCaseValid: !!password.match(lowerCaseLetters) });

      const upperCaseLetters = atleastOneCapsRegex;
      this.setState({ isUpperCaseValid: !!password.match(upperCaseLetters) });

      const numbers = digitsRegex;
      this.setState({ isNumberValid: !!password.match(numbers) });

      const specialChar = specialCharRegex;
      this.setState({ isSpecialCharacter: !!password.match(specialChar) });

      this.setState({ isLengthValid: password.length >= 8 });
  }
  validateSignUpEmail = (value: string, errors: any) => {
    const { t }: any = this.props
    const emailValidation = configJSON.emailRegex;
    if (!value) {
      errors.signUpEmail = t("BoAuthErrors.login.empty");
    } else if (!emailValidation.test(value)) {
      errors.signUpEmail = t("BoAuthErrors.login.validEmail");
    }
  }
  goToTermspageSignup = ()=>{
    localStorage.setItem("cameFrom", "Register")
    this.storeSignupCreds()
    this.props.navigation.navigate("TermsAndCondition")
  }
  handleCheckSignup = (e:any)=>{
    const terms = e.target.checked === true ? "true" : "false"
    this.setState({ checkBox: terms });
    localStorage.setItem("terms", terms)
    
  }
  submitButton = async (e: React.FormEvent<HTMLFormElement>) => {
    const lang = localStorage.getItem('lang')
    if(lang===null){
      localStorage.setItem("lang", "en")
    }
    e.preventDefault();
    const errors: any = {};
    let { signUpEmail, signUpPassword } = this.state.signUpForm;

    this.validateSignUpEmail(signUpEmail, errors);
    this.validateSignUpPassword(signUpPassword);

    this.setState({
      signUpErrors: errors,isFocused:true
    }, () => {
      if (Object.keys(this.state.signUpErrors).length === 0) {
        this.validateEmail()
      }
    });
  };
  handleClickShowPassword = () => {
    this.setState({
      visibilityPassword: !this.state.visibilityPassword
    });
  };
   makeAnimationStartHandler = () => (event:Event) => {
    const autofilled = !!event.target?.matches("*:-webkit-autofill");
    this.setState({isAutoFilled:autofilled})
  };
  validateEmail = () => {
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body=new FormData()
    body.append("email",this.state.signUpForm.signUpEmail.trim())
    this.validateEmailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.validateEmail}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
