import React from "react";
// Customizable Area Start
import {
    Box,Button,
    Typography, Grid, Table, TableHead, TableRow, TableCell, styled , TableBody
} from "@material-ui/core";
import {
    withStyles
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
export const configJSON = require("./config");
import PdfController, {
    PdfSiteDetails,
    Props,
} from "./PdfController.web";
import { lamsaLogo } from "../../CustomisableUserProfiles/src/assets";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { timeIcon } from "./assets";
import { calendarIcon } from "../../customisableusersubscriptions/src/assets";
import { StyledDialog, styles } from "./RequestPdf.web";
import { CONSTANTS } from "../../../components/src/theme";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
interface Scheduling {
    start_time: string
    end_time: string
    start_date: Date
    end_date: Date
}
// Customizable Area End



// Customizable Area Start
interface BoInformation {
    name: string;
    company_name: string;
    email: string;
    street_address: string;
    city: string;
    post_code: number;
    full_phone_number: string;
  }
  interface ProductItem {
    id: string;
    type: string;
    attributes: {
        service_id: null | number;
        product_id: number;
      quantity: number;
      currency: null | string;
      unit_price: string;
      description: string;
      total: string;
      quote_id: null | string;
      job_id: number;
      product_name: string;
      visit_id: null | string;
    };
    visits: {
        data: Visit[];
    };
  }
  
  interface ProductsData {
    data: ProductItem[];
  }
  interface Employee {
    map(arg0: (employee: Employee, index: number) => JSX.Element): React.ReactNode;
    length: number;
    id: number;
    name: string;
    email: string;
}
interface Visit {
    map: object;
    id: string;
    type: string;
    attributes: {
        start_date: string;
    };
}
interface JobData {
    attributes: {
        start_date: string;
    };
    data: {
        map(arg0: (visit: JobData, index: number) => JSX.Element): React.ReactNode;
        id: string;
        type: string;
        attributes: {
            job_title: string;
        };
        visits: {
            data: Visit[];
        };
    };
}
  
 const customerDetails = (classes: ClassNameMap<string>,t: ((key: string) => string) | undefined,pdfSiteDetails:PdfSiteDetails,requestPdfDetails:{
    data: object; custmoer_name: string , job_id:number,bo_information:BoInformation , start_date: string , 
    end_date: string, start_time: string , end_time: string , job_title: string ,sub_total: null | number,  total: null | number, discount_type: null | string , discount: null | number ,  tax: null | number,
    products : ProductsData , employees:Employee , visits: JobData ,visits_count: string

}) => {
    return (
        <>
       
        <div className={classes.requestUserDetails} style={webStyle.requestUserDetails}>
            <Grid container direction="row" spacing={3} >
                <Grid item xs={6}>
                    <Box>
                        <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>{requestPdfDetails?.bo_information?.name} </Typography>
                        <Typography variant="body1" className={classes.sourceText}>{requestPdfDetails?.bo_information?.email}</Typography>
                        <Typography variant="body1" className={classes.sourceText}>{requestPdfDetails?.bo_information?.street_address}&nbsp;{requestPdfDetails?.bo_information?.post_code}</Typography>
                        <Typography variant="body1" className={classes.sourceText}>{requestPdfDetails?.bo_information?.full_phone_number}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>{pdfSiteDetails.site_name} </Typography>
                        <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.email}</Typography>
                        <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.site_address}</Typography>
                        <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.phone_number}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box style={webStyle.titleDe}>
                        <Box>
                            <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Job Date</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{requestPdfDetails.start_date}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Job ID</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{requestPdfDetails.job_id}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Visit Number</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{requestPdfDetails.visits_count}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Scheduled Date and Time</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{"Start:"} {requestPdfDetails.start_date}&nbsp;{requestPdfDetails.start_time}</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{"End:"} {requestPdfDetails.end_date}&nbsp;{requestPdfDetails.end_time}</Typography>
                        </Box>
                    </Box>
                </Grid>
                
            </Grid>
        </div>
        <div>
            <Grid item xs={12}>
                    <Box id="jobPdf-4">
                        <Typography id="jobPdf-1" variant="body1" className={classes.customerHeadingText} style={webStyle.fontDarkspace}>Job Title&nbsp;{requestPdfDetails.job_title}</Typography>
                    </Box>
                    <Box id="jobPdf-3">
                        <Typography id="jobPdf-2" variant="body1" className={classes.customerHeadingText} style={webStyle.fontDarkspace}>Product/Services</Typography>
                    </Box>
                    <TableStyle id="jobPdf-5">
                        <Table aria-label="simple table" id="jobPdf-6">
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell> # </TableCell>
                                    <TableCell className="title-th-des" align="left">Description</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Unit Price</TableCell>
                                    <TableCell align="center">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body" id="jobpdf-53">
                                {requestPdfDetails?.products?.data.map((item: ProductItem, index:number) => (
                                    <TableRow key={index} id="jobpdf-54" >
                                        <TableCell id="jobpdf-55" component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell id="jobpdf-56" align="left">{item.attributes.product_name}</TableCell>
                                        <TableCell id="jobpdf-57" align="center">{item.attributes.quantity}</TableCell>
                                        <TableCell id="jobpdf-58" align="center">{item.attributes.unit_price}</TableCell>
                                        <TableCell id="jobpdf-59" align="center">{item.attributes.total}</TableCell>
                                    </TableRow>
                                ))}
                                    <TableRow>
                                        <TableCell className="borderRemove" colSpan={4} align="right">Subtotal</TableCell>
                                        <TableCell className="borderRemove" align="center">{requestPdfDetails.sub_total}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="borderRemove" colSpan={4} align="right">Discount</TableCell>
                                        <TableCell className="borderRemove" align="center">{requestPdfDetails.discount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} align="right">Tax</TableCell>
                                        <TableCell align="center">{requestPdfDetails.tax}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="colDark borderRemove" colSpan={4} align="right">Total</TableCell>
                                        <TableCell className="colDark borderRemove" align="center">{requestPdfDetails.total}</TableCell>
                                    </TableRow>
                                    
                            </TableBody>
                        </Table>
                    </TableStyle>
                </Grid>
            </div>
            <div>
            <Grid item xs={12}>
                    <Box style={webStyle.footerSpace}>
                        <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Assigned Team Members </Typography>
                        <Typography variant="body1" className={classes.sourceText}>
                                {requestPdfDetails?.employees?.map((employee : Employee, index : number) => (
                                    <span key={index}>
                                    {employee.name}
                                    {index < requestPdfDetails.employees.length - 1 && ', '}
                                    </span>
                                ))}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Visits: Scheduled Visits </Typography>
                        <Typography variant="body1" className={classes.sourceText}>
                            {requestPdfDetails?.visits?.data?.map((visit:JobData, index : number) => (
                                <span key={index}>
                                    {visit?.attributes?.start_date}
                                </span>
                            ))}
                            </Typography>
                    </Box>
                </Grid> 
            </div>
        </>
    )
}
export const Sechudling = (classes: ClassNameMap<string>,t: ((key: string) => string) | undefined,
requestPdfDetails:Scheduling) => {
    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" className={classes.titleHeading26}>{ t? t('quote.dateTime'):"Date/Time Scheduling"}</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Typography style={webStyle.appointmentLayout} component={"div"} className={classes.description18}>
                        {t ? t('jobs.tableRow.startDate') : "State Date"}
                        &nbsp;<img src={calendarIcon} width={"16px"} height={"16px"} />&nbsp;-&nbsp;{requestPdfDetails.start_date}
                    </Typography>
                    <Typography style={webStyle.appointmentLayout} component={"div"} className={classes.description18}>
                        {t ? t('jobs.tableRow.startTime') : "Start Time"}
                        &nbsp;<img src={timeIcon} width={"16px"} height={"16px"} />&nbsp;-&nbsp;
                        {requestPdfDetails.start_time}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Typography style={webStyle.appointmentLayout} component={"div"} className={classes.description18}>
                        {t ? t('jobs.label.endDate') : "End Date"}
                        &nbsp;<img src={calendarIcon} width={"16px"} height={"16px"} />&nbsp;-&nbsp;{requestPdfDetails.end_date}
                    </Typography>
                    <Typography style={webStyle.appointmentLayout} component={"div"} className={classes.description18}>
                        {t ? t('jobs.tableRow.endTime') : "End Time"}
                        &nbsp;<img src={timeIcon} width={"16px"} height={"16px"} />&nbsp;-&nbsp;
                        {requestPdfDetails.end_time}
                    </Typography>
                </Grid>
            </Grid>

        </>
    )
}
// Customizable Area End

export class JobPdf extends PdfController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { props } = this
        const { closePdf, classes,t } = props
        const { pdf_url, employees } = this.state.requestPdfDetails
        const { request, pdf_logo, pdf_hex_colour } = this.state.requestPdfDetails
        const Team = () => {
            return (
                <Grid container direction="row" justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.titleHeading26}>
                            {t ? t('jobs.teamAssign') : "Team Assign"}
                        </Typography>
                    </Grid>
                    {employees & employees?.map((employee: any) => {
                        return (
                            <Grid item xs={12} key={employee?.id}>
                                <Typography variant="body1" className={classes.sourceText}>{employee?.name || "-"}</Typography>
                            </Grid>
                        )

                    })}

                </Grid>
            )
        }
        const pdfContent = () => {
            return (
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-start" spacing={2} >
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={classes.titleHeading26}>{request?.id}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {customerDetails(classes,t,this.state.pdfSiteDetails,this.state.requestPdfDetails)}
                        </Grid>

                    </Grid>

                </Grid>
            );
        }
        // created date
        const dateString = this.state.requestPdfDetails.created_at;
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
        return (
            <StyledDialog
                fullWidth={true}
                maxWidth={"lg"}
                open={this.props.openPdf}
                onClose={closePdf}
            >
                <Box style={webStyle.downloadLayout}>
                    <Box style={webStyle.closeIconButton}>
                        <CloseIcon
                            style={{ fontSize: 30 }}
                            onClick={this.props.closePdf}
                        />
                    </Box>
                </Box>
                <div style={{ padding: "3rem" }} className="requestPdf">
                    <Box className={classes.containerLayout} style={{ border: `5px solid ${pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
                        <Box className={classes.requestInnerContainer} style={{ border: `1px solid ${pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
                            <div style={webStyle.pdfLogoConainer}>
                                <img
                                    style={(!this.state.pdfLoading && this.state.requestPdfDetails?.company_logo?.url) ? webStyle.companyLogo : webStyle.lamsaLogo}
                                    src={(!this.state.pdfLoading && this.state.requestPdfDetails?.company_logo?.url) || lamsaLogo}
                                    alt=""
                                />
                            </div>
                            <Box style={webStyle.shippingContainer}>
                                <Box>
                                    {pdfContent()}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </div>
                <Box className={classes.downloadButtonLayout}>
                    <CreateQuote
                        data-test-id="downloadPdf"
                    >
                        <Typography
                            component={'a'}
                            href={this.state.requestPdfDetails?.pdf_url?.url}
                            download
                            target="_blank"
                            className={classes.downloadPdfText}
                            variant="subtitle2"
                        >
                            {t ? t('request.button.download') : configJSON.downloadLabel}
                        </Typography>
                    </CreateQuote>

                </Box>
                <LoadingBackdrop
                    loading={this.state.pdfLoading}
                />
            </StyledDialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    fontDark: {
        fontWeight: 500 ,
        color: "black",
    },
    fontDarkspace:{
        fontWeight: 500 ,
        color: "black",
        margin:"0 0 20px 0"
    },
    requestUserDetails:{
        borderBottom:"1px solid black",
        paddingBottom:20,
        marginBottom:20
    },
    pdfLogoConainer: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    titleDe:{
        display:"flex", 
        flexDirection:"column" as const,
        alignItems: 'end' ,
        textAlign: 'end' as const,
        gap:20
    },
    downloadLayout: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "3rem 3rem 0 3rem",
        alignItems: "center"
    },
    closeIconButton: {
        cursor: "pointer"
    },
    lamsaLogo: {
        width: "125px",
        height: "40px"
    },
    companyLogo: {
        width: "100px",
        height: "100px"
    },
    requestOrderIconContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "12px",
        padding: "1rem 0 1rem"
    },
    purchaseOrderIcon: {
        width: "20px",
        height: "20px"
    },
    shippingContainer: {
        justifyContent: "space-between",
    },
    hr: {
        display: "flex",
        height: "1px",
        border: "1px solid rgba(0,0,0,0.1)",
        margin: "1rem 0 1rem"
    },
    appointmentLayout: {
        display: "flex",
        alignItems: "center"
    },
    footerSpace:{
        margin:"50px 0 20px 0"
    },
    spaces:{
        margin:"0 0 20px 0"
    }
}

const TableStyle = styled(Box)({
    "& .borderRemove":{
        borderBottom:"none !important"
     },
    "& .table-body":{
        "& tr":{
            height:"50px !important",
        },
        "& th":{
            "&:first-child":{
                minWidth: 30
            },
        },
        "& .MuiTableCell-root":{
            padding:"0px !important"
        },
    },
    "& .table-header":{
        height:"45px !important",
        
        "& .title-th-des":{
            display:"flex",
            justifyContent:"start",
            alignItems:"center",
            height:"45px !important",
        },
        "& .MuiTableCell-root":{
            border:"none",
            fontWeight: 500 ,
            color: "black",
        },
        "& th":{
            background:"#f0f0f0",
            "&:last-child":{
                borderRadius: "0 6px 6px 0",
            },
            "&:first-child":{
                borderRadius: "6px 0 0 6px",
                minWidth: 30
            }
        }
    },
    "& .colDark":{
        fontWeight: "500 !important" ,
        color: "black !important",
    }
})

const JobPdfStyles = withStyles(styles)(JobPdf);
export default withCommonStyles(JobPdfStyles);
// Customizable Area End