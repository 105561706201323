import React from "react";
import {
    Box,
    Grid,
    Typography,
    MenuItem, Container, ListItemIcon, FormHelperText
    // Customizable Area Start
    ,Hidden
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import RequestFormController, { Props, configJSON } from "./RequestFormController.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { convertToJob, convertToQuote, downloadPdf } from "./assets"
import { backIconMobile } from "../../CustomisableUserProfiles/src/assets";
import CustomerModel from "./CustomerModal.web";
import moment from "moment";
import InternalNotesAttachements from "./InternalNotesAttachements.web";
import CustomerDetails from "./CustomerDetails.web";
import CreationProcess from "./CreationProcess.web";
import { GoogleMap, Marker } from "@react-google-maps/api";
import ClearIcon from '@material-ui/icons/Clear';
import withRequestStyles from "./RequestStyles.web";
import TimePicker from "../../../components/src/TimePicker";
import { withSnackbar } from "../../../components/src/toastweb";
import { goBack } from "../../../components/src/CommonFunction"
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import CustomTeamMember from "../../../components/src/CustomTeamMember.web"
import { JobsStyledMenu } from "../../JobListing2/src/JobsUtility.web";
import { AntSwitch } from "../../../components/src/CustomSwichStyled.web"
import { StyledCheckbox } from '../../appointmentmanagement/src/Calendar.web'
import { CancelButton, ContainedButton, CustomizedTextField, OutlinedButton, SelectCustomerButton } from "../../../components/src/CustomizableComponents.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme"
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import RequestPdfWeb from "../../saveaspdf/src/RequestPdf.web";
import { dropDownArrow } from "../../ProjectReporting/src/assets";
import { withTranslation } from "react-i18next";
const selectedLanguage =  localStorage.getItem("lang");
// Customizable Area End
// Customizable Area Start

// Customizable Area End

export class RequestForm extends RequestFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    mobileBackIcon = () => {
        return selectedLanguage === "ar" ? {...webStyle.backIconMobile, ...webStyle.transformStyle} : webStyle.backIconMobile
    }
    // Customizable Area End

    render() {

        const { props, handleRequestChange } = this;
        const { classes,t } = props;
        const { dialogOpen } = this.state
        const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
        const { title, serviceDetails, requestSource, appointmentDate, appointmentTime, others } = this.state.requestForm;
        return (
            // Customizable Area Start
            <>
                <DashboardSideBar oldProps={undefined} permissions={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.requests')} active={3} active1={false} active2={false} />
                <StyledRoot languageSelected={this.state.selectLanguage}>
                    <Box sx={{ marginTop: "8rem" }} >
                        <Container maxWidth={false}>
                            <Grid item xs={12} style={{ marginBottom: "25px" }}>
                                <CreationProcess active={0} t={this.props.t} selectLanguage={this.state.selectLanguage}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <Grid container direction="row" justifyContent="flex-start" spacing={2} alignItems="center">
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                    <Grid item xs={12} style={{marginBottom:'10px'}}>
                                                        <Grid item style={webStyle.backIconContainer}>
                                                            <Hidden smUp>
                                                                <img style={this.mobileBackIcon()} src={backIconMobile} onClick={()=>goBack(this.props.navigation)} alt="" />
                                                            </Hidden>
                                                        </Grid>
                                                        <Typography variant="subtitle1"
                                                            className={classes.titleHeading26}>
                                                            {t('request.title')}
                                                            <span style={webStyle.errorText}>*</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <CustomizedTextField
                                                            fullWidth
                                                            variant="outlined"
                                                            onChange={this.handleRequestChange}
                                                            onBlur={this.handleOnBlur}
                                                            name={"title"}
                                                            value={title}
                                                            data-test-id="title"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <ErrorMessage message={this.state.requestForm.title === '' || (this.state.requestForm.title && this.state.requestForm.title.length > 40) ? this.state.isErrorFormData.title : ''} />
                                            </Grid>
                                        </Grid>
                                    </Grid>   
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        {this.state.selectButtonStatus ?
                                            <>
                                                <CustomerDetails
                                                    crudStatus={'create'}
                                                    modeType={`${t('request.requestFor')}`}
                                                    details={this.state.customerDetails.attributes}
                                                    siteDetails={this.state.siteDetails}
                                                    dailogOpen={this.handleOpenDialog}
                                                    clearCustomer={this.clearCustomer}
                                                    t={this.props.t}
                                                    converted={""} />
                                            </> :
                                            <SelectCustomerButton
                                                variant="contained"
                                                onClick={this.handleOpenDialog}
                                            >
                                                {t('invoice.button.selectCus')}
                                            </SelectCustomerButton>
                                        }

                                        <ErrorMessage message={this.state.siteDetails && this.state.customerDetails.attributes ? '' : this.state.isErrorFormData.customerDetails} />
                                        <ErrorMessage message={this.state.isErrorFormData.siteDetails} />
                                    </Grid>
                                    {this.state.selectButtonStatus &&
                                        <Grid item xs={12} md={4} className={classes.mt_50}>
                                            <Grid container direction="row" justifyContent="flex-start" spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.mb_15}`}>{t('invoice.location')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <GoogleMap
                                                        mapContainerStyle={webStyle.selectedCustomerMapStyles}
                                                        center={this.state.defaultLocation}
                                                        zoom={5}
                                                        data-test-id="mapComponent"
                                                        onLoad={(map) => {
                                                            this.onMapLoad(map);
                                                        }}
                                                        options={{
                                                            disableDefaultUI: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            fullscreenControl: false,
                                                            zoomControl:false
                                                        }}
                                                    >
                                                        {this.state.getCustomerPlace && (
                                                            <Marker position={this.state.getCustomerPlace} />
                                                        )}
                                                    </GoogleMap>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Hidden smDown>
                                    <Grid container direction="row" justifyContent="flex-start" spacing={2} 
                                    style={webStyle.serviceBoxTexts}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" className={classes.titleHeading26}>{t('request.serviceText')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.serviceText')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomizedTextField
                                                variant="outlined"
                                                multiline
                                                minRows={3}
                                                maxRows={3}
                                                className={classes.serviceInputCreate}
                                                onChange={handleRequestChange}
                                                name={"serviceDetails"}
                                                value={serviceDetails}
                                                data-test-id="serviceDetailsText"
                                            />
                                            <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>{this.state.isErrorFormData.serviceDetails}</FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>
                                    <Grid item xs={12}>
                                        <Hidden mdUp>
                                            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.marginTop_10}`}>{t('request.serviceText')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.serviceText')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomizedTextField
                                                        variant="outlined"
                                                        multiline
                                                        minRows={3}
                                                        maxRows={3}
                                                        className={classes.serviceInputCreate}
                                                        onChange={handleRequestChange}
                                                        name={"serviceDetails"}
                                                        value={serviceDetails}
                                                        data-test-id="serviceDetailsText"
                                                    />
                                                    <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>
                                                        {this.state.isErrorFormData.serviceDetails}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.marginTop_10}`}>{t('request.source')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.source')}
                                                    <Typography display="inline" variant="caption" color="error">
                                                        {configJSON.subTitles.AsentricSymbol}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomizedTextField
                                                    variant="outlined"
                                                    className={classes.requestInputCreate}
                                                    select
                                                    fullWidth
                                                    onBlur={this.handleSourceOnBlur}
                                                    onChange={handleRequestChange}
                                                    name={"requestSource"}
                                                    value={requestSource}
                                                    data-test-id="requestSource"
                                                >
                                                    {this.state.requestSource.map((item: any, index: number) => {
                                                        return (<MenuItem key={item.id} value={item.sourceValue} style={webStyle.sourceText}>{item.sourceName}</MenuItem>)
                                                    })}

                                                </CustomizedTextField>
                                                <FormHelperText id="standard-weight-helper-text" className={classes.errorText} style={{ display: "flex" }}>{requestSource ? '' : this.state.isErrorFormData.requestSource}</FormHelperText>
                                            </Grid>
                                            {this.state.requestForm.requestSource === "others" && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.others')}
                                                            <Typography display="inline" variant="caption" color="error">
                                                                {configJSON.subTitles.AsentricSymbol}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CustomizedTextField
                                                            fullWidth
                                                            className={classes.requestInputCreate}
                                                            variant="outlined"
                                                            onChange={this.handleRequestChange}
                                                            name={"others"}
                                                            value={others}
                                                            data-test-id="others"
                                                        />
                                                        <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>{this.state.isErrorFormData.others}</FormHelperText>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                               
                        </Container>
                        <Container maxWidth={false}>
                            <Grid item xs={12} md={9} className={classes.gutterTop2}>
                                <Grid container direction="row" justifyContent="flex-start" spacing={3} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" className={classes.titleHeading26}>{t('request.scheduling')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container direction="row" justifyContent="flex-start" spacing={2} alignItems="center">

                                            <Grid item xs={12}>
                                                <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.dateText')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomDatePicker
                                                    selectedDate={appointmentDate}
                                                    data-test-id='changeAppointmentDate'
                                                    dateChange={this.handleDateChange}
                                                />
                                                <FormHelperText id="standard-weight-helper-text" className={classes.errorText} style={{ display: "flex" }}>{this.state.isErrorFormData.appointmentDate}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container direction="row" justifyContent="flex-start" spacing={2} alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.timeText')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TimePicker
                                                    time={appointmentTime}
                                                    data-test-id="change_time"
                                                    onChange={(timeValue: any) => this.handleChangeHours(timeValue)}
                                                    timeFormat={this.state.timeFormat}
                                                    t={t}
                                                    languageSelected={this.state.selectLanguage}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={3}>
                                <Hidden xsDown>
                                    <Grid item xs={12} sm={6}/>
                                    </Hidden>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container style={{marginLeft:"4px"}}>
                                            <StyledCheckbox
                                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                                name='all_day'
                                                data-test-id='allDay'
                                                onChange={this.handleCheckAllDay}
                                                checked={this.state.all_day}
                                            />
                                            <Typography style={{marginTop:"-3px"}} variant="body1" color="textPrimary" className={`${classes.subTitle20} ${classes.ml_10}`} >{t('Calendar.label.allDay')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.mt_2}>
                                    <AntSwitch
                                        onChange={this.handleCheckOnSite}
                                        data-test-id='onSite'
                                        checked={this.state.requestForm.onSite} />
                                    <Grid item className={`${classes.text_align} ${classes.ml_10}`}>
                                        <Typography variant="body1" className={this.state.requestForm.onSite === true ? classes.switchText : classes.switchText1}>{t('request.onSite')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.mt_2}>
                                    <Typography variant="subtitle1" className={classes.titleHeading26}>
                                        {t('Calendar.label.assignTeamMember')}
                                    </Typography>
                                </Grid>
                                <CustomTeamMember
                                    getEmployeeData={this.state.getEmployeeData}
                                    values={this.state.teamMember}
                                    name="employee_id"
                                    data-test-id='teamMember'
                                    selectLanguage={this.state.selectLanguage}
                                    onChange={this.handleChangeTeam}
                                    t={t}
                                    onClick={(employeeId: string)=>this.handleRemoveTeam(employeeId)}
                                />
                            </Grid>
                            <Grid item xs={12} md={9} className={classes.gutterTop3}>
                                <InternalNotesAttachements
                                    data-test-id="internal_notes"
                                    t={this.props.t}
                                    editStatus={true}
                                    updateInternalNotes={this.updateInternalNotes}
                                    languageSelected={this.state.selectLanguage}
                                    updateInternalAttachments={this.updateInternalAttachments} />
                                <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>{this.state.isErrorFormData.internalNotes}</FormHelperText>
                            </Grid>
                            <Grid container direction="row" className={`${classes.flexDirection} ${classes.margin} ${classes.mt_1}`}>
                                <Grid item xs={12} sm={5} md={6} className={classes.mt_1}>
                                    <CancelButton
                                        data-test-id="create_page"
                                        variant="outlined"
                                        onClick={this.cancelCreateRequest}>
                                        <Typography variant='subtitle2' className={classes.buttonText}>{t('invoice.button.cancel')}</Typography>
                                    </CancelButton>
                                </Grid>
                                <Grid item xs={12} sm={7} md={6} className={classes.flexDirection} container direction='row' justifyContent="flex-end">
                                    <OutlinedButton 
                                        className={`${classes.mr_1} ${classes.ml_1}`}
                                        variant="outlined"
                                        data-test-id="save"
                                        onClick={() => this.saveRequestbtn('save')}
                                    >
                                        <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('request.button.saveRequest')}</Typography>
                                    </OutlinedButton>
                                    <ContainedButton
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleButton}
                                        data-test-id="saveMoreInfo"
                                        className={classes.mt_1}
                                    >
                                        <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('request.button.saveAnd')}</Typography>
                                        <img style={
                                            this.state.anchorEl ?
                                                { ...webStyle.dropDownArrow, transform: "rotate(180deg)" } :
                                                webStyle.dropDownArrow
                                            }
                                            src={dropDownArrow} alt=""
                                        />
                                    </ContainedButton>
                                    <JobsStyledMenu
                                        id="customized-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.handleButtonClose}
                                        data-test-id="closeToggle"
                                    >
                                        <div className={classes.saveAndBtn} style={{ padding: '10px' ,marginLeft:"5px"}}>
                                            <div className={classes.saveEndText}>
                                                {t('request.button.saveAnd')}
                                            </div>
                                            <div className={classes.cursor}>
                                                <ClearIcon onClick={this.handleButtonClose} />
                                            </div>
                                        </div>

                                        <MenuItem>
                                            <ListItemIcon style={webStyle.width_35}>
                                                <img src={convertToQuote} width="20px" height="20px" />
                                            </ListItemIcon>
                                            <Typography component={'div'} className={classes.popupBtnText}
                                                data-test-id="saveMoreQuote"
                                                onClick={() => this.saveRequestbtn('saveMoreQuote')}

                                            >{t('request.popupBtn.quote')}</Typography>
                                        </MenuItem>
                                        <MenuItem >
                                            <ListItemIcon style={webStyle.width_35}>
                                                <img src={convertToJob} width="20px" height="20px" />
                                            </ListItemIcon>
                                            <Typography component={'div'} className={classes.popupBtnText} data-test-id="saveMoreJob"
                                                onClick={() => this.saveRequestbtn('saveMoreJob')}

                                            >{t('request.popupBtn.job')}</Typography>
                                        </MenuItem>
                                        <MenuItem>
                                            <ListItemIcon style={webStyle.width_35}>
                                                <img src={downloadPdf} width="20px" height="20px" />
                                            </ListItemIcon>
                                            <Typography component={'div'} data-test-id='saveMorePdf' className={classes.popupBtnText} onClick={() => this.saveRequestbtn('saveMorePdf')}>{t('request.popupBtn.download')}</Typography>
                                        </MenuItem>
                                    </JobsStyledMenu>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </StyledRoot>

                {
                    dialogOpen &&
                    <CustomerModel
                        location={this.props.navigation.history.location} type={""} requestId={""}
                        open={dialogOpen}
                        languageSelected={this.state.selectLanguage}
                        getCustomerDetails={this.getCustomerDetails}
                        getSiteDetails={this.getSiteDetails}
                        selectedCustomerButtonStatus={this.getCustomerDailogStatus1}
                        getCustomerSelectedStatus={this.getCustomerSelectedStatus}
                        customerDetails={this.state.customerDetails?.attributes}
                        siteDetails={this.state.siteDetails}
                        saveMapData={this.saveMapData}
                        t={this.props.t}
                        handleCusValidation={this.handleCusValidation}
                        crudStatus={""} navigation={this.props.navigation} id={""} history={this.props.navigation.history} />
                }
                {
                    this.state.pdfStatus &&
                    <RequestPdfWeb
                        type="Request"
                        requestId={this.state.requestDetails?.id}
                        openPdf={this.state.pdfStatus}
                        closePdf={this.saveMorepdfClose}
                        t={this.props.t}
                    />
                }
                <LoadingBackdrop
                    loading={this.state.requestFormLoading}
                />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    errorText:{
        color: CONSTANTS.color.crimsonRed,
        fontSize: "20px",
        fontWeight: 400,
    },
    selectedCustomerMapStyles: {
        width: "100%",
        minHeight: "320px",
        borderRadius: "10px",
    },
    width_35:{
        minWidth:"35px"
    },
    dropDownArrow: {
        width: "16px",
        height: "8px",
        marginLeft:"15px"
    },
    backIconMobile: {
        width: "12px",
        height: "24px",
      },
      transformStyle: {
        transform: "rotate(180deg)"
      },
      backIconContainer:{
        display:"flex",
        alignItems:"center",
        gap:"1rem",
        marginBottom:"10px"
    },
    sourceText: {
        fontSize: "22px",
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
        color: CONSTANTS.color.lightBrown
    },
    serviceBoxTexts : {
        width:"115%"
    }
}


const RequestStyles = withCommonStyles(RequestForm)
const RequestFormComponent = withSnackbar(RequestStyles);
export default withTranslation()(withRequestStyles(RequestFormComponent))
// Customizable Area End