import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid, Hidden, IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { ProfileStyledButton } from "../../CustomisableUserProfiles/src/utility.web"

import { theme } from "../../../components/src/theme"
import {
  mainLogo,
  sidePage2,
  sidePage1,
  sidePage3,
} from "./assets";
import { goBack } from "../../../components/src/CommonFunction";
import { firstSideImg, mobileArrow } from "../../email-account-login/src/assets";
import { mobileLogo } from "../../LanguageSupport/src/assets";
import { withTranslation } from "react-i18next";

// Customizable Area End

import TermsAndConditionsController, {
  Props,
} from "./TermsAndConditionsController";

export class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {!this.state.termLoading ?
          <Grid container direction="row" alignItems="center" className={classes.pageLayout}>
            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.bgImg} />
            <Grid item xs={12} sm={1} md={2} lg={2} xl={2} />
            <Grid item xs={12} sm={5} md={5} lg={6} xl={6} >
              <Grid container direction="row" justifyContent="center" className={classes.termsLayout}>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Grid item xs={12}>
                  <IconButton
                    onClick={() => goBack(this.props.navigation)}>
                    <img className={classes.mobileArrow} src={mobileArrow}
                      width={12} height={24} alt="mobile arrow" />
                  </IconButton>
                </Grid>
                </Hidden>
                <Grid item xs={12}>
                  <img src={mainLogo} className={classes.logoImgEmp} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.userTitleEmp}>
                      {this.props.t('termsScreen.heading')}

                  </Typography>
                </Grid>
                <Grid item xs={12}className={classes.termsContent}>
                  {this.state.termsData && this.state.termsData.length > 0 && this.state.termsData.map((item: any) => (
                    <Box key={item?.id} id={item?.id}>
                      <Typography
                        style={webStyle.oneTitle}
                        className={classes.oneTitle}
                        variant="h6"
                      >
                        {item?.attributes?.id}. {item?.attributes?.title?.replace(/<p>/g, '').replace(/<\/p>/g, '')}
                      </Typography>
                      <Typography
                        style={webStyle.oneBody}
                        className={classes.oneBody}
                        variant="subtitle2"
                        dangerouslySetInnerHTML={{
                          __html: (
                            item?.attributes?.body
                          )
                        }}
                      ></Typography>
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', marginTop: "20px" }}>
                  <ProfileStyledButton
                    data-test-id="btnAcceptTerms"
                    style={{ marginLeft: "auto" }}
                    className={classes.acceptButton}
                    onClick={this.acceptTerms}
                  >
                    <Typography variant="body1" className={classes.btntext}>
                      {this.props.t('termsScreen.accept')}
                    </Typography>
                  </ProfileStyledButton>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={1} md={1} lg={2} xl={2} className={classes.secondLayoutImage}>
              <img className={classes.secondImage} src={firstSideImg} alt="firstSideImg" />
            </Grid>
          </Grid>
          :
          <LoadingBackdrop
            loading={this.state.termLoading}
          />
        }

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  pageLayout: {
    [theme.breakpoints.up("sm")]: {

      height: "100%"
    }
  },
  userTitleEmp: {
    fontWeight: 600,
    paddingTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30
    }
  },
  logoImgEmp: {
    width: "150px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "120px",
    },
  },
  bgImg: {
    backgroundImage: `url(${sidePage1})`,
    height: "100vh",
    backgroundSize: "100% 100%",
    position: "relative",
    transform: "rotateY(180deg)",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${mobileLogo})`,
      height: '86px',
      transform: "none",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${sidePage1})`,
    },
    [theme.breakpoints.between("sm", "md")]: {
      backgroundImage: `url(${sidePage2})`,
    },
    [theme.breakpoints.between("md", "lg")]: {
      backgroundImage: `url(${sidePage3})`,
    },
  },
  btntext: {
    fontWeight: 400,
    textTransform: "none",
    color: "white",
    textAlign: "center",
  },
  acceptButton: {
    [theme.breakpoints.down("xs")]: {
      width: '100%'
    },
  },
  acceptTextLayout: {
    height: "340px",
    overflowY: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: '100%'
    },
  },
  termsLayout: {
    // display: "flex",
    // justifyContent: "center",
    height: "500px",

    [theme.breakpoints.down("xs")]: {
      padding: '2rem'
    },
  },
  termsContent:{
     height: "340px",
    overflowY: "scroll",
     [theme.breakpoints.down("xs")]: {
      height: "500px"
    },
  },
  secondLayoutImage: {
    display: 'flex',
    alignItems: 'end',
    transform: "rotateY(180deg)",
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      minHeight:'55% !important'
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "100%",
      // width:'100% !important',
      // flexBasis:"100% !important"
      position:'absolute',
      right:0,
      bottom:0
    },
  },
  imageLayout: {
    [theme.breakpoints.up("sm")]: {
      height: '100%'
    },
  },
  secondImage: {
    width: '80px',
    [theme.breakpoints.down("xs")]: {
      width: '47px',
      height: '47px'
    },
  },
  mobileArrow: {

  },
  containerHeight: {
    [theme.breakpoints.down("xs")]: {
      height: '100%'
    },
  }
})
const webStyle = {
  oneTitle: {
    color: "#05037a",
    fontWeight: 600,
    textTransform: "uppercase" as const,
    marginTop: "2rem"
  },
  oneBody: {
    opacity: 0.7,
    color: "#414042",
    fontWeight: 400,
    lineHeight: "40px",
    textAlign: "justify" as const,
    marginTop: "1rem"
  },
};
//@ts-ignore
const Terms= withStyles(styles)(TermsAndConditions)
export default withTranslation()(Terms)
// Customizable Area End
