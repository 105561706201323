import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { checkLoggedInUserDashboard , empvalidateFormDataProfileV1} from "./utility.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  openToastHandler:Function
  t: (key: string) => string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: any;
  id: any;
  errorMsg: string;
  loading: boolean;
  passwordData: {
    oldPassword: any;
    newPassword: any;
    confirmPassword: any;
  };
  formDetails: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    streetAddress: string;
    city: string;
    country: string;
    postcode: string;
    language: string;
  };
  isErrorFormDetails: any;

  enablePasswordFieldOld: boolean;
  enablePasswordFieldNew: boolean;
  enablePasswordFieldConfirm: boolean;
  openLogOut: boolean;
  countryCode: any;
  countryCodeList: any;
  updateProfileLoading: boolean;
  countryCodeLoading: boolean;
  toggleLanguageModal: boolean;
  companyLogo: any;
  convertedFile: any;
  imageType: any;
  successMessage: any;
  errorMessage: any;
  success: boolean;
  error: any;
  profileUpdateSuccessful: boolean;
  profileActive: string;
  logoChangeModal: boolean;
  isHovered: boolean;
  isHovered2: boolean;
  profile_image: any;
  editImage: boolean;
  passErr: any;
  isErrorFormData: any;
  mapLoading: any;
  lang:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  wrapperRef: any;
  updatePasswordCallApiId: any = "";
  fetchProfileDataCallApiId: any = "";
  updateProfileDataCallApiId: any = "";
  deleteProfileImageCallApiId: any = "";
  autocomplete: any;
  inputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.autocomplete = null;
    this.inputRef = React.createRef();
    this.state = {
      mapLoading: false,
      dashboardData: [],
      errorMsg: "",
      profile_image: "",
      token: "",
      id: "",
      loading: false,
      passwordData: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      formDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        streetAddress: "",
        city: "",
        country: "",
        postcode: "",
        language: "",
      },
      editImage: false,
      isErrorFormDetails: {},
      enablePasswordFieldOld: false,
      enablePasswordFieldNew: false,
      enablePasswordFieldConfirm: false,
      openLogOut: true,
      countryCode: "",
      countryCodeList: "",
      updateProfileLoading: false,
      countryCodeLoading: false,
      toggleLanguageModal: false,
      companyLogo: "",
      convertedFile: "",
      imageType: "",
      success: false,
      error: false,
      successMessage: "",
      errorMessage: "",
      profileUpdateSuccessful:false,
      profileActive: "Profile",
      logoChangeModal: false,
      isHovered: false,
      isHovered2: false,
      passErr: {
        oldPassErr: false,
        newPassErr: false,
        confirmPassErr: false,
        oldPassMsg: "",
        newPassMsg: "",
        confirmPassMsg: "",
      },
      isErrorFormData: {},
      lang:"en"
    };
    this.wrapperRef = React.createRef<any>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const token = localStorage.getItem("authToken")
    this.checkAuthTokenProfile(token)
    this.getToken();

    this.setState({ token: await StorageProvider.get("authToken") });
    this.setState({ id: await StorageProvider.get("account_id") });

    const lang = await getStorageData("lang")
    this.setState({ lang })

    this.fetchAccountData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.updatePasswordCallApiId) {
          this.updatePasswordApiResponse(responseJson);

          this.parseApiCatchErrorResponse(errorResponse);
        } else if (apiRequestCallId === this.fetchProfileDataCallApiId) {
          this.fetchProfileDataApiResponse(responseJson);
        } else if (apiRequestCallId === this.updateProfileDataCallApiId) {
          this.updateProfileDataApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteProfileImageCallApiId) {
          this.handleDeleteProfileImageResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkAuthTokenProfile = (token:any)=>{
    if (!token) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }
  }
  //**********chnage  password block************ */
  handleDeleteProfileImageResponse(responseJson: any) {
    if (!responseJson.errors) {
      localStorage.setItem("profileDataFetched", "false");
      this.setState({
        companyLogo: null,
        logoChangeModal: false,
        profile_image: null,
      });
    } else {
      //Check Error Response
      this.handleProfileTokenExpireError(responseJson)
     
    }
  }

  updatePasswordApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      if (!responseJson.error) {
        this.setState(
          { successMessage: "You have successfully changed your password" },
          () => {
            this.setState({
              errorMessage: "",
              success: true,
              error: false,
            });
          }
        );
      } else {
        if (responseJson.error === "Old password is incorrect") {
          this.setState(
            {
              errorMessage:
                "Your old password was entered incorrectly. Please enter it again.",
            },
            () => {
              this.setState({
                success: false,
                successMessage: "",
                error: true,
              });
            }
          );
        } else {
          this.setState({ errorMessage: responseJson.error }, () => {
            this.setState({
              error: true,
              success: false,
              successMessage: "",
            });
          });
        }
      }
    } else {
      //Check Error Response
      this.handleProfileTokenExpireError(responseJson)
     
    }
  }
  fetchProfileDataApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        street_address,
        city,
        post_code,
        language,
        country_code,
        profile_image,
        country_name,
      } = responseJson?.data?.attributes;
      this.setState({
        countryCode: country_code || "",

        formDetails: {
          ...this.state.formDetails,
          firstName: first_name || "",
          lastName: last_name || "",
          email: email || "",
          phone: phone_number || "",
          streetAddress: street_address || "",
          city: city || "",
          country: country_name || "",
          postcode: post_code || "",
          language: language || "",
        },
      });
      this.setState({
        companyLogo: "",
        profile_image: profile_image || "",
      });
    } else {
      //Check Error Response
      this.handleProfileTokenExpireError(responseJson)
     
    }
  }
  updateProfileDataApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        street_address,
        city,
        post_code,
        language,
        country_code,
        profile_image,
        country_name,
      } = responseJson.data.attributes;
      this.setState({
        profileUpdateSuccessful: true,
        formDetails: {
          ...this.state.formDetails,
          firstName: first_name || "",
          lastName: last_name || "",
          email: email || "",
          phone: phone_number || "",
          streetAddress: street_address || "",
          city: city || "",
          country: country_name || "",
          postcode: post_code || "",
          language: language || "",
        },
      },()=>{
         window.location.reload();
      });
      this.setState({
        countryCode: country_code || "",
      });
      this.setState({
        companyLogo: "",
        profile_image: profile_image || "",
      });
      localStorage.setItem("profileDataFetched", "false");
    } else {
      //Check Error Response
      this.handleProfileTokenExpireError(responseJson)
     
    }
  }


  handleProfileTokenExpireError = async (responseJson: any) => {
    if (
      responseJson.errors[0]?.token == "Token has Expired" ||
      responseJson.errors[0]?.token == "Invalid token"
    ) {
      this.props?.openToastHandler(responseJson?.errors[0].token, "error");
      await this.profiletokenExpired();
    } else {
       this.parseApiErrorResponse(responseJson);
    }
  };

  profiletokenExpired = async () => {
    const isEmployee = await checkLoggedInUserDashboard();
    this.profiletokenExpiredCallback(isEmployee);
  };

  profiletokenExpiredCallback = (isEmployee: any) => {
    const redirectUrl = isEmployee ? "/Employee/Login" : "/Login";
    setTimeout(this.profiletokenExpiredTimeCallback.bind(this, redirectUrl), 1000);
  };

  profiletokenExpiredTimeCallback = (url: any) => {
    localStorage.clear();
    this.props.history.push(url);
  };

  updatePassword = () => {
    const header = {
      "Content-Type": configJSON.updatePasswordContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePasswordCallApiId = requestMessage.messageId;
    const httpBody = {
      old_password: this.state.passwordData.oldPassword,
      new_password: this.state.passwordData.newPassword,
      confirm_password: this.state.passwordData.confirmPassword,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatePasswordApiEndPoint}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updatePasswordMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  fetchAccountData = () => {
    const headers = {
      "Content-Type": configJSON.fetchProfileDataContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchProfileDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchProfileDataApiEndPoint}/${this.state.id}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fetchProfileDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfileData = () => {
    const header = {
      "Content-Type": configJSON.updateProfileDataContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileDataCallApiId = requestMessage.messageId;

    const attrs = {
      first_name: String(this.state.formDetails.firstName).trim(),
      last_name: String(this.state.formDetails.lastName).trim(),
      email: String(this.state.formDetails.email).trim(),
      full_phone_number:
        this.state.countryCode + " " + String(this.state.formDetails.phone).trim(),
      country_name: String(this.state.formDetails.country).trim(),
      city: String(this.state.formDetails.city).trim(),
      language: String(this.state.formDetails.language).trim(),
      post_code: String(this.state.formDetails.postcode).trim(),
      street_address: String(this.state.formDetails.streetAddress).trim(),
      country_code: this.state.countryCode,
      phone_number: String(this.state.formDetails.phone).trim(),
    };
    const getImageType = this.findImageType();

    const data = {
      type: "email_account",
      attributes: attrs,
      profile_image: getImageType,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateProfileDataApiEndPoint}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleDeleteUploadedLogo = () => {
    const header = {
      "Content-Type": configJSON.deleteProfileImageContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProfileImageCallApiId = requestMessage.messageId;

    const httpBody = {
      data: {},
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProfileImageApiEndPoint}${this.state.id}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteProfileImageMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  findImageType = () => {
    return this.state.imageType === "image/png"
      ? this.state.convertedFile.replace("data:image/png;base64,", "")
      : this.findJpgImageType();
  };

  findJpgImageType = () => {
    return this.state.imageType === "image/jpg"
      ? this.state.convertedFile.replace("data:image/jpg;base64,")
      : this.state.convertedFile.replace("data:image/jpeg;base64,", "");
  };

  profilevalidatePassword = (value: string, errors: any, name: any) => {
    const {
      atleastOneCapsRegex,
      atleastLowerCaseRegex,
      digitsRegex,
      specialCharRegex,
    } = configJSON.passwordValidators;
    if (!atleastOneCapsRegex.test(value)) {
      errors[name] = "Atleast one upper case is required"!;
    } else if (!atleastLowerCaseRegex.test(value)) {
      errors[name] = "Atleast one lower case is required"!;
    } else if (!specialCharRegex.test(value)) {
      errors[name] = "Atleast one special character is required"!;
    } else if (!digitsRegex.test(value)) {
      errors[name] = "Atleast one number is required"!;
    } else if (value.length < 8) {
      errors[name] = "Password length must be 8 characters";
    }
  };

  profilevalidateBothPasswords = (errors: any) => {
    let { confirmPassword, newPassword, oldPassword } = this.state.passwordData;
    if (oldPassword === newPassword) {
      return this.setState({
        error: true,
        success: false,
        successMessage: "",
        errorMessage: "Create a New Password that isn't your Current Password",
      });
    } else if (newPassword !== confirmPassword) {
      errors.commonError = "New Password and Confirm Password should be same!";
    }
    this.setState({ isErrorFormData: errors }, () => {
      if (Object.keys(this.state.isErrorFormData).length === 0) {
        this.updatePassword();
      }
    });
  };

  profileResetPasswordSubmit = () => {
    const errors: any = {};
    let { newPassword, confirmPassword } = this.state.passwordData;
    this.profilevalidatePassword(newPassword, errors, "newPassword");
    this.profilevalidatePassword(confirmPassword, errors, "confirmPassword");
    this.profilevalidateBothPasswords(errors);
  };

  setPassword = (e: any) => {
    const { name, value } = e.target;
    const errors: any = {};
    name === "newPassword" &&
      this.profilevalidatePassword(value, errors, "newPassword");
    name === "confirmPassword" &&
      this.profilevalidatePassword(value, errors, "confirmPassword");
    this.setState({
      passwordData: {
        ...this.state.passwordData,
        [e.target.name]: e.target.value,
      },
      errorMessage: "",
      success: false,
      successMessage: "",

      isErrorFormData: errors,
      error: false,
    });
  };

  handleClickShowPasswordOld = () => {
    this.setState({
      enablePasswordFieldOld: !this.state.enablePasswordFieldOld,
    });
  };
  handleClickShowPasswordNew = () => {
    this.setState({
      enablePasswordFieldNew: !this.state.enablePasswordFieldNew,
    });
  };
  handleClickShowPasswordConfirm = () => {
    this.setState({
      enablePasswordFieldConfirm: !this.state.enablePasswordFieldConfirm,
    });
  };

  checkEnableNew = (check: any) => {
    if (check) {
      return "text";
    } else {
      return "password";
    }
  };

  //******Logout module functions ********/

  handleLogoutDialog = () => {
    this.setState({
      openLogOut: !this.state.openLogOut,
    });
  };
  handleLogout = () => {
    this.handleLogoutDialog();
    localStorage.clear()
    this.props.navigation.navigate("Login");
  };
  handleCancelLogout = () => {
    this.handleLogoutDialog();
    this.props.navigation.navigate("Profile");
  };
  /**************************/
  /************profile functions**************/
  handleFileCallback = (reader: any) => {
    this.setState({ convertedFile: reader.result, logoChangeModal: false });
  };

  handleFile = (e: any) => {
    this.setState({ companyLogo: e.target.files[0], profile_image: "" });
    let readFile = e.target.files[0];
    this.setState({ imageType: readFile?.type });

    const reader = new FileReader();
    reader.addEventListener("load", this.handleFileCallback.bind(this, reader));
    if (readFile) {
      reader.readAsDataURL(readFile);
    }
  };
  handleSuccessModal = () => {
    this.setState({ profileUpdateSuccessful: false });
  };

  handleChangeFormDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleLanguageModalOpen = () => {
    this.setState({ toggleLanguageModal: !this.state.toggleLanguageModal });
  };

  handleChangeCountryCode = (event: any, value: any) => {
    this.setState({ countryCode: value });
  };

  handleLanguageDropdown = (name: any) => {
    this.setState({
      formDetails: { ...this.state.formDetails, language: name },
    });
    this.setState({ toggleLanguageModal: false });
  };

  handleUpdateProfileCall = () => {
    if (Object.keys(this.state.isErrorFormDetails).length === 0) {
      this.updateProfileData();
    }
  };

  handleDetailsSubmit = () => {
    const language = this.state.formDetails.language
    this.setState(
      {
        isErrorFormDetails: empvalidateFormDataProfileV1(
          this.state.formDetails,this.props.t
        ),
      },() => {
        if (language === 'Arabic') { localStorage.setItem("lang", 'ar')
        } 
        else {
          localStorage.setItem("lang", 'en');
        }
        this.handleUpdateProfileCall()
      }
    );
  };

  handleClickEditIcon = () => {
    this.setState({
      logoChangeModal: !this.state.logoChangeModal,
      editImage: true,
    });
  };

  handleMouseEnterChangeLogo = () => {
    this.setState({
      isHovered: true,
    });
  };

  handleMouseLeaveChangeLogo = () => {
    this.setState({
      isHovered: false,
    });
  };

  handleMouseEnterDeleteLogo = () => {
    this.setState({
      isHovered2: true,
    });
  };

  handleMouseLeaveDeleteLogo = () => {
    this.setState({
      isHovered2: false,
    });
  };

  //autocomplete address fuction start
  onLoadProfile = (autocompleteV1: any) => {
    this.autocomplete = autocompleteV1;
    this.setState({ mapLoading: true });
  };

  handleGoogleFormDataProfile = (
    cityV1: string,
    zipcodeV1: any,
    countryV1: any,
    formattedAddressV1: string
  ) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,

        postcode: zipcodeV1,
        city: cityV1,
        streetAddress: formattedAddressV1,
        country: countryV1,
      },
    });
  };

  handleGeocodeCallbackProfile = (
    addressComponentsV1: any,
    formattedAddressV1: string
  ) => {
    let zipcodeV2 = "";
    let cityV2 = "";
    let countryV2 = "";
    addressComponentsV1.forEach((componentV2: any) => {
      if (componentV2.types.includes("postal_code")) {
        zipcodeV2 = componentV2.long_name;
      } else if (componentV2.types.includes("locality")) {
        cityV2 = componentV2.long_name;
      } else if (componentV2.types.includes("country")) {
        countryV2 = componentV2.long_name;
      }
    });
    this.handleGoogleFormDataProfile(
      cityV2,
      zipcodeV2,
      countryV2,
      formattedAddressV1
    );
  };
  handleGeocodeCallProfile = async (placeV1: any) => {
    const addressComponentsV2 = placeV1.address_components;
    const formattedAddressV2 = placeV1.formatted_address;
   await this.handleAdressGeocode(addressComponentsV2,formattedAddressV2,placeV1)
  };

  handleAdressGeocode = async (
    addressComponentsV2: any,
    formattedAddressV2: any,
    placeV1: any
  ) => {
    if (addressComponentsV2 && formattedAddressV2) {
      this.handleGeocodeCallbackProfile(
        addressComponentsV2,
        formattedAddressV2
      );
    } else {
      const placeV2 = this.autocomplete?.getPlace();
      const geocoderV2 = new window.google.maps.Geocoder();

      await geocoderV2.geocode(
        { placeId: placeV2 ? placeV2.place_id : placeV1.place_id },
        (results: any, status: any) => {
          this.handleGeocodeResponseProfile(status, results);
        }
      );
    }
  };

  handleGeocodeResponseProfile = (statusV1: string, resultsV1: any) => {
    if (statusV1 === "OK") {
      const addressComponentsV3 = resultsV1[0].address_components;
      const formattedAddressV3 = resultsV1[0].formatted_address;

      let zipcodeV3 = "";
      let cityV3 = "";
      let countryV3 = "";
      addressComponentsV3.forEach((componentV1: any) => {
        if (componentV1.types.includes("postal_code")) {
          zipcodeV3 = componentV1.long_name;
        } else if (componentV1.types.includes("locality")) {
          cityV3 = componentV1.long_name;
        } else if (componentV1.types.includes("country")) {
          countryV3 = componentV1.long_name;
        }
      });
      this.handleGoogleFormDataProfile(
        cityV3,
        zipcodeV3,
        countryV3,
        formattedAddressV3
      );
    }
  };

  fetchAddressProfile = async (placeV1: any) => {
    await this.handleGeocodeCallProfile(placeV1);
  };
  handleKeyPressProfile = async (eventV1: any) => {
    if (eventV1.key === "Enter") {
      const inputValue = eventV1.target.value;

      const geocoderV1 = new window.google.maps.Geocoder();

      await geocoderV1.geocode({ address: inputValue }, (results, status) => {
        this.handleKeyPressCallback(status, results);
      });
    }
  };
  handleKeyPressCallback = (statusV1: any, resultsV1: any) => {
    if (statusV1 === "OK" && resultsV1 && resultsV1.length > 0) {
      const placeV1 = resultsV1[0];
      this.fetchAddressProfile(placeV1);
    }
  };
  onPlaceChangedProfile = async () => {
    this.onPlaceChangedCallback(this.autocomplete);
  };
  onPlaceChangedCallback = (autoComplete: any) => {
    if (autoComplete != null) {
      const place = autoComplete?.getPlace();
      this.fetchAddressProfile(place);
    }
  };
  //autocomplete address fuction end


  // Customizable Area End
}
