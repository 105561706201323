import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import {WithStyles} from "@material-ui/core"
import { ValueType, ActionMeta } from "react-select";
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/CommonFunction";
export interface TaxRateResponse {
  id: number;
  company_information_id: number;
  name: string;
  rate: number;
  description: string;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler: Function;
  history: any;
  location: any;
  isEditable:any;
  openNextModal:any;
  priceSectionData:any;
  calculateTotal:any;
  handlePriceSection:any
  productServices:any
  oldProps: any;
  data:any
  collectedPayment:any;
  collectedPaymentForPrice:any
  handlePaymentModalOpenFromPrice?:any;
  handlePaymentModalCloseFromPrice?:any;
  getSubTotal:any;
  onClose:any
  invoicePayment:any;
  handleChange:any;
  handleChangeCurrencyRef:any;
  handleChangePaymentMethodRef:any;
  collectedPaymentForm:{
    payment_method:string
    amount:string
    details:string
    pay_balance:boolean
    collected:boolean
  };
  handleCollectionPaymentModal:any;
  handleCheckBalance:any
  handleCollectionPaymentClose:any;
  handlePaymentModalClose:any
  discountDialog: any,
  handleDiscountDialogOpen:any
  handleCollectionPaymentSave:any;
  currency:string
  selectCurrency: (((value: ValueType<{ value: string; label: string; }, false>, actionMeta: ActionMeta<{ value: string; label: string; }>) => void) & ((value: ValueType<{ value: string; label: string; }, false>, action: ActionMeta<{ value: string; label: string; }>) => void)) | undefined
  selectedCurrency:string
  handleTransactionDate:Date
  selectedLang:string,
  selectTaxRate:Function
  emptyPriceSectionData:Function
  handlePay_balence: Function,
  handleCollected: Function
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  deleteDialogOpen: boolean;
  taxDialog: boolean;
  depositDialog: boolean;
  currency: string;
  discount: any;
  taxFormData: {
    taxName: any;
    taxRate: any;
  };
  data: any;
  isEdit: boolean;
  total: any;
  anchorId:string,
  anchorEl: Element | null;
  taxAnchorId:string,
  taxAnchorEl: Element | null;
  taxRates:TaxRateResponse[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PriceSectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wrapperRef: any;
  getTaxListsApiCallId: string = "";
  createTaxRateApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      taxRates:[],
      taxAnchorEl:null,
      taxAnchorId:"",
      anchorEl:null,
      anchorId:"",
      deleteDialogOpen: false,
      taxDialog: false,
      depositDialog: false,
      currency: "£",
      discount: null,
      taxFormData: {
        taxName: "",
        taxRate: null
      },
      data: {},
      isEdit: false,
      total: null,
    };
    this.wrapperRef = React.createRef<any>();

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getTaxListsApiCallId) {
        this.handleTaxRateResponse(responseJson);
      } if (apiRequestCallId === this.createTaxRateApiCallId) {
        this.createTaxRateResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getTaxRatesApi();
    // Customizable Area End
  }
  handleTaxRateResponse = (responseJson: TaxRateResponse[]) => {
    if (responseJson && responseJson.length > 0) {
      this.setState({ taxRates: responseJson })
    } else {
      this.setState({ taxRates: [] })
    }
  }
  createTaxRateResponse = (responseJson: { errors: string; }) => {
    if (responseJson && !responseJson.errors) {
      this.getTaxRatesApi()
    } 
  }
  handlePriceSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue = Math.max(0, Number(value));
    const subtotal = this.props.getSubTotal(this.props.data);
    let discountValue;
    if (this.props.selectedCurrency === '%') {
      discountValue = Math.min(numericValue, 100);
    } else {
      discountValue = Math.min(numericValue, subtotal);
    }
    this.props.handlePriceSection({
      target: {
        name,
        value: discountValue.toString()
      } as HTMLInputElement
    } as React.ChangeEvent<HTMLInputElement>);
  };
  getTaxRatesApi =async() => {
    const token = await getStorageData(configJSON.storageToken)
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };
    // this.setState({ getQuotesLoading: true })
    const apiEndPoint = configJSON.getTaxRates

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTaxListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),apiEndPoint);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
  createTaxRate = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    let createTaxRateBody = new FormData();
    createTaxRateBody.append("data[name]", this.props.priceSectionData.tax_name);
    createTaxRateBody.append("data[rate]",this.props.priceSectionData.tax );

    const createTaxRateApiCall = apiCall({
      header: header,
      httpBody: createTaxRateBody,
      url: `${configJSON.getTaxRates}`,
      httpMethod: configJSON.exampleAPiMethod
    });
    this.createTaxRateApiCallId = createTaxRateApiCall.messageId;
    runEngine.sendMessage(createTaxRateApiCall.id, createTaxRateApiCall);
  }
  handleClickOpenDialog = () => {
    if(this.props.isEditable){
      this.setState({ deleteDialogOpen: true });
    }
  };

  handleCloseDialog = () => {
    this.setState({ deleteDialogOpen: false });
    if (this.props.collectedPaymentForm) {
      this.props.collectedPaymentForm.payment_method = '';
      this.props.collectedPaymentForm.amount = '';
      this.props.collectedPaymentForm.details = '';
      this.props.collectedPaymentForm.pay_balance = false;
      this.props.collectedPaymentForm.collected = false;
    }
  };

  handleSaveCollectedPayment = () => {
    this.setState({ deleteDialogOpen: false });
  };
  handleCreateTaxRate = () => {
    this.setState({ taxDialog: false },()=>{
      this.createTaxRate()
    });
  }
  preventMinus = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === '-') {
        e.preventDefault();
    }
  };
  handleTaxDialogOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.isEditable) {
      this.setState({
        taxDialog: true, 
        taxAnchorEl: event&&event.currentTarget,
        taxAnchorId: "secondTax-popover",
      });
      this.setState({ depositDialog: false });
      this.props.emptyPriceSectionData("tax")
    }
  }

  handleCancelTaxDialog = () => {
    this.setState({ taxDialog: false });
    this.setState({
      taxFormData: {
        ...this.state.taxFormData,
        taxName: this.state.data.taxName,
        taxRate: this.state.data.taxRate,
      },
      taxAnchorEl: null,
      taxAnchorId: "",
    });
    this.props.emptyPriceSectionData("tax")
  }
  openTaxPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.isEditable) {
      this.setState({
        anchorEl: event.currentTarget,
        anchorId: "tax-popover",
      })
    }
  };
  closeTaxPopover = () => {
    this.setState({
      anchorEl: null,
      anchorId: "",
    })
  }
 
}
// Customizable Area End
