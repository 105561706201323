import React, { Component } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  ClickAwayListener
} from '@material-ui/core';
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  imagebutton_left,
  imagebutton_right,
  dropDownSelectIcon,
  calendarIcon
} from "../../blocks/dashboard/src/assets";
import DatePicker, { registerLocale } from "react-datepicker";
import { ar } from "date-fns/locale";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "../../blocks/dashboard/src/ReactDatePicker.css";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CONSTANTS } from "./theme";
import { withTranslation } from "react-i18next";

const datePickerStyle = (selectedLanguage: string) => {
  return selectedLanguage === "ar" ? {...webStyle.datePickerContainer, transform: "translate(-152px, 252px)"} : webStyle.datePickerContainer
}

const StyledTextFieldStatus = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "56px",
      fontSize: "1.1667rem",
      color: CONSTANTS.color.grey,
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      cursor: "pointer",
      lineHeight: "16px",
      paddingRight:"14px",
      paddingLeft:"5px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      }
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1c18af",
      borderWidth: "2px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "#1c18af",
    },
    "& .MuiSvgIcon-root": {
      marginTop: "-5px",
      fontSize: "2.1rem",
    }
  }
}))(TextField)

interface Props {
  languageSelected: string;
  classes: ClassNameMap;
  status: string;
  selectedDate: {
    startDate: Date;
    endDate: null| Date;
  }
  handleSelectStatus: Function;
  handleDateModalOpen: () => void;
  handleStatusModalClose: () => void;
  dateModal: boolean;
  handleDateChange: any;
  datePickerRef: React.RefObject<DatePicker>;
  t: (key: string) => string;
  type?:string
}

class CustomDatePicker extends Component<Props> {
  
  render() {
    const {
      selectedDate,
      classes,
      status,
      handleDateModalOpen,
      handleStatusModalClose,
      handleSelectStatus,
      handleDateChange,
      datePickerRef,
      languageSelected,
      t,
      type
    } = this.props;
    const { startDate, endDate } = selectedDate;
    if (languageSelected === 'ar') {
      registerLocale("ar", ar);
      moment.locale('ar');
    } else {
      moment.locale('en');
    }

    const formatDates = (date: Date|null) => {
      return moment(date).format("MMM DD, YYYY");
    };
    
    const formattedStartDate = formatDates(startDate);
    const formattedEndDate = formatDates(endDate);

    const mockDateStatus = type ? [
      { id: 1, title: t('dashboard.today'),value:'today' },
      { id: 2, title: t('dashboard.thisWeek'),value:'this_week' },
      { id: 3, title: t('dashboard.thisMonth'),value:'this_month' },
      { id: 5, title: t('dashboard.custom'),value:'custom' },
    ] :[
      { id: 1, title: t('dashboard.today') ,value:'today'},
      { id: 2, title: t('dashboard.thisWeek'),value:'this_week' },
      { id: 3, title: t('dashboard.thisMonth') ,value:'this_month'},
      { id: 4, title: t('dashboard.thisYear'),value:'this_year' },
      { id: 5, title: t('dashboard.custom'),value:'custom' },
    ]
    const textFieldStyled = () =>{
      if(languageSelected==='ar'){
        if(type){
          return classes.textfieldStyleAr
        }else{
          return classes.textfieldStyleAr,classes.textFieldWidth
        }
      } else if (type==="EMP" || !type) {
        return classes.textFieldWidth
      }else{
        return classes.textfieldStyleEn,classes.textFieldWidth1  
      }
    }
    return (
      <Box className="child-component">
        <StyledTextFieldStatus
          className={textFieldStyled()}
          fullWidth
          variant="outlined"
          data-testid="openDatePicker"
          type={type}
          value={status}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <img
                  style={
                    !this.props.dateModal ?
                    webStyle.dropDownSelectIcon :
                    { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }
                  }
                  src={dropDownSelectIcon}
                  alt=""
                />
              </InputAdornment>
            ),
          }}
          onClick={handleDateModalOpen}
        ></StyledTextFieldStatus>
        {this.props.dateModal ? (
          <ClickAwayListener onClickAway={handleStatusModalClose}>
            <Typography>
              <Box style={webStyle.statusModalContainer}>
                <Box style={webStyle.statusModal}>
                  {mockDateStatus.map((item) => (
                    <MenuItem
                      style={
                        status === item.title ?
                        { ...webStyle.menuItemStyle, backgroundColor: CONSTANTS.color.cement } :
                        webStyle.menuItemStyle
                      }
                      className={classes.menuItemStyle}
                      key={item.id}
                      value={item.title}
                      id={`selectStatus_${item.id}`}
                      data-testid={`selectStatus_${item.id}`}
                      onClick={() => handleSelectStatus(item)}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Box>
              </Box>
            </Typography>
          </ClickAwayListener>
        ) : null}
        {(status === t('dashboard.custom') && this.props.dateModal) &&
          <>
            <Box
              style={this.props.languageSelected === "ar" ?
                {...webStyle.startEndDateContainer, transform: "translate(-152px, 167px)"} :
                webStyle.startEndDateContainer
              }
              className={this.props.languageSelected === "ar" ? classes.startEndDateContainerAr : classes.startEndDateContainer}
            >
              <Box style={webStyle.statEndDateInnerContainer}>
                <Typography style={webStyle.startDateText} variant="subtitle2">
                  {t('dashboard.startDate')}
                </Typography>
                <Box style={webStyle.startDateTextfield}>
                  <Typography style={webStyle.startDateStyle} variant="subtitle2">
                    {formattedStartDate}
                  </Typography>
                  <img src={calendarIcon} style={webStyle.calendarIcon} alt="" />
                </Box>
              </Box>
              <Box style={webStyle.statEndDateInnerContainer}>
                <Typography style={webStyle.startDateText} variant="subtitle2">
                  {t('dashboard.endDate')}
                </Typography>
                <Box style={webStyle.startDateTextfield}>
                  <Typography style={webStyle.startDateStyle} variant="subtitle2">
                    {endDate === null ? t('dashboard.select') : formattedEndDate}
                  </Typography>
                  <img src={calendarIcon} style={webStyle.calendarIcon} alt="" />
                </Box>
              </Box>
            </Box>
            <div
              style={datePickerStyle(languageSelected)}
              className={
                languageSelected === "ar" ?
                `${classes.datePickerContainer} ${classes.datePicker}` :
                `${classes.datePickerContainerMobile} ${classes.datePicker}`
              }
            >
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                ref={datePickerRef}
                startDate={startDate}
                endDate={endDate}
                inline
                selectsRange
                locale={languageSelected}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                }) => (
                  <>
                    <div style={webStyle.customHeader}>
                      <span style={webStyle.textFormate}>{moment(date).format("MMMM YYYY")}</span>
                      <img
                        src={this.props.languageSelected === "ar" ? imagebutton_right : imagebutton_left}
                        style={webStyle.imageButton}
                        onClick={decreaseMonth}
                        alt=""
                      />
                      <img
                        src={this.props.languageSelected === "ar" ? imagebutton_left : imagebutton_right}
                        style={webStyle.imageButton}
                        onClick={increaseMonth}
                        alt=""
                      />
                    </div>
                    <span style={webStyle.dateHr}></span>
                  </>
                )}
                renderDayContents={(_, date) => {
                  return <span>{moment(date).format("DD")}</span>;
                }}
              />
            </div>
          </>
        }
      </Box>
    );
  }
}

export const styles = (theme: Theme) => ({
  textfieldStyleEn: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingLeft:"0px"
    },
  },
  textFieldWidth:{
    maxWidth:"150px",
    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      padding: "15px 0 15px 14px",
    },
  },
  textFieldWidth1:{
    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      padding: "15px 0 15px 14px",
    },
  },
  datePickerContainer: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-50px, 363px) !important"
    }
  },
  datePickerContainerMobile: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(51px, 363px) !important"
    }
  },
  startEndDateContainer: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(51px, 278px) !important",
    }
  },
  startEndDateContainerAr: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-48px, 278px) !important",
    }
  },
  datePicker: {
    padding: "1rem 0",
    backgroundColor: "#fff",
    border: 0,
    maxWidth: "100%",
    width: "274px",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.12)",
    display: "flex",
    justifyContent: "center",
  }
});

const webStyle = {
  datePickerContainer: {
    position: "absolute" as const,
    transform: "translate(152px, 252px)",
    zIndex: 1
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer"
  },
  imageButton: {
    height: "25px",
    width: "25px",
    cursor: "pointer"
  },
  statEndDateInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  startDateText: {
    color: CONSTANTS.color.lightGrey,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  startDateStyle: {
    color: CONSTANTS.color.textLightGrey,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: 0
  },
  startDateTextfield: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    width: "121px",
    minHeight: "30px",
    borderRadius: "4px",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "4px"
  },
  calendarIcon: {
    height: "14px",
    width: "14px",
    cursor: "pointer",
  },
  startEndDateContainer: {
    display: "flex",
    position: "absolute" as const,
    width: '100%',
    maxWidth: "274px",
    height: "80px",
    borderRadius: "5px",
    transform: "translate(152px, 167px)",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    padding: "0.8rem",
    zIndex:1,
    justifyContent: "space-between",
  },
  customHeader: {
    margin: "0px 6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textFormate: {
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    color: CONSTANTS.color.darkBlue
  },
  dateHr: {
    display: 'flex',
    height: "1px",
    margin: "10px -18px",
    backgroundColor: CONSTANTS.color.darkBlue
  },
  statusModalContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "100%",
    maxWidth: "150px",
    minHeight: "102px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  statusModal: {
    padding: "1rem 0px",
    position: "relative" as const,
  },
  menuItemStyle: {
    color: CONSTANTS.color.grey,
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
}

export default withTranslation()(withStyles(styles)(CustomDatePicker));
