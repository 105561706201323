import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
export const configJSON = require("./config");

// Customizable Area Start
import {
  radioButton,
  radioButtonOff,
  radioSelected,
  radioUnSelected,
} from "../assets/assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkLoggedInUser } from "./utility.web";
import { apiCall,debounce } from "../../../components/src/CommonFunction";

interface Data {
  id?: number;
  title?: string;
  enable?: boolean;
  category?: string;
  actions?: string[];
}

interface StaffPermission {
  id: number;
  attributes: {
    category: string;
    action: string;
    active: boolean;
  }
}

interface ToggleDataTypes {
  id: number;
  title: string;
  hidden: boolean;
}

interface SelectedOptions {
  id?: number;
  title?: string;
  action?: string;
  category?: string;
  radioSelected?: string;
  radioUnSelected?: string;
  fields?: {
    id?: number;
    label?: string;
  }[];
  radioEnable?: string;
  radioDisable?: string;
  enable?: boolean;
}

interface BillingFormData {
  id: string;
  name: string;
  hourly_rate: string;
  tax_rate: string;
}

interface ErrorFormData {
  fullName?: string;
  email?: string;
  phone?: string;
}

interface ErrorBillingFormData {
  name?: string;
  hourly_rate?: string;
  tax_rate?: string;
}

export interface Customer {
  id: string;
  type: string;
  attributes: Attributes;
}
interface CustomerStatusData {
  title:string,
  id:number,
  statusName:string
}
interface Attributes {
  email: string;
  name: string;
  phone_number: string;
  employee_id: number | null;
  account_id: number;
  notes: string;
  status: string;
  job_follow_up: boolean;
  quote_follow_up: boolean;
  created_at: string;
  invoice_follow_up: boolean;
  updated_at: string;
  files: File[];
}
interface ToggleDataProp {
  id:number,
  title:string
  hidden:boolean,
}
export interface MocktableRow {
  id:number,
  key:string,
  title:string
}

interface BillingRateData {
  id: number;
  type?: string;
  active: boolean;
  hourly_rate: string | null;
  attributes: {
    active: boolean;
    name: string;
  }
}


const data: Data[] = []

const mockToggleDataEmployees = [
  { id: 1, title: configJSON.employeeName, hidden: true },
  { id: 2, title: configJSON.phoneNumberLabel , hidden: true },
  { id: 3, title: configJSON.emailAddressLabel, hidden: true },
  { id: 4, title: configJSON.defaultBillingRateLabel, hidden: true },
]

const mockToggleDataEmployeesAr = [
  { id: 1, title: "اسم الموظف", hidden: true },
  { id: 2, title: "رقم التليفون" , hidden: true },
  { id: 3, title: "عنوان البريد الإلكتروني", hidden: true },
  { id: 4, title: "معدل الفواتير الافتراضي", hidden: true },
]
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: { pathname: string };
  text: string;
  openToastHandler: Function;
  history: any;
  oldProps: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusModal: boolean;
  toggleModal: boolean;
  sortDirection: string;
  sortColumn: string;
  toggleDataEmployees: ToggleDataTypes[];
  status: string;
  mockStaffPermissionData: Data[];
  showText: boolean;
  showTextPayRate: boolean;
  billingRateDialogOpen: boolean;
  isEdit: boolean;
  deleteDialogOpen: boolean;
  selectedOptions: SelectedOptions[];
  formData: {
    id: string;
    fullName: string;
    email: string;
    phone: string;
  };
  emailEdit: string;
  countryCode: string;
  countryCodeEdit: string;
  emailAddressAsUsername: boolean;
  resendEmailInvitation: boolean;
  defaultBillingRate: string;
  hourlyRate: string;
  isErrorFormData: ErrorFormData;
  isErrorBillingFormData: ErrorBillingFormData;
  employeeProfile: File | null;
  deletedImageId: string | null;
  employeeProfileEdit: string | null;
  employeeProfileImage: { id?: string; employee_image?: string };
  employeeProfileEditLocal: File | null;
  employeeProfileUploaded: boolean;
  billingFormData: {
    name: string;
    hourly_rate: string | null;
    tax_rate: string | null;
  };
  billingRateData: any;
  filteredBillingRateData: BillingRateData[];
  billingRateModal: boolean;
  billingDeleteDialogOpen: boolean;
  employeesData: any;
  createEmployeeLoading: boolean;
  getAllEmployeesLoading: boolean;
  retrieveAllPermissionsLoading: boolean;
  showEmployeeLoading: boolean;
  createNewBillingRateLoading: boolean;
  getEmployeeAllBillingRatesLoading: boolean;
  showSingleBillingRatesLoading: boolean;
  deleteEmployeeBillingRatesLoading: boolean;
  updateNewBillingRateLoading: boolean;
  deleteEmployeeLoading: boolean;
  updateEmployeeLoading: boolean;
  showEmployeeData: {
    attributes?: {
      full_name?: string;
    }
  };
  searchEmployee: string;
  billingRateId: string | null | number;
  profileChangeModal: boolean;
  isHovered: boolean;
  isHovered2: boolean;
  languageSelected: string;
  checkedColumn: number[],
  checkedItems:string[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createEmployeeApiCallId: string = "";
  getAllEmployeesApiCallId: string = "";
  deleteEmployeeApiCallId: string = "";
  showEmployeeApiCallId: string = "";
  searchEmployeeApiCallId: string = "";
  updateEmployeeApiCallId: string = "";
  retrieveAllPermissionsApiCallId: string = "";
  getEmployeeAllBillingRatesApiCallId: string = "";
  createNewBillingRateApiCallId: string = "";
  showSingleBillingRateApiCallId: string = "";
  deleteEmployeeBillingRateApiCallId: string = "";
  editEmployeeBillingRateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      statusModal: false,
      toggleModal: false,
      sortDirection: "asc",
      sortColumn: "",
      toggleDataEmployees: mockToggleDataEmployees,
      status: "all",
      mockStaffPermissionData: data,
      showText: false,
      showTextPayRate: false,
      billingRateDialogOpen: false,
      isEdit: false,
      deleteDialogOpen: false,
      selectedOptions: [],
      formData: {
        id: "",
        fullName: "",
        email: "",
        phone: "",
      },
      emailEdit: "",
      countryCode: "",
      countryCodeEdit: "",
      emailAddressAsUsername: false,
      resendEmailInvitation: false,
      defaultBillingRate: "",
      hourlyRate: "",
      isErrorFormData: {},
      isErrorBillingFormData: {},
      employeeProfile: null,
      deletedImageId: null,
      employeeProfileEdit: null,
      employeeProfileImage: {},
      employeeProfileEditLocal: null,
      employeeProfileUploaded: false,
      billingFormData: {
        name: "",
        hourly_rate: null,
        tax_rate: null
      },
      billingRateData: [],
      filteredBillingRateData: [],
      billingRateModal: false,
      billingDeleteDialogOpen: false,
      employeesData: [],
      createEmployeeLoading: false,
      getAllEmployeesLoading: false,
      retrieveAllPermissionsLoading: false,
      showEmployeeLoading: false,
      createNewBillingRateLoading: false,
      getEmployeeAllBillingRatesLoading: false,
      showSingleBillingRatesLoading: false,
      deleteEmployeeBillingRatesLoading: false,
      updateNewBillingRateLoading: false,
      deleteEmployeeLoading: false,
      updateEmployeeLoading: false,
      showEmployeeData: {},
      searchEmployee: "",
      billingRateId: null,
      profileChangeModal: false,
      isHovered: false,
      isHovered2: false,
      languageSelected: "",
      checkedColumn:[1,2,3,4],
      checkedItems:['all','active','archived']
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getAllListSearchEmployees();
    window.scrollTo(0, 0);
    if (!await getStorageData("authToken")) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }

    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    this.setState({ status: selectedLanguage !== "ar" ? "all" : "'D,EJ9" });

    if(selectedLanguage === "ar") {
      this.setState({ toggleDataEmployees: mockToggleDataEmployeesAr });
    }

    const pathName = this.props.location?.pathname;
    if (pathName == configJSON.Employees) {
      await removeStorageData(configJSON.employee_id);
    }

    this.retrieveAllPermissions();
    this.setState({ retrieveAllPermissionsLoading: true });
    const employee_id = await getStorageData(configJSON.employee_id);
    if(employee_id) {
      this.debounceEetAllListSearchEmployees();
      this.showEmployee();
      this.getEmployeeAllBillingRates();
      this.setState({ showEmployeeLoading: true });
      this.setState({ getEmployeeAllBillingRatesLoading: true });
    }
    this.setState({ getAllEmployeesLoading: true });

    const deleteEmployeeToaster = await getStorageData(configJSON.deleteEmployeeToaster);
    await removeStorageData(configJSON.deleteEmployeeToaster);

    const { t } = this.props;
    if(deleteEmployeeToaster) {
      this.props.openToastHandler(t('boEmployees.employeeDeleteSuccess'), "success");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  tokenExpired = (isEmployee: boolean) => {
    setTimeout(this.setTimeOutHandler.bind(this, isEmployee), 2000);
  }

  setTimeOutHandler = (isEmployee: boolean) => {
    localStorage.clear();
    this.props?.history?.push(isEmployee ? "/Employee/Login" : "/Login");
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      const { t } = this.props;
      if (apiRequestCallId === this.createEmployeeApiCallId) {
        if (!responseJson.errors) {
          this.setState({ createEmployeeLoading: false });
          this.props.openToastHandler(t('boEmployees.employeeCreateSuccess'), "success");
          setStorageData(configJSON.employee_id, responseJson.data?.id);
          this.showEmployee();
          this.getAllListSearchEmployees();
          this.getEmployeeAllBillingRates();
        } else if (responseJson.errors) {
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorMessageHandler(error);
          }
        } else {
          //Check Error Response
          this.setState({ createEmployeeLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    this.handleGetAllEmployees(message);
    this.handleDeleteEmployee(message);
    this.handleShowEmployee(message);
    this.handleSearchEmployee(message);
    this.handleUpdateEmployee(message);
    this.handleCreateNewBillingRates(message);
    this.handleShowSingleBillingRate(message);
    this.handleEmployeeGetAllBillingRates(message);
    this.handleGetAllPermissions(message);
    this.handleDeleteEmployeeBillingRate(message);
    this.handleEditEmployeeBillingRate(message);
    // Customizable Area End
  }

  // Customizable Area Start  
  errorMessageHandler = async (error: { token: string, email: string }) => {
    const { email, token } = error;
    this.setState({ createEmployeeLoading: false });
    
    if (email === configJSON.emailAlreadyTakenlabel) {
      this.props.openToastHandler(email, configJSON.errorLabel);
    } else if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  errorHandlerMessage = async (error: { token: string }) => {
    const { token } = error;
    if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }
  
  extractDataFromMessage = (message: Message) => {
    return {
      apiRequestCallId: message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ),
      responseJson: message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ),
      errorResponse: message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ),
    };
  };

  handleGetAllEmployees = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllEmployeesApiCallId) {
        if (!responseJson.errors) {
          this.setState({ employeesData: responseJson });
          this.setState({ getAllEmployeesLoading: false });
          this.setState({ employeesData: responseJson });
        } else if (responseJson.errors) {
          this.setState({ getAllEmployeesLoading: false });
          this.setState({ employeesData: [] });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        }  else {
          //Check Error Response
          this.setState({ getAllEmployeesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeleteEmployee = async (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteEmployeeApiCallId) {
        if (!responseJson.errors) {
          await removeStorageData(configJSON.employee_id);
          this.setState({
            deleteDialogOpen: false,
            deleteEmployeeLoading: false,
          });
          await setStorageData(configJSON.deleteEmployeeToaster, configJSON.deleteEmployeeValue);
          this.props.navigation.history?.push(configJSON.EmployeesNavigation);
        } else if (responseJson.errors) {
          this.setState({
            deleteEmployeeLoading: false,
            deleteDialogOpen: false
          });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        } else {
          //Check Error Response
          this.setState({ deleteEmployeeLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleShowEmployee = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId === this.showEmployeeApiCallId) {
      if (!responseJson.errors) {
        this.setState({ showEmployeeLoading: false });
        this.setState({ showEmployeeData: responseJson?.data });
        const formDataEdit = {
          id: responseJson?.data?.id,
          fullName: responseJson?.data?.attributes?.full_name,
          email: responseJson?.data?.attributes?.email,
          phone: responseJson?.data?.attributes?.phone_number?.split(" ")[1],
        }
        const countryCodeEdit = responseJson?.data?.attributes?.phone_number?.split(" ")[0];
        this.setState({
          formData: formDataEdit,
          countryCode: countryCodeEdit,
          emailAddressAsUsername: responseJson?.data?.attributes?.use_email_as_username,
          resendEmailInvitation: responseJson?.data?.attributes?.resend_email_invitation,
          employeeProfileEdit: responseJson?.data?.attributes?.employee_image?.url,
          employeeProfileImage: responseJson?.data?.attributes?.employee_image,
        });

        const staffPermission = responseJson?.data?.attributes?.staff_permissions?.data;
        if (Array.isArray(staffPermission)) {
          const staffPermissions = staffPermission?.map((item: StaffPermission) => ({
            id: item?.id,
            category: item?.attributes?.category?.toLowerCase().replace(/ /g, "_"),
            action: item?.attributes?.action,
            active: item?.attributes?.active,
          }));
          this.setState({ selectedOptions: staffPermissions });
          const updatedData = this.state.mockStaffPermissionData.map((item: Data) => {
            const category = item?.title?.toLowerCase().replace(/ /g, "_");
            const staffPermission = staffPermissions.find((permission) => permission.category === category);
            const enable = staffPermission ? staffPermission.active : false;
          
            return {
              ...item,
              enable: enable,
            };
          });
          
          this.setState({ mockStaffPermissionData: updatedData });
          
        }
      } else if (responseJson.errors) {
        this.setState({ showEmployeeLoading: false });
        const error = responseJson?.errors?.[0];
        this.getErrorMessage(error);
      } else {
        //Check Error Response
        this.setState({ showEmployeeLoading: false });
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  handleSearchEmployee = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.searchEmployeeApiCallId) {
        if (!responseJson.errors) {
          this.setState({ employeesData: responseJson,getAllEmployeesLoading:false  });
        } else if (responseJson?.data?.[0]?.message == configJSON.noDataFoundEmployeeLabel) {
          this.props.openToastHandler(t('boEmployees.employeeNotFound'), "success");
          this.setState({ employeesData: [],getAllEmployeesLoading:false  });
        } else if (responseJson.errors) {
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        }  else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleUpdateEmployee = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.updateEmployeeApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            updateEmployeeLoading: false,
            employeeProfileImage: {},
            deletedImageId: null,
          });
          this.props.openToastHandler(t('boEmployees.employeeUpdateSuccess'), "success");
          this.showEmployee();
        } else if (responseJson.errors) {
          this.setState({ updateEmployeeLoading: false });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorMessageHandler(error);
          }
        } else {
          //Check Error Response
          this.setState({ updateEmployeeLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleCreateNewBillingRates = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createNewBillingRateApiCallId) {
        if (!responseJson.errors) {
          this.setState({ createNewBillingRateLoading: false });
          this.getEmployeeAllBillingRates();
          this.setState({
            billingRateDialogOpen: false,
          });
          this.props.openToastHandler(t('boEmployees.billingRateCreateSuccess'), "success");
          this.setState({
            billingFormData: { name: "", hourly_rate: "", tax_rate: "" }
          });
        } else if (responseJson.errors) {
          this.setState({
            createNewBillingRateLoading: false,
            billingRateDialogOpen: false,
          });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        } else {
          //Check Error Response
          this.setState({ createNewBillingRateLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleShowSingleBillingRate = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.showSingleBillingRateApiCallId) {
        if (!responseJson.errors) {
          this.setState({ showSingleBillingRatesLoading: false });
          const { name, hourly_rate, tax_rate } = responseJson?.data?.attributes;
          const billingRateDataEdit = {
            name: name,
            hourly_rate: hourly_rate,
            tax_rate: tax_rate
          }
          if(this.state.billingRateId) {
            this.setState({ billingFormData: billingRateDataEdit });
          }
        } else if (responseJson.errors) {
          this.setState({
            showSingleBillingRatesLoading: false,
            billingRateDialogOpen: false,
          });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ showSingleBillingRatesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleEmployeeGetAllBillingRates = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getEmployeeAllBillingRatesApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getEmployeeAllBillingRatesLoading: false });
          const billingRatesData = responseJson?.data;
          const defaultBillingRate = billingRatesData?.filter((item: BillingRateData) => item?.attributes?.active === true);
          this.setState({
            billingRateData: [...billingRatesData],
            defaultBillingRate: defaultBillingRate[0]?.attributes?.name,
            hourlyRate: defaultBillingRate[0]?.attributes?.hourly_rate
          });
        } else if (responseJson.errors) {
          this.setState({ getEmployeeAllBillingRatesLoading: false });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        } else {
          //Check Error Response
          this.setState({ getEmployeeAllBillingRatesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleGetAllPermissions = (message: Message) => {
    const { apiRequestCallId, responseJson,errorResponse } = this.extractDataFromMessage(message);
      if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.retrieveAllPermissionsApiCallId) {
        if (!responseJson.errors) {
          this.setState({ retrieveAllPermissionsLoading: false });

          let actionId = 1;
         
          if (Array.isArray(responseJson)) {
            const updatedData = responseJson.map((item: any, index: number) => {
              const actions = item?.actions?.map((action: { id: number; label: string }) => ({ id: actionId++, label: action }));
              
              return {
                id: index + 1,
                title: item?.category,
                radioSelected: radioSelected,
                radioUnSelected: radioUnSelected,
                fields: actions,
                radioEnable: radioButton,
                radioDisable: radioButtonOff,
                enable: false
              };
            });

            this.setState({ mockStaffPermissionData: updatedData });
            const selectedOptions = updatedData?.map((item: Data) => ({
              category: item?.title?.toLowerCase().replace(/ /g, "_"),
              action: this.getAction(item as { title: string }),
              active: Boolean(updatedData?.find((p: { title: string; enable: boolean; }) => p?.title === item?.title && p?.enable)),
            }));
            this.setState({ selectedOptions: selectedOptions });
          }
        } else if (responseJson.errors) {
          this.setState({ retrieveAllPermissionsLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ retrieveAllPermissionsLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  getAction = (item: { title: string }) => item.title === this.props.t('boEmployees.employeeData') ? this.props.t('boEmployees.viewTheirOwnData') : this.getActionCallback(item);
  getActionCallback = (item: { title: string }) => item.title === this.props.t('boEmployees.scheduling') ? this.props.t('boEmployees.viewOnly') : this.props.t('boEmployees.none')
  getErrorMessage = (error: { token: string }) => {
    if (error) {
      this.errorHandlerMessage(error);
    }
  }

  handleDeleteEmployeeBillingRate = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteEmployeeBillingRateApiCallId) {
        if (!responseJson.errors) {
          this.setState({ deleteEmployeeBillingRatesLoading: false });
          this.setState({ billingDeleteDialogOpen: false });
          this.getEmployeeAllBillingRates();
          this.setState({
            hourlyRate: ""
          });
          this.props.openToastHandler(t('boEmployees.billingRateDeleteSuccess'), "success");
        } else if (responseJson.errors) {
          this.setState({
            deleteEmployeeBillingRatesLoading: false,
            billingDeleteDialogOpen: false,
          });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        } else {
          //Check Error Response
          this.setState({ deleteEmployeeBillingRatesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleEditEmployeeBillingRate = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.editEmployeeBillingRateApiCallId) {
        if (!responseJson.errors) {
          this.setState({ updateNewBillingRateLoading: false });
          this.setState({ billingRateDialogOpen: false });
          this.getEmployeeAllBillingRates();
          this.props.openToastHandler(t('boEmployees.billingRateUpdateSuccess'), "success");
        } else if (responseJson.errors) {
          this.setState({
            updateNewBillingRateLoading: false,
            billingRateDialogOpen: false,
          });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorHandlerMessage(error);
          }
        } else {
          //Check Error Response
          this.setState({ updateNewBillingRateLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  createEmployee = async () => {
    const { fullName, email, phone } = this.state.formData;
    const { emailAddressAsUsername, resendEmailInvitation }: any = this.state;
    const { employeeProfile } = this.state;
    const { countryCode } = this.state;
    const { billingRateData, selectedOptions } = this.state;
    const updatedData: BillingRateData[] = billingRateData.map(({ id, ...rest }: BillingRateData) => rest);
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[full_name]", fullName?.trim());
    formdata.append("data[phone_number]", `${countryCode || ""} ${phone?.trim() || ""}`);
    formdata.append("data[email]", email?.trim());
    formdata.append("data[status]", 'active');
    formdata.append("data[use_email_as_username]", emailAddressAsUsername);
    formdata.append("data[resend_email_invitation]", resendEmailInvitation);
    if (updatedData && updatedData.length > 0) {
      updatedData.forEach((billingRateData: BillingRateData, index: number) => {
        const keyPrefix = `data[billing_rates_attributes][${index}]`;
        Object.entries(billingRateData).forEach(([key, value]: any) => {
          const fullKey = `${keyPrefix}[${key}]`;
          formdata.append(fullKey, String(value)?.trim());
        });
      });
    }

    selectedOptions.forEach((option: any, index: number) => {
      const keyPrefix = `data[staff_permissions_attributes][${index}]`;
    
      Object.entries(option).forEach(([key, value]: any) => {
        const fullKey = `${keyPrefix}[${key}]`;
        formdata.append(fullKey,key ==="active" ? true :value);
      });
    
    });
    
    if(employeeProfile !== null) {
      formdata.append("data[employee_image]", employeeProfile);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEmployeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createEmployeeAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteEmployee = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteEmployeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${this.state.formData?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  showEmployee = async () => {
    const id = await getStorageData(configJSON.employee_id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showEmployeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${id}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getAllListSearchEmployees = async () => {
    const apiEndPoint = configJSON.employeesEndPoint
    const token = await getStorageData(configJSON.storageToken)
    const header = {
      "token": token
    }; 
    const status = this.state.status.toLowerCase();
    let employeeListUrl 
    if(this.state.searchEmployee && this.state.sortColumn){
      employeeListUrl= `${apiEndPoint}?field=${this.state.sortColumn}&order=${this.state.sortDirection}&query=${this.state.searchEmployee}&lang=${this.state.languageSelected}&status=${this.state.status}`
    } else if(this.state.searchEmployee){
      employeeListUrl= `${apiEndPoint}?query=${this.state.searchEmployee}&lang=${this.state.languageSelected}&status=${this.state.status}`
    } else if(this.state.sortColumn){
      employeeListUrl=`${apiEndPoint}?field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${this.state.languageSelected}&status=${this.state.status}`
    } else{
      employeeListUrl= `${apiEndPoint}?lang=en&status=${this.state.status}`
    }
    this.setState({getAllEmployeesLoading:true})
    const getEmployeeAPI = apiCall({
      header: header,
      httpBody: '',
      url:employeeListUrl,
      httpMethod:configJSON.validationApiMethodType,
    });
    this.searchEmployeeApiCallId = getEmployeeAPI.messageId;
    runEngine.sendMessage(getEmployeeAPI.id, getEmployeeAPI);
  }
  debounceEetAllListSearchEmployees = debounce(this.getAllListSearchEmployees, 700);
  updateEmployee = async () => {
    const { employeeProfileEditLocal, deletedImageId, employeeProfileEdit } = this.state;
    const { fullName, email, phone } = this.state.formData;
    const { emailAddressAsUsername, resendEmailInvitation }: any = this.state;
    const { countryCode } = this.state;
    const { filteredBillingRateData, selectedOptions } = this.state;
    const updatedData: BillingRateData[] = filteredBillingRateData?.map(({ type, ...rest }: BillingRateData) => rest);
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[full_name]", fullName?.trim());
    formdata.append("data[phone_number]", `${countryCode || ""} ${phone?.trim() || ""}`);
    if(this.state.emailEdit) {
      formdata.append("data[email]", email?.trim());
    }
    formdata.append("data[use_email_as_username]", emailAddressAsUsername);
    formdata.append("data[resend_email_invitation]", resendEmailInvitation);
    if (updatedData && updatedData.length > 0 && updatedData?.find((item: BillingRateData) => item?.attributes?.name === this.state.defaultBillingRate)) {
      updatedData?.forEach((billingRateData: any, index: number) => {
        const keyPrefix = `data[billing_rates_attributes][${index}]`;
        Object.entries(billingRateData)?.forEach(([key, value]: any) => {
          const fullKey = `${keyPrefix}[${key}]`;
          formdata.append(fullKey, String(value)?.trim());
        });
        formdata.append(`${keyPrefix}[active]`, billingRateData?.attributes?.active);
      });
    }

    selectedOptions?.forEach((option: SelectedOptions, index: number) => {
      const keyPrefix = `data[staff_permissions_attributes][${index}]`;
    
      Object.entries(option)?.forEach(([key, value]: any) => {
        const fullKey = `${keyPrefix}[${key}]`;
        formdata.append(fullKey, value);
      });
    
    });

    if(employeeProfileEditLocal !== null) {
      formdata.append("data[employee_image]", employeeProfileEditLocal);
    }

    if(employeeProfileEdit === null && deletedImageId !== null) {
      formdata.append("data[employee_image_deleted]", deletedImageId);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEmployeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putEmployeesAPIEndPoint}${this.state.formData?.id}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  retrieveAllPermissions = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.retrieveAllPermissionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.retrieveAllPermisionsAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getEmployeeAllBillingRates = async () => {
    const id = await getStorageData(configJSON.employee_id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmployeeAllBillingRatesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${id}/billing_rates?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  createNewBillingRate = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "data":{
        "name": this.state.billingFormData.name?.trim(),
        "hourly_rate": String(this.state.billingFormData.hourly_rate)?.trim(),
        "tax_rate": String(this.state.billingFormData.tax_rate)?.trim(),
        "active": false
      }
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewBillingRateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${this.state.formData?.id}${configJSON.employeesBillingRatesAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createEmployeeAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  showSingleBillingRate = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showSingleBillingRateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${this.state.formData?.id}${configJSON.employeesBillingRatesAPIEndPoint}/${this.state.billingRateId}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteEmployeeBillingRate = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteEmployeeBillingRateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${this.state.formData?.id}${configJSON.employeesBillingRatesAPIEndPoint}/${this.state.billingRateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  updateNewBillingRate = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "data": {
        "name": this.state.billingFormData.name?.trim(),
        "hourly_rate": String(this.state.billingFormData.hourly_rate)?.trim(),
        "tax_rate": String(this.state.billingFormData.tax_rate)?.trim(),
      }
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editEmployeeBillingRateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesEndPoint}/${this.state.formData?.id}${configJSON.employeesBillingRatesAPIEndPoint}/${this.state.billingRateId}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchEmployeesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSortEmployees = (columnKey: string) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (columnKey === this.state.sortColumn) {
      newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
    }
    this.setState({
      sortDirection: newSortDirection,
      sortColumn: columnKey
    },()=>{
      this.debounceEetAllListSearchEmployees()
    });
  };

  handleToggleEmployees = (item: ToggleDataProp, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target&&event.target.checked) {
      this.setState({ checkedColumn: [...this.state.checkedColumn, item.id].sort((a, b) => a - b) });

    } else {
      this.setState({ checkedColumn: this.state.checkedColumn.filter((id: number) => id !== item.id).sort((a, b) => a - b) });
    }
  };
  handleClickUploadProfile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const employee_id = await getStorageData(configJSON.employee_id);
    const file = event.target?.files?.[0];
    if(file) {
      this.setState({
        employeeProfile: file,
        employeeProfileUploaded: true,
        isHovered: false,
        profileChangeModal: false,
      });
    }

    if(employee_id) {
      if(file) {
        this.setState({
          employeeProfileEditLocal: file
        });
      }
    }
  }

  handleDeleteUploadedLogo = async () => {
    this.setState({ employeeProfileUploaded: false });
    const employee_id = await getStorageData(configJSON.employee_id);
    if(employee_id) {
      this.setState({
        employeeProfileEdit: null,
        employeeProfileImage: {},
      });
    }
    if(this.state.employeeProfileImage?.id) {
      this.setState({
        deletedImageId: this.state.employeeProfileImage?.id
      });
    }
    this.setState({
      employeeProfileEditLocal: null,
      employeeProfile: null,
      profileChangeModal: false,
      isHovered2: false
    });
  }

  handleClickEditIcon = () => {
    this.setState({ profileChangeModal: !this.state.profileChangeModal });
  }

  handleMouseEnterChangeLogo = () => {
    this.setState({ isHovered: true });
  }

  handleMouseLeaveChangeLogo = () => {
    this.setState({ isHovered: false });
  }

  handleMouseEnterDeleteLogo = () => {
    this.setState({ isHovered2: true });
  }

  handleMouseLeaveDeleteLogo = () => {
    this.setState({ isHovered2: false });
  }

  handleChangeEmailCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailAddressAsUsername: e.target.checked });
  }
  
  handleChangeResendEmailInvitation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ resendEmailInvitation: e.target.checked });
  }

  updateSelectedOptions = (updatedItems: any) => {
    this.setState((prevState) => {
      const updatedOptions = prevState.selectedOptions.map((option: SelectedOptions) => {
        const updatedItem = updatedItems.find((item: { title: string }) => item.title.toLowerCase().replace(/ /g, "_") === option.category);
        if (updatedItem) {
          const action = updatedItem.enable ? (option.action || updatedItem.fields[0].label) : "";
          return {
            ...option,
            action,
            active: updatedItem.enable,
          };
        }
        return option;
      });
  
      return {
        selectedOptions: updatedOptions,
      };
    });
  };
  
  handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, item: { title: string }) => {
    const newSelectedOptions = this.state.selectedOptions.map((option: SelectedOptions) => {
      if (option.category === item.title.toLowerCase().replace(/ /g, "_")) {
        return { ...option, action: event.target.value };
      }
      return option;
    });
    this.setState({ selectedOptions: newSelectedOptions });
  };

  handleClickOpenDialog = () => {
    this.setState({ deleteDialogOpen: true });
  }
  
  handleCloseDialog = () => {
    this.setState({ deleteDialogOpen: false });
  }
  
  handleMouseEnter = () => {
    this.setState({ showText: true });
  };

  handleMouseLeave = () => {
    this.setState({ showText: false });
  };
  
  handleMouseEnterPayRate = () => {
    this.setState({ showTextPayRate: true });
  };

  handleMouseLeavePayRate = () => {
    this.setState({ showTextPayRate: false });
  };

  handleBillingRateDialogOpen = () => {
    this.setState({
      billingRateDialogOpen: true,
      billingRateId: null,
      isEdit: false,
      billingFormData: {
        name: "",
        hourly_rate: "",
        tax_rate: "",
      }
    });
    this.setState({
      isErrorBillingFormData: {}
    });
  }

  handleCloseBillingRateDialog = () => {
    this.setState({
      billingRateDialogOpen: false,
      billingRateId: null,
      isEdit: false
    });
  }

  validateBillingFormData = (values: Record<string, string | null>) => {
    const errors: Record<string, string> = {};
    const numberRegex = /^\s*\d+(\.\d+)?\s*$/;
    const { t } = this.props;
    if (!values.name) {
      errors.name = t('boEmployees.enterName');
    } else if (values.name.length > 23) {
      errors.name = t('boEmployees.nameLength');
    }

    if (!values.hourly_rate) {
      errors.hourly_rate = t('boEmployees.hourlyRateRequired');
    } else if (!numberRegex.test(values.hourly_rate)) {
      errors.hourly_rate = t('boEmployees.numericalError');
    }

    if (!values.tax_rate) {
      errors.tax_rate = t('boEmployees.taxRateRequired');
    } else if (!numberRegex.test(values.tax_rate)) {
      errors.tax_rate = t('boEmployees.numericalError');
    } else if (parseFloat(values.tax_rate) > 100) {
      errors.tax_rate = t('boEmployees.taxRateLength');
    } else if (!/^\d+(\.\d{1,2})?$/.test(values.tax_rate)) {
      errors.tax_rate = t('boEmployees.taxRateLengthDesc');
    }

    return errors;
  }

  handleSaveBillingRate = () => {
    const { t } = this.props;
    this.setState({ isErrorBillingFormData: this.validateBillingFormData(this.state.billingFormData) },
      () => {
        if(Object.keys(this.state.isErrorBillingFormData).length === 0) {
          this.setState({
            billingRateData: [...this.state.billingRateData, {id: this.state.billingRateData.length + 1, ...this.state.billingFormData, active: false}],
            billingRateDialogOpen: false,
          });
          this.props.openToastHandler(t('boEmployees.billingRateCreateSuccess'), "success");
          this.setState({
            billingFormData: {
              name: "",
              hourly_rate: "",
              tax_rate: ""
            }
          });
        }
      }
    );
  }

  handleSaveAddNewBillingRate = () => {
    this.setState({ isErrorBillingFormData: this.validateBillingFormData(this.state.billingFormData) },
      () => {
        if(Object.keys(this.state.isErrorBillingFormData).length === 0) {
          this.createNewBillingRate();
          this.setState({ createNewBillingRateLoading: true });
        }
      }
    );
  }

  handleUpdateBillingRate = () => {
    const { t } = this.props;
    this.setState({ isErrorBillingFormData: this.validateBillingFormData(this.state.billingFormData) },
      () => {
        if(Object.keys(this.state.isErrorBillingFormData).length === 0) {
          if(this.state.billingRateId) {
            const updatedData = this.state.billingRateData.map((item: BillingRateData) => {
              if(item.id === this.state.billingRateId) {
                return {
                  ...item,
                  name: this.state.billingFormData.name,
                  hourly_rate: this.state.billingFormData.hourly_rate,
                  tax_rate: this.state.billingFormData.tax_rate
                }
              }
              return item;
            });
            this.setState({
              billingRateData: updatedData
            });
          }
          this.setState({ billingRateDialogOpen: false });
          this.setState({
            isEdit: false
          });
          this.props.openToastHandler(t('boEmployees.billingRateUpdateSuccess'), "success");
        }
      }
    );
  }

  handleUpdateNewBillingRate = () => {
    this.setState({ isErrorBillingFormData: this.validateBillingFormData(this.state.billingFormData) },
      () => {
        if(Object.keys(this.state.isErrorBillingFormData).length === 0) {
          this.updateNewBillingRate();
          this.setState({ updateNewBillingRateLoading: true });
        }
      }
    );
  }
  
  handleStatusModalOpen = () => {
    this.setState({ statusModal: !this.state.statusModal });
    this.setState({ toggleModal: false });
  }

  handleToggleModalOpen = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
    this.setState({ statusModal: false });
  }

  handleStatusModalClose = () => {
    this.setState({ statusModal: false });
  }
  handleSelectStatus = (item: CustomerStatusData, customerStatusData: CustomerStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
        if (item.statusName === "all") 
          {
            this.setState({
                checkedItems: customerStatusData.map((subItem: CustomerStatusData) => subItem.statusName),
                status:"all"
            }, this.getAllListSearchEmployees)
        } else 
        {
            this.setState({
                checkedItems: [...this.state.checkedItems,
                item.statusName
                ],
                status: [...this.state.checkedItems,
                item.statusName
                ].join(",")
            },  this.getAllListSearchEmployees)
        }
    } else {
        if (item.statusName === "all") {
            this.setState({
                checkedItems: [],
                status:""
            },  this.getAllListSearchEmployees)
        } else {
            this.setState({
                checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
                status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
            },  this.getAllListSearchEmployees)
        }
    }
}


  handleToggleModalClose = () => {
    this.setState({ toggleModal: false });
  }

  handleCreateEmployee = async () => {
    this.props.navigation.history?.push(configJSON.CreateEmployee);
    await removeStorageData(configJSON.employee_id);
  }

  handleChangeCountryCode = (event: React.ChangeEvent<{}>, value: any) => {
    this.setState({ countryCode: value });
  };

  handleChangeFormData = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formData: {...this.state.formData, [e.target.name]: e.target.value},
    });
    const employee_id = await getStorageData(configJSON.employee_id);
    if(employee_id && e.target.name === "email") {
      this.setState({ emailEdit: e.target.value });
    }
  }

  handleChangeBillingFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      billingFormData: {...this.state.billingFormData, [e.target.name]: e.target.value}
    });
  }

  handleChangeDefaultBillingrateModalOpen = () => {
    this.setState({ billingRateModal: !this.state.billingRateModal });
  }

  handleChangeBillingRate = async (item: { id: number; name: string }) => {
    const employee_id = await getStorageData(configJSON.employee_id);
    if(employee_id) {
      const filteredBillingRateData = this.state.billingRateData?.map((data: BillingRateData) => {
        if(data?.id === item?.id) {
          return { ...data, attributes: {...data?.attributes, active: true} };
        } else {
          return { ...data, attributes: {...data?.attributes, active: false} };
        }
      });
      const activeData = filteredBillingRateData?.filter((i: BillingRateData) => i?.attributes?.active === true);
      this.setState({
        filteredBillingRateData: filteredBillingRateData,
      }, () => {
        this.setState({
          defaultBillingRate: activeData[0]?.attributes?.name,
          hourlyRate: activeData[0]?.attributes?.hourly_rate,
          billingRateModal: false
        });
      });
    }else {
      const filteredBillingRateData = this.state.billingRateData.map((data: BillingRateData) => {
        if(data.id === item.id) {
          return { ...data, active: true };
        } else {
          return { ...data, active: false };
        }
      });
      this.setState({
        defaultBillingRate: item.name,
        billingRateData: filteredBillingRateData,
        billingRateModal: false
      });
    }
   
  }

  handleClickOpenEditDialog = async (item: BillingFormData) => {
    const employee_id = await getStorageData(configJSON.employee_id);
    this.setState({
      billingRateDialogOpen: true,
      isEdit: true
    });
    this.setState({
      isErrorBillingFormData: {}
    });
    this.setState({ billingRateId: item?.id }, () => {
      if(employee_id) {
        this.showSingleBillingRate();
        this.setState({ showSingleBillingRatesLoading: true });
      } else {
        const billingFormDataEdit = {
          id: item?.id,
          name: item?.name,
          hourly_rate: item?.hourly_rate,
          tax_rate: item?.tax_rate
        }
        if(item?.id === this.state.billingRateId) {
          this.setState({
            billingFormData: billingFormDataEdit
          });
        }
        return item;
      }
    });
  }

  handleClickAway = () => {
    this.setState({ billingRateModal: false });
  }

  handleBillingRateDeleteDialogOpen = (item: { id: string | number; name: string }) => {
    this.setState({ billingDeleteDialogOpen: true });
    this.setState({ billingRateId: item?.id });
  }

  handleCloseDeleteDialog = () => {
    this.setState({ billingDeleteDialogOpen: false });
  }

  handleDeleteBillingRateData = () => {
    this.deleteEmployeeBillingRate();
    this.setState({ deleteEmployeeBillingRatesLoading: true });
  }

  handleDeleteBillingRate = () => {
    const { t } = this.props;
    const filteredData = this.state.billingRateData.filter((item: BillingRateData) => item.id !== this.state.billingRateId);
    this.setState({ billingRateData: filteredData });
    this.setState({ billingDeleteDialogOpen: false });
    this.props.openToastHandler(t('boEmployees.billingRateDeleteSuccess'), "success");
  }

  validateFormData = (values: Record<string, string>) => {
    const errors: Record<string, string> = {};
    const regexName = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    const regexEmail = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
    const regexPhone = /^\s*\d+\s*$/;
    const { t } = this.props;
    if (!values.fullName) {
      errors.fullName = t('boEmployees.fullNameRequired');
    } else if (values.fullName.length > 23) {
      errors.fullName = t('boEmployees.fullNameLength');
    } else if (!regexName.test(values.fullName)) {
      errors.fullName = t('boEmployees.alphabeticalError');
    }

    if (!values.email) {
      errors.email = t('boEmployees.emailAddressRequired');
    } else if (!regexEmail.test(values.email)) {
      errors.email = t('boEmployees.invalidEmailAddress');
    }

    if (values.phone && !regexPhone.test(values.phone)) {
      errors.phone = t('boEmployees.numericalError');
    }

    return errors;
  }

  handleEmployeeCancel = () => {
    this.props.navigation.history?.push(configJSON.EmployeesNavigation);
  }

  handleSaveEmployeeData = () => {
    this.setState({ isErrorFormData: this.validateFormData(this.state.formData) },
      () => {
        if(Object.keys(this.state.isErrorFormData).length === 0) {
          this.createEmployee();
          this.setState({ createEmployeeLoading: true });
        }
      }
    );
  }

  handleUpdateEmployeeData = () => {
    this.setState({ isErrorFormData: this.validateFormData(this.state.formData) },
      () => {
        if(Object.keys(this.state.isErrorFormData).length === 0) {
          this.updateEmployee();
          this.setState({ updateEmployeeLoading: true });
        }
      }
    );
  }

  handleProceedDelete = () => {
    this.deleteEmployee();
    this.setState({ deleteEmployeeLoading: true });
  }

  handleViewEmployee = async (item: { id: string; }) => {
    this.props.navigation.history?.push(configJSON.CreateEmployee);
    await setStorageData(configJSON.employee_id, item?.id);
  }

  handleChangeSearchEmployee = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchEmployee: e.target.value }, () => {
      this.debounceEetAllListSearchEmployees();
    });
  }

  handleGoBackIcon = () => {
    this.props.navigation.history.goBack();
  }

}
// Customizable Area End