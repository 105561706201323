//@ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { docExtensionIcon, jpgExtensionIcon, pdfExtensionIcon, pngExtensionIcon, pptExtensionIcon, txtExtensionIcon, xlsExtensionIcon } from "../../RequestManagement/src/assets";
// Customizable Area Start
enum CSSClass {
  productTitle = "productTitle",
  productQty = "productQty",
  productPrice = "productPrice",
  productTotal = "productTotal",
  productQtyHidden = "productQtyHidden",
  productPriceHidden = "productPriceHidden",
  productTotalHidden = "productTotalHidden",
  productTitleAr = "productTitleAr",
  productQtyAr = "productQtyAr",
  productPriceAr = "productPriceAr",
  productTotalAr = "productTotalAr",
  productQtyHiddenAr = "productQtyHiddenAr",
  productPriceHiddenAr = "productPriceHiddenAr",
  productTotalHiddenAr = "productTotalHiddenAr",
  productLabelColor = "productLabelColor",
  font_lightGrey = "font_lightGrey",
  errorText = "errorText",
  display_flex = "display_flex",
  PriceUpload = "PriceUpload",
  productLayout = "productLayout",
  nameProductInput = "nameProductInput",
  quantityInput = "quantityInput",
  productsOptionDivider = "productsOptionDivider",
  subHeaderTitle = "subHeaderTitle",
  fileLayout = "fileLayout",
  uploadFileBox = "uploadFileBox",
  imageBox = "imageBox",
  imageBoxV1 = "imageBoxV1",
  imageUpload = "imageUpload",
  textBox = "textBox",
  createCustTextTypo = "createCustTextTypo"
}

interface ComponentClasses {
  [key: string]: CSSClass;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any
  // Customizable Area Start
  navigation:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  active: any;
  toaster: boolean;
  fileToaster: boolean;
  toasterText: string;
  files: any;
  editedFiles: any;
  convertedFiles: any;
  progress: any;
  completed: boolean;
  count: number;
  extensionIcons: any;
  extensionAllowed: any;
 
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductServiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wrapperRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      active: 0,
      toaster: false,
      toasterText: "",
      fileToaster: false,
      remindersDialogOpen: false,
      files: [],
      editedFiles: [],
      convertedFiles: [],
      progress: 0,
      completed: false,
      count: 0,
      extensionIcons: {
        jpg: jpgExtensionIcon,
        png: pngExtensionIcon,
        jpeg: jpgExtensionIcon,
        doc: docExtensionIcon,
        pdf: pdfExtensionIcon,
        xls: xlsExtensionIcon,
        txt: txtExtensionIcon,
        ppt: pptExtensionIcon,
        docx: docExtensionIcon,
        xlsx: xlsExtensionIcon,
        pptx: pptExtensionIcon,
      },
      showActionBtn: false,
      extensionAllowed: ["jpg", "png"],

      // Customizable Area Start
      // Customizable Area End
    };
    this.wrapperRef = React.createRef<any>();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  handleAddProductService=()=>{
    localStorage.setItem("cancelRedirection", this.props.history.location.pathname)
    this.props.history?.push("/Settings/ProductAndServices");
  }
   preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
  };

  getClassQtyTrue = (classes: ComponentClasses, isEnglish: string) => isEnglish ? classes.productQty : classes.productQtyAr;
  getClassQtyFalse = (classes: ComponentClasses, isEnglish: string) => isEnglish ? classes.productPriceHidden : classes.productPriceHiddenAr;

  getClassPriceTrue = (classes: ComponentClasses, isEnglish: string) => isEnglish ? classes.productPrice : classes.productPriceAr
  getClassPriceFalse = (classes: ComponentClasses, isEnglish: string) => isEnglish ? classes.productPriceHidden : classes.productPriceHiddenAr;

  getClassTotalTrue = (classes: ComponentClasses, isEnglish: string) => isEnglish ? classes.productTotal : classes.productTotalAr
  getClassTotalFalse = (classes: ComponentClasses, isEnglish: string) => isEnglish ? classes.productTotalHidden : classes.productTotalHiddenAr;

  qtyClasses = (classes: ComponentClasses, isEnglish: string, isHidden) => {
    return isHidden
      ? this.getClassQtyTrue(classes, isEnglish)
      : this.getClassQtyFalse(classes, isEnglish)
  }
  priceClasses = (classes: ComponentClasses, isEnglish: string, isHidden) => {
    return isHidden
      ? this.getClassPriceTrue(classes, isEnglish)
      : this.getClassPriceFalse(classes, isEnglish)
  }

  totalClasses = (classes: ComponentClasses, isEnglish: string, isHidden) => {
    return isHidden
      ? this.getClassTotalTrue(classes, isEnglish)
      : this.getClassTotalFalse(classes, isEnglish)
  }

  refactoredTotal = (multipleServices: boolean, classes: ComponentClasses, isEnglish: string, isHidden: boolean) => {
    let selectedClass;
    if (multipleServices) {
      selectedClass = this.totalClasses(classes, isEnglish, isHidden)
    } else {
      selectedClass = isEnglish ? classes.productTotal : classes.productTotalAr;
    }
    return selectedClass
  }
  refactoredClasses = (key:string,multipleServices: boolean, classes: ComponentClasses, isEnglish: string, isHidden: boolean) => {
    let selectedClass;
    if (key === "qty") {
      if (multipleServices) {
        selectedClass = this.qtyClasses(classes, isEnglish, isHidden)
      } else {
        selectedClass = isEnglish ? classes.productQty : classes.productQtyAr;
      }
    } else if (key === "price") {
      if (multipleServices) {
        selectedClass = this.priceClasses(classes, isEnglish, isHidden)
      } else {
        selectedClass = isEnglish ? classes.productPrice : classes.productPriceAr;
      }
    } else {
      selectedClass = this.refactoredTotal(multipleServices, classes, isEnglish, isHidden)
    }
    return selectedClass
  }

  getClassStyles = (classes: ComponentClasses, key: string)=> {
    const { languageSelected, isEditable, productServices } = this.props;
    const isEnglish = languageSelected === "en";
    let selectedClass; // Define the type for selectedClass
  
    if (key === "product") {
      selectedClass = isEnglish ? classes.productTitle : classes.productTitleAr;
    } else {
      const multipleServices = productServices&&productServices.length > 1;
      const isHidden = isEditable === false;
      selectedClass = this.refactoredClasses(key,multipleServices, classes, isEnglish, isHidden)
    }
  
    return selectedClass;
  };  
  

  // Customizable Area End
}
