import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core"
import { Details } from "../../RequestManagement/src/RequestFormController.web";
import { extensionDoc, extensionJpg, extensionPdf, extensionPng, extensionPpt, extensionTxt, extensionXls } from "./assets";

// Customizable Area Start
import { apiCall, checkTokenExpired,dateMinusOne,navigateTo ,filterListData,debounce} from "../../../components/src/CommonFunction";
import moment from "moment";
import { checkLoggedInUser, checkPermission } from "../../CustomisableUserProfiles/src/utility.web"
type IsPermit = [string, boolean];
interface ProductServiceType {
    product_id: number,
    service_id: number,
    description: number,
    quantity: number,
    unitPrice: number,
    totalCost: number,
    files: object,
    completed: boolean,
    progress: number
}
interface Error {
    title: string,
    product_id: object,
    service_id: object,
    customerDetails: string
}

interface RecurringJobData {
    jobStartDate: Date;
    jobEndDate: Date;
    frequency: string;
    duration: {
        day: string;
        frequency: string;
    };
    summary: string;
    day: number; // Every
    monthlyDay: string; // Monthly string
    weekDays: string; // which day S, M
};
interface ProductItem {
    id: string;
    type: string;
    attributes: {
        product_id: number;
        service_id: number | null;
        quantity: number;
        unit_price: string;
        currency: string | null;
        total: string;
        description: string | null;
        job_id: number | null;
        quote_id: number | null;
        visit_id: number;
        product_name: string;
        files: any[] | null;
    };
}

interface Employee {
    id: number;
    name: string;
    email: string;
}
interface EmpItem {
    isSelected: boolean
    full_name: string
    employee_image: {
      url: string
    }
  }
  interface MockItem {
    attributes: EmpItem
  }
  interface ValueObject{
    title: string;
    start: Date;
    end: Date;
    attributes: {
      start_date: Date;
      start_time: Date;
      end_date: string;
      end_time: string;
      "admin_address": [],
      'company_logo': null,
      'created_at': Date,
      'currency': string,
      'customer_id': number,
      'customer_name': string,
      'discount': string,
      'files': null,
      'invoice_deposits': [],
      'invoice_id': string,
      'issue_date': string,
      'job_id': string,
      'job_title': string,
      'newEngStatus': string,
      'notes': string,
      'payment_due': string,
      'pdf_url': {},
      'products': [],
      'quote_id': string,
      'site_id': string,
      'status': string
      'sub_total': number,
      'tax': number,
      'total': number
      'tax_name': string,
      'title': string,
      'updated_at': string,
      'id': string,
      'type': string
    },
    id: string,
    type: string
  }
  interface MockJob{
    title: string; 
    id:string
    attributes: { 
        job_title: string; 
        start_date: Date; 
        start_time: Date; 
        end_date: string; 
        end_time: string; 
    }; 
    start: Date; 
    end: Date; 
  }
  interface AttributesArray {
    data: ValueObject[]
  }
interface TaxRateResponse {
    id: number;
    company_information_id: number;
    name: string;
    rate: number;
    description: string;
    created_at: string;
    updated_at: string;
}
type AnchorElType = HTMLElement | SVGElement | null
interface ProductService {
    quantity: number;
    unitPrice: string;
    totalCost: string;
}
export interface Visit {
    id: string;
    type: string;
    attributes: {
        visits_title: string;
        description: string;
        start_date: string;
        end_date: string;
        start_time: string;
        end_time: string;
        markascompleted: boolean;
        employee_id: number;
        job_id: number;
        products: {
            data: ProductItem[];
        };
        employees: Employee[];
        employee_image: (string | null)[];
    };
}
const startDate1 = localStorage.getItem("startDate");
const endDate1 = localStorage.getItem("endDate");
const calendarView = localStorage.getItem("calendarView");
const startDate = () => {
    if (startDate1) {
        return new Date(startDate1)
    } else {
        return new Date()
    }
}
const endDate = () => {
    if (endDate1) {
        return new Date(endDate1)
    } else {
        return new Date()
    }
}

const startTime = () => {
    if (startDate1 && !calendarView) {
        return moment(startDate1).format('HH:mm A')
    } else if (calendarView) {
        return "09:00 AM"
    } else {
        return "09:00 AM"
    }
}
const endTime = () => {
    if (endDate1 && !calendarView) {
        return moment(endDate1).format('HH:mm A')
    } else if (calendarView) {
        return "09:00 AM"
    } else {
        return "09:00 AM"
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    active: number
    openToastHandler: Function
    type: string
    details: any
    modeType: string
    seeDetails: any
    t: (key: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string
    jobsPageLoading: boolean
    customerDailog: boolean
    isJobEdit: boolean
    isTitleEdit: boolean
    anchorEl: any
    anchorElV1: any
    teamAssigneDailog: boolean
    isCalenderVisible: boolean
    sendEmailDailog: boolean
    jobPdfDailog: boolean
    jobCreateToasterStatus: boolean
    jobUpdateToasterStatus: boolean
    deleteDailogStatus: boolean
    isJobErrorFormData: any
    savingMode: string
    jobForm: {
        jobId: string
        jobTitle: string
    },
    jobType: string
    JobTypeDetails: {
        jobStartDate: any,
        jobEndDate: any,
        jobStartTime: string,
        jobEndTime: string,
    },
    employeeDetails: any
    productData: any,
    getCustomerPlace: any
    defaultLocation: any
    internalNotes: string,
    internalAttachments: any
    customerDetails: Details
    siteDetails: any,
    productServices: any,
    createdJobDetails: any
    jobCompleteData:any,
    customerSelected: boolean,
    currencySym: string
    completed: boolean
    files: any
    progress: number;
    isEditable: boolean
    count: any
    extensionIcons: any,
    fileToaster: any,
    imgToasterText: any;
    showErrorMessage: boolean;
    errorMessage: string;
    productSearch: string;
    singleJobDetails: any;
    jobMode: any
    jobConvertedFiles: any
    jobEditedFiles: any
    employee_id: string | number,
    drawerStatus: boolean,
    details: any,
    pageReceivedFrom: string,
    jobLoading: boolean,
    jobCreateToasterText: string,
    selctedCustomerJob: boolean,
    recurringJobData: any
    jobVisits: Visit[]
    jobScheduling: string
    mockFullWeekDays: string[]
    visitDialogOpen: boolean;
    selectedRadio: string;
    anchorElMore: any;
    teamMember:object[]
    getEmployeeData:MockItem[]
    getJobData:MockJob[]
    priceSectionData: {
        sub_total: number,
        total: number,
        tax: number,
        discount: number,
        required_deposit: string,
        tax_name: string
    }
    collectedPaymentForm: {
        id:string
        amount: string,
        payment_method: string,
        transaction_date: Date|string,
        details: string,
        pay_balance: boolean,
        collected: boolean
    },
    collectedPayment:boolean
    discountDialog:boolean
    selectCurrency:string
    anchorEl1: AnchorElType;
    nextModal:string
    openNextModal:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class JobActionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdGetProductServices: string = "";
    apiCallIdCreateJob: string = "";
    apiCallIdUpdateJob: string = "";
    apiCallIdDeleteJob: string = "";
    apiCallIdGetJobDetails: string = "";
    getCustomerDetailsApiCallId: string = "";
    mapRef: any;
    token: string = ""
    apiCallIdGetConvertedDetails: string = "";
    apiCallIdGetSiteDetails: string = "";
    apiCallIdGetEmployee: string = "";
    apiCallIdGetQuoteProductsDetails:string="";
    apiCallIdGetCurrency:string=''
    getJobApiCallId: string = ''
    markAsCompleteJobApiCallId: string = ''
    reopenJobApiCallId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.handleChangePaymentMethodRef=this.handleChangePaymentMethodRef.bind(this)
        this.mapRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectedLanguage:'',
            jobsPageLoading: true,
            customerDailog: false,
            isJobEdit: false,
            isTitleEdit: false,
            anchorEl: null,
            anchorElV1: null,
            jobCompleteData:[],
            teamAssigneDailog: false,
            isCalenderVisible: false,
            sendEmailDailog: false,
            jobPdfDailog: false,
            jobCreateToasterStatus: false,
            jobUpdateToasterStatus: false,
            deleteDailogStatus: false,
            isJobErrorFormData: {},
            savingMode: "",
            jobForm: {
                jobId: "",
                jobTitle: ""
            },
            jobType: "one_of_job",
            JobTypeDetails: {
                jobStartDate: startDate(),
                jobEndDate: endDate(),
                jobStartTime: startTime(),
                jobEndTime: endTime(),
            },
            currencySym:'',
            employeeDetails: [],
            productData: [],
            getCustomerPlace: null,
            defaultLocation: {
                lat: 25.276987,
                lng: 55.296249
            },
            internalNotes: "",
            internalAttachments: [],
            customerDetails: {},
            siteDetails: {},
            createdJobDetails: {},
            productServices: [{
                product_id: "",
                service_id: '',
                productName: "",
                description: "",
                quantity: 0,
                unitPrice: 0,
                totalCost: 0,
                files: [],
                completed: false,
            }],
            customerSelected: false,
            completed: false,
            files: [],
            progress: 0,
            isEditable: true,
            count: 0,
            extensionIcons: {
                jpg: extensionJpg,
                png: extensionPng,
                jpeg: extensionJpg,
                doc: extensionDoc,
                pdf: extensionPdf,
                xls: extensionXls,
                txt: extensionTxt,
                ppt: extensionPpt,
                docx: extensionDoc,
                xlsx: extensionXls,
                pptx: extensionPpt,
            },
            fileToaster: false,
            imgToasterText: "",
            showErrorMessage: false,
            errorMessage: "",
            productSearch: "",
            singleJobDetails: {},
            jobMode: "",
            jobConvertedFiles: [],
            jobEditedFiles: [],
            employee_id: "",
            drawerStatus: true,
            details: {},
            pageReceivedFrom: "",
            jobLoading: true,
            jobCreateToasterText: "",
            selctedCustomerJob: false,
            recurringJobData: {
                frequency: "",
                duration: {
                    day: "",
                    frequency: "",
                },
                day: 0,
                weekDays: "",
                monthlyDay: "",
                summary: "",
            },
            jobVisits: [],
            jobScheduling: "",
            mockFullWeekDays: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
            visitDialogOpen: false,
            selectedRadio: 'Information',
            anchorElMore: null,
            teamMember:[],
            getEmployeeData:[],
            getJobData:[],
            priceSectionData: {
                sub_total: 0,
                total: 0,
                tax: 0,
                discount: 0,
                required_deposit: "",
                tax_name: "",
            },
            collectedPaymentForm: {
                id:'',
                amount: '',
                payment_method: '',
                transaction_date: new Date(),
                details: '',
                pay_balance: false,
                collected: false
            },
            collectedPayment: true,
            discountDialog: false,
            selectCurrency: '',
            anchorEl1: null,
            nextModal:'',
            openNextModal:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        localStorage.removeItem('convertType')
        const selectedLanguage = await getStorageData("lang");
        this.setState({ selectedLanguage: selectedLanguage })
        const selectedEventId = await getStorageData('selectedEventId')
        const customerJobId = await getStorageData('customerTabId')
        if(selectedEventId||customerJobId){
            this.getJobDetails()
        }
        this.getEmployee()
        this.getJob()
        this.createJobproductAndServices()
        const jobCreatedStatus = await getStorageData(configJSON.storageText.created)
       
        if (jobCreatedStatus) {
            this.setState({ jobCreateToasterStatus: true, jobCreateToasterText: `${this.props.t('jobs.toasterText.created')}` },()=>{
                this.props.openToastHandler &&   this.props.openToastHandler(this.state.jobCreateToasterText,'success')

            });
        }
        await removeStorageData(configJSON.storageText.created)
        const pageReceived = this.props.navigation?.history?.location.state?.convertedType
        const jobMode = this.props.navigation?.history?.location.state?.type
        if(this.props.navigation?.history?.location?.state?.reDirectionFrom==='customer'){
            this.getJobCustomerDetails()
        }
        if (jobMode === "edit" && pageReceived === "RequestTo") {
            this.getConvertedDetails()
            this.getJobDetails()
        }
        if (pageReceived == "RequestTo") {
            localStorage.setItem("convertType", "RequestTo")
            this.getConvertedDetails()
            this.manageQuoteProducts()
        }
        if (jobMode === "edit") {
            this.getJobDetails()
        }
        if (pageReceived == "QuoteTo") {
            this.getConvertedDetails()
            localStorage.setItem("convertType", "QuoteTo")
        }
        if (jobMode === "edit" && pageReceived == "QuoteTo") {
            this.getConvertedDetails()
            this.getJobDetails()
        }
        this.getCurrency()
        window.scrollTo(0, 0);
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.apiCallIdGetProductServices) {
                this.getProductServicesApiResponce(responseJson)
            }
            if (apiRequestCallId === this.apiCallIdCreateJob) {
                this.createJobApiResponce(responseJson)
            }
            if (apiRequestCallId === this.apiCallIdDeleteJob) {
                this.deleteJobApiResponce(responseJson)
            }
            if (apiRequestCallId === this.apiCallIdUpdateJob) {
                this.updateJobApiResponce(responseJson)
            }
            if (apiRequestCallId === this.apiCallIdGetJobDetails) {
                this.getJobDetailsApiResponce(responseJson)
            }
            if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
                this.getCustomerDetailsApiResponce(responseJson)
            }
            if(apiRequestCallId === this.apiCallIdGetCurrency){
                this.getCurrencyApiResponse(responseJson)
            }
            this.moreApiCalls(apiRequestCallId, responseJson)
        }
        // Customizable Area End
    }
    // Customizable Area Start
    onMapLoad = (map: google.maps.Map) => {
        this.mapRef.current = map;
        const zoomInButton = document.createElement('button');
        zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
            <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
        </svg>`;
        zoomInButton.style.backgroundColor = 'white';
        zoomInButton.style.marginTop = '10px';
        zoomInButton.style.marginBottom = '10px';
        zoomInButton.style.border = 'none';
        zoomInButton.style.paddingLeft = '5px';
        zoomInButton.style.cursor = 'pointer';
        zoomInButton.style.padding = '6px 3px 0px 5px';
        zoomInButton.style.borderRadius = '4px';
        zoomInButton.title = 'Zoom In';
        zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
        const zoomOutButton = document.createElement('button');
        zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
        </svg>`;
        zoomOutButton.style.border = 'none';
        zoomOutButton.style.cursor = 'pointer';
        zoomOutButton.style.backgroundColor = 'white';
        zoomOutButton.style.marginBottom = '0px';
        zoomOutButton.style.padding = '6px 3px 0px 5px';
        zoomOutButton.style.borderRadius = '4px';
        zoomOutButton.style.paddingLeft = '5px';
        zoomOutButton.title = 'Zoom Out';
      
        zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
      
        const controlDiv = document.createElement('div');
        controlDiv.style.position = 'absolute';
        controlDiv.style.marginRight = '20px';
        controlDiv.style.display = 'flex';
        controlDiv.style.flexDirection = 'column';
        controlDiv.style.zIndex = '1';
        controlDiv.style.marginBottom = '10px';
        controlDiv.style.right = '10px';
        controlDiv.style.justifyContent = 'space-between'; 
        controlDiv.appendChild(zoomInButton);
        controlDiv.appendChild(zoomOutButton);
        const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
        map.controls[controlPosition].push(controlDiv);
    }; 
    manageQuoteProducts=()=>{
        const QuoteId=this.props.navigation?.history?.location?.state?.quoteid
        const action=this.props.navigation?.history?.location?.state?.convertedType
        if(QuoteId&&action){
            this.getQuoteProductDetails()
        }
    }
    getEmpApiResponse(responseJson: { errors: string; data: []; }) {
        if (responseJson && !responseJson.errors) {
          this.setState({ getEmployeeData: responseJson.data})
        } else {
          if (responseJson&&responseJson.errors) {
            checkTokenExpired(responseJson,this.props.openToastHandler,this.props.navigation)
            this.setState({ getEmployeeData: [] })
          }
        }
      }
    updateEvents = () => {
        const { jobForm, JobTypeDetails, getJobData } = this.state;
        const dateString = this.state.JobTypeDetails.jobStartDate;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;

        const dateString1 = this.state.JobTypeDetails.jobEndDate;
        const date1 = new Date(dateString1);
        const year1 = date1.getFullYear();
        const month1 = ('0' + (date1.getMonth() + 1)).slice(-2);
        const day1 = ('0' + date1.getDate()).slice(-2);
        const formattedDate1 = `${year1}-${month1}-${day1}`;

        const existingEventIndex = getJobData.findIndex((item:MockJob) => (item.id !== jobForm.jobId));
    
            const updatedEvent = {
                ...getJobData[existingEventIndex],
                title: jobForm.jobTitle,
                start : moment.utc(`${formattedDate} ${JobTypeDetails.jobStartTime}`).toDate(),
                end : this.state.jobType === "recurring_job" ? 
                moment.utc(`${formattedDate} ${JobTypeDetails.jobEndTime}`).toDate() : 
                moment.utc(`${formattedDate1} ${JobTypeDetails.jobEndTime}`).toDate()
            };
    
            const newEvents = [...getJobData];
            newEvents[existingEventIndex] = updatedEvent;
    
            this.setState({ getJobData: newEvents });
    };
    
    debounceUpdateEvents = debounce(this.updateEvents, 1000);
    getJobApiResponse = (responseJson:Record<string,AttributesArray>) => {
        const allJobData = filterListData(responseJson)
        if (responseJson && !responseJson.errors) {
            let newArray:MockJob[] = []
             Object.values(allJobData).flatMap((item) => {
                newArray.push(...item.data)
                return item.data.map((item: MockJob) => {
                    item.title = item.attributes.job_title
                    item.start = moment.utc(`${item.attributes?.start_date} ${item.attributes?.start_time}`).toDate()
                    item.end = moment.utc(`${item.attributes?.end_date} ${item.attributes?.end_time}`).toDate()
                  });
            });
            this.setState({ getJobData: newArray||[], jobLoading: false })
        } else {
            if (responseJson && responseJson.errors) {
                checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
                this.setState({ getJobData: [], jobLoading: false })
            }
        }
    }
    moreApiCalls = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.apiCallIdGetConvertedDetails) {
            this.getDetailsApiResponce(responseJson)
        }
        if (apiRequestCallId === this.apiCallIdGetSiteDetails) {
            this.apiResponceSiteInformatation(responseJson)
        }
        if(apiRequestCallId === this.apiCallIdGetQuoteProductsDetails){
            this.quoteProductDetailsApiResponce(responseJson)
        }
        if (apiRequestCallId === this.apiCallIdGetEmployee) {
            this.getEmpApiResponse(responseJson)
        }
        if (apiRequestCallId === this.getJobApiCallId) {
            this.getJobApiResponse(responseJson)
        }
        if(apiRequestCallId === this.markAsCompleteJobApiCallId){
            this.markAsCompleteJobApiResponce(responseJson)
        }
        if(apiRequestCallId === this.reopenJobApiCallId){
            this.reopenJobApiResponce(responseJson)
        }
    }
    getCustomerDetailsApiResponce = (responseJson: any) => {
        this.createJobproductAndServices()
        if (responseJson && !responseJson.errors) {
            this.setState({
                jobLoading: false
            })
            this.setState({
                customerDetails: responseJson.data,
            },()=>{
                this.createJobproductAndServices()
            })
            if(this.props.navigation.history.location.state.id){
                this.setState({selctedCustomerJob:true})
            }
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)

        }

    }
    getCurrencyApiResponse(responseJson: any) {
        if (responseJson && !responseJson.errors) {
          this.setState({ currencySym:responseJson.currency_code })
        } else {
          if (responseJson&&responseJson.errors) {
            for (let key in responseJson.errors) { 
              this.setState({errorMessage:`${key} ${responseJson.errors[key][0]}`,showErrorMessage:true})
            } 
          }
        }
      }

    oppositeFrequency = (str: string): [string, string] => {
        // Split the string at the dot
        const parts = str.split('.');

        if (parts.length !== 2) {
            // If the string doesn't contain a dot, return the original string
            return [str, ''];
        }

        const frequency = parts[1].toLowerCase();
        let oppositeFrequency = '';

        // Determine the opposite frequency based on the second part of the string
        switch (frequency) {
            case 'days':
                oppositeFrequency = 'Daily';
                break;
            case 'weeks':
                oppositeFrequency = 'Weekly';
                break;
            case 'months':
                oppositeFrequency = 'Monthly';
                break;
            case 'years':
                oppositeFrequency = 'Yearly';
                break;
            default:
                oppositeFrequency = str; // If not matched, return the original string
        }

        return [parts[0], oppositeFrequency];
    };

    getJobDetailsApiResponce = (responseJson: any) => {
        const { frequency, duration, summary, every } = responseJson.data.attributes;
        if (responseJson && !responseJson.errors) {
            if (responseJson.message === "you are not authorized") {
                return this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
            }
            const jobEditForm = {
                jobId: responseJson.data?.attributes?.job_id,
                jobTitle: responseJson.data.attributes.job_title,
            }
            let oneOffJobDetails = {
                jobStartDate: new Date(responseJson.data.attributes.start_date),
                jobEndDate: new Date(responseJson.data.attributes.end_date),
                jobStartTime: responseJson.data.attributes.start_time || "",
                jobEndTime: responseJson.data.attributes.end_time || "",
            }
            let editedFiles = responseJson.data.attributes.files || []
            const employeeData = responseJson.data.attributes.employees.map((item: { id: string })=>{
                return item.id
            })
            let productsData = responseJson.data.attributes.products.data.map((product: any, index: number) => {
                return {
                    product_id: product.attributes.product_id,
                    service_id: product?.attributes?.service_id,
                    productName: product.attributes.product_name,
                    description: product.attributes.description,
                    quantity: product.attributes.quantity,
                    unitPrice: product.attributes.unit_price,
                    totalCost: product.attributes.total || "",
                    files: product?.attributes?.files || [],
                    product_item_id: product?.id,
                    completed: true,
                    pogress: 100,
                    currency:product.attributes.currency
                }
            })
            const durationData = duration && this.oppositeFrequency(duration)
            this.setState({
                jobScheduling:responseJson.data.attributes.scheduling,
                singleJobDetails: responseJson?.data,
                JobTypeDetails: oneOffJobDetails,
                jobForm: jobEditForm,
                teamMember:employeeData,
                internalNotes: responseJson.data.attributes.internal_notes,
                productServices: productsData,
                jobEditedFiles: editedFiles || [],
                employee_id: responseJson.data.attributes.employee_id,
                jobVisits:responseJson.data.attributes.visits.data || [],
                priceSectionData: {
                    tax: responseJson.data.attributes.tax,
                    tax_name: responseJson.data.attributes.tax_name,
                    discount: responseJson.data.attributes.discount,
                    required_deposit: responseJson.data.attributes.currency,
                    sub_total: 0,
                    total: 0
                },
                selectCurrency: responseJson.data.attributes.discount_type,
                collectedPaymentForm: {
                    id:responseJson.data.attributes.invoice_deposits[0]?.id,
                    amount: responseJson.data.attributes.invoice_deposits[0]?.amount,
                    payment_method: responseJson.data.attributes.invoice_deposits[0]?.payment_method,
                    transaction_date: new Date(responseJson.data.attributes.invoice_deposits[0]?.transaction_date),
                    details: responseJson.data?.attributes?.invoice_deposits[0]?.details,
                    pay_balance: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].pay_balance,
                    collected: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].collected
                },
                recurringJobData: this.setRecurringJobData({frequency,summary,every,durationData})


            }, () => {
              
                this.getJobCustomerDetails()
                this.getSelectedJobSite()
            })
        }
        localStorage.removeItem('customerTabId')
    }
    setRecurringJobData = (response: {
        frequency: string,
        summary: string,
        every: number,
        durationData: string[]
    }): {
        frequency: string,
        duration: { day: string, frequency: string },
        day: number,
        weekDays: string,
        monthlyDay: string,
        summary: string
    } => {
        const { frequency, summary, every, durationData } = response;

        const data = {
            frequency: frequency ? frequency.charAt(0).toUpperCase() + frequency.slice(1) : "",
            duration: {
                day: durationData && durationData.length > 0 ? durationData[0] : "",
                frequency: durationData && durationData.length > 1 ? durationData[1] : "",
            },
            day: every || 0,
            weekDays: "", // Initialize this to whatever value it should have
            monthlyDay:frequency==="monthly" && summary || "",
            summary: summary || "",
        };

        return data;
    };
    
    renderEmployeeDetails = (item: any) => {
        return {
            id: item.id,
            first_name: item.name
        }
    }
    createJobApiResponce = (responseJson: any) => {

        if (responseJson && !responseJson.errors) {
            this.setState({
                createdJobDetails: responseJson.data
            }, async () => {
                await setStorageData(configJSON.storageText.created, true)
                this.manageSaveMore()
                const redirectUrl = await getStorageData("calendarRedirection");
                    this.redirect(redirectUrl);
            })
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        }
    }
    markAsCompleteJobApiResponce = (responseJson: { data:any, errors: string; message: string; }) => {
        if (responseJson && !responseJson.errors) {
            localStorage.setItem("selectedEventId", this.state.createdJobDetails&&this.state.createdJobDetails.id)
            localStorage.setItem('type', 'edit')
            navigateTo({
                props: this.props,
                screenName: "JobEdit",
            })
            this.setState({ jobLoading: false ,anchorEl: null , jobCompleteData:responseJson.data })
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        }
    }
    reopenJobApiResponce = (responseJson: { errors: string; message: string; }) => {
        if (responseJson && !responseJson.errors) {
            navigateTo({
                props: this.props,
                screenName: "Jobs",
            })
            this.setState({ jobLoading: false })
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        }
    }
    updateJobApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            const updateJobEditForm = {
                jobId: responseJson.data.data.attributes.job_id,
                jobTitle: responseJson.data.data.attributes.job_title,
            }
            let editedFiles = responseJson.data.data.attributes.files
            let updateProductsData = responseJson.data.data.attributes.products.data.map((product: any, index: number) => {
                return {
                    product_id: product.attributes.product_id,
                    service_id: product.attributes.service_id,
                    productName: product.attributes.product_name,
                    description: product.attributes.description,
                    quantity: product.attributes.quantity,
                    unitPrice: product.attributes.unit_price,
                    totalCost: product.attributes.total,
                    files: product.attributes.files || [],
                    product_item_id: product?.id,
                    completed: true,
                    currency:product.attributes.currency
                }
            })

            let oneOffJobDetailsUpdated = {
                jobStartDate: new Date(responseJson.data.data.attributes.start_date),
                jobEndDate: new Date(responseJson.data.data.attributes.end_date),
                jobStartTime: responseJson.data.data.attributes.start_time || "",
                jobEndTime: responseJson.data.data.attributes.end_time || "",
            }
            this.setState({
                singleJobDetails: responseJson.data.data,
                jobForm: updateJobEditForm,
                JobTypeDetails: oneOffJobDetailsUpdated,
                internalNotes: responseJson.data.data.attributes.internal_notes || "",
                productServices: updateProductsData,
                jobEditedFiles: editedFiles || [],
                internalAttachments:[],
                jobCreateToasterStatus: true,
                jobCreateToasterText: `${this.props.t('jobs.toasterText.updated')}`,
                isJobEdit: false,
                employee_id: responseJson.data.data.attributes.employee_id,

            }, async () => {
                this.props.openToastHandler(this.state.jobCreateToasterText,'success')
                this.getJobCustomerDetails()
                this.getSelectedJobSite()
                this.getJobDetails()
                const redirectUrl = await getStorageData("calendarRedirection");
                    this.redirect(redirectUrl);
            })
        }

    }
    deleteJobApiResponce = async (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                jobLoading: false
            })
            await setStorageData("jobArcheiveStatus", true)
            this.props.navigation.history.push('/Jobs')
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)

        }

    }
    getProductServicesApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ productData: responseJson })
            this.setState({ jobsPageLoading: false })
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        }
    }

    // Api calls started
    getEmployee = async () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const apiEndPoint = configJSON.getEmployeeApiEndPoint
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        const getEmployeeApiCall = apiCall({
            header: header,
            httpBody: null,
            url: `${apiEndPoint}`,
            httpMethod: configJSON.validationApiMethodType,
        });
        this.apiCallIdGetEmployee = getEmployeeApiCall.messageId;
        runEngine.sendMessage(getEmployeeApiCall.id, getEmployeeApiCall);
    }
    getJobCustomerDetails = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.storageToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCustomerDetailsApiCallId = requestMessage.messageId;
        const jobMode = this.props.navigation?.history?.location.state?.type

        if (this.state.pageReceivedFrom === "RequestTo" || "QuoteTo") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.customersEndPoint}/${this.state.details?.attributes?.customer_id}?lang=${this.state.selectedLanguage}`
            );
        }
        const jobType = await getStorageData("type")
        if(jobType==='edit'){
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.customersEndPoint}/${this.state.singleJobDetails?.attributes?.customer_id}`
            );  
        }

        if (jobMode === "edit") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.customersEndPoint}/${this.state.singleJobDetails?.attributes?.customer_id}`
            );
        }
        if (this.props.navigation?.history?.location.state?.reDirectionFrom === "customer") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.customersEndPoint}/${this.props.navigation?.history?.location.state?.id}`
            );
        }


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    createJobproductAndServices = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.storageToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdGetProductServices = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.products_and_servicesEndPoint}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    convertFrequencyValue = (frequency: string) => {
        let frequencyValue;
        if (frequency === `${this.props.t('jobs.scheduling.frequency.daily')}`) {
            frequencyValue = `${this.props.t('jobs.scheduling.days')}`;
        } else if (frequency === `${this.props.t('jobs.scheduling.frequency.weekly')}`) {
            frequencyValue = `${this.props.t('jobs.scheduling.frequency1.weeks')}`;
        } else if (frequency === `${this.props.t('jobs.scheduling.frequency.monthly')}`) {
            frequencyValue = `${this.props.t('jobs.scheduling.frequency1.months')}`;
        } else {
            frequencyValue = `${this.props.t('jobs.scheduling.frequency1.years')}`;
        }
        return frequencyValue
    };

    appendFormdata = (key: string, createJobBody: FormData) => {
        let value = this.state.recurringJobData[key];
        if (key === "duration") {
            // Handle duration separately
            let frequencyValue = this.convertFrequencyValue(value.frequency);

            let durationValue = value.day + "." + frequencyValue;
            createJobBody.append(`data[${key}]`, durationValue);
        } else if (key === "day") {
            createJobBody.append(`data[every]`, value);
        } else if (key === "frequency") {
            createJobBody.append(`data[${key}]`, value.toLowerCase());
        } else {
            createJobBody.append(`data[${key}]`, value);
        }
    }



    createJob = () => {
        const startTimejob = this.state.JobTypeDetails.jobStartTime
        const endTimejob = this.state.JobTypeDetails.jobEndTime
        const translatedOpensAt = this.translateToEnglishTime(startTimejob);
        const translatedClosedAt = this.translateToEnglishTime(endTimejob);
        const transactionDate: Date|string = this.state.collectedPaymentForm.transaction_date
        const header = {
            "token": localStorage.getItem(configJSON.storageToken)
        };
        this.setState({
            jobLoading: true
        })
        let createJobBody = new FormData();
        createJobBody.append("data[job_title]", this.state.jobForm.jobTitle);
        //@ts-ignore
        createJobBody.append("data[customer_id]", this.state.customerDetails.id);
        createJobBody.append("data[site_id]", this.state.siteDetails.id || this.state.details?.attributes?.site_id);
        createJobBody.append("data[start_date]", this.state.JobTypeDetails.jobStartDate);
        createJobBody.append("data[end_date]", this.state.JobTypeDetails.jobEndDate);
        createJobBody.append("data[start_time]", translatedOpensAt);
        createJobBody.append("data[end_time]", translatedClosedAt);
        createJobBody.append("data[scheduling]", this.state.jobType)
        createJobBody.append("data[employee_ids]", this.state.teamMember.toString())
        createJobBody.append("data[internal_notes]", this.state.internalNotes)
        createJobBody.append("data[sub_total]", this.getSubTotal(this.state.productServices).toString());
        createJobBody.append("data[total]", this.calculateTotal());
        createJobBody.append("data[tax]", this.state.priceSectionData.tax.toString()); 
        createJobBody.append("data[tax_name]", this.state.priceSectionData.tax_name); 
        createJobBody.append("data[discount]", this.state.priceSectionData.discount.toString());
        createJobBody.append("data[currency]", this.state.currencySym);
        createJobBody.append("data[discount_type]", this.state.selectCurrency);
        createJobBody.append("data[invoice_deposit_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
        createJobBody.append("data[invoice_deposit_attributes][amount]",this.state.collectedPaymentForm.amount);
        createJobBody.append("data[invoice_deposit_attributes][transaction_date]", transactionDate.toString());
        createJobBody.append("data[invoice_deposit_attributes][pay_balance]", this.state.collectedPaymentForm.pay_balance.toString());
        createJobBody.append("data[invoice_deposit_attributes][details]",this.state.collectedPaymentForm.details);
        createJobBody.append("data[invoice_deposit_attributes][collected]", this.state.collectedPaymentForm.collected.toString());
        if (this.props.navigation?.history?.location.state?.convertedType === "RequestTo") {
            createJobBody.append("data[request_id]", this.state.details.id)

        }
        if (this.props.navigation?.history?.location.state?.quoteid) {
            createJobBody.append("data[request_id]", this.state.details?.attributes?.request_id || "")
            createJobBody.append("data[quote_id]", this.props.navigation?.history?.location.state?.quoteid)
        }

        for (let file of this.state.internalAttachments) {
            createJobBody.append("data[files][]", file);
        }


        if (this.state.jobType === "recurring_job") {
            createJobBody.append("data[day]", this.state.mockFullWeekDays[new Date(this.state.JobTypeDetails.jobStartDate).getDay()]);
            for (const key in this.state.recurringJobData) {
                if (this.state.recurringJobData.hasOwnProperty(key)) {
                    this.appendFormdata(key, createJobBody)
                }
            }
        }



        { this.updateProductServiceBody(createJobBody) }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdCreateJob = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.JobsEndPoint}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), createJobBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getJobDetails = async () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const selectedEventId = await getStorageData('selectedEventId')
        const cusJobId = await getStorageData('customerTabId')
        const jobId = this.props.navigation?.history?.location?.state?.jobId || selectedEventId
        this.apiCallIdGetJobDetails = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.JobsEndPoint}/${jobId||cusJobId}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getCurrency = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "token": token
        };
        const getCurrencyApiCall = apiCall({
            header: header,
            httpBody: null,
            url: configJSON.getCurrency,
            httpMethod: configJSON.validationApiMethodType,
        });
        this.apiCallIdGetCurrency = getCurrencyApiCall.messageId;
        runEngine.sendMessage(getCurrencyApiCall.id, getCurrencyApiCall);
    }
    translateToEnglishTime = (arabicTime: string) => {
    const [hour, minute, period] = arabicTime?.split(/[ :]/);
    let translatedHour = String(hour);
    let translatedMinute = String(minute);
    
    const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
    const englishNumerals = "0123456789";
    
    for (let checktime = 0; checktime < arabicNumerals.length; checktime++) {
      translatedHour = translatedHour.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
      translatedMinute = translatedMinute.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
    }

    let translatedPeriod = period;
    if (period === "مساء") {
      translatedPeriod = "PM";
    } else if (period === "صباخا") {
      translatedPeriod = "AM";
    }

    if (period === "مساء" && parseInt(translatedHour, 10) < 12) {
      translatedHour = String(parseInt(translatedHour, 10) + 12);
    }

    translatedHour = translatedHour.padStart(2, '0');
    translatedMinute = translatedMinute.padStart(2, '0');

    return `${translatedHour}:${translatedMinute} ${translatedPeriod || ""}`;
  };
    updateJob = async () => {

        const startTime = this.state.JobTypeDetails.jobStartTime
        const endTime = this.state.JobTypeDetails.jobEndTime
        const translatedOpensAt = this.translateToEnglishTime(startTime);
        const translatedClosedAt = this.translateToEnglishTime(endTime);
        const transactionDate:Date|string = this.state.collectedPaymentForm.transaction_date
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "token": token
        };
        this.setState({
            jobLoading: true
        })
        let updateJobBody = new FormData();
        updateJobBody.append("data[job_title]", this.state.jobForm.jobTitle);
        //@ts-ignore
        updateJobBody.append("data[customer_id]", this.state.customerDetails?.id);
        updateJobBody.append("data[site_id]", this.state.siteDetails?.id || this.state.singleJobDetails.attributes?.site_id);
        updateJobBody.append("data[start_date]", this.state.JobTypeDetails.jobStartDate);
        updateJobBody.append("data[end_date]", this.state.JobTypeDetails.jobEndDate);
        updateJobBody.append("data[start_time]", translatedOpensAt);
        updateJobBody.append("data[end_time]", translatedClosedAt);
        updateJobBody.append("data[scheduling]", this.state.jobType)
        updateJobBody.append("data[employee_ids]", this.state.teamMember.toString())
        updateJobBody.append("data[internal_notes]", this.state.internalNotes);
        updateJobBody.append("data[sub_total]", this.getSubTotal(this.state.productServices).toString());
        updateJobBody.append("data[total]", this.calculateTotal());
        updateJobBody.append("data[tax]", this.state.priceSectionData.tax.toString()); 
        updateJobBody.append("data[tax_name]", this.state.priceSectionData.tax_name); 
        updateJobBody.append("data[discount]", this.state.priceSectionData.discount.toString());
        updateJobBody.append("data[currency]", this.state.currencySym);
        updateJobBody.append("data[discount_type]", this.state.selectCurrency);
        updateJobBody.append("data[invoice_deposits_attributes][id]", this.state.collectedPaymentForm.id);
        updateJobBody.append("data[invoice_deposits_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
        updateJobBody.append("data[invoice_deposits_attributes][amount]",this.state.collectedPaymentForm.amount);
        updateJobBody.append("data[invoice_deposits_attributes][transaction_date]", transactionDate.toString());
        updateJobBody.append("data[invoice_deposits_attributes][pay_balance]", this.state.collectedPaymentForm.pay_balance.toString());
        updateJobBody.append("data[invoice_deposits_attributes][details]",this.state.collectedPaymentForm.details);
        updateJobBody.append("data[invoice_deposits_attributes][collected]", this.state.collectedPaymentForm.collected.toString());
        for (let file of this.state.internalAttachments) {
            updateJobBody.append("data[files][]", file);
        }
        for (let file of this.state.jobConvertedFiles) {
            updateJobBody.append("data[file_id][]", file.id);
        }
        if (this.state.jobType === "recurring_job") {
            for (const key in this.state.recurringJobData) {
                updateJobBody.append("data[day]", this.state.mockFullWeekDays[new Date(this.state.JobTypeDetails.jobStartDate).getDay()]);
                if (this.state.recurringJobData.hasOwnProperty(key)) {
                    this.appendFormdata(key, updateJobBody)
                }
            }
        }
        { this.updateProductServiceBody(updateJobBody) }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdUpdateJob = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.JobsEndPoint}/${this.state.singleJobDetails?.id}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), updateJobBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateApiMethodtype
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    updateProductServiceBody = (updateJobBody: any) => {
        this.state.productServices?.forEach((product: { quantity: string, unitPrice: string, totalCost: string, description: string; file_id: string | []; files: []; product_item_id: string, product_id: string, service_id: string, }, index: number) => {
            updateJobBody.append(`data[product_items_attributes][${index}][quantity]`, product.quantity);
            updateJobBody.append(`data[product_items_attributes][${index}[unit_price]`, product.unitPrice);
            updateJobBody.append(`data[product_items_attributes][${index}[currency]`, this.state.currencySym);
            updateJobBody.append(`data[product_items_attributes][${index}][total]`, product.totalCost);
            updateJobBody.append(`data[product_items_attributes][${index}][description]`, product.description);

            if (product.product_item_id) {
                updateJobBody.append(`data[product_items_attributes][${[index]}][product_item_id]`, product.product_item_id);
            }
            if (product.product_id) {
                updateJobBody.append(`data[product_items_attributes][${[index]}][product_id]`, product.product_id);
            }
            if (product.service_id) {
                updateJobBody.append(`data[product_items_attributes][${[index]}][service_id]`, product.service_id);
            }
        })
    }

    deleteJobSoft = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "token": token
        };
        this.setState({
            jobLoading: true
        })

        let deleteJobBody = new FormData();
        deleteJobBody.append("data[status]", "cancelled")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdDeleteJob = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.JobsEndPoint}/${this.state.singleJobDetails?.id}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), deleteJobBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateApiMethodtype
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Api calls ended
    manageSaveMore = () => {
        if (this.state.savingMode === "saveMoreEmail") {
            this.handleCloseSaveMore()
            this.handleEmailOpen()

        } else if (this.state.savingMode === "saveMorePdf") {
            this.handleCloseSaveMore()
            this.handlePdfOpen()

        } else if (this.state.savingMode === "saveMoreQuote") {
            this.props.navigation.history.push("/Quote/Create", {
                requestId: this.state.details.id,
                convertedType: "RequestTo",
                jobId: this.state.createdJobDetails?.id
            })
        } else if (this.state.savingMode === "saveMoreMarkAsComplete"){
            this.markAsCompleteJob()
        }
        else {
            this.props.navigation.history.push("/Job/Edit", {
                convertedType: this.props.navigation?.history?.location.state?.convertedType,
                jobId: this.state.createdJobDetails?.id,
                type: "edit",
                requestId: this.state.details.id,
                quoteid: localStorage.getItem("quote_id")
            })
        }

    }
    saveMapData = (customer: any) => {
        const selectedPlace = {
            lat: parseFloat(customer.latitude),
            lng: parseFloat(customer.longitude),
        };
        this.setState({ getCustomerPlace: selectedPlace });

        const map = this.mapRef.current;
        if (map) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(selectedPlace);
            map.fitBounds(bounds, 100);
            map.setZoom(15);
        }
    }

    handleJobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ jobForm: { ...this.state.jobForm, [event.target.name]: event.target.value } },
            () => {
                this.debounceUpdateEvents()
                this.setState({ isJobErrorFormData: { ...this.state.isJobErrorFormData, ...this.jobTitleValidation() } })
            });
    }
    handleOnBlur = () => {
        this.setState({ isJobErrorFormData: { ...this.state.isJobErrorFormData, ...this.jobTitleValidation() } })
    }

    productOnBlur = () => {
        this.setState({ isJobErrorFormData: { ...this.state.isJobErrorFormData, ...this.productServiceValidation() } })
    }
    redirect = (url: string) => {
        if (url) {
            setTimeout(this.clearRedirectUrl, 2000);
            navigateTo({
                props: this.props,
                screenName: "Calendar",
              })
        } else {
            setTimeout(this.clearRedirectUrl, 2000);
        }
    };
    clearRedirectUrl = async () => {
        await removeStorageData("calendarRedirection");
        await removeStorageData("selectedEventId");
        await removeStorageData("calendarView")
        await removeStorageData("type");
        await removeStorageData("startDate");
        await removeStorageData("endDate");
    };
    saveJob = (save: string) => {
        this.setState({
            savingMode: save,
            isJobErrorFormData: this.validateJobForm()
        },
            async () => {
                if (Object.keys(this.state.isJobErrorFormData).length === 0) {
                    this.createJob();
                }
            }
        );
    }
    updateJobBtn = () => {
        this.setState({
            isJobErrorFormData: this.validateJobForm()
        },
            async () => {                     
                if (Object.keys(this.state.isJobErrorFormData).length === 0) {
                    this.updateJob();
                }
            }
        );
    }
    reopen=(status:string)=>{
        this.updateJobBtn()
        if(status==='reopen'){
            this.reopenJob()
        }else{
            this.markAsCompleteJob()
        }
    }
    validateJobTitle = (title: any, errors: any) => {
        if (!title) {
            errors.title = configJSON.errorJobText.titleEmptyText
        } else if (!configJSON.characteValidationRegex.test(title)) {
            errors.title = configJSON.errorJobText.titleCharactersText;
        } else if ((title).length > 40) {
            errors.title = configJSON.errorJobText.titleCharacterslengthText
        }
    }
    validateJobNotes = (notes: string, errors: any) => {
        if (notes && !configJSON.characteValidationRegex.test(notes)) {
            errors.internalNotes = configJSON.errorJobText.notesCharacterText;
        }
    }
    //   Routes started
    cancelCreateRequest = () => {
        this.props.navigation.history.push("/Jobs")
    }
    generateInvoice = () => {
        const jobId = this.state.singleJobDetails&&this.state.singleJobDetails.attributes&&this.state.singleJobDetails.attributes.job_id
        this.props.navigation.history.push('/Invoice', {
            jobId: jobId,
            selectJobId:JSON.stringify(parseInt(jobId&&jobId.match(/(\d+)/)[0], 10)),
            customerId: this.state.customerDetails&&this.state.customerDetails.id,
            siteId: this.state.siteDetails?.id || this.state.singleJobDetails.attributes?.site_id,
            isCustomerSelected: true,
            isSelectedJob: true,
            productServices: this.state.productServices.map((item: ProductServiceType) => {
                return {
                    product_id: item.product_id,
                    service_id: item.service_id,
                    description: item.description,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    totalCost: item.totalCost,
                    files: item.files || [],
                    completed: true,
                    progress: 100
                }
            })
        })
    }

    handleViewDataCallback = (isEmployee: string, isPermit: IsPermit) => {
        if (isEmployee) {
            return this.handleViewDataCallbackV1(isPermit)
        } else {
            return ["VIEW", "CREATE", "EDIT", "DELETE", "COMPLETE"];
        }
    };

    handleLanguageViewData = async (passed_category: string) => {
        const isPermit = await checkPermission(passed_category) as IsPermit;
        const isEmployee = await checkLoggedInUser();
        return this.handleViewDataCallback(isEmployee, isPermit);
    };

    handleViewDataCallbackV2 = (isPermit: IsPermit) => {
        if (isPermit && isPermit[0] === this.props.t('jobs.ViewOwnData')) {
            return ["VIEW"];
        }
        else if (isPermit && isPermit[0] === this.props.t('jobs.EditOwnData')) {
            return ["EDIT", "VIEW"];
        }
    }

    handleViewDataCallbackV1 = (isPermit: IsPermit) => {
        if (isPermit && !isPermit[1]) {
            return [];
        }
        switch (isPermit && isPermit[0]) {
            case this.props.t('jobs.ViewCreateEditAndDeleteTasksCreated'):
                return ["VIEW", "CREATE", "EDIT", "DELETE"];
            case this.props.t('jobs.ViewCreateEditAndDelete'):
                return ["VIEW", "CREATE", "EDIT", "DELETE"];
            case this.props.t('jobs.ViewCreateEdit'):
                return ["VIEW", "CREATE", "EDIT"];
            case this.props.t('jobs.ViewOnly'):
                return ["VIEW"];
            case this.props.t('jobs.ViewCompleteTasksAssignedThem'):
                return ["VIEW", "COMPLETE"];
            case this.props.t('jobs.None'):
                return ["NONE"];
            default:
                return this.handleViewDataCallbackV2(isPermit);
        }
    }

    // Popups And Dailogs
    handleOpenDialog = async () => {
        const jobs = this.props.t("jobs.job")
        const isAuthorized = await this.handleLanguageViewData(jobs);
        this.handleOpenV1DialogCallback(isAuthorized)

    }
    handleOpenV1DialogCallback = (isAuthorized: any) => {
        if (isAuthorized.includes("CREATE")) {
            this.setState({
                customerDailog: true
            })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }
    handleOpenSaveMore = (event: any) => {
        this.setState({
            anchorElV1: event&&event.currentTarget
        }, this.handleOpenCallbackV1)
    }

    handleOpenMore = (event: any) => {
        this.setState({
            anchorElMore: event.currentTarget
        })
    }

    handleOpenCallbackV1 = async () => {
        const jobs = this.props.t("jobs.job")
        const isAuthorized = await this.handleLanguageViewData(jobs); 
        this.handleOpenCallback(isAuthorized)
    }

    handleOpenCallback = (isAuthorized: any) => {
        if (isAuthorized.includes("EDIT")) {
            this.setState({
                anchorEl: this.state.anchorElV1
            })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }

    handleCloseSaveMore = () => {
        this.setState({
            anchorEl: null
        })
    };
    handleCloseMore = () => {
        this.setState({
            anchorElMore: null
        })
    };
    handleEmailOpen = () => {
        this.setState({
            sendEmailDailog: true
        })
    }
    getEmailModal = (status: boolean) => {
        this.setState({
            sendEmailDailog: false
        })
    }
    handlePdfOpen = () => {
        this.setState({
            jobPdfDailog: true
        })
    }
    handlePdfclose = () => {
        this.setState({
            jobPdfDailog: false
        }, () => {
            this.props.navigation.history.push("/Job/Edit", {
                type: 'edit',
                jobId: this.state.createdJobDetails?.id
            })
        })
    }
    getCustomerDetails = (details: any) => {
        this.setState({
            customerDetails: details
        })
    }
    getJobSiteDetails = (sitDetails: any) => {
        this.setState({
            siteDetails: sitDetails
        }, () => {
            this.saveMapData(this.state.siteDetails)
        })
    }
    getOneOffJobDetails = (details: any) => {
        this.setState({ JobTypeDetails: details },()=>{
            this.debounceUpdateEvents()
        })
    };

    getRecurringJobDetail = (details: RecurringJobData) => {
        this.setState({ recurringJobData: details },()=>{
            this.debounceUpdateEvents()
        })
    }

    getActiveJobs = (job: any) => {
        this.setState({ jobType: job })
    }

    updateInternalNotes = (internalNotes: string) => {
        this.setState({ internalNotes: internalNotes })
    }
    updateInternalAttachments = (attachments: any) => {
        this.setState({ internalAttachments: attachments })
    }

    // Product And Services
    handleChangeProductInput = (
        index: number,
        event: { target: { name: string; value: string; }; value: { type: string; id: string; total_price: string; description: string; }; }
    ) => {
        const values = [...this.state.productServices];
        if (event?.target) {
            values[index][event.target.name] = event.target.value;
            this.setState({ productServices: values })
        } else {
            if (event.value.type === 'service') {
                values[index]['service_id'] = event.value.id;
                values[index]['product_id'] = null;
                values[index]['quantity'] =
                    1
                values[index]['totalCost'] = event.value.total_price
                values[index]['unitPrice'] =
                    event.value.total_price
            } else {
                values[index]['service_id'] = null;
                values[index]['product_id'] = event.value.id;
                values[index]['quantity'] = 1
                values[index]['unitPrice'] = event.value.total_price
            }
            values[index]['description'] = event.value.description;
            this.setState({ productServices: values })
        }
    };
    handleDownloadClick = async (file: any) => {
        try {
            const url = file.url ? file.url : URL.createObjectURL(file);

            const response = await fetch(url);
            const blob = await response.blob();

            // Create an anchor element
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = file.name;

            // Trigger a click on the anchor link
            downloadLink.click();

            // Clean up the object URL
            URL.revokeObjectURL(downloadLink.href);
        } catch (error) {
            this.setState({
                showErrorMessage: true,
                errorMessage: "Error Downloading The File, Please Try Again.",
            });
            this.props.openToastHandler(this.state.errorMessage, "error")
        }
    };
    handleChangeProductFiles = (index: any, event: any) => {
        const values = [...this.state.productServices];
        const files: any = event.target?.files;
        if (files.length > 0 && [...values[index].files, ...files].length > 4) {
            this.props.openToastHandler(`${this.props.t('invoice.maxFile')}`, "error")

        } else if (files.length > 0 && [...values[index].files, ...files].length > 0 && [...values[index].files, ...files].length < 5) {
            this.setState({
                showErrorMessage: false,
                errorMessage: "",
            });
            if (values[index].files && values[index].files.length === 0) {
                values[index][event.target?.name] = [...files]
            } else {
                values[index][event.target?.name] = [...values[index].files, ...files]
            }
            this.setState({ productServices: values, fileToaster: false, imgToasterText: "" }, () => {
                this.handleFileUpload(files, index);
            });
        }

    };
    handleFileUpload = (files: any, index: any) => {

        if (files) {
            for (const file of files) {

                const reader: any = new FileReader();
                reader.onloadstart = this.readUploadOnLoadStart(index);
                reader.onprogress = (event: any) => this.readUploadOnProgress(event, index);
                reader.onload = this.readUploadOnLoad(index);
                reader.readAsDataURL(file);
            }
        }
    };
    readUploadOnLoadStart = (index: any) => {
        const values = [...this.state.productServices];
        values[index]['progress'] = 0
        this.setState({ productServices: values });
    };
    readUploadOnProgress = (event: any, index: any) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            const values = [...this.state.productServices];
            values[index]['progress'] = percentComplete
            this.setState({ productServices: values });
        }
    };
    readUploadOnLoadCallbackTimeout = (index: any) => {
        const values = [...this.state.productServices];
        values[index]['completed'] = true
        this.setState({ productServices: values, count: 0 });
    };

    readUploadOnLoadCallback: any = (index: any) => {
        setTimeout(this.readUploadOnLoadCallbackTimeout.bind(this, index), 500);
    };
    readUploadOnLoad = (index: any) => {
        const values = [...this.state.productServices];
        values[index]['progress'] = 100
        setTimeout(() => {
            this.setState({ productServices: values });
            this.setState(
                (prevState) => ({
                    count: prevState.count + 1,
                }),
                () => this.readUploadOnLoadCallback(index)
            );
        }, 500);
    };
    handleProductDocument = (e: any) => {
        let reader = new FileReader();
        const files = e.target?.files[0];
        if (files) {
            reader.readAsDataURL(files);
        }
        this.setState({
            productServices: { ...this.state.productServices, [e.target?.name]: e.target?.files }
        });
    }
    addField = () => {
        this.setState({
            productServices: [...this.state.productServices, {
                product_id: "",
                service_id: '',
                productName: "",
                description: "",
                quantity: '',
                unitPrice: '',
                totalCost: '',
                files: [],
                completed: false
            }]
        });
    }
    handleRemoveFile = (index: any, file: any) => {
        if (this.state.isEditable) {
            const values = [...this.state.productServices];
            const filesFiltered = Array.from(values[index]['files']).filter((f: any) => {
                return f.name !== file.name;
            });
            values[index]['files'] = filesFiltered
            values[index]['completed'] = true
            values[index]['progress'] = 100
            if (values[index]['file_id'] && values[index]['file_id'].length > 0) {
                values[index]['file_id'] = [...values[index]['file_id'], file.id]
            } else {
                values[index]['file_id'] = [file.id]
            }

            this.setState({ productServices: values })
        }
    };
    handleChangeSearchInput = (event: any) => {
        this.setState({ productSearch: event })
    }
    removeField = (index: any) => {
        const values = [...this.state.productServices];
        values.splice(index, 1);
        this.setState({ productServices: values })
    };

    handleUpdateCancel = () => {
        this.setState({ isJobEdit: false })

    }
    handleRequestEdit = async () => {
        const jobs = this.props.t("jobs.job")
        const isAuthorized = await this.handleLanguageViewData(jobs); 
        
        this.handleJobEditCallback(isAuthorized)
    }
    
    handleJobEditCallback = (isAuthorized: any) => {
        console.log('isAuthorized edit ===>', isAuthorized);
        
        if (isAuthorized.includes("EDIT")) {
            console.log('isAuthorized.includes("EDIT")', isAuthorized.includes("EDIT"));
            this.setState({
                isJobEdit: true
            })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }
    handleJobCreatedToaster = () => {
        this.setState({
            jobCreateToasterStatus: false
        })
    }
    handleJobUpdatedCloseToaster = () => {
        this.setState({
            jobUpdateToasterStatus: false
        })
    }
    handleOpenJobDeleteDailog = async () => {
        const jobs = this.props.t("jobs.job")
        const isAuthorized = await this.handleLanguageViewData(jobs); 
        this.handleOpenDeleteDialogCallback(isAuthorized)

    }
    handleOpenDeleteDialogCallback = (isAuthorized: any) => {
        if (isAuthorized.includes("DELETE")) {
            this.setState({ deleteDailogStatus: true })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }
    handleCloseJobDeleteDailog = () => {
        this.setState({ deleteDailogStatus: false })
    }
    openEmailModal = () => {
        this.setState({
            sendEmailDailog: true
        })
    }
    closeEmailModal = (status: boolean, messageStatus: string) => {
        this.setState({
            sendEmailDailog: status
        }, () => {
            if (messageStatus === "success") {
                this.props.openToastHandler(`${this.props.t('invoice.emailSuccessMsg')}`, "success")

            }
        })
    }

    getJobEditedFiles = (files: any) => {
        this.setState({
            jobEditedFiles: files
        })
    }
    getJobConvertedFiles = (files: any) => {
        this.setState({
            jobConvertedFiles: files
        })
    }


    // Validation product ans services

    validateInputFields = (title: any, errors: any, key: any) => {
        if (!title) {
            errors[key] = configJSON.errorsJobtext[key].titleEmptyText
        }
    }
    validateJobProductsAndServices = (array: any, errors: any) => {
        array.forEach((item: any, index: any) => {
            if ((item.product_id === null || item.product_id === '') && (item.service_id === null || item.service_id === '')) {
                errors.product_id[index] = `${this.props.t('errorMessage.productService')}`
            }
        });
    }
    clean(obj: any) {
        for (let propName in obj) {
            if (obj[propName]?.length === 0) {
                delete obj[propName];
            }
        }
        return obj
    }
    jobTitleValidation = () => {
        const errors: Error = { product_id: [], service_id: [], customerDetails: "", title: "" };
        this.validateInputFields(this.state.jobForm.jobTitle, errors, 'title')
        if (!this.state.jobForm.jobTitle) {
            errors.title = `${this.props.t('jobs.errorMsg.title')}`
        } else if ((this.state.jobForm.jobTitle).length > 40) {
            errors.title = `${this.props.t('jobs.errorMsg.titleLength')}`
        } 
        this.clean(errors)
        return errors;
    }
    productServiceValidation = () => {
        const errors: object = { product_id: [], service_id: [] };
        this.validateJobProductsAndServices(this.state.productServices, errors);
        this.clean(errors)
        return errors;
    }
    handleCusValidation = () => {
        this.setState({ isJobErrorFormData: { ...this.state.isJobErrorFormData, ...this.customerValidation() } })
    }
    customerValidation = () => {
        const errors: Error = { product_id: [], service_id: [], customerDetails: "", title: "" };
        if (Object.keys(this.state.siteDetails).length === 0) {
            errors.customerDetails = `${this.props.t('errorMessage.customerId')}`
        }
        if (Object.keys(this.state.customerDetails).length === 0) {
            errors.customerDetails = `${this.props.t('errorMessage.customerId')}`
        }
        this.clean(errors)
        return errors;
    }
    validateJobForm = () => {
        const errors: any = { product_id: [], service_id: [] };
        this.validateInputFields(this.state.jobForm.jobTitle, errors, 'title')
        if (!this.state.jobForm.jobTitle) {
            errors.title = `${this.props.t('jobs.errorMsg.title')}`
        } else if ((this.state.jobForm.jobTitle).length > 40) {
            errors.title = `${this.props.t('jobs.errorMsg.titleLength')}`
        }
        if (Object.keys(this.state.customerDetails).length === 0) {
            errors.customerDetails = `${this.props.t('errorMessage.customerId')}`

        }
        if (this.state.JobTypeDetails.jobStartDate < dateMinusOne(new Date())) {
            errors.jobStartDate = `${this.props.t('Calendar.ErrorMessage.minDate')}`
        }
        if (this.state.JobTypeDetails.jobEndDate < dateMinusOne(new Date()) && this.state.jobType === 'one_of_job') {
            errors.jobEndDate = `${this.props.t('Calendar.ErrorMessage.minDate')}`
        }
        this.validateJobProductsAndServices(this.state.productServices, errors);
        this.clean(errors)
        return errors;
    }


    handleDrawerOpen = () => {
        this.setState({ drawerStatus: true });
    }

    handleDrawerClose = () => {
        this.setState({ drawerStatus: false });

    };

    getConvertedDetails = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const quoteId = this.props.navigation?.history?.location?.state.quoteid
        const RequestId = this.props.navigation?.history?.location?.state.requestId
        const Type = this.props.navigation?.history?.location?.state.convertedType
        const jobMode = this.props.navigation?.history?.location?.state.convertedType

        this.apiCallIdGetConvertedDetails = requestMessage.messageId;

        if (Type === "RequestTo" || jobMode === "edit") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.requestEndPoint}/${RequestId}`
            );
        }
        if (Type === "QuoteTo" || jobMode === "edit") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.quoteEndPoint}/${quoteId}?lang=${this.state.selectedLanguage}`
            );
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDetailsApiResponce = (responceJson: any) => {
        if (responceJson && !responceJson.errors) {

            let convertedProducts = responceJson.data?.attributes?.products?.data.map((product: any, index: number) => {
                return {
                    product_id: product.attributes.product_id,
                    service_id: product?.attributes?.service_id,
                    productName: product.attributes.product_name,
                    description: product.attributes.description,
                    quantity: product.attributes.quantity,
                    unitPrice: product.attributes.unit_price,
                    totalCost: product.attributes.total || "",
                    files: product?.attributes?.files || [],
                    product_item_id: product?.id,
                    completed: true,
                    pogress: 100
                }
            })
            this.setState({
                details: responceJson?.data,
                productServices: convertedProducts || [],

            }, () => {
                this.getJobCustomerDetails()
                this.getSelectedJobSite()
            })
        }

    }
    editJobStatus = () => {
        this.setState({
            isJobEdit: false
        })
    }

    handleQuoteToJob = () => {
        localStorage.removeItem('quote_id')
        this.props.navigation.history.push('/Quote/Create', {
            convertedType: "RequestTo",
            requestId: this.state.details?.id,
            jobId: this.state.singleJobDetails?.id
        })
    }


    selectedJobCustomerButtonStatus = (selected: boolean) => {
        this.setState({
            selctedCustomerJob: selected,
        })
    }
    getCustomerSelectedStatus = (customerSelected: boolean, customerDailog: boolean) => {
        this.setState({
            customerSelected: customerSelected,
            customerDailog: customerDailog,

        })
    }
    clearJobCustomer = () => {
        this.setState({
            siteDetails: {},
            customerDetails: {},
            selctedCustomerJob: false
        }, () => {
            this.setState({ isJobErrorFormData: this.customerValidation() })
        })
    }

    getSelectedJobSite = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        this.setState({
            jobLoading: true
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdGetSiteDetails = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.customerSitesEndPoint}/${this.state.singleJobDetails?.attributes?.site_id || this.state.details?.attributes?.site_id}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    apiResponceSiteInformatation = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                siteDetails: responseJson.data
            }, () => {
                this.saveMapData(this.state.siteDetails)
            })
        }
    }
    getQuoteProductDetails = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const quoteId = this.props.navigation?.history?.location?.state.quoteid

        this.apiCallIdGetQuoteProductsDetails = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.quoteEndPoint}/${quoteId}?lang=${this.state.selectedLanguage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getJob = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        this.setState({ jobLoading: true })
        const apiEndPoint = configJSON.JobsEndPoint
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getJobApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),`${apiEndPoint}?status=all&employee_id=${this.state.teamMember}` );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    markAsCompleteJob = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        const apiEndPoint = configJSON.JobsEndPoint
        const type = this.props.navigation?.history?.location?.state?.type
        let apiUrl
        if (type === 'edit') {
            apiUrl = `${apiEndPoint}/${this.state.singleJobDetails.id}/mark_as_completed`
        } else {
            apiUrl = `${apiEndPoint}/${this.state.createdJobDetails.id}/mark_as_completed`
        }
        this.setState({ jobLoading: true })
       
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.markAsCompleteJobApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), apiUrl);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateApiMethodtype
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    reopenJob = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        this.setState({ jobLoading: true })
        const apiEndPoint = configJSON.JobsEndPoint
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.reopenJobApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${apiEndPoint}/${this.state.singleJobDetails.id}/reopen`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateApiMethodtype
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    quoteProductDetailsApiResponce=(responceJson:any)=>{
        if (responceJson && !responceJson.errors) {
            let productsData = responceJson.data?.attributes?.products?.data.map((product: any, index: number) => {
              return {
                product_id: product.attributes.product_id,
                service_id: product?.attributes?.service_id,
                productName: product.attributes.product_name,
                description: product.attributes.description,
                quantity: product.attributes.quantity,
                unitPrice: product.attributes.unit_price,
                totalCost: product.attributes.total || "",
                files: product?.attributes?.files || [],
                product_item_id: product?.id,
                completed: true,
                    pogress: 100
                }
            })

            this.setState({ productServices: productsData || [] })
        }
    }
    convertDate = (inputDateString: string) => {
        if (inputDateString) {
            const inputDate = new Date(inputDateString);
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const month = monthNames[inputDate.getMonth()];
            const day = inputDate.getDate();
            const year = inputDate.getFullYear();
            const formattedDateString = `${month} ${day}, ${year}`;
            return formattedDateString
        } else {
            return "--"
        }
       
    }
    handleVisitDialogClose = () => {
        this.setState({
            visitDialogOpen: false
        })
    }
    handleVisitDialogOpen = () => {
        this.setState({
            visitDialogOpen: true
        })
    }
    handleRadioChange = (event: any) => {
        this.setState({
            selectedRadio: event.target.value
        })
    }
    handleChangeTeam = (selected: { value: string; }[]) => {
        this.setState({ teamMember: selected })
    }
    handleRemoveTeam = (employeeId: string) => {
        this.setState({
            teamMember: this.state.teamMember&&this.state.teamMember.filter((item: string | object) => {
                return item !== employeeId
            })
        })
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, [event.target?.name]: event.target?.value } });
    }
    handleTransactionDate = (date: Date) => {
        this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, transaction_date: date } })
    }
    handlePriceSection = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ priceSectionData: { ...this.state.priceSectionData, [event.target?.name]: event.target?.value } });
    }
    handlePay_balance = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, pay_balance: event.target?.checked } });
    }
    handleCollected = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, collected: event.target?.checked } });
    }
    selectTaxRateInvoice = (taxRate: TaxRateResponse) => {
        this.setState({
            priceSectionData: {
                ...this.state.priceSectionData,
                tax_name: taxRate.name,
                tax: taxRate.rate,
            }
        })
    }
    emptyPriceSectionDataInvoice = (keyType: string) => {
        if (keyType === "discount") {
            this.setState({
                priceSectionData: {
                    ...this.state.priceSectionData,
                    discount: 0,
                }
            })
        } else {
            this.setState({
                priceSectionData: {
                    ...this.state.priceSectionData,
                    tax_name: "",
                    tax: 0,
                }
            })
        }

    }
    handleChangePaymentMethodRef(selected: { value: string }) {
        this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, payment_method: selected.value } })
    }
    selectCurrency = (selected: { value: string; label: string }) => {
        const { priceSectionData } = this.state;
        this.setState({
            selectCurrency: selected.value,
            priceSectionData: {
              ...priceSectionData,
              discount: 0
            }
          });
    }
    handleDiscountDialogOpen = () => {
        if (this.state.isEditable) {
            this.setState({ discountDialog: !this.state.discountDialog });
        }
    }
    getSubTotal = (productServices: ProductService[]) => {
        let total:number = 0
        productServices?.forEach((item: ProductService) => {
            if (item.quantity && item.unitPrice) {
                total += item.quantity * parseFloat(item.unitPrice);
            } else {
                total += parseFloat(item.totalCost);
            }
        });
        return total
    }
    currencyDiscount = (discount: number,subTotal:number) =>{
        if(this.state.selectCurrency !== '%'){
          return subTotal - this.state.priceSectionData.discount
        }else{
          return discount
        }
      }
      subTotalAfterDiscount = (currencyDiscountAmount:number,subTotal:number) =>{
        if(this.state.selectCurrency !== '%'){
          return currencyDiscountAmount
        }else{
          return subTotal - currencyDiscountAmount
        }
      }
      calculateTotal = () => {
        const subTotal = this.getSubTotal(this.state.productServices);
        const discount = (subTotal * this.state.priceSectionData.discount) / 100;
        const currencyDiscountAmount = this.currencyDiscount(discount,subTotal)
        const subtotalAfterDiscount = this.subTotalAfterDiscount(currencyDiscountAmount,subTotal);
        const taxAmount = (subtotalAfterDiscount * this.state.priceSectionData.tax) / 100;
        const total = subtotalAfterDiscount + taxAmount;
        return total.toFixed(2);
      };
    handlePaymentModalClose = () => {
        this.setState({
            collectedPayment: false,
            anchorEl1: null,
            nextModal: '',
            collectedPaymentForm: {
                id:'',
                amount: '',
                payment_method: '',
                details: '',
                pay_balance: false,
                collected: false,
                transaction_date: ''
            }
        })
    }
    handleCollectionPaymentModal = () => {
        this.setState({
          collectedPaymentForm: {
              id:'',
              amount: '',
              payment_method: '',
              transaction_date: '',
              details: '',
              pay_balance: false,
              collected: false
          }
        })
        this.setState({ isJobErrorFormData: this.validateJobForm() },
          () => {
            if (Object.keys(this.state.isJobErrorFormData).length === 0) {
              this.createJob();
            }
          }
        )
        this.setState({ nextModal: 'payment' });
      }
    handleCollectionPaymentClose = () => {
        this.setState({
            openNextModal: 'payment'
            , collectedPayment: false,
            nextModal: '',
            collectedPaymentForm: {
                id:'',
                amount: '', payment_method: '', transaction_date: '', details: '',
                pay_balance: false,
                collected: false
            }
        });
    }
    // Customizable Area End
}
