import React from "react";
// Customizable Area Start
import {
  Grid, Typography, Button, Box,Tooltip 
} from "@material-ui/core";
import {
  withStyles, createStyles, Theme
} from "@material-ui/core/styles";
import { uploadIcon , uploadButtonIcon } from "./assets";
import { StyledTextFieldQuote } from "../../RequestManagement/src/Utility.web"
import { FileUpload } from "../../../components/src/FileUpload.web";
import ResuableController, { Props, configJSON } from "./ResuableController.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import ClearIcon from '@material-ui/icons/Clear';
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from "../../../components/src/theme";
import withRequestStyles from "./RequestStyles.web";
// Customizable Area End



export class InternalNotesAttachments extends ResuableController {
  constructor(props: Props) {
    super(props);
  }

  showUploadedFilesCreate = (file: any) => {
    const { classes } = this.props
    return ( <>
      <Grid item xs={10} lg={7} key={file?.name} className={classes.fileLayout}>
        <Box className={classes.imageBox}>
          <Box className={classes.imageBoxV1}>
            <img
              className={classes.imageUpload}
              src={
                file?.name && this.state.extensionIcons[file.name.split(".")[1]]
              }
              alt="upload"
            />
          </Box>
          {/* text section */}
          <Box className={classes.textBox}>
            <Box className={classes.imageBoxV1}>
              <Typography
                gutterBottom
                variant="body2"
                className={classes.CreateCustTextTypo}
              >
                {file?.name && file.name.length > 20
                  ? file.name
                    .split(".")[0]
                    .concat(".")
                    .concat(file.name.split(".")[1])
                    .substring(0, 20) + "..."
                  : file?.name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.CreateSizeText}>
              {Math.floor(file?.size / 1024)}kb
            </Typography>
          </Box>
          <Box className={classes.uploadFileBox}>
            {this.props.editStatus &&
              <ClearIcon
                style={{ cursor: "pointer" }}
                onClick={this.removeUploadFile.bind(this, file)}
              />
            }
          </Box>
        </Box>
      </Grid>
      <Grid item xs={1} lg={4} className={classes.uploadIconGrid}>
      <Tooltip title="download">
        <img src={uploadButtonIcon} className={classes.uploadIconButton} data-test-id="downloadFiles" onClick={this.downloadUploadFiles.bind(this, file)} />
        </Tooltip>
      </Grid>
      </>
    );
  };
  uploadBox = () => {
    const { classes, t } = this.props;
    return <Grid item xs={12}>
      <Button
        variant="outlined"
        component="label"
        disableRipple
        disableElevation
        disableFocusRipple
        className={!this.props.editStatus?classes.uploadActive:classes.upload}
      >
        <img src={uploadIcon} className={classes.uploadIcon} />
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant="body2"
            className={classes.subTitle18}
          >
            <input
              hidden
              type="file"
              multiple
              data-test-id="uploadFiles"
              onChange={this.uploadFileChange}
              disabled={!this.props.editStatus}
            />
            {t ? t('ProductService.uploadFile') : 'Upload Files'}
          </Typography>
        </Box>
      </Button>

      {!this.state.completed && this.state.progress > 0 && (
        <FileUpload
          {...this.props}
          progress={this.state.progress}
          languageSelected={this.props.languageSelected}
        />
      )}
    </Grid>
  }
  disableStyle = () =>{
    const { classes } = this.props;
    return !this.props.editStatus  ? `${classes.notesInput} ${classes.disableBg}` : classes.notesInput
  }
  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.notesHeading}>{t? t('internalNote.title'):configJSON.titleFieldHeadings.notesText}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>

                {this.props.editStatus ?

                  <StyledTextFieldQuote
                    variant="outlined"
                    multiline
                    minRows={5}
                    maxRows={5}
                    className={classes.notesInput}
                    placeholder={t ? `${t('internalNote.placeholder.lorem')}` : "Note details"}
                    name="message"
                    value={this.state.internalNotes || this.props.internalNotes}
                    onChange={this.handleInternalNotesMessage}
                    data-test-id="notesmessage"
                  />
                  :
                  <StyledTextFieldQuote
                    variant="outlined"
                    multiline
                    minRows={5}
                    maxRows={5}
                    className={this.disableStyle()}
                    placeholder={t ? `${t('internalNote.placeholder.lorem')}` : "Note details"}
                    name="message"
                    value={this.state.internalNotes || this.props.internalNotes}
                    data-test-id="notesmessage"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                {this.uploadBox()}
                  <Grid item xs={12} className={classes.uploadrGrid}>
                    {
                      this.props.type != "edit" ?
                        <>
                        {this.state.completed &&
                          this.state.files.length > 0 &&
                          <Grid container>
                            <Typography variant="body1" gutterBottom className={`${classes.fontWeight_500} ${classes.mt_20} ${classes.mb_20} ${classes.font_lightBrown}`} >
                              {t ? t('ProductService.uploadFile') : "Upload Files:"}:
                            </Typography>
                            <Box className={classes.fileUploadBox}>
                            <Grid container direction="row" className={classes.uploadBoxGrid}>
                              {this.state.files.map((file: any) => {
                                return this.showUploadedFilesCreate(file);
                              })}
                            </Grid>
                            </Box>
                          </Grid>
                        }
                        </>
                        :
                        <>
                          {this.state.completed && [
                            ...this.state.editedFiles,
                            ...this.state.files,
                          ] &&
                            [...this.state.editedFiles, ...this.state.files]
                              .length > 0 &&
                              <Grid container>
                            <Typography variant="body1" gutterBottom className={`${classes.fontWeight_500} ${classes.mt_20} ${classes.mb_20} ${classes.font_lightBrown}`} >
                              {t ? t('ProductService.uploadFile') : "Upload Files:"}:
                            </Typography>
                            <Box className={classes.fileUploadBox}>
                              <Grid container direction="row" className={classes.uploadBoxGrid}>
                                {[...this.state.editedFiles, ...this.state.files].map(
                                  (file: any) => this.showUploadedFilesCreate(file)
                                )}
                              </Grid>
                            </Box>
                          </Grid>
                            }
                        </>
                    }
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const InternalNotesAttachmentsStyles = (theme: Theme) =>
  createStyles({
    notesHeading: {

      color: CONSTANTS.color.lightBrown,
      fontWeight: theme.typography.fontWeightMedium
    },
    disableBg: {
      backgroundColor: "#F2F2F2",
      cursor: "default",
      pointerEvents: "none"
    },
    notesInput: {
      width: "100%",
      borderRadius: "8px",
    },
    uploadIcon: {
      width: "20px",
      height: "20px",
      marginLeft: "12px",
      marginRight: "12px"
    },
    mt_20:{
        marginTop: '20px',
    },
    mb_20:{
      marginBottom:"20px"
    },
    fontWeight_500: {
      fontWeight: CONSTANTS.fontWeight.fontWeight500
    },
    uploadIconButton:{
      width:"40px",
      height:"40px",
      cursor:"pointer",
      [theme.breakpoints.down("xs")]: {
        width: "32px",
        height: "32px",
        cursor:"pointer"
      }  
    },
    uploadIconGrid:{
      alignItems:"center",
      display:"flex",
      marginLeft:"15px",
      [theme.breakpoints.down("xs")]: {
        marginLeft:"9px",
      },
    },
    uploadBoxGrid:{
      maxHeight:"240px",
      overflow:"scroll"
    },
    upload: {
      border: `2px dashed ${CONSTANTS.color.darkBlue}`,
      height: "60px",
      background: CONSTANTS.color.blueLignt,
      color: CONSTANTS.color.darkBlue,
      fontSize: "14px",
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: "capitalize",
      borderRadius: "8px",
      width: "100%",
      "&:hover": {
        background: CONSTANTS.color.blueLignt,
      }
    },
    uploadActive : {
      height: "60px",
      border: `2px dashed ${CONSTANTS.color.darkBlue}`,
      background: CONSTANTS.color.blueLignt,
      color: CONSTANTS.color.darkBlue,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: "14px",
      borderRadius: "8px",
      textTransform: "capitalize",
      width: "100%",
      "&:hover": {
        background: CONSTANTS.color.blueLignt,
      },
      cursor: "auto"
    },
    fileUploadBox:{
      width:"100%",
      padding:"20px 25px",
      border:"1px solid #D5D5D5",
      borderRadius:"12px",
      [theme.breakpoints.down("xs")]: {
        padding:"18px 13px",
      },
    },
    fileLayout: {
      width:"100%",
      marginBottom: "8px",
      borderRadius: "8px",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 0px 17px #1c18af",
    },
    font_lightBrown: {
      color: CONSTANTS.color.lightBrown
    },
    uploadFileBox: {
      width: "100%",
      margin: 0,
      display: "flex",
      justifyContent: "flex-end"
    },
    CreateCustUploadTypo: {
      color: CONSTANTS.color.lightBrown,
      fontSize: "14px",
      fontWeight: 400,
      textAlign: "right",
      marginBottom: 0,
      padding: "3px 5px 0 0",
      cursor: "pointer",
    },
    imageBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "12px 9px 12px 9px",
    },
    imageBoxV1: {
      marginRight: "9px",
    },
    imageUpload: {
      width: "38px",
      height: "40px",
    },
    textBox: {
      display: "flex",
      width:"100%"
    },
    uploadrGrid: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      padding: 0,
    },
    CreateCustTextTypo: {
      color: CONSTANTS.color.lightBrown,
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: 0,
    },
    CreateSizeText: {
      color: CONSTANTS.color.lightBrown,
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: 0,
      fontSize:"14px"
    },

  });

  

const InternalAttachments = withCommonStyles(InternalNotesAttachments)
const InternalAttachmentsRequestStyles = withRequestStyles(InternalAttachments)
const InternalNotesAttachmentsToaster = withSnackbar(InternalAttachmentsRequestStyles)
export default withStyles(InternalNotesAttachmentsStyles)(InternalNotesAttachmentsToaster);
// Customizable Area End