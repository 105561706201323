import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  InputBase,
  TextField, Divider,
  Popover,Button
} from "@material-ui/core";
import {
  styled,
  withStyles, Theme,createStyles
} from "@material-ui/core/styles";
import PriceSectionController, { Props,TaxRateResponse } from "./PriceSectionController.web";
import CollectedPaymentModal from "../../InvoiceBilling/src/CollectedPaymentModal.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { CONSTANTS } from "../../../components/src/theme";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import Select from "react-select";
import { t } from "i18next";
import { withSnackbar } from "../../../components/src/toastweb";
import { deleteLogoIcon } from "../../Settings/src/assets";
// Customizable Area End
// Customizable Area Start
export const StyledGrid = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    '& > .MuiGrid-item': {
      padding: "8px",
    },
  },
}));

export const StyledTypography = styled(Typography)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "3rem",
  }
}));

export const StyledBox = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "2.5rem 3rem 3rem 3rem !important",
  }
}));

export const StyledGridItem = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: "4rem !important",
  },
}));

export const StyledGridItem2 = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: "1.5rem !important",
  },
}));

export const StyledDiscountDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: "39.5% !important",
  },
}));

export const StyledTaxDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    top:"auto !important",
    left: "auto !important",
  },
}));

export const StyledDepositDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: "67.5% !important",
  },
}));
const customSelectStyles = {
  control: (styles: object) => ({
    ...styles,
    
     border: "none", boxShadow: 'none',
    '&:hover': {
      borderColor: 'none', boxShadow: 'none' // Remove border color when hovered
    },
  }),
  placeholder: (styles:object) => ({ ...styles, display:'none' }),
  indicatorSeparator: (styles: object) => ({ ...styles, backgroundColor: "none" }),
};
interface StyledRadioProps extends RadioProps {
  classes: {
    root: string;
    checked: string;
  };
};
const stylesRadio = (theme: Theme) => ({
  root: {
    color: '#1C18AF',
    '&$checked': {
      color: '#1C18AF',
    },
  },
  checked: {},
});
export const StyledRadio = withStyles(stylesRadio)((props: StyledRadioProps) => (
  <Radio color="default" {...props} />
));
// Customizable Area End

export class PriseSection extends PriceSectionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const currency = [
      { value: "%", label: '%' },
      { value: this.props.currency, label: this.props.currency },
    ];
    const discountType = this.props.selectedCurrency
    return (
      <>
       <Popover
          id={this.state.taxAnchorId}
          open={Boolean(this.state.taxDialog)}
          anchorEl={this.state.taxAnchorEl}
         
          onClose={this.handleCancelTaxDialog}

          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          
           

          <StyledTaxDiv style={{ ...webStyle.discountDialog, ...webStyle.taxDialog }} ref={this.wrapperRef}>
            <Typography variant="body2" style={{ ...webStyle.modalTexts, ...webStyle.modalTextTaxBackDrop }}>
              +{t('price.tax')}
            </Typography>
            <Typography className={classes.discountText}>{t('price.createTax')}</Typography>
            <Divider orientation="horizontal" />
            <Box style={{ ...webStyle.currencyContainer, maxWidth: "305px", minHeight: "56px" }}>
              <Box style={webStyle.taxNameContainer}>
                <InputBase
                  data-test-id="formDataTax"
                  className={classes.inputBase}
                  placeholder={`${t('price.placeholder.taxName')}`}
                  name="tax_name"
                  value={this.props.priceSectionData.tax_name}
                  disabled={!this.props.isEditable}
                  onChange={this.props.handlePriceSection}

                />
              </Box>
              <InputBase
                data-test-id="formDataTax"
                className={classes.inputBase}
                placeholder={`${t('price.placeholder.taxRate')}`}
                name="tax"
                type="number"
                disabled={!this.props.isEditable}
                value={this.props.priceSectionData.tax}
                onChange={this.props.handlePriceSection}

              />
            </Box>
            <Box style={webStyle.dialogButtonsContainer}>
              <Button
                className={classes.cancelButtonContainer}
                onClick={this.handleCancelTaxDialog}
              >
                <Typography variant="caption" className={classes.cancelButtonText}>
                  {t('suppliers.cancel')}
                </Typography>
              </Button>
              <Button
                style={{ maxWidth: "147px" }}
                className={classes.addButtonContainer}
                onClick={this.handleCreateTaxRate}
              >
                <Typography variant="caption" className={classes.addButtonText}>
                  {t('price.createTax')}
                </Typography>
              </Button>
            </Box>
          </StyledTaxDiv>

      
        </Popover>
        <Popover
          id={this.state.anchorId}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}

          onClose={this.closeTaxPopover}

          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Grid container className={classes.taxPopoverContainer}>
            <Grid item>
              <Box p={"1rem"} className={classes.selectTaxRate}>
                <Typography variant="body2" className={classes.blackColor}>
                 {t("quote.selectTaxRate")}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box p={"1rem"} height={"110px"} overflow={"scroll"} display={"flex"} alignItems={"center"} flexDirection={"column"}>
                {this.state.taxRates && this.state.taxRates.length > 0 ?

                  <>
                    {this.state.taxRates.map((taxRate: TaxRateResponse,index:number) => {
                      return (
                        <Box display={"flex"} alignItems={"center"} width={"100%"} >
                            <StyledRadio
                              value={this.props.priceSectionData.tax}
                              name={ index===0 ? "tax_name":"tax"}
                              checked={this.props.priceSectionData.tax === taxRate.rate}
                              onChange={this.props.selectTaxRate.bind(this,taxRate)}
                            />
                          <Typography variant="body2" color="textPrimary">
                            {taxRate.name} {taxRate.rate}%
                          </Typography>
                          </Box>
                      )
                    })}
                  </>
                  : ""}
              </Box>
            </Grid>
            <Divider className={classes.taxDivider} />
            <Grid item>
              <Box p={"1rem"} display={"flex"} justifyContent={"center"}>
                <Typography variant="body2" data-test-id="handleTaxDialogOpen" onClick={this.handleTaxDialogOpen} style={webStyle.modalTexts}>
                 { `${'+'}  ${t("quote.createTaxRate")}` }
                 
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Popover>
        <Box className={classes.rateContainer}>
        
          <Box className={classes.subTotalBox}>
            <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{t('price.subtotal')}</Typography>
            <Typography variant="body2" className={classes.total}>
              {this.props.getSubTotal(this.props.data)} {this.props.currency}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.subTotalBox}>
            <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{t('price.discount')}</Typography>
            {!this.props.discountDialog ?
              <Typography  data-testid='openDiscountDialog' variant="body2" style={webStyle.modalTexts} onClick={this.props.handleDiscountDialogOpen}>
                {!this.props.priceSectionData.discount ? `${'+'} ${t('price.discount')}` : `${this.props.priceSectionData.discount} ${this.props.selectedCurrency}`}
              </Typography> :
              <Grid container justifyContent="center" style={{ maxWidth: '186px' }}>
                <StyledTextFieldDiscount
                  variant="outlined"
                  data-test-id="formDataDiscount"
                  name="discount"
                  placeholder="0.00"
                  type="number"
                  onKeyPress={this.preventMinus}
                  onChange={this.handlePriceSection}
                  value={this.props.priceSectionData.discount}
                  className={classes.quantityInput}
                  InputProps={{
                    inputProps: { 
                      min: 0,
                      },
                    endAdornment: <div className={classes.endAdornment}>
                      <Select
                        aria-label="search"
                        onChange={this.props.selectCurrency}
                        styles={customSelectStyles}
                        defaultValue={{
                          label: discountType,
                          value: discountType
                        }}
                        options={currency} />
                    </div>
                  }}
                />
              </Grid>
            }
            <Box>
            <Box className={classes.taxDeleteBox}>
            <Typography variant="body2"
              className={classes.blackColor}>
              {this.props.priceSectionData.discount} {this.props.selectedCurrency}
                </Typography>
                <img
                  className={classes.deleteIcon}
                  src={deleteLogoIcon}
                  alt=""
                  data-testid='emptyPrice'
                  onClick={this.props.emptyPriceSectionData.bind(this, "discount")}
                />
              </Box>
            </Box>
           
           
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.subTotalBox}>
            <Typography variant="body2" color="textPrimary" className={`${classes.subTitle20} ${classes.taxLabel}`}>{t('price.tax')}</Typography>
            <Typography data-test-id="openTaxPopover" component={"div"} className={classes.addTax} style={webStyle.modalTexts} onClick={this.openTaxPopover}>
              {!this.props.priceSectionData.tax_name && !this.props.priceSectionData.tax ?
                `${'+'}  ${t("quote.addTax")}` : `${this.props.priceSectionData.tax_name || ""} ${this.props.priceSectionData.tax}%`}
            </Typography>
           
           
          </Box>
         
          <Divider className={classes.divider} />
          <Box className={classes.subTotalBox}>
            <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{t('ProductService.total')}</Typography>
            <Typography variant="body2" className={classes.total} >
              {`${Number(this.props.calculateTotal())} ${this.props.currency}` || "0"}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.subTotalBox}>
            <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{t('price.requiredDeposit')}</Typography>
            <Typography variant="body2" data-testId="OpenDialogId" style={webStyle.modalTexts} onClick={this.handleClickOpenDialog}>
              {this.props.collectedPaymentForm.amount === '' ? `${'+'} ${t('price.requiredDeposit')}` : `${this.props.collectedPaymentForm.amount} ${this.props.currency}`}
            </Typography>
          </Box>
          {this.state.deleteDialogOpen && (
            <CollectedPaymentModal
              invoicePayment={this.props.invoicePayment}
              hideEmailButton={true}
              onClose={this.handleCloseDialog}
              handleCollectionPaymentSave={this.handleSaveCollectedPayment}
              handleChange={this.props.handleChange}
              calculateTotal={this.props.calculateTotal}
              handlePaymentModalClose={this.props.onClose}
              collectedPaymentForm={this.props.collectedPaymentForm}
              priceSectionData={this.props.priceSectionData}
              handleCheckBalance={this.props.handleCheckBalance}
              currency={this.props.currency}
              handleChangePaymentMethodRef={this.props.handleChangePaymentMethodRef}
              handleCollectionPaymentModal={this.props.handleCollectionPaymentModal}
              handleCollectionPaymentClose={this.props.handleCollectionPaymentClose}
                handleTransactionDate={this.props.handleTransactionDate}
                selectedLang={this.props.selectedLang}
                handlePay_balence={this.props.handlePay_balence}
                handleCollected={this.props.handleCollected}
              />
            )}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles:any = (theme: Theme) =>
createStyles ({
  taxDeleteBox :{
    display:"flex",
    gap:"1rem",
    alignItems:"center",
  },
  deleteIcon:{
    width: "30px",
    height: "30px",
    marginBottom:"3px",
    cursor:"pointer"
  },
  subTotalBox:{
    display:"flex",
    padding:"0 0rem 0 0",
    justifyContent:"space-between",
    alignItems:"center",
    [theme.breakpoints.down("xs")]: {
     flexDirection:"column",
     padding:0,
     gap:"1rem"
    },
  },
  selectTaxRate:{
    backgroundColor:"#ececec",
  },
  taxLabel:{
    width:"50%",
    [theme.breakpoints.down("xs")]: {
     width:"unset"
     },
  },
  addTax:{
    minWidth:"9%",
    textAlign:"right" as const
  },
  taxPopoverContainer: {
    display:"flex",
    flexDirection:"column" as const,
    borderRadius: "10px",
    width: "251px",
    height: "222px",
  

},
  blackColor:{
    color: CONSTANTS.color.black,
  },
  quantityInput: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    padding: '0px !important'
  },
  endAdornment: {
    borderLeft: "1px solid #979797",
    marginLeft: 0,
    color: CONSTANTS.color.black,
    width: "-webkit-fill-available",
  },
  divider: {
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
    transform: "rotate(180deg)",
    margin: "2rem 0 2rem 0",
    [theme.breakpoints.down('xs')]: {
      width: "50%",
      margin: "2rem auto",

    },
  },
  taxDivider: {
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
    transform: "rotate(180deg)",
    width: "90%",
    margin: "0 auto",
    
  },
  subTotal: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      
    },
  },
  subTotalDeposit: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
      
    }
  },
  rateInnerContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: 'center',
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      flexDirection: "column"
    },
    gap:"2rem",
  
  },
  rateContainer: {
    position: "relative",
    marginTop: "1rem",
    width: "100%",
   padding:"2rem 1rem 2rem 1rem",
    minHeight: "118px",
   
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    [theme.breakpoints.down('xs')]: {
      marginTop: "0rem",
    }
  },
  total: {
    fontWeight: theme.typography.fontWeightMedium,
    color:theme.palette.common.black
  },
  cancelButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "74px",
    minHeight: "41px",
    borderRadius: "6px",
    color: CONSTANTS.color.darkBlue,
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
    cursor: "pointer",
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        border:"none",
        cursor: "pointer"
    },
  },
  addButtonContainer: {
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
        border:"none"
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "60px",
    minHeight: "41px",
    borderRadius: "6px",
    color:CONSTANTS.color.white,
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    cursor: "pointer",
    transition: 'background-color 0.3s, color 0.3s',
  },
  cancelButtonText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  addButtonText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  inputBase: {
    fontSize: "20px",
    fontWeight: theme.typography.fontWeightRegular,
    padding: "0 10px"
  },
  discountText: {
    color: CONSTANTS.color.lightCement,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "start",
    paddingBottom: "3px",
    fontSize:theme.typography.caption.fontSize
  },
});
export const StyledTextFieldDiscount = withStyles({
  root: {
    "& .MuiInputBase-root": {
    color:CONSTANTS.color.black ,
      fontFamily: "Rubik",
    },
    "& .css-1uccc91-singleValue": {
      color: CONSTANTS.color.black,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
     
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .css-6q0nyr-Svg":{
      height:"25px",
      width:"25px"
    },
 
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"

    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px"

    },
    '& .MuiOutlinedInput-input': {
      padding: '0px',
      paddingLeft: '8px',
      paddingRight:"8px"
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingLeft: "20px"
    }
  }
})(TextField);
const webStyle = {

  modalTexts: {
    color: CONSTANTS.color.blueViolet,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    cursor: "pointer",
  },
  discountDialog: {
    padding: "1.5rem",
    width: "100%",
    maxWidth: "351px",
    minHeight: "183px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  backDropStyle: {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(142 138 138 / 50%)",
    zIndex: 1,
    backdropFilter: "blur(2px)"
  },
  modalTextTaxBackDrop: {
    position: "absolute" as "absolute",
    top: "-4%",
    left: "7%",
    transform: "translate(-50%, -50%)"
  },
  dialogButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px"
  },
  currencyContainer: {
    margin: "22px 0 22px",
    width: "100%",
    minHeight: "41px",
    borderRadius: "6px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex"
  },
  taxNameContainer: {
    display: "flex",
    border: "0.5px solid rgb(216, 216, 216)"
  },
  taxDialog: {
    maxWidth: "351px",
    minHeight: "214px",
    top: "170%",
    left: "59.5%"
  },
}
const PriceCommonStyles=withCommonStyles(withSnackbar(PriseSection))
export default withStyles(styles)(PriceCommonStyles);
// Customizable Area End