import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Container,
  TextField,Menu, MenuItem,
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  visaImage,
  mastercardImage,
  payPalImage,
  iIcon,
  afterHoverIcon,
  blueBackgroundCard,
  violetBackgroundCard,
  chipIcon,
  wifiIcon,
  visaIcon
} from "./assets";
import {deleteIcon} from "../../appointmentmanagement/src/assets"
import { SettingsSideBar } from "./SettingsSideBar.web";
import "./CustomScrollbar.css";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledDialog } from "./ManagePlan.web"
import { StyledBox } from "./CompanyInformation.web";
import { StyledCheckbox } from "../../JobListing2/src/JobsUtility.web"
import { withSnackbar } from "../../../components/src/toastweb";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
// Customizable Area End

import AddPaymentController, {
  Props,
} from "./AddPaymentController.web";

// Customizable Area Start
interface GetCardData {
  id: number,
  number: string,
  exp_month: string,
  tap_card_id: string,
  account_id: number,
  created_at: string,
  exp_year: string,
  cvc: string,
  name: string,
  updated_at: string,
  card_type: string,
  default_set: boolean
}
const mockPaymentCard = [
  { id: 1, title: "MASTERCARD", icon: mastercardImage, width: "56px", height: "44px" },
  { id: 2, title: "VISA", icon: visaImage, width: "56px", height: "23px" },
  { id: 3, title: "PAYPAL", icon: payPalImage, width: "56px", height: "56px" },
]

const getConditionalStyle = (isError: any) => {
  return isError && webStyle.errorStyle;
};
export const StyledTextFields = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontSize: "18px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
      }
    },
    "& .MuiOutlinedInput-input":{
      color: CONSTANTS.color.midGrey,
    },
  }
}))(TextField);

// Customizable Area End

export class AddPayment extends AddPaymentController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  cardType=(card_type:string,classes:ClassNameMap<string>)=>{
    return <Typography className={classes.text_white}>
    {card_type === 'VISA' &&
      <img className={classes.deleteIcon} src={visaIcon} />
    }
    {card_type === 'MASTERCARD' &&
      <img className={classes.mastercardImage} src={mastercardImage} />
    }
  </Typography>
  }
  deleteImg = (classes: ClassNameMap<string>, item: GetCardData) => {
    return this.state.getCardData.length !== 1 && <img
      data-testid={`deleteCard_${item.id}`}
      onClick={() => this.handleDelete(item.id)}
      className={classes.deleteIcon}
      src={deleteIcon}
    />
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const { addNewCard,paymentCard, showText,openCancelTransactionModal,loading } = this.state;
    const { cardName, cardNumber, expDate, cvv } = this.state.cardFormData;
    const cardType = this.state.getCardData && this.state.getCardData.map((item) => {
      return item.card_type
    })
    const showErrorMessage = (message: string | undefined) => {
      return (
        <Typography
          variant="body1"
          style={webStyle.errorMessage}
        >
          {message}
        </Typography>
      );
    }
    
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined}  {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <SettingsSideBar   progress={100} {...this.props} active={2} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={{marginTop: "8.5rem", paddingBottom: addNewCard ? "4rem" : "18rem"}}
            className={classes.boxContainerAddPayment}
          >
            <Container maxWidth={false}>
              <Typography style={webStyle.mainHeaderText} variant="subtitle1">
                {t('settings.addPayment')}
              </Typography>
              <Box style={webStyle.cardOuterContainer}>
                {mockPaymentCard.map((item) => (
                  
                  <Box
                    data-test-id="handleSelectCard"
                    style={
                       item.title === paymentCard || item.title === cardType.join() ?
                        { ...webStyle.cardContainer, ...webStyle.cardContainerStyle } :
                        webStyle.cardContainer
                    }
                    key={item.id} 
                    data-testid={`handleSelectCard${item.id}`}
                    onClick={() => this.handleSelectCard(item.title)}
                  >
                    <img
                      style={{width: item.width, height: item.height}}
                      src={item.icon}
                      alt=""
                    />
                  </Box>
                ))}
              </Box>
              {showErrorMessage(this.state.isErrorFormData.paymentCard)}
              {this.state.selectCard &&
                <>
                  <Grid container>
                    <Grid item lg={10} md={12} xs={12}>
                      <Typography style={{...webStyle.formText,...webStyle.addNewCardStyle, paddingTop: 0}} className={classes.formText} variant="body1" color="textPrimary">
                        {t('settings.cardName')}
                      </Typography>
                      <StyledTextFields
                        data-test-id="handleChangeCardData"
                        style={getConditionalStyle(this.state.isErrorFormData.cardName)}
                        fullWidth
                        variant="outlined"
                        name="cardName"
                        value={cardName}
                        onChange={this.handleChangeCardData}
                        error={Boolean(this.state.isErrorFormData.cardName)}
                      />
                      {showErrorMessage(this.state.isErrorFormData.cardName)}
                    </Grid>
                    <Grid item lg={10} md={12} xs={12}>
                      <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                        {t('settings.cardNumber')}
                      </Typography>
                      <StyledTextFields
                        data-test-id="handleChangeCardData"
                        style={getConditionalStyle(this.state.isErrorFormData.cardNumber)}
                        fullWidth
                        variant="outlined"
                        name="cardNumber"
                        value={cardNumber}
                        onChange={this.handleChangeCardData}
                        inputProps={{ maxLength: 20 }}
                        error={Boolean(this.state.isErrorFormData.cardNumber)}
                      />
                      {showErrorMessage(this.state.isErrorFormData.cardNumber)}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={5} md={6} xs={12}>
                      <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                        {t('settings.expDate')}
                      </Typography>
                      <StyledTextFields
                        data-test-id="handleChangeCardData"
                        style={getConditionalStyle(this.state.isErrorFormData.expDate)}
                        fullWidth
                        variant="outlined"
                        placeholder={t('settings.mm/yy')}
                        name="expDate"
                        value={expDate}
                        onChange={this.handleChangeCardData}
                        inputProps={{ maxLength: 5 }}
                        error={Boolean(this.state.isErrorFormData.expDate)}
                      />
                      {showErrorMessage(this.state.isErrorFormData.expDate)}
                    </Grid>
                    <Grid item lg={5} md={6} xs={12}>
                      <Box style={webStyle.cvvContainer}>
                        <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                          {t('settings.cvv')}
                        </Typography>
                        <div
                          data-test-id="handleMouse"
                          className={classes.hoverContainer}
                          onMouseEnter={this.handleMouseEnter}
                          onMouseLeave={this.handleMouseLeave}
                        >
                          <img
                            style={webStyle.iIcon}
                            src={showText ? afterHoverIcon : iIcon}
                            alt=""
                          />
                          {showText &&
                            <div
                              style={
                                this.state.languageSelected === "ar" ?
                                {...webStyle.hoverStyle, transform: "translate(280px, -100px)"} :
                                webStyle.hoverStyle
                              }
                              className={classes.hoverStyle}
                            >
                              <Typography
                                style={webStyle.cvvTooltip}
                                variant="caption"
                              >
                                {t('settings.lastThreeDigits')}
                              </Typography>
                            </div>
                          }
                        </div>
                      </Box>
                      <StyledTextFields
                        data-test-id="handleChangeCardData"
                        style={getConditionalStyle(this.state.isErrorFormData.cvv)}
                        fullWidth
                        variant="outlined"
                        name="cvv"
                        value={cvv}
                        onChange={this.handleChangeCardData}
                        inputProps={{ maxLength: 3 }}
                        error={Boolean(this.state.isErrorFormData.cvv)}
                      />
                      {showErrorMessage(this.state.isErrorFormData.cvv)}
                    </Grid>
                  </Grid>
                <Box style={webStyle.checkBoxContainer}>
                  <StyledCheckbox
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox', 'data-testid': "defaultCheck" }}
                    onChange={this.handleChange}
                  />
                  <Typography style={{ ...webStyle.formText, padding: 0 }} variant="body1" color="textPrimary">
                    Set As Default
                  </Typography>
                </Box>
                <Box
                    style={webStyle.buttonsContainer}
                    className={classes.buttonsContainer}
                  >
                    <Button
                      data-test-id="handleCancelButton"
                      data-testid='handleCancel'
                      style={webStyle.buttonContainerCancel}
                      className={`${classes.buttonCommon} ${classes.buttonContainerMobile}`}
                      onClick={this.handleCancelButton}
                    >
                      <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextMobile} variant="subtitle2">
                        {t('settings.cancel')}
                      </Typography>
                    </Button>
                    <Button
                      style={webStyle.buttonContainerSave}
                      className={`${classes.buttonCommon} ${classes.buttonContainerMobile}`}
                      onClick={this.handleSaveButton}
                      data-test-id='save'
                    >
                      <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobile} variant="subtitle2">
                        {t('settings.save')}
                      </Typography>
                    </Button>
                  </Box>
                  <StyledDialog
                  open={openCancelTransactionModal}
                  onClose={this.handleCloseCancelTransactionModal}
                  aria-labelledby="responsive-dialog-title"
                >
                  <Grid container style={{ justifyContent: "end" }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleCloseCancelTransactionModal}>
                      <CloseIcon style={{ color: "#1E1F1F" }} />
                    </IconButton>
                  </Grid>
                  <Box style={webStyle.changeTransactionContainer}>
                    <Box>
                      <Typography style={{ ...webStyle.mainHeaderText, ...webStyle.paddingBottomStyle }} variant="subtitle1">
                        Cancel Transaction
                      </Typography>
                      <Typography className={classes.transactionDesc} variant="body1" color="textPrimary">
                        Are you sure you want to cancel the transaction?
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.btnContainer}>
                    <>
                      <Button
                        style={webStyle.buttonContainerCancelTransaction}
                        className={`${classes.buttonDialogueCommon} ${classes.buttonContainerMobile}`}
                        onClick={this.handleCloseCancelTransactionModal}
                      >
                        <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextMobile} variant="subtitle2">
                          {t('settings.cancel')}
                        </Typography>
                      </Button>
                      <Button
                        style={webStyle.buttonContainerContinue}
                        className={`${classes.buttonDialogueCommon} ${classes.buttonContainerMobile}`}
                        onClick={this.handleContinue}
                        data-testid='handleContinue'
                      >
                        <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobileCancel} variant="subtitle2">
                          Continue
                        </Typography>
                      </Button>
                    </>
                  </Box>
                </StyledDialog>
                </>}
              {!this.state.selectCard && this.state.getCardData.length > 0 && <>
                <Grid container>
                  <Typography className={classes.mt_15}>My Card</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} className={`${classes.mt_15} ${classes.mb_25} ${classes.cardContainer}`} direction="row">
                    {this.state.getCardData.map((item: GetCardData) => {
                      return <Grid item className={item.default_set === false ? classes.blueBackgroundCardLayout : classes.violetBackgroundCardLayout}>
                        <Grid container className={classes.p_20} style={{ justifyContent: "space-between" }}>
                          <Typography className={classes.text_white}>American Express</Typography>
                          {this.cardType(item.card_type,classes)}
                        </Grid>
                        <Grid container className={classes.p_20} style={{ justifyContent: "space-between" }}>
                          <Typography variant="subtitle2" className={classes.text_white}>
                            {item.number.replace(/(.{4})/g, '$1 ')}
                            </Typography>
                          <img className={classes.chipIcon} src={chipIcon} />
                        </Grid>
                        <Grid container className={`${classes.p_20} ${classes.pb_0}`}>
                          <Typography className={`${classes.text_white} ${classes.font_14}`}>Card Holder</Typography>
                        </Grid>
                        <Grid container className={`${classes.p_20} ${classes.pt_5} ${classes.pb_0}`} style={{ justifyContent: "space-between" }}>
                          <Typography className={`${classes.text_white} ${classes.font_14}`}>{item.name}</Typography>
                          <Typography className={`${classes.text_white} ${classes.font_14}`}>Expire {`${item.exp_month}/${item.exp_year} `}</Typography>
                          <img className={classes.wifiIcon} src={wifiIcon} />
                        </Grid>
                        <Grid container style={{ justifyContent: "end" }} 
                          className={`${classes.p_20} ${classes.pt_10} ${classes.ml_10}`}
                        >
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            data-testid={`openMenu_${item.id}`}
                            onClick={this.handleOpenMenu}
                          >
                            <MoreVertIcon className={classes.text_white} />
                          </IconButton>
                          {item.default_set === true &&
                            <Menu
                              id="long-menu"
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.handleCloseMenu}
                            >
                              <MenuItem data-testid={`defaultCard_${item.id}`} onClick={() => this.handleCloseMenu(item.id)}>
                                Set Default
                              </MenuItem>
                            </Menu>
                          }
                        </Grid>
                      </Grid>
                    })}
                  </Grid>
                  <Grid className={classes.cardContainer} container spacing={1} direction="row" >
                    {this.state.getCardData.map((item: GetCardData) => {
                      return <Grid item className={`${classes.defaultTextBg}`}>
                        <Grid container style={{ justifyContent: "space-between", padding: "33px 20px" }}>
                          <Typography className={classes.text_darkBlue}>
                            {item.default_set === false ? 'Default' : 'Set As Default'}
                          </Typography>
                          {this.deleteImg(classes,item)}
                        </Grid>
                      </Grid>
                    })}
                  </Grid>
                </Grid></>
              }
              <Grid container style={{ justifyContent: "center" }}>
                <LoadingBackdrop loading={loading} />
              </Grid>
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  blueBackgroundCardLayout: {
    backgroundImage: `url(${blueBackgroundCard})`,
    backgroundSize: "100% 100%",
    height:"18rem",
    position: "relative" as const,
    minWidth:"48%",
    marginRight:"15px",
    [theme.breakpoints.down("md")]: {
      minWidth: "49%",
      height:"16.5rem"
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "49%",
      height:"16.5rem"
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "-moz-available",
      height:"16.5rem"
    },
  },
  violetBackgroundCardLayout: {
    backgroundImage: `url(${violetBackgroundCard})`,
    backgroundSize: "100% 100%",
    height:"18rem",
    position: "relative" as const,
    minWidth:"48%",
    marginRight:"15px", [theme.breakpoints.down("xs")]: {
      width: "362px",
      height: "288px"
    }
  },
  defaultTextBg:{
    background:"rgba(77, 74, 202, 0.15)",
    height:"5rem",
    borderRadius:"0px 0px 27px 27px",
    minWidth:"48%",
    marginRight:"15px",
    [theme.breakpoints.down("md")]: {
      minWidth: "49%",
     
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "49%",
    
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "-moz-available",
    
    },
  },
  cardContainer: {
    flexWrap: "nowrap" as const,
    justifyContent: "space-between"
  },
  chipIcon:{
    height:"25px"
  },
  wifiIcon:{
    height:"14px",
  },
  deleteIcon:{
    height:"17px",
    alignSelf:"center"
  },
  mastercardImage:{
    height:"34px",
    alignSelf:"center"
  },
  mb_25:{
    marginBottom:"-25px"
  },
  ml_10:{
    marginLeft:"10px"
  },
  p_20:{
    padding:"20px"
  },
  pt_5:{
    paddingTop:"5px"
  },
  pt_10:{
    paddingTop:"10px"
  },
  pt_27:{
    paddingTop:"27px",
  },
  pb_0:{
    paddingBottom:"0px"
  },
  text_white:{
    color: "white"
  },
  text_darkBlue: {
    color: CONSTANTS.color.darkBlue,
    fontSize: 'initial',
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  cardContainerStyle: {
    border: `1px solid ${CONSTANTS.color.royalBlue}`,
    backgroundColor: "rgba(77,74,202,0.15)"
  },
  font_14:{
    fontSize:"14px"
  },
  boxContainerAddPayment: {
    [theme.breakpoints.down("md")]: {
      padding: "0 1rem 8rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0rem 8rem !important",
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0rem 3rem !important",
    },
  },
  mainHeaderText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    }
  },
  cardTitleContainerOther: {
    [theme.breakpoints.down(460)]: {
      padding: "2rem 0px 0.5rem !important"
    }
  },
  closeButton: {
    marginRight: "-11px",
    marginTop: "-11px",
    color: CONSTANTS.color.lightGrey,
    width: "15px",
    height: "16px",
},
  btnContainer:{
    paddingTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  buttonsContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "3rem !important"
    }
  },
  mt_15:{
    marginTop:"15px"
  },
  buttonCommon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
  },
  buttonDialogueCommon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "100%",
    minHeight: "50px",
    borderRadius: "10px",
  },
  buttonContainerMobile: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "47px !important",
      maxWidth: "100% !important"
    }
  },
  buttonTextMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  formText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      paddingTop: "1rem !important",
    }
  },
  doubleClickText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px !important",
    }
  },
  cardNumber: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  },
  hoverStyle: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0px, -55px) !important",
    }
  },
  transactionDesc: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "27px",
    paddingTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
      lineHeight: "20px !important"
    }
  },
  hoverContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative !important",
      width: "100% !important",
      display: "flex !important",
      justifyContent: "flex-end !important",
      alignItems: "center !important",
    }
  },
  cardContainer1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    width: "88px",
    height: "88px",
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.steelGrey}`,
    backgroundColor: CONSTANTS.color.veryLightGrey,
    cursor: "pointer"
  },
});

const webStyle = {
  changeTransactionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mainHeaderText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    paddingBottom: "1.5rem"
  },
  addNewCardStyle: {
    padding: "2rem 0 1rem"
  },
  cardOuterContainer: {
    display: "flex",
    gap: "1rem",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    width: "88px",
    height: "88px",
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.steelGrey}`,
    backgroundColor: CONSTANTS.color.veryLightGrey,
    cursor: "pointer"
  },
  cardContainerStyle: {
    border: `1px solid ${CONSTANTS.color.royalBlue}`,
    backgroundColor: "rgba(77,74,202,0.15)"
  },
  formText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    paddingTop: "2rem",
    paddingBottom: "0.5rem",
  },
  iIcon: {
    width: "24px",
    height: "24px",
  },
  cvvTooltip: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 1rem"
  },
  hoverStyle: {
    width: "100%",
    maxWidth: "308px",
    height: "54px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.lavenderBlue,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    position: "absolute" as const,
    transform: "translate(-280px, -100px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99
  },
  cvvContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  buttonContainerCancel: {
    textTransform: "none" as const,
    backgroundColor: CONSTANTS.color.white,
    maxWidth: "200px",
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
  },
  buttonTextCancel: {
    color: CONSTANTS.color.crimsonRed,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  buttonsContainer: {
    paddingTop: "15rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingTop: "1.5rem"
  },
  buttonContainerSave: {
    textTransform: "none" as const,
    maxWidth: "232px",
    backgroundColor: CONSTANTS.color.darkBlue,
  },
  buttonContainerCancelTransaction: {
    textTransform: "none" as const,
    backgroundColor: CONSTANTS.color.white,
    maxWidth: "150px",
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
  },
  buttonContainerContinue:{
    textTransform: "none" as const,
    maxWidth: "150px",
    backgroundColor: CONSTANTS.color.darkBlue,
  },
  buttonTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  cardNumber: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  errorStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px"
  },
  paddingBottomStyle: {
    paddingBottom: 0
  },
}

const AddPaymentStyles = withStyles(styles)(AddPayment);
export default withTranslation()(withSnackbar(AddPaymentStyles));
// Customizable Area End