Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.validationApiContentType = "application/json";
exports.authToken="authToken";
exports.makeChargeAPIEndPoint = "/bx_block_amazonpayintegration/create_charge"
exports.fetchChargeAPIEndPoint="/bx_block_amazonpayintegration/get_charge"
exports.createApiMethod = "POST";
exports.getApiMethod = "GET";

//regex
exports.regexName = /^(?=.{1,23}$)(?!.*(?: [^a-zA-Z']|[a-zA-Z'] [^a-zA-Z']) )[a-zA-Z']+(?: [a-zA-Z']+)*/;
exports.regex = {
  // email:/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  // phone:/^\d{10}$/,
  phone:/^\s*\d+\s*$/,
  email:/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
  zipcode:/^\s*\d+\s*$/,
  number:/^-?\d+(\.\d+)?$/, //check only numbers
  spaces:/^[a-zA-Z\s]*$/, // check alphabetical, allow spaces anywhere
  address:/^[a-zA-Z0-9,.]+(?:\s[a-zA-Z0-9,.]+)*$/,//check address with charactrs and symbols
  alphaNumeric:/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, //check charact numbers
  passRegex :/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*]).{8,}$/ //
};
exports.regexAr = {
  // email: /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
  // phone: /^\s*\d+\s*$/,
  lengthTypo:/[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]{23}/g,
  phone: /^\s*[\d٠١٢٣٤٥٦٧٨٩]+\s*$/, // Arabic numerals
  email: /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
  zipcode: /^\s*\d+\s*$/,
  number: /^-?[\d٠١٢٣٤٥٦٧٨٩]+(?:\.\d+)?$/, // Arabic numerals
  spaces: /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFa-zA-Z\s]*$/, // check alphabetical, allow spaces anywhere
  spaces_numbers: /^[\u0600-\u06FFa-zA-Z0-9\s]*$/, // allow spaces numbers also
  address: /^[a-zA-Z0-9,.]+(?:\s[a-zA-Z0-9,.]+)*$/, // check address with characters and symbols
  alphaNumeric: /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, // check characters and numbers
}
exports.passwordValidators = {
  atleastOneCapsRegex: /[A-Z]+/,
  atleastLowerCaseRegex: /[a-z]+/,
  specialCharRegex: /\W+/,
  digitsRegex: /\d+/,
};
//profile error text
exports.customersErrorText = {
  requiredTypo: "Please enter name!",
  addressRequiredTypo: "Address is required!",
  lengthTypo: "Name should be less than 23 characters!",
  alphabeticTypo: "Please enter in alphabetical characters!",
  phoneTypo: "This is not a valid Phone Number!",
  emailTypo: "Please enter a valid Email Address!",
  numbersTypo: "Please enter in numbers!",
  stateLengthTypo:"State should be less than 50 characetrs!",
  phoneTypoV1:"This is not a valid Mobile Number!",
  alphaNumericTypo:"Please enter in alphanumeric characters!"
};
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";

//Business profile
exports.updatePasswordApiEndPoint = "account_block/update_password";
exports.updatePasswordContentType = "application/json";
exports.updatePasswordMethod = "POST";
exports.fetchProfileDataApiEndPoint = "account_block/accounts";
exports.fetchProfileDataContentType = "application/json";
exports.fetchProfileDataMethod = "GET";
exports.updateProfileDataApiEndPoint = "account_block/update_profile";
exports.updateProfileDataContentType = "application/json";
exports.updateProfileDataMethod = "PATCH";
exports.deleteProfileImageApiEndPoint = "account_block/destroy_profile_image/"
exports.deleteProfileImageContentType = "application/json";
exports.deleteProfileImageMethod = "DELETE";

//Employee profile
exports.employeeupdatePasswordApiEndPoint = "account_block/change_password";
exports.employeeupdatePasswordContentType = "application/json";
exports.employeeupdatePasswordMethod = "POST";
exports.employeefetchProfileDataApiEndPoint = "account_block/employees";
exports.employeefetchProfileDataContentType = "application/json";
exports.employeefetchProfileDataMethod = "GET";
exports.employeeupdateProfileDataApiEndPoint = "account_block/update_profile";
exports.employeeupdateProfileDataContentType = "application/json";
exports.employeeupdateProfileDataMethod = "PUT";
exports.employeedeleteProfileImageApiEndPoint = "account_block/destroy_employee_image"
exports.employeedeleteProfileImageContentType = "application/json";
exports.employeedeleteProfileImageMethod = "DELETE";

exports.secretKey = "eD%TN@3bc%l},F5eplIMCyd9h!Y2"

// FAQs
exports.authToken = "authToken";
exports.tokenExpired = "Token has Expired";
exports.invalidToken = "Invalid token";
exports.errorLabel = "error";

exports.frequentlyAskedQuestionsLabel = "Frequently Asked Questions";
exports.haveQuestionsLabel = "Have questions? We’re here to help.";
exports.noDataFoundLabel = "No Data Found";

exports.dashboardContentType = "application/json";
exports.faqsAPIMethod = "GET";
exports.faqsAPIEndPoint = "bx_block_custom_form/faqs";
exports.searchFaqsAPIEndPoint = "bx_block_custom_form/faqs?search_key=";
// Customizable Area End
