import React from 'react';
import { Dialog, DialogTitle, WithStyles, withStyles, Typography, Divider, Grid, Button, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {createStyles} from "@material-ui/core/styles"
import { StyledTextFieldRequest } from './Utility.web';
import { CustomerText } from '../../../components/src/CustomizableComponents.web';
import { arrowRight } from './assets';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// Customizable Area End

// Customizable Area Start
interface Props extends WithStyles {
    siteDailog: boolean;
    handleSiteClose: any;
    searchSites: any;
    createSite: () => void;
    handleBack: () => void
    customerSites: any
    selectedSiteCustomer: any
    siteDetails: any
    t?: (key: string) => string;
    languageSelected:string
}
// Customizable Area End


export const SiteModalStyle = (theme: any) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#999999',
            width: "15px",
            height: "16px",
        },
        display:{
            display:"flex"
        },
        createBtn: {
            borderRadius: "8px",
            backgroundBlendMode: "normal",
            background: "#1c18af",
            height: "56px",
            color: "#ffffff",
            fontFamily: "Rubik",
            fontSize: "17px",
            fontWeight: 600,
            "&:hover": {
                background: "#1c18af",
                color: "#ffffff",
            }

        },
        orText: {
            width: "17px",
            height: "16px",
            color: "#999999",
            fontFamily: "Rubik",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "16px"
        },
        'input': {
            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color: "#1c18af",
                fontFamily: "Rubik"
            }
        },
        searchLayout: {
            paddingTop: "2rem",
            paddingBottom: "2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            flexBasis: 0
        },
        searchLayoutChild: {
            [theme.breakpoints.down("sm")]: {
                gap:"1rem"
            }
        },
        activeText: {
            color: "#1c18af",
            fontFamily: "Rubik",
            fontSize: "17px",
            fontWeight: 400,
        },
        activeDivider: {
            background: "#1c18af"
        },
        paper: {
            width: "530px",
            height: "600px",
            borderRadius: "10px",
            [theme.breakpoints.up("xl")]: {
                width: "634px",
                height: "678px",
                borderRadius: "10px",
            }
        },
        siteText: {
            fontfamily: "rubik",
            fontSize: "18px"
        },
        siteNameLayout: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "15px",
            cursor: "pointer"
        },
        customerName: {
            textTransform: "capitalize",
            fontSize: "18px",
            fontFamily: "Rubik"
        },
        titleText: {
            minHeight: "28px",
            color: "#34313a",
            fontSize: "24px",
            fontWeight: 500,
        },
        padding:{
            padding:"16px 13px"
        },
        backIcon:{
           color:"#34313a", 
        },
        marginLeftRight:{
            marginLeft:"10px",
            marginRight:"10px"
        },
        arrowBackIcon: {
            width: "14px",
            height: "17px",
        },
        transformStyle: {
            transform: "rotate(180deg)"
        },
        marginTop_10:{
            marginTop:"10px"
        }

    });
export class SiteModal extends React.Component<Props> {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    arrowBackIcon = () => {
        const { classes } = this.props;
        return this.props.languageSelected === "ar" ? `${classes.arrowBackIcon} ${classes.transformStyle}` : classes.arrowBackIcon
    }
    BackIcon = () => {
        const { classes } = this.props;
        return this.props.languageSelected === "ar" ? `${classes.backIcon} ${classes.transformStyle}` : classes.backIcon
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes,t } = this.props;
        return (
            <Dialog
                maxWidth="lg"
                open={this.props.siteDailog}
                onClose={this.props.handleSiteClose}
                PaperProps={{ classes: { root: classes.paper } }}
            >
                <DialogTitle className={classes.padding}>
                    <Grid item xs={12} className={classes.marginTop_10} style={{ alignItems: "center", display: "flex" }}>
                        <Grid container style={{ display: "contents" }}>
                            <KeyboardBackspaceIcon data-test-id='handleBack' className={this.BackIcon()} onClick={this.props.handleBack} />
                            <Typography className={`${classes.titleText} ${classes.marginLeftRight}`}>{t ? t('siteModal.title') : "Select Or Create Site"}</Typography>
                        </Grid>
                    </Grid>
                    <IconButton aria-label="close" data-test-id="search_sites" className={classes.closeButton} onClick={this.props.handleSiteClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Grid item xs={12} className={classes.searchLayout}>
                    <Grid container direction='row' justifyContent='center' alignItems='center' className={classes.searchLayoutChild}>
                        <Grid item xs={12} md={5}>
                            <StyledTextFieldRequest
                                fullWidth
                                variant='outlined'
                                placeholder={`${t?t('siteModal.searchSite'):"Search Sites..."}`}
                                InputProps={{ classes: { input: classes['input'] } }}
                                onChange={this.props.searchSites}
                                data-test-id="search_sites"
                            />

                        </Grid>
                        <Grid item xs={12} md={2} container justifyContent='center'>
                            <Typography className={classes.orText}>{t?t('customerModal.or'):"or"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Button className={classes.createBtn} fullWidth
                                onClick={this.props.createSite}
                            >
                                {t? t('siteModal.createNewSite'):"CREATE NEW SITE"}
                            </Button>
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12} >
                    <Grid container >
                        <Grid item xs={12} style={{
                            paddingLeft: "0.7rem",
                            paddingRight: "0.7rem"
                        }}>
                            <Typography className={classes.activeText}>{t?t('siteModal.current'):"Current"}</Typography>
                            <Divider className={classes.activeDivider} />
                        </Grid>

                        <Grid item xs={12}>
                            {this.props.customerSites && this.props.customerSites.length > 0 ? this.props.customerSites.map((_list: any, index: number) => {
                                return (
                                    <>
                                        <CustomerText key={_list.id} selected={this.props.siteDetails?.site_name === _list.site_name}>
                                            <ListItemText
                                                className={classes.display}
                                                data-test-id="selectSite"
                                                onClick={() => { this.props.selectedSiteCustomer(_list) }}
                                                primary={_list.site_name} />
                                            <ListItemIcon>
                                                <img className={this.arrowBackIcon()} src={arrowRight}/>
                                            </ListItemIcon>
                                        </CustomerText>
                                    </>
                                )
                            }) :
                                <>
                                    <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center' style={{ marginTop: "25%" }}>
                                        <Typography className={classes.customerName}>{t?t('siteModal.noSiteText'):"No customer sites Found"}</Typography>
                                    </Grid>
                                </>}
                        </Grid>

                    </Grid>

                </Grid>

            </Dialog>
        )
        // Customizable Area End

    }
}
// Customizable Area Start
export default withStyles(SiteModalStyle)(SiteModal);
// Customizable Area End
