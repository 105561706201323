import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import moment from "moment";
import {StyledTextField} from "./Maps.web"
import { checkLoggedInUserDashboard,} from "../../dashboard/src/utility.web";
import { apiCall,checkTokenExpired,debounce } from "../../../components/src/CommonFunction";
import { getStorageData } from "../../../framework/src/Utilities";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler:Function
  history: any
  t: (key: string) => string;
  classes: ClassNameMap<string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedRadioBtn:string
  filterMenu:any
  sortValue:string
  sortFilter:any
  durationFilter:string
  employeeFilter:string
  searchMapLocation:string
  getPlace:any
  jobLocation:string
  getMapListData:any
  mapLoading:boolean
  showErrorMessage:boolean
  errorMessage:any
  isFullscreen:boolean
  selectedDate:any
  closeDatePortal:boolean
  showDatePicker:boolean
  anchorEl:any
  currentDate:any
  employeesData:any
  searchQuery :any
  timer: any
  getEmployeesListLoading:boolean
  typedValue:any
  employeeId:string
  selectLang:string
  overlayData:{
    job_tite:string,
    job_id:any,
    start_time:string,
    start_date:string,
    address:string,
    customer_name:string
  },
  isEmployee:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MapsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  datePickerRef: any;
  mapRef: any;
  employeeListApiCallId:string=''
  searchMapListApiCallId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.mapRef = React.createRef();
    this.datePickerRef = React.createRef<any>();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      selectedRadioBtn:'job',
      filterMenu:null,
      sortValue:'',
      sortFilter:"Sort",
      durationFilter:'',
      employeeFilter:'',
      showErrorMessage:false,
      getMapListData:[],
      searchMapLocation:'',
      errorMessage:'',
      getPlace:'',
      jobLocation:'',
      mapLoading:false,
      isFullscreen:false,
      selectedDate: "",
      closeDatePortal:false,
      showDatePicker:false,
      anchorEl:"",
      isEmployee:false,
      currentDate:moment().format("DD/MM/YYYY"),
      employeesData:[],
      searchQuery:"",
      timer: "",
      getEmployeesListLoading:false,
      typedValue:"",
      employeeId:"",
      selectLang:"",
      overlayData:{
        job_tite:"",
        job_id:"",
        start_time:"",
        start_date:"",
        address:"",
        customer_name:""
      },
    };
    this.executeDelayChange = this.executeDelayChange.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectLang: selectedLanguage })
    window.scrollTo(0, 0);
    this.searchMapList();
    this.setState({ mapLoading: true });
    this.checkLoggedInuser()
    // Customizable Area End
  }

  // Customizable Area Start
  checkLoggedInuser = async () => {
    const user = await checkLoggedInUserDashboard();
    this.setState({ isEmployee: user });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.searchMapListApiCallId) {
        this.handleSearchMapListApiResponse(responseJson)
      } else if (apiRequestCallId===this.employeeListApiCallId){
        this.handleEmployeeListData(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeRadioBtn = (event:any) => {
    this.setState({ selectedRadioBtn: event.target.value, mapLoading:true }, this.debounceSearchMapList)
  }
   handleSort = (event:any) => {
    this.setState({ sortValue: event.target.value })
  };
  handleOpenFilterMenu = (event: any) => {
    this.setState({ filterMenu: event.currentTarget, anchorEl: event.currentTarget })
    if (!this.state.isEmployee) {
      this.getEmployeeLists(this.state.searchQuery)
    }

  }
  handleCloseFilterMenu = () => {
    this.setState({ filterMenu: null })
  };
  handleChangeSortFilter=(selected:string)=>{
    this.setState({ sortFilter: selected },()=>{
      this.searchMapList()
    })
  }

  handleChangeDurationFilter=(selected:any)=>{
    this.setState({ durationFilter:selected.value })
  }
  handleChangeEmployeeFilter=(selected:any)=>{
    this.setState({ employeeFilter:selected.value })
  }
  handleChangeSearchMaps = (event: any) => {
    this.setState({ searchMapLocation: event.target.value})
    this.debounceSearchMapList()
  }
  handleChangeGetEmployeeData = (event:any,newVal:any)=>{
  
    clearTimeout(this.state.timer);
    const timer = setTimeout(this.changeDelayTime, 500);
    this.setState({searchQuery:newVal,timer})
  }

  changeDelayTime = () => {
    this.executeDelayChange();
  };

  executeDelayChange() {
    // Perform the input change logic here, e.g., call an API with the new input value.
   this.getEmployeeLists(this.state.searchQuery)
  }
  saveMapJobData = (mapData: any,jobData:any) => {
    const selectedPlace = {
      lat: parseFloat(mapData?.latitude),
      lng: parseFloat(mapData?.longitude),
    };
    this.setState({
      getPlace: {
        selectedPlace: selectedPlace,
        jobData: jobData,
      },
      overlayData:{
        job_id:jobData.attributes?.job_id || "",
        job_tite:jobData.attributes?.job_title || "",
        start_date:moment(jobData.attributes?.start_date).format('D MMM') || "",
        start_time:jobData.attributes?.start_time || "",
        address:jobData.attributes?.site_address?.address || "",
        customer_name:jobData.attributes?.custmoer_name || "",
      }
    });
  
    const map = this.mapRef?.current;
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(selectedPlace);
      map.fitBounds(bounds, 100);
      map.setZoom(15);
    }
  }
 

  // Api call response
  handleSearchMapListApiResponse = (responseJson: any) => {
    this.setState({
      mapLoading: false,
      errorMessage: null,
    })

    if (responseJson && responseJson.data && responseJson.data.length > 0 && !responseJson.errors) {
      this.setState({

        getPlace: responseJson.data,
        getMapListData: responseJson.data
      })

      this.saveMapJobData(responseJson?.data[0]?.attributes?.site_address, responseJson?.data[0])


    } else if (responseJson && responseJson.data && responseJson.data.length === 0) {
      this.setState({
        getPlace: [],
        getMapListData: []
      })

    } else if (responseJson && responseJson.errors) {
      checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history,this.props)
      for (let key in responseJson.errors) {
        this.setState({ errorMessage: `${key} ${responseJson.errors[key][0]}`, showErrorMessage: true })
      }
      this.setState({ getMapListData: [], mapLoading: false })
    }
  }
  handleEmployeeListData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ getEmployeesListLoading: false });
      const employeesData = responseJson.data;
      const options = employeesData.map((item: any) => {
        return { id: item.id, label: item.attributes.full_name, }
      })
      this.setState({ employeesData: options });

    }
    else if (responseJson && responseJson.errors) {
      checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history, this.props)
    }
  }
  // Api call
  convertUrl = (employeeId: any,newDate:any) => {
    return employeeId === "" ? 
    `${configJSON.searchMapLocation}?query=${this.state.searchMapLocation}&sort=${this.state.sortFilter.value}&date=${newDate}&lang=${this.state.selectLang}`
     :
     `${configJSON.searchMapLocation}?&employee_id=${this.state.employeeId}&query=${this.state.searchMapLocation}&sort=${this.state.sortFilter.value}&date=${newDate}&lang=${this.state.selectLang}`
  }
  searchMapList = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const date = this.state.selectedDate
    const newDate = date==="Invalid date"?"":date 
    this.setState({mapLoading:true})
    const searchMap = apiCall({
      header: header,
      httpBody: '',
      url:this.convertUrl(this.state.employeeId,newDate),
      httpMethod: "GET",
    });
    this.searchMapListApiCallId = searchMap.messageId;
    runEngine.sendMessage(searchMap.id, searchMap);
  }
  debounceSearchMapList = debounce(this.searchMapList, 700);
  getEmployeeLists = (searchParam:string)=>{
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const searchEmployeeList = apiCall({
      header: header,
      httpBody: '',
      url:`${configJSON.getAllEmployesAPiEndPoint}?query=${searchParam}&lang=${this.state.selectLang}`,
      httpMethod: "GET",
    });
    this.employeeListApiCallId = searchEmployeeList.messageId;
    runEngine.sendMessage(searchEmployeeList.id, searchEmployeeList);
  }

  handleDateChange = (date: any) => {
    this.setState({ selectedDate: date, showDatePicker: false },
      this.searchMapList)
  }
  handleShowCalendar = (event:any) => {
    this.setState({ anchorEl:event.currentTarget.getBoundingClientRect(),showDatePicker: !this.state.showDatePicker },
      );
  }
  handleHideCalendar = () => {
    this.setState({ showDatePicker: false });
  }

  handleClosePopup = ()=>{
    this.setState({anchorEl:null, filterMenu:null,closeDatePortal:false})
  }
  handleAutoCompleteChange = (e: any, val: any) => {
    this.setState({ typedValue: val},this.setEmployeeId.bind(this,val));
  }
  setEmployeeId  = (val:any)=>{
    this.setState({employeeId:val?.id ? val.id:""},this.searchMapList)
  }
  stopPropogation = (e: any) => e.stopPropagation()

  renderOptionLabel = (option: any) => option.label
  renderOption = (option: any) => (
    <React.Fragment>
      {option.label}
    </React.Fragment>
  )
  renderInput=(params: any) => (
    <StyledTextField
      {...params}
      variant="outlined"
      placeholder={`${this.props.t('dashboardNavbar.employee')}`}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
      onKeyDown={this.stopPropogation}
    />
  )
  clearAllfilter = () => {
    this.setState({
      sortFilter:  { value: "date", label: `${this.props.t('map.date')}` }, selectedDate: "", typedValue: "",
      employeeId: ""
    },this.searchMapList)
  }

  clearEmpListss = () => {
    this.setState({ typedValue :"" })
  }

  clearDateList = () => {
    this.setState({ selectedDate : "" })
  }

  clearSortList = () => {
    this.setState({ sortFilter:"" })
  }

  jobView = () => {
    this.props.history?.push("/Job/Edit", {
      jobId: this.state.getPlace.jobData.id,
      type: "edit"
    });
  }
  resetFunction = () => {
    this.setState({typedValue:"",selectedDate:"",sortFilter:""})
    }
}

// Customizable Area End