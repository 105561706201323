import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {PricingPlanMonthly,GetChargeResponse,CreateChargeResponse} from "../../landingpage/src/PricingController.web"
export const configJSON = require("./config");
import {
  checkPermissionDashboard,
  checkLoggedInUserDashboard,
  getAuthTokenDashboard
} from "./utility.web";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
const responseCodes = ["000","001", "100","200"];
import CryptoJS from "crypto-js";
import React from "react";
interface SidebarItem {
  id: number;
  title: string;
  icon: string;
  width: string;
  height: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  navMenuItem: any;
  active: any;
  active1: any;
  active2: any;
  history: any;
  oldProps: any;
  openToastHandler: Function;
  permissions: any;
  t: (key: string) => string;
  classes?:Record<string,string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  create: boolean;
  showSettingsModal: boolean;
  activeTab: string;
  planModal: boolean;
  activePlan: string;
  planType: string;
  isEmployee: boolean;
  isScrolled: boolean;
  selectedId: number;
  selectedPlan:PricingPlanMonthly | null;
  loading:boolean;
  redirect_url:string;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  makeChargeApiCallId: string = ""
  fetchChargeApiCallId: string = ""
  tabRef : React.RefObject<HTMLDivElement>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.tabRef = React.createRef(); 
    this.state = {
      open: false,
      create: false,
      showSettingsModal: false,
      activeTab: "Monthly",
      planModal: false,
      activePlan: "",
      planType: "",
      isEmployee: false,
      isScrolled: false,
      selectedId: -1,
      selectedPlan:null,
      loading:false,
      redirect_url:"",
      languageSelected: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const params = new URLSearchParams(window.location?.search);
    const tap_id = params.get('tap_id');
    this.checkTapPaymentId(tap_id)
    const user = await checkLoggedInUserDashboard();
    this.setState({ isEmployee: user });

    const pathName = this.props.navigation.history?.location?.pathname;
    if(pathName === "/CreateCustomer") {
      this.setState({ selectedId: 1 });
    } else if(pathName === "/Request/Create") {
      this.setState({ selectedId: 2 });
    } else if(pathName === "/Quote/create") {
      this.setState({ selectedId: 3 });
    } else if(pathName === "/Job/Create") {
      this.setState({ selectedId: 4 });
    } else if(pathName === "/Invoice") {
      this.setState({ selectedId: 5 });
    }

    this.showCreatePopup();

    const selectedlanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedlanguage });
    this.tabRef.current?.scrollIntoView?.({ block: "center",behavior: "instant" as ScrollBehavior }) 
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.makeChargeApiCallId) {
      this.makeChargeResponse(responseJson)
    } else if (apiRequestCallId === this.fetchChargeApiCallId) {
      this.fetchChargeApiResponse(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getSidebarImageStyle = (item : SidebarItem) => {
    if(this.props.active2 === item.id - 1 ){
      return {width: item.width, height: item.height, marginBottom: "0px", filter: "brightness(0) invert(1)"}
    }else{
      return {width: item.width, height: item.height, marginBottom: "0px"}
    }
  }
  getSidebarImageStyleTab2 = (item : SidebarItem) => {
    if(this.props.active1 === item.id - 1 ){
      return {width: item.width, height: item.height, marginBottom: "0px", filter: "brightness(0) invert(1)"}
    }else{
      return {width: item.width, height: item.height, marginBottom: "0px"}
    }
  }
  checkTapPaymentId = (tap_id: string | null)=>{
    if(tap_id){
      this.setState({loading:true})
      this.handlefetchCharge(tap_id)
    }
  }
  redirectToDashboard = ()=>{
    this.props.navigation.navigate("Dashboard")
  }
  makeChargeResponse = (responseJson: CreateChargeResponse ) => {
    this.setState({loading:false})
    if (responseJson && !responseJson.errors) {
      window.location.href = responseJson.transaction.url
    } else if (responseJson && responseJson.errors) {
      const { token, description } = responseJson.errors[0]
      this.props.openToastHandler(token || description, "error")
    }
  };

  fetchChargeApiResponse = (responseJson: GetChargeResponse) => {
    const { message } = responseJson.gateway.response
    this.setState({ loading: false })
    if (responseCodes.includes(responseJson.response.code)) {
      this.props.openToastHandler(message, "success")
      setTimeout(this.redirectToDashboard, 500)
    } else if ((responseJson && responseJson.errors)) {
      const { token } = responseJson?.errors[0];
      this.props.openToastHandler(token || message, "error")

    } else if (!responseCodes.includes(responseJson.response.code)) {
      this.props.openToastHandler(message, "error")
    }
  };
  showCreatePopup = () => {
    if(window.innerWidth < 1280) {
      this.setState({ create: false });
    }
  }
  
  handleCloseDrawer = () => {
    this.setState({ open: false });
  };

  handleClickCreate = (id: number) => {
    if(id === 1) {
      this.setState({ create: true });
    }
  }

  handleClickAwayCreate = () => {
    this.setState({ create: false });
  }

  handleNavigateCreateItems = (id: number) => {
    this.setState({ selectedId: id });
    if(id === 1) {
      this.handleNavigate("CreateCustomer");
    } else if(id === 2) {
      this.props.navigation.history.push("/Request/Create");
    } else if(id === 3) {
      localStorage.removeItem("quote_id");
      this.props.navigation.history.push("/Quote/create");
    } else if(id === 4) {
      this.props.navigation.history.push("/Job/Create");
    } else if(id === 5) {
      this.props.navigation.history.push("/Invoice");
    }
  }

  handleTabValueChange = (event: any, value: number) => {
  this.state.isEmployee
  ? this.handleTabValueChangeCallbackV1(value,"EmployeeDashboard")
  : this.handleTabValueChangeCallbackBO(value,"Dashboard")
  };

  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleTabValueChangeCallbackV1 = (value:any,dashBoardUrl:any)=>{
    if (value === 1) {
      this.handleNavigate(dashBoardUrl);
    } else if (value === 2) {
      this.handleTabValueChange1("", value, "tasks", "TaskAllocator");
    } else if (value === 3) {
      this.handleTabValueChange1("", value, "request", "Requests");
    } else if (value === 4) {
      this.handleTabValueChange1("", value, "quote", "Quotes");
    } else if (value === 5) {
      this.handleTabValueChange1("", value, "job", "Jobs");
    } else if (value === 6) {
      this.handleTabValueChange1("", value, "invoice", "Invoices");
    } else if (value === 7) {
      this.handleNavigate("Maps");
    } else if (value === 8) {
      this.handleTabValueChange1("", value, "scheduling", "Calendar");
    } else if (value === 9) {
      this.handleNavigate("Timesheet");
    }
  }

  handleTabValueChange1 = async (
    event: any,
    value: number,
    passed_category: string,
    url: string
  ) => {
    const loggedInUser = await checkLoggedInUserDashboard();

    const isPermit = await checkPermissionDashboard(passed_category);

    this.handleTabValueChange1CallBack(
      value,
      loggedInUser,
      isPermit,
      url,
      passed_category
    );
  };

  handleTabValueChange3 = async (event: any, value: any) => {
    const loggedInUser = await checkLoggedInUserDashboard();
    this.handleTabValueChange3Callback(value,loggedInUser)
  };

  handleTabValueChange3Callback = async(value:number, loggedInUser:any)=>{
    if (value === 0) {
      const isPermit = await checkPermissionDashboard("supplier_data");

      this.handleTabValueChange1CallBack(
        value,
        loggedInUser,
        isPermit,
        "Suppliers",
        "supplier_data"
      );
    } else if (value === 1) {
      const isPermit = await checkPermissionDashboard("customers_data");

      this.handleTabValueChange1CallBack(
        value,
        loggedInUser,
        isPermit,
        "Customers",
        "customers_data"
      );
    }else if (value === 2) {
      this.handleNavigate("Employees");
    }
  }

  handleTabValueChange1CallBack = async (
    value: number,
    loggedInUser: any,
    isPermit: any,
    url: string,
    passed_category: string
  ) => {
    
    if (value === 0) {
      const isPermit = await checkPermissionDashboard("supplier_data");
      this.checkPermissionSuppliers(loggedInUser, isPermit);
    } else if (value === 6 ) {
      const isPermit = await checkPermissionDashboard(passed_category);
      this.checkPermissionInvoices(loggedInUser, isPermit);
    } else if (value === 8 ) {
      const isPermit = await checkPermissionDashboard('scheduling');
      this.checkPermissionCalender(loggedInUser, isPermit);
    } else if (value === 1 || value === 4 || value === 2 || value === 3 || value === 5) {
      this.checkPermissionCustomers(
        loggedInUser,
        isPermit,
        url,
        passed_category
      );
    } 
  };
  checkPermissionInvoices = (loggedInUser: boolean, isPermit: any) => {
    if (loggedInUser) {
      if (isPermit && !isPermit[1]) {
        this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      } else if (isPermit && isPermit[0] === "None") {
        this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      } else {
        this.handleNavigate("Invoices");
      }
    } else {
      this.handleNavigate("Invoices");
    }
  };
  checkPermissionCalender = (loggedInUser: boolean, isPermit: any) => {
    if (loggedInUser) {
      if (isPermit && !isPermit[1]) {
        this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      } else if (isPermit && isPermit[0] === "None") {
        this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      } else {
        this.handleNavigate("Calendar");
      }
    } else {
      this.handleNavigate("Calendar");
    }
  };
  checkPermissionCustomers = (
    loggedInUser: boolean,
    isPermit: any,
    url: string,
    passed_category: string
  ) => {
    if (loggedInUser) {
      if (isPermit && !isPermit[1]) {
        this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      } else if (isPermit && isPermit[0] === "None") {
        this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      } else {
        localStorage.removeItem("customerName");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("editedCustomerId");
        localStorage.removeItem("customerPage");
        this.handleNavigate(url);
      }
    } else {
      removeStorageData("customerName");
      removeStorageData("customer_id");
      removeStorageData("editedCustomerId");
      this.handleNavigate(url);      
    }
  };

  checkPermissionSuppliers = (loggedInUser: boolean, isPermit: any) => {
    if (loggedInUser) {
      if (isPermit && !isPermit[1]) {
        this.props.openToastHandler("You are not authorised.", "error");
      } else if (isPermit && isPermit[0] === "None") {
        this.props.openToastHandler("You are not authorised.", "error");
      } else {
        this.handleNavigate("Suppliers");
      }
    } else {
      this.handleNavigate("Suppliers");
    }
  };

  handleTabValueChange2 = (event: any, value: number) => {
    if (value === 0) {
      this.handleNavigate("RequestReport");
    } else if (value === 1) {
      this.handleNavigate("CompanyInformation");
    }
  };

  handleModalSettings = () => {
    this.setState({ showSettingsModal: !this.state.showSettingsModal });
  };

  handleClickAway = () => {
    this.setState({ showSettingsModal: false });
  };

  handleClickMonthly = () => {
    this.setState({ activeTab: "Monthly" });
  };

  handleClickYearly = () => {
    this.setState({ activeTab: "Yearly" });
  };

  handleClosePlanModal = () => {
    this.setState({ planModal: false });
  };

  handleClickProceedPlan = () => {
    this.setState({ planModal: false });
    this.handleMakeCharge(this.state.selectedPlan);
  };


  handleButtonStartUsing = async (item: PricingPlanMonthly) => {
    const token = await getAuthTokenDashboard();
    this.redirectToLogin(token, item)
   
  }

  redirectToLogin = (token: string, item:PricingPlanMonthly) => {
    if (token) {
      this.setState({
        planModal: true,
        activePlan: item.planPrice,
        planType: item.title,
        selectedPlan:item
      })
    } else {
      this.props.navigation.history.push("/Login")
    }
  }

  handleTabNavigate1 = (item: any) => {
    if (item.id === 1) {
      this.handleNavigate("Create");
    } else if (item.id === 2) {
      this.handleNavigate("Dashboard");
    } else if (item.id === 3) {
      this.handleNavigate("TaskAllocator");
    } else if (item.id === 4) {
      this.handleNavigate("Requests");
    } else if (item.id === 5) {
      this.handleNavigate("Quotes");
    } else if (item.id === 6) {
      this.handleNavigate("Jobs");
    } else if (item.id === 7) {
      this.handleNavigate("Invoices");
    } else if (item.id === 8) {
      this.handleNavigate("Maps");
    } else if (item.id === 9) {
      this.handleNavigate("Calendar");
    } else if (item.id === 10) {
      this.handleNavigate("Timesheet");
    }
  }

  handleTabNavigate2 = (item: any) => {
    if (item.id === 1) {
      this.handleNavigate("Suppliers");
    } else if (item.id === 2) {
      this.handleNavigate("Customers");
    } else if (item.id === 3) {
      this.handleNavigate("Employees");
    }
  }

  handleTabNavigate3 = (item: any) => {
    if (item.id === 1) {
      this.handleNavigate("Reports");
    } else if (item.id === 2) {
      this.handleNavigate("CompanyInformation");
    }
  }

  handleNavigateDashboard = () => {
    this.handleNavigate("Dashboard");
  }

  handleNavigateFAQs = () => {
    this.handleNavigate("FAQs");
  }
  handlefetchCharge= (tap_id: string) => {
    const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchChargeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchChargeAPIEndPoint}?tap_id=${tap_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.getApiMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleMakeCharge= (item:PricingPlanMonthly |null) => {
    
    this.setState({loading:true})
    
    const header = {
      "token": localStorage.getItem(configJSON.authToken),
    };
  
    let formdata = new FormData();

    const body = {
      redirect_url:"/FreeTrialExpired",
      token_id:"src_card",
      currency_code: item && item.currency,
      pricing_plan_id:item && item.id
    }
   
    formdata.append("redirect_url", body.redirect_url);
    formdata.append("token", body.token_id);
    formdata.append("currency_code", String(body.currency_code));
    formdata.append("pricing_plan_id", String(body.pricing_plan_id));


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.makeChargeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makeChargeAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  checkPermissionCallDashboardBO = (ciphertextV1:string, passedCategoryV1:string)=>{
    if(ciphertextV1){
      let bytesV1 = CryptoJS.AES.decrypt(ciphertextV1, configJSON.secretKey);
      let getPermissionsV1 = JSON.parse(bytesV1.toString(CryptoJS.enc.Utf8));
     
      const parsedPermissionsv1 = getPermissionsV1;
      if (parsedPermissionsv1 && parsedPermissionsv1.length > 0) {
       
        for (const permissionV1 of parsedPermissionsv1) {
          const { feature_name,  feature } = permissionV1;
          if (feature_name === passedCategoryV1) {
            return [feature] as boolean[];
          }
        }
      } else {
        return [false];
      }
    }
  }

 checkPermissionDashboardBO = async (passedCategoryV1: string) => {
  const ciphertextV1 = await getStorageData("Bo permissions");
  return this.checkPermissionCallDashboardBO(ciphertextV1,passedCategoryV1)
};

checkPermissionCustomersBO = (navigateUrl: string,  isPermit: boolean[] | undefined) => {  
    if (isPermit && !isPermit[0]) {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    } else if (isPermit && isPermit[0] === false) {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    } else {
      this.handleNavigate(navigateUrl);
    }
};

handleTabValueChange1CallBackBo = async (
  value: number,
  isPermit: boolean[] | undefined,
  navigateUrl: string,
  passed_category: string
) => {
  
  if (value === 0) {
    const isPermit = await this.checkPermissionDashboardBO("supplier_data");
    this.checkPermissionCustomersBO(navigateUrl,isPermit);
  } else if (value === 6  || value === 7 || value === 9) {
    const isPermit = await this.checkPermissionDashboardBO(passed_category);
    this.checkPermissionCustomersBO(navigateUrl, isPermit);
  } else if (value === 8 ) {
    const isPermit = await this.checkPermissionDashboardBO('Calendar Management');
    this.checkPermissionCustomersBO(navigateUrl, isPermit);
  } else if (value === 1 || value === 4 || value === 2 || value === 3 || value === 5) {
    this.checkPermissionCustomersBO(
      navigateUrl,
      isPermit,
    );
  } 
};

  handleTabValueChangeBO = async (
    value: number,
    passed_category: string,
    navigateUrl: string
  ) => {
    removeStorageData("status")
    removeStorageData("timeSheetStatus")

    const isPermit = await this.checkPermissionDashboardBO(passed_category);
    this.handleTabValueChange1CallBackBo(
      value,
      isPermit,
      navigateUrl,
      passed_category
    );
  };

  handleTabValueChangeCallbackBO = (value:number,dashBoardUrl:string)=>{
    switch (value) {
      
      case 1:
        this.handleNavigate(dashBoardUrl);
        break;
      case 2:
        this.handleTabValueChangeBO(value, "Tasks Creation", "TaskAllocator");
        break;
      case 3:
        this.handleTabValueChangeBO(value, "Request Management", "Requests");
        break;
      case 4:
        this.handleTabValueChangeBO(value, "Quote Management", "Quotes");
        break;
      case 5:
        this.handleTabValueChangeBO(value, "Job Template", "Jobs");
        break;
      case 6:
        this.handleTabValueChangeBO(value, "Invoice Management", "Invoices");
        break;
      case 7:
        this.handleTabValueChangeBO(value, "GPS Tracking", "Maps");
        break;
      case 8:
        this.handleTabValueChangeBO(value, "Calendar Management", "Calendar");
        break;
      case 9:
        this.handleTabValueChangeBO(value, "Time Tracking", "Timesheet");
        break;
      default:
        break;
    }
  }

}
// Customizable Area End
