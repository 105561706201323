import React from "react";

// Customizable Area Start
import { Box, Grid, Container, Typography, Button,withWidth } from "@material-ui/core";
import { withStyles , Theme, StyleRulesCallback} from "@material-ui/core/styles";
import {
  arrowRight,
  arrowLeft,
  calendarIcon,
  arrowUp,
} from "./assets";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { Calendar as CustomCalender, Event, View, momentLocalizer } from "react-big-calendar";
import { StyledRoot } from "../../dashboard/src/utility.web";
import "date-fns";
import moment from "moment";
import { withSnackbar } from "../../../components/src/toastweb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../dashboard/src/ReactDatePicker.css";
import EmployeeDashboardController, {
  CalEvent,
  ConvertedEvent,
  Props,
} from "./EmployeeDashboardController.web";
import { checkEventOrNot,checkBoxShadowEventOrNot } from "../../../components/src/CommonFunction";
import calendarStyles from '../../appointmentmanagement/src/Calendar.style'
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {  CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
const localizer = momentLocalizer(moment)
import CustomDatePicker from "../../../components/src/CustomDatePicker";
// Customizable Area End

// Customizable Area Start
const mockTimeSheetData : any= [
  {
    id: 1,
    jobName: "JobXXX",
    startTime: "9:00 AM",
    endTime: "12:00 PM",
  },
  {
    id: 2,
    jobName: "JobXXX",
    startTime: "1:00 PM",
    endTime: "3:00 PM",
  },
  {
    id: 3,
    jobName: "JobXXX",
    startTime: "4:00 PM",
    endTime: "6:00 PM",
  },
  {
    id: 4,
    jobName: "JobXXX",
    startTime: "9:00 AM",
    endTime: "12:00 PM",
  },
  {
    id: 5,
    jobName: "JobXXX",
    startTime: "1:00 PM",
    endTime: "3:00 PM",
  },
  {
    id: 6,
    jobName: "JobXXX",
    startTime: "4:00 PM",
    endTime: "6:00 PM",
  },
];

interface ObjOfDate{
  "label": string,
  "date": Date|string,
  "drilldownView":string,
  "isOffRange": boolean,
  "onDrillDown"?:Function
}

export function scheduleBgColor (events:CalEvent[]|undefined,event:object|Date|string) {
  if (checkEventOrNot({ eventDate: event, eventsArray: events })) {
      return '#BBD1BA'
     }else {
    return ''
  }
}
export function scheduleHorizontalLine (events:CalEvent[] | undefined,event:object|Date|string) {
  if (checkBoxShadowEventOrNot({ eventDate: event, eventsArray: events })) {
      return 'inset 4px 0em #386237'
  }else {
    return ''
  }
}
export function customDayPropGetter(event:Date|string,events:CalEvent[] | undefined,view:View| undefined){
  return {
    style: {
      background: view==='month'? scheduleBgColor(events,event):'',
      boxShadow: view==='month'? scheduleHorizontalLine(events,event):'',
    },
  }
}
export function dateHeaderStyle(date:ObjOfDate,events: CalEvent[]|undefined) {
  if (date.isOffRange === true) {
   return 'white'
  } else {
    if (checkEventOrNot({ eventDate: date.date, eventsArray: events })) {
        return '#386237'
    } else {
      return '#626262'
    }
  }
}
export function scheduleEventBox(event:Event|ConvertedEvent) {
  return <Box component="span"
    style={{ color: '#386237'}}>
    {event.title}
  </Box>
}
// Customizable Area End

export class EmployeeDashboard extends EmployeeDashboardController {
  constructor(props: Props) {
    super(props);
  }
  //Customizable Area Start
  mockCalendarData = (item: any,classes:any) => {
    return (
      <Grid item>
        <Typography
        variant="body1"
          style={{
            color: "#808191",
            fontWeight: CONSTANTS.fontWeight.fontWeight500,
            textAlign: "center",
          }}
        >
          {moment(item.date).format("DD MMM")}
        </Typography>
        <Typography
          className={classes.calDay}
          style={{
            color: item.id == 1 ? "black" : "#808191",
           
            fontWeight: CONSTANTS.fontWeight.fontWeight400,
            fontFamily: "Rubik",
            textAlign: "center",
          }}
        >
          {moment(item.date).format("dddd")}
        </Typography>
      </Grid>
    );
  };
  
  renderMockCard = (item: any, classes: any,index:number) => {
    const {t}= this.props
    return (
      <Grid item lg={6} md={6} xs={6} key={item.id}>
        <Box
          style={webStyle.card}
          className={classes.card}
        >
          <Box className={classes.cardSecondDiv}>
            <Typography
              style={webStyle.cardTitle}
              className={classes.cardCenterAlign}
              variant="subtitle2"
            >
              {t(`employeeDashboard.dashboardCard.${index}`)}
            </Typography>
            <Typography
              style={webStyle.cardCount}
              variant="h1"
              className={`${classes.cardCenterAlign} ${classes.cardCount}`}
            >
              {item.count}
            </Typography>
            <Box style={webStyle.percentageContainer} className={classes.percentageContainer}>
              <Box className={classes.percentageContainerFirstChild}>
                <img style={parseFloat(item.percentage) < 0 ? { ...webStyle.icon, transform: "scaleY(-1)" } : webStyle.icon} src={arrowUp} alt="" />
                <Typography
                  style={webStyle.percentage}
                  variant="subtitle2"
                >
                  {item.percentage}
                </Typography>
              </Box>
            
              <Typography
                style={webStyle.description}
                variant="body2"
                className={classes.percentageContainerFirstChildDescription}
              >
                {item.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  renderCustomTypo = (content: string, classes: any, card: string) => {
    return (
      <Typography
        className={classes.jobsLeftBodyContent}
        style=
        {content === "Customer Name" ||
          content === "Scheduled Date/Time" ||
          content === "Task Name" ||
          content === "To Do" ||
          content === "Due Date"
          ? this.customTypoStyles(content) :
          this.customTypoStylesV1(content)}
      >
        {content}
      </Typography>
    )
  }

  customTypoStyles = (content: string) => {
    return content === "Scheduled Date/Time" ||
      content === "To Do" ||
      content === "Due Date" ?
      { ...webStyle.jobsLeftBodyContent, marginTop: "1.5rem" } :
       webStyle.jobsLeftBodyContent
  }

  customTypoStylesV1 = (content: string) => {
    const {t} = this.props;
    return content === t("customers.noDataFound") ?
      {
        ...webStyle.jobsRightBodyContent,
        textAlign: "center", marginTop: "7rem"
      } :
      { ...webStyle.jobsRightBodyContent, textAlign: "start" }
  }

  renderJobsHeaderCard = (isMobile:boolean)=>{
    const {t} = this.props
    return (
      <Box style={webStyle.jobsHeaderCard} data-test-id="toggleJobs" onClick={this.toggleJobs.bind(this, isMobile)}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="subtitle1" style={webStyle.jobsStyle}>
            {t("employeeDashboard.jobs")}
            </Typography>
          </Grid>
          <Grid item xs={4} style={this.state.lang === "en" ? webStyle.commonTextAlign : webStyle.commonTextAlignArabic}>
            <Button data-test-id="handleNavigate" style={webStyle.commonButton}
              onClick={this.handleNavigate.bind(this, "/Jobs",)}>
              <Typography variant="body2"   style={webStyle.jobsHeaderButton}>
                {t("employeeDashboard.viewAll")}
              </Typography>
            </Button>
        </Grid>
      </Grid>
    </Box>
    )
  }

  renderJobsHeightStyles = () => {
    return this.state.lang === "en" ? webStyle.jobsRightBodyContent : { ...webStyle.jobsRightBodyContent, ...webStyle.commonTextAlignArabic }
  }
  renderJobsHeight = (toggleJob: boolean, isMobile: boolean, classes: any) => {
    const {t} = this.props
    if (toggleJob || !isMobile) {
      return (
        <Box className={classes.jobsHeight} style={{ maxHeight: "283px", overflow: "auto" }}>
          <Grid direction="column" justifyContent="flex-start" alignItems="center">
          {this.state.jobsData && this.state.jobsData.length === 0 &&
              this.renderCustomTypo(t("customers.noDataFound"), classes, "No")
            }
            {this.state.jobsData && this.state.jobsData.length > 0 && 
            this.state.jobsData.map((item: any) => (
              <>
                {/* laarge screen */}
                <Grid item container direction="row"
                  alignItems="center"
                  xs={12} className={classes.hideSmallElement} style={webStyle.jobsContainer}>
                  <Grid item xs={6}>
                    <Grid
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.jobsLeftBodyContent}
                          style={webStyle.jobsLeftBodyContent}
                        >
                          {t("employeeDashboard.customerName")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.jobsLeftBodyContent}
                          style={webStyle.jobsLeftBodyContent}
                        >
                          {t("employeeDashboard.scheduledDate")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid direction="column" alignItems="flex-end">
                      <Grid item xs={12}>
                        <Typography
                          className={classes.jobsLeftBodyContent}
                          style={this.renderJobsHeightStyles()}
                        >
                          {item?.customer_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.jobsLeftBodyContent}
                          style={this.state.lang === "en" ? webStyle.jobsRightBodyContent : { ...webStyle.jobsRightBodyContent, ...webStyle.commonTextAlignArabic }}
                        >

                          {item?.schedule_time ? `${item?.schedule_date}, ${item?.schedule_time}` : item?.schedule_date}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* //small screen */}
                <Grid item container style={webStyle.jobsContainer}
                  className={classes.hideLargeElement}
                >
                  <Grid item xs={12} style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem"
                  }} >
                    <Box style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem"
                    }}>

                      {this.renderCustomTypo("Customer Name", classes, "JOBS")}
                      {this.renderCustomTypo(item?.customer_name, classes, "JOBS")}
                      {this.renderCustomTypo("Scheduled Date/Time", classes, "JOBS")}
                      {this.renderCustomTypo(
                        item?.schedule_time ?
                          `${item?.schedule_date}, ${item?.schedule_time}` :
                          item?.schedule_date, classes, "JOBS")}

                    </Box>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      )
    }
  }
  
  renderTaskTopBar = (isMobile: boolean) => {
    const{t}= this.props
    return (
      <Box style={webStyle.taskTopBar} data-test-id="toggleTasks" onClick = {this.toggleTasks.bind(this,isMobile)}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <Typography variant="subtitle1" style={webStyle.tasksStyle}>
            {t("employeeDashboard.tasks")}
            </Typography>
          </Grid>
          <Grid item xs={4} style={this.state.lang==="en" ? webStyle.commonTextAlign : webStyle.commonTextAlignArabic}>
            <Button data-test-id="handleNavigate" style={webStyle.commonButton} 
              onClick={this.handleNavigate.bind(this, "/Tasks",)}>
              <Typography
                style={{
                  ...webStyle.jobsHeaderButton,
                  color: "#FF90B4",
                }}
                variant="body2"
                
              >
                {t("employeeDashboard.viewAll")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderTaskOuterContainer = (classes: any) => {
    const { t} = this.props
    return (
      <Box style={webStyle.tasksOuterContainer} className={classes.hideSmallElement}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <Typography variant="subtitle1" style={webStyle.taskBarSecondayTitle}>
              {t("employeeDashboard.taskName")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" style={webStyle.taskBarSecondayTitle}>
            {t("employeeDashboard.toDo")}
            </Typography>
          </Grid>
          <Grid item xs={4} style={this.state.lang==="en" ? webStyle.commonTextAlign : webStyle.commonTextAlignArabic}>
            <Typography variant="subtitle1" style={webStyle.taskBarSecondayTitle}>
            {t("employeeDashboard.dueDate")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderTaskHeight = (toggleTask: boolean, isMobile: boolean, classes: any) => {
    const {t} = this.props;
    if (toggleTask || !isMobile) {
      return (
        <Box className={classes.tasksHeight} style={{ maxHeight: "315px", overflow: "auto" }}>
          <Grid direction="column" justifyContent="flex-start"
            alignItems="center">
            {this.state.tasksData && this.state.tasksData.length === 0 &&
              this.renderCustomTypo(t("customers.noDataFound"), classes, "No")
            }
            {this.state.tasksData && this.state.tasksData.length > 0 && 
             this.state.tasksData.map((item: any) => (
              <Box key={item.task_name} className={classes.hideSmallElement} style={webStyle.tasksInnerContainer} >
                <Grid className={classes.hideSmallElement} item container direction="row" xs={12} style={webStyle.tasksContainer}>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.jobsLeftBodyContent}
                      style={this.state.lang === "en" ? webStyle.taskMiddleBodyContent : { ...webStyle.taskMiddleBodyContent, ...webStyle.commonTextAlign }}
                    >
                      {item?.task_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography
                        className={classes.jobsLeftBodyContent}
                        style={this.state.lang === "en" ? webStyle.taskMiddleBodyContent : { ...webStyle.taskMiddleBodyContent, ...webStyle.commonTextAlign }}
                      >
                        {item?.task_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.jobsLeftBodyContent}
                      style={{
                        ...webStyle.taskMiddleBodyContent,
                        textAlign: this.state.lang === "en" ? "right" : "left",
                      }}
                    >
                      {item?.due_date}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.jobsLeftBodyContent}
                      style={{
                        ...webStyle.taskMiddleBodyContent,
                        textAlign: this.state.lang === "en" ? "left" : "right",
                      }}
                    >
                      {item?.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}

            {this.state.tasksData.map((item: any) => (
              <Grid item key={item.task_name} container style={webStyle.jobsContainer}
                className={classes.hideLargeElement}
              >
                <Grid item xs={12}
                 style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem"
                }} >
                  <Box display={"flex"} flexDirection={"column"} gridGap={"0.5rem"}
                  >

                    {this.renderCustomTypo("Task Name", classes, "TASKS")}
                    {this.renderCustomTypo(item?.task_name, classes, "TASKS")}
                    {this.renderCustomTypo("To Do", classes, "TASKS")}
                    <Box flexDirection={"row"} display={"flex"} gridGap={"0.5rem"}>
                      {this.renderCustomTypo(item?.task_name, classes, "TASKS")}
                      {this.renderCustomTypo(item?.status, classes, "TASKS")}
                    </Box>
                    {this.renderCustomTypo("Due Date", classes, "TASKS")}
                    {this.renderCustomTypo(item?.due_date, classes, "TASKS")}

                  </Box>
                </Grid>
              </Grid>

            ))}
          </Grid>

        </Box>
      )
    }
  }
  renderGoogleMap = (center: any, markerPlaces: any) => {
    return (
      <GoogleMap
        mapContainerStyle={webStyle.googleMaps}
        center={center}
        zoom={10}
        data-test-id="googleLoad"
        onLoad={(map) => { this.onMapGoogleLoad(map) }}
        options={{
          disableDefaultUI: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl:false
        }}
      >
        {markerPlaces?.map((marker: any, index: number) => (
          <Marker
            key={index}
            position={{ lat: marker?.latitude, lng: marker?.longitude }}
          />
        ))}
      </GoogleMap>
    )
  }

  renderTimesheetTopBar = (isMobile: boolean) => {
    const{t}= this.props
    return (
      <Box style={webStyle.timeSheetTopBar} data-test-id="toggleTimesheet" onClick={this.toggleTimesheet.bind(this, isMobile)}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" >
          <Grid item xs={5} lg={7}>
            <Typography variant="subtitle1" style={webStyle.timesheetStyle} >
            {t("employeeDashboard.timesheet")}
            </Typography>
          </Grid>
          <Grid item xs={4} lg={5} style={this.state.lang==="en" ? webStyle.commonTextAlign : webStyle.commonTextAlignArabic}>
            <Button 
              data-test-id="handleNavigate"
              onClick={this.handleNavigate.bind(this, "/Timesheet",)} 
              style={webStyle.commonButton}
            >
              <Typography
                style={{
                  ...webStyle.jobsHeaderButton,
                  color: "#FFAF1A",
                }}
                variant="body2"
                
              >
                {t("employeeDashboard.viewAll")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderTimesheetOuterContainer = (toggleTimesheet: boolean, isMobile: boolean, classes: any) => {
    const {t} = this.props;
    if (toggleTimesheet || !isMobile) {
      return (
        <Box style={webStyle.timesheetOuterContainer}>
          <Grid direction="column" justifyContent="flex-start" alignItems="center" >
            {mockTimeSheetData.length === 0 &&
              this.renderCustomTypo(t("customers.noDataFound"), classes, "No")
            }
            {mockTimeSheetData.length > 0 && mockTimeSheetData.map((item:any) => (

              <Grid key={item.id} item container
                direction="row" xs={12}
                style={webStyle.timesheetContainer}>
                <Grid direction="column" alignItems="flex-start">
                  <Grid item xs={12}>
                    <Typography
                      className={classes.jobsLeftBodyContent}
                      style={{
                        ...webStyle.jobsLeftBodyContent,
                        paddingBottom: "1rem",
                      }}
                    >
                      {item.jobName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.jobsLeftBodyContent}
                      style={{
                        ...webStyle.jobsLeftBodyContent,
                        color: "#4c4c4c",
                        fontWeight: 500,
                      }}
                    >
                      ({item.startTime}&nbsp;-&nbsp;{item.endTime}
                      )
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

            ))}
          </Grid>
        </Box>
      )
    }
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const {  markerPlaces, center } = this.state;
    const dayFormat = (date:Date) => moment(new Date(date)).format("D MMM dddd").toString()
    const { classes, width ,t }: any = this.props;
    const isMobile = ['xs'].includes(width);
  
    return (
      <>
        <DashboardSideBar oldProps={undefined} permissions={undefined} history={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.dashboard')} active={1} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.lang}>
          <Box sx={webStyle.container} className={classes.boxContainer}>
            <Container maxWidth={false}>
              <div style={{ maxWidth: "150px" }}>
                <CustomDatePicker
                  data-test-id="customDatePicker"
                  selectedDate={this.state.selectedDate}
                  status={this.state.status}
                  dateModal={this.state.dateModal}
                  datePickerRef={this.datePickerRef}
                  languageSelected={this.state.lang}
                  handleDateChange={this.handleEmpDateChange}
                  handleSelectStatus={this.handleEmpSelectStatus}
                  handleDateModalOpen={this.handleEmpDateModalOpen}
                  handleStatusModalClose={this.handleEmpStatusModalClose}
                  type="EMP"
                />
              </div>
              <Grid container spacing={3} style={{paddingTop: "2rem"}}>
                {this.state.dashboardCard.map((item: any,index:number) => (
                 this.renderMockCard(item, classes,index)
                ))}
              </Grid>
              <Grid container spacing={3} style={{ paddingTop: "2rem" }}>
                <Grid item lg={8} md={12} xs={12}>
                  <Box style={webStyle.jobsCard} className={classes.hideSmallElement}>

                    {this.renderJobsHeaderCard(isMobile)}
                    {this.renderJobsHeight(this.state.toggleJob, isMobile, classes)}
                   
                  </Box>
                  <Box style={this.state.toggleJob ?
                    { ...webStyle.jobsCard } :
                    { ...webStyle.jobsCard, minHeight: 0 }} 
                    className={classes.hideLargeElement}>
                    {this.renderJobsHeaderCard(isMobile)}
                    {this.renderJobsHeight(this.state.toggleJob, isMobile, classes)}
                  </Box>
                </Grid>

                <Grid item lg={4} md={12} xs={12} className={classes.hideSmallElement}>
                  {this.renderGoogleMap(center, markerPlaces)}
                </Grid>
              </Grid>
              
              <Grid container spacing={3} style={{paddingTop: "1rem"}}>
                <Grid item lg={12} md={12} xs={12}>
                  <Box style={webStyle.jobsCard}>
                    <Box style={webStyle.schedualTopBar}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={4}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              width: "100%",
                              minHeight: "28px",
                              color: "#ed9134",
                  
                              fontWeight: CONSTANTS.fontWeight.fontWeight500,
                            }}
                          >
                           {t("employeeDashboard.schedule")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={{ minHeight: "369px" }}>
                      <Grid container direction="column">
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            style={{ padding: "29px 20px 0px 20px" }}
                          >
                            <Grid item xs>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#5c5c5d",
                                  fontWeight: CONSTANTS.fontWeight.fontWeight500,
                                }}
                              >
                                {t("employeeDashboard.today")}, {moment().format("DD MMMM, YYYY")}
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                                <Box style={webStyle.calendarMonthsContainer}>
                                  {this.state.calendarStatus.map((item: any,index:number) => (
                                    <Button
                                      data-test-id="handleChangeMonthlyWeekly"
                                      className={this.state.mockCalendarStatus === item.title ? classes.weeklyContainer : classes.weeklyContainer1}
                                      key={item.id}
                                      onClick={() => this.handleChangeMonthlyWeekly(item)}
                                    >
                                      <Typography
                                      className={classes.weeklyText}
                                        style={this.state.mockCalendarStatus === item.title ? {...webStyle.weeklyText} : webStyle.weeklyText}
                                        component={"div"}
                                      >
                                      {t(`employeeDashboard.calendarStatus.${index}`)}
                                      </Typography>
                                    </Button>
                                  ))}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <CustomCalender
                          culture={this.state.lang === 'ar' ? 'ar' : "en"}
                          className={`${classes.customCalender} ${classes.customBigCalender} ${classes.calendarHeight}`}
                          localizer={localizer}
                          events={this.state.events}
                          timeslots={2}
                          defaultView="week"
                          view={this.state.view}
                          defaultDate={new Date()}
                          date={this.state.selectedDate}
                          formats={{dayFormat}}
                          dayPropGetter={(event: Date) => customDayPropGetter(event,this.state.events,this.state.view)}
                          components={{
                            month: {
                              dateHeader:(date:ObjOfDate) =>
                                <Box component="span"style={{ color: dateHeaderStyle(date, this.state.events) }}>
                                  {date.label}
                                </Box>,
                              event: (event:Event) =>
                                <div>{scheduleEventBox(event)}</div>
                            },
                            week: {
                              event: (event:Event) =>
                                <div>{scheduleEventBox(event)}</div>
                            }
                          }}
                        />
                      </Grid>
                    </Box>
                    <Box></Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ paddingTop: "2rem" }}>
                <Grid item lg={8} md={12} xs={12}>
                  <Box style={{ ...webStyle.jobsCard, minHeight: "380px" }} className={classes.hideSmallElement}>
                   {this.renderTaskTopBar(isMobile)}
                   {this.renderTaskOuterContainer(classes)}
                   {this.renderTaskHeight(this.state.toggleTask,isMobile,classes)}
                  </Box>

                  <Box style={this.state.toggleTask ?
                    { ...webStyle.jobsCard ,minHeight: "380px"} :
                    { ...webStyle.jobsCard, minHeight: 0 }} className={classes.hideLargeElement}>
                   {this.renderTaskTopBar(isMobile)}
                   {this.renderTaskOuterContainer(classes)}
                   {this.renderTaskHeight(this.state.toggleTask,isMobile,classes)}
                  </Box>
                </Grid>
                <Grid item lg={4} md={12} xs={12}>
                  <Box style={{ ...webStyle.jobsCard, minHeight: "426px" }}
                    className={classes.hideSmallElement}
                  >
                    {this.renderTimesheetTopBar(isMobile)}
                    {this.renderTimesheetOuterContainer(this.state.toggleTask,isMobile,classes)}

                  </Box>
                  <Box style={this.state.toggleTimesheet ?
                    { ...webStyle.jobsCard, minHeight: "426px" } :
                    { ...webStyle.jobsCard, minHeight: 0 }}
                    className={classes.hideLargeElement}
                  >
                    {this.renderTimesheetTopBar(isMobile)}
                    {this.renderTimesheetOuterContainer(this.state.toggleTimesheet,isMobile,classes)}

                  </Box>
                </Grid>

                <Grid item lg={4} md={12} xs={12} className={classes.hideLargeElement}>
                  {this.renderGoogleMap(center, markerPlaces)}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </StyledRoot>
        <LoadingBackdrop
          loading={this.state.dashboardLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles:StyleRulesCallback<Theme, {}, string> = (theme: Theme) => ({
  customCalender: {
    '& .rbc-month-view': {
      boxShadow: "none",
      marginTop: '0px',
      border: 'none',
      '& .rbc-month-header': {
        '& .rbc-header': {
          [theme.breakpoints.down("xs")]: {
            padding: "1px !important",
            paddingBottom:"8px !important"
          },
          padding: '25px',
          fontWeight: 400,
          color: 'rgb(153, 153, 153)'
        },
      },
    },
    '& .rbc-time-view': {
      boxShadow:'none',
      marginTop:'0px',
      width:'100%',
     
       '& .rbc-allday-cell':{
        height:'10%'
       },
      '& .rbc-day-bg + .rbc-day-bg':{
        borderLeft:'none'
      },
      '& .rbc-time-header-content':{
        borderLeft:'none'
      },
      '& .rbc-header': {
        borderBottom: 'none',
        padding: '16px',
        [theme.breakpoints.down("sm")]: {
          padding: '10px',
        },
        [theme.breakpoints.down("xs")]: {
          padding: '2px',
        },
        '& .rbc-button-link ': {
          maxWidth: '75px',
          wordBreak: 'break-word',
          fontWeight:CONSTANTS.fontWeight.fontWeight500
        }
    },
    },
    '& .rbc-header':{
      flex:1,
      overflow:'inherit',
      textOverflow:"initial",
      whiteSpace:'initial',
    },
    '& .rbc-toolbar':{
      display:'none'
    },
    '& .rbc-header + .rbc-header':{
      borderLeft:'none'
    },
    '& .rbc-today':{
      background:'none'
    },
    '& .rbc-event, .rbc-day-slot .rbc-background-event': {
      color: '#386237',
    },
    '& .rbc-event-content': {
      paddingTop: '0px !important',
    }
  },
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important"
    }
  },
  calendarHeight:{
    height:'500px', 
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      width:"100%",
    },
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none !important",
    },
  },
  cardSecondDiv: {
    padding: "1rem 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0.3rem"
    },

  },
  cardCenterAlign: {
   
    [theme.breakpoints.down("xs")]: {
     textAlign:"center" as const
    },

  },
  cardCount: {
   
    [theme.breakpoints.down("xs")]: {
     paddingTop:"1.5rem !important"
    },

  },
  percentageContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column" as const
    }
  },
  percentageContainerFirstChild: {
    display: "flex",
    flexDirection: "row" as const,
    gap:"10px",
    alignItems:"center" ,
    [theme.breakpoints.down("xs")]: {
      justifyContent:"center"
    },
  },
  percentageContainerFirstChildDescription:{
    [theme.breakpoints.down("xs")]: {
      textAlign:"center" as const
    },
  },
  googleMaps: {
    [theme.breakpoints.down("md")]: {
      height: "auto !important",
    },
  },
  jobsLeftBodyContent :{
    fontSize:"1.4583rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.3281rem !important"
    },
  },
  calDay:{
    fontSize:"0.9479rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8632rem !important"
    },
  },
  weeklyText:{
    fontSize:"0.8750rem",
    textTransform: "none" as const,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7968rem !important"
    },
  },
  hideSmallElement:{
    [theme.breakpoints.down("xs")]: {
     display:"none !important"
    },
  },
  hideLargeElement: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  datePickerContainer: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(60px, 0px) !important",
    },
  },
  datePickerContainerArabic: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-60px, 0px) !important",
    },
  },
  calendarContainer:{
    [theme.breakpoints.down("xs")]: {
      margin:"0 auto"
    },
  },
  jobsHeight:{
    [theme.breakpoints.down("xs")]: {
      maxHeight:"369px !important"
    },
  },
  tasksHeight:{
    [theme.breakpoints.down("xs")]: {
      maxHeight:"550px !important"
    },
  },
  weeklyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "68px",
    height: "25px",
    cursor: "pointer",
    borderRadius: "4px", 
    color: theme.palette.common.white,
    backgroundColor: "#1c18af",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  weeklyContainer1: {
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      cursor: "pointer",
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
    },
    width: "68px",
    height: "25px",
    color: "#808191",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});


const webStyle = {
  container: {
    marginTop: "8rem",
  },
  calendarContainer: {
    maxWidth: "266px",
    width: "100%",
    borderRadius: "4px",
    minHeight: "40px",
    backgroundColor: "#fffefe",
    border: "1px solid #ededed",
    boxShadow: "0px 6px 7px rgba(28,24,175,0.05)",
  },
  arrowsContainer: {
    padding: "0 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrow: {
    cursor: "pointer",
    width: "8px",
  },
  datePickerContainer: {
    zIndex: 1,
    position: "absolute" as const,
    transform: "translate(232px, 0px)",
  },
  calendarIconContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    maxWidth: "206px",
    width: "100%",
    borderRadius: "4px",
    height: "40px",
    cursor: "pointer",
    backgroundColor: "#1c18af",
  },
  textWeek: {
    lineHeight: "20px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    color:CONSTANTS.color.white
  },
  calendarIcon: {
    height: "20px",
    filter: "brightness(0) invert(1)",
    width: "20px",
  },
  verticalHr: {
    height: "100%",
    border: "1px solid #8e8e99",
    width: "1px",
  },
  card: {
    width: "100%",
    borderRadius: "10px",
    minHeight: "177px",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    backgroundColor: "#ffffff",
    border:"2px solid rgb(28, 24, 175)"
  },
  cardTitle: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
  },
  cardCount: {
    color: CONSTANTS.color.blueViolet,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "30px",
    paddingTop: "2.8rem",
  },
  percentageContainer: {
    display: "flex",
    justifyContent: "flex-start",
   
    gap: "10px",
    paddingTop: "1.5rem",
    
  },
  icon: {
    height: "22px",
    width: "17px",
  },
  percentage: {
    color: "#a0a0a0",
    lineHeight: "13px",
    fontWeight: 400,
  },
  description: {
    color: CONSTANTS.color.lightGrey,
    lineHeight: "20px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  calendarMonthsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    maxWidth: "206px",
    minHeight: "25px",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 14px rgba(0,0,0,0.05)",
  },
  weeklyText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "18px",
    textAlign: "center" as const,
  },
  googleMaps: {
    width: "100%",
    height: "356px",
    borderRadius: "10px",
  },
  jobsCard: {
    width: "100%",
    minHeight: "356px",
    borderRadius: "10px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
  },
  jobsHeaderCard: {
    width: "100%",
    minHeight: "66px",
    borderRadius: "10px 10px 0 0",
    background: "#dbd5ff",
    backgroundBlendMode: "normal",
    padding: "18px 25px",
  },
  jobsHeaderButton: {
    height: "19px",
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
   
    textTransform: "initial" as const,
  },
  commonButton: {
    padding: 0,
  },
  jobsStyle: {
    width: "100%",
    minHeight: "28px",
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  jobsContainer: {
    borderBottom: "1px solid #e4e4e4",
    margin: "0",
    width: "100%",
    padding: "18px 25px 18px 25px",
  },
  timesheetStyle: {
    width: "100%",
    minHeight: "28px",
    color: "#FFAF1A",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  timesheetOuterContainer: {
    maxHeight: "382px",
    overflow: "auto"
  },
  timesheetContainer: {
    borderBottom: "1px solid #e4e4e4",
    padding: "18px 25px 18px 25px",
    alignItems: "center",
    height: "95px",
  },
  jobsLeftBodyContent: {
   // height: "20px",
    color: CONSTANTS.color.lightGrey,
    fontFamily: "Rubik",
    fontWeight:CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
   
  },
  jobsRightBodyContent: {
    // height: "30px",
    color: "#4c4c4c",
    fontFamily: "Rubik",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "30px",
    textAlign: "right" as "right",
  },
  tasksStyle: {
    width: "100%",
    minHeight: "28px",
    color: "#FF90B4",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  tasksOuterContainer: {
    width: "100%",
    minHeight: "66px",
    padding: "18px 25px 18px 25px",
    borderBottom: "1px solid #999999",
  },
  tasksInnerContainer: {
    minHeight: "105px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgb(228, 228, 228)",
  },
  tasksContainer: {
    padding: "0px 25px",
    alignItems: "center",
    justifyContent: "center",
  },
  taskMiddleBodyContent: {
    color: "#4c4c4c",
    fontFamily: "Rubik",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "30px",
    textAlign: "left" as "left",
  },
  schedualTopBar: {
    width: "100%",
    height: "66px",
    borderRadius: "10px 10px 0 0",
    background: "#ffddbc",
    backgroundBlendMode: "normal",
    padding: "18px",
  },
  taskTopBar: {
    height: "66px",
    borderRadius: "10px 10px 0 0",
    background: "#ffd7e4",
    backgroundBlendMode: "normal",
    padding: "18px 25px",
  },
  taskBarSecondayTitle: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "30px",
  },
  timeSheetTopBar: {
    height: "66px",
    borderRadius: "10px 10px 0 0",
    background: "#ffe4b2",
    backgroundBlendMode: "normal",
    padding: "18px 25px",
  },
  commonTextAlign:{
    textAlign:"right" as const
  },
  commonTextAlignArabic:{
    textAlign:"left" as const
  }
};
const EmployeeDashboardSnackbar = withSnackbar(EmployeeDashboard)
const calendarStyle = calendarStyles(EmployeeDashboardSnackbar)
export default withTranslation()(withStyles(styles)(withWidth()(calendarStyle)));
// Customizable Area End
