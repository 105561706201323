import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { checkLoggedInUserSettings } from "./utility.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { checkTokenExpired } from "../../../components/src/CommonFunction";
import React, { ChangeEvent } from 'react';
interface Navigation {
    history: History;
    navigate: Function
}
interface History {
    location: Location;
    push: Function
    goBack: Function
}
interface Location{
  search: string
  pathname: string
}
interface PlanData{
  current_plan:string
  plans:PlanMonthly
  subscription_id : number
  errors: { token: string; }[]
}
interface UpdatePlan{
  errors: { token: string; }[]
  not_used_amount:{}
}
interface PlanMonthly {
  data: PlanAttributes[]
}
interface PlanAttributes{
  attributes: {
    id: string;
    plan_type: string;
    plan_price: number;
    currency: string;
    amount_of_slider:number
    body: string;
    button_text: string
    button_text_setting: string
    plan_description: {
      id: number;
      plan_type: string;
      discription: string;
    }[];
    buttonBody: string;
    buttonText: string;
    choosen: boolean;
    details: string
  },
}
interface FeatureRow { 
  id: string; 
  feature_name: string; 
  try_us: boolean; 
  essential: boolean; 
  standard: boolean; 
  premium: boolean; 
}
interface FeatureData {
  0: {
    "job_management": [FeatureRow]
  },
  1: {
    "request_quoting": [FeatureRow]
  },
  2: {
    "invoices_payments": [FeatureRow]
  },
  3: {
    "customers": [FeatureRow]
  },
  4: {
    "worker_management": [FeatureRow]
  },
  5: {
    "additional_features":[FeatureRow]
  },
  
  }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  id: string;
  // Customizable Area Start
  openToastHandler: Function
  history: History
  location: Location
  t: (key: string) => string;
  classes:ClassNameMap<string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    plan: string|null
    managePlan: string
    planDialogOpen: boolean;
    changePlanDialogOpen: boolean
    changePlan: boolean
    cancelPlanDialogOpen: boolean
    deleteCardDialogOpen: boolean;
    isEmployee: boolean;
    loading: boolean
    languageSelected: string;
    renewPlan: boolean
    activeTab: string
    getPlanData:PlanData|null
    getFeatureData: FeatureData | null
    updatePlanData: UpdatePlan|null
    selectPlan:string
    currency: string
    pricingPlanId: string
    redirectUrl:string
    sliderAmount:number
    amountOfSlider:number
    isSlider:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string|number;
  // Customizable Area End
}

export default class ManagePlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChargeApiCallId: string = ""
  getPlanApiCallId: string = ""
  updatePlanApiCallId: string = ""
  createChargedApiCallId: string = ""
  cancelPlanApiCallId: string = ""
  renewPlanApiCallId: string = ""
  getFeaturesApiCallId: string = ""
  getChargeSliderApiCallId: string = ''
  createChargeForSliderApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleChangeSlider = this.handleChangeSlider.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      plan:"Essential",
      currency:'',
      pricingPlanId:'',
      amountOfSlider:0,
      managePlan:"",
      planDialogOpen: false,
      changePlanDialogOpen: false,
      changePlan: false,
      cancelPlanDialogOpen: false,
      deleteCardDialogOpen: false,
      isEmployee: false,
      loading: false,
      languageSelected: "",
      renewPlan: false,
      activeTab: 'monthly',
      getPlanData:null,
      getFeatureData: null,
      updatePlanData:null,
      selectPlan:'',
      redirectUrl:'',
      sliderAmount:0,
      isSlider:false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    
    const user = await checkLoggedInUserSettings()
    this.setState({ isEmployee: user })
    this.getPlan()
    this.getFeatures()
    const params = new URLSearchParams(this.props.location?.search);
    const tap_id = params.get('tap_id');
      if (tap_id) {
        this.setState({ loading: true })
          this.getChargeSlider(tap_id) 
          this.getCharge(tap_id)
    }
    const redirect_url = this.props.navigation.history?.location.pathname;
    this.setState({redirectUrl:redirect_url})
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
       if (apiRequestCallId === this.getChargeApiCallId) {
        this.getChargeApiResponse(responseJson)
      } else if (apiRequestCallId === this.getPlanApiCallId) {
        this.getPlanApiResponse(responseJson)
      } else if (apiRequestCallId === this.updatePlanApiCallId) {
        this.updatePlanApiResponse(responseJson)
      } else if (apiRequestCallId === this.createChargedApiCallId) {
        this.createChargeResponse(responseJson)
      } else if (apiRequestCallId === this.cancelPlanApiCallId) {
        this.cancelPlanApiResponse(responseJson)
      } else if (apiRequestCallId === this.renewPlanApiCallId) {
        this.renewPlanApiResponse(responseJson)
      } else if (apiRequestCallId === this.getFeaturesApiCallId) {
        this.getFeaturesApiResponse(responseJson)
      } else if (apiRequestCallId === this.getChargeSliderApiCallId) {
        this.getChargeSliderApiResponse(responseJson)
      } else if (apiRequestCallId === this.createChargeForSliderApiCallId) {
        this.createChargeForSliderApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  createChargeResponse = (responseJson: { errors: { token: string; description: string; }[]; transaction: { url: string; }; }) => {
    this.setState({loading:false})
    if (responseJson && !responseJson.errors) {
      window.location.href = responseJson.transaction.url
    } else if (responseJson && responseJson.errors) {
      const { token, description } = responseJson.errors[0]
      this.props.openToastHandler(token || description, "error")
    }
  };
  createChargeForSliderApiResponse = (responseJson: { errors: { token: string; description: string; }[]; transaction: { url: string; }; }) => {
    this.setState({loading:false})
    if (responseJson && !responseJson.errors) {
      window.location.href = responseJson.transaction.url
    } else if (responseJson && responseJson.errors) {
      const { token, description } = responseJson.errors[0]
      this.props.openToastHandler(token || description, "error")
    }
  };
  getChargeApiResponse = (responseJson: { gateway: { response: { message: string; }; }; id: string, errors: { token: string; }[]; }) => {
    const { message } = responseJson.gateway.response
    this.setState({loading:false})
    if (responseJson && responseJson.id && !responseJson.errors) {
      this.props.navigation.navigate("Billing")
      this.props.openToastHandler(message, "success")
    } else if (responseJson && responseJson.errors) {
      const { token } = responseJson.errors[0];
      this.props.navigation.navigate("Billing")
      this.props.openToastHandler(token || message, "error")
    }
  };
  getChargeSliderApiResponse =(responseJson: { gateway: { response: { message: string; }; }; id: string, errors: { token: string; }[]; }) => {
    const { message } = responseJson.gateway.response
    this.setState({loading:false})
    if (responseJson && responseJson.id && !responseJson.errors) {
      this.props.openToastHandler(message, "success")
      this.props.navigation.navigate("Billing")
    } else if (responseJson && responseJson.errors) {
      const { token } = responseJson.errors[0];
      this.props.openToastHandler(token || message, "error")
      this.props.navigation.navigate("Billing")
    }
  };
  getPlanApiResponse=(responseJson:PlanData)=>{
    if (responseJson && !responseJson.errors) {
        this.setState({ getPlanData: responseJson,loading:false })

        const plan = this.state.getPlanData && this.state.getPlanData.plans.data && this.state.getPlanData.plans.data.find(
          (item) => item.attributes.plan_type === responseJson.current_plan
        );
      
      this.state.getPlanData && this.state.getPlanData.plans.data && this.state.getPlanData.plans.data.map((item) => {
        return this.setState({
          currency: item.attributes.currency,
          pricingPlanId: item.attributes.id,
          amountOfSlider: item.attributes.amount_of_slider,
          selectPlan:plan && plan.attributes.plan_type === responseJson.current_plan
          ? plan.attributes.id
          : item.attributes.id,
        })
      })
      } else {
        if (responseJson&&responseJson.errors) {
          checkTokenExpired(responseJson,this.props.openToastHandler,this.props.history)
          this.setState({ getPlanData: null,loading:false })
        }
      }
  }
  updatePlanApiResponse=(responseJson: UpdatePlan)=>{
    if (responseJson && !responseJson.errors) {
      this.setState({ updatePlanData: responseJson, loading: false })
      if (this.state.isSlider === true) {
        return this.createChargeForSlider()
      } else {
       return this.handleCreateCharge()
      }
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
        this.setState({ updatePlanData: null, loading: false })
      }
    }
  }
  cancelPlanApiResponse=(responseJson: { errors: string; message: string; })=>{
    if (responseJson && !responseJson.errors) {
      this.setState({ loading: false })
      this.props.openToastHandler(responseJson.message, "success")
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
        this.setState({ loading: false })
      }
    }
  }
  renewPlanApiResponse=(responseJson: { errors: string; message: string; })=>{
    if (responseJson && !responseJson.errors) {
      this.setState({ loading: false })
      this.props.openToastHandler(responseJson.message, "success")
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
        this.setState({ loading: false })
      }
    }
  }
  getFeaturesApiResponse = (responseJson: { errors: string; data: FeatureData; }) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ getFeatureData: responseJson.data, loading: false })
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
        this.setState({ getFeatureData: null, loading: false })
      }
    }
  }
  handleSelectManagePlan = (title: string, selectPlanId: string) => {
    this.setState({ managePlan: title, selectPlan: selectPlanId });
  }
  handleOpenCancelPlanModal = () => {
    this.setState({ planDialogOpen: true ,renewPlan:false, changePlanDialogOpen:false, cancelPlanDialogOpen:true});
  }
  handleClosePlanModal = () => {
    this.setState({ planDialogOpen: false, changePlanDialogOpen: false, cancelPlanDialogOpen: false, changePlan: false });
  }
  handleConfirmPlan=()=>{
    this.setState({ planDialogOpen: false, renewPlan: true },()=>{
      this.cancelPlan()
    });
  }
  handleChangePlan = () => {
    this.setState({ changePlan: true, planDialogOpen: false, renewPlan: false });
  }
  closeModal = () => {
    this.setState({planDialogOpen:false})
  }
 
  handleChangeSlider(event: ChangeEvent<{}>,newValue: number | number[]) {
    this.setState({ sliderAmount: newValue as number , isSlider:true})
  }
  handleRenewPlan = () => {
    this.setState({ renewPlan: false, planDialogOpen: false, changePlan: false },()=>{
      this.renewPlan()
    });
  }
  handleOpenChangePlanModal = () => {
    this.setState({ planDialogOpen: true,renewPlan: false, changePlanDialogOpen: true, cancelPlanDialogOpen:false });
  }
  handleOpenRenewPlanModal = () => {
    this.setState({ renewPlan: true, planDialogOpen: true, changePlanDialogOpen: false, cancelPlanDialogOpen: false })
  }
  handleClickMonthly = () => {
    this.setState({ activeTab: "monthly" }, () => {
      this.getPlan()
    });
  }
  handleClickYearly = () => {
    this.setState({ activeTab: "yearly" }, () => {
      this.getPlan()
    });
  }
  handleCancel = () => {
    this.props.navigation.history?.goBack();
  }
  getPlan = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.getPlanAPIEndPoint
    this.setState({ loading: true })
    let getPlanUrl
    getPlanUrl = `${apiEndPoint}?key=${this.state.activeTab}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlanApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getPlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updatePlan = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.updatePlanAPIEndPoint
    this.setState({ loading: true })
    let updatePlanUrl
    updatePlanUrl = `${apiEndPoint}/pricing_plan_id=${this.state.selectPlan}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePlanApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updatePlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  cancelPlan = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.updatePlanAPIEndPoint
    this.setState({ loading: true })
    let cancelPlanUrl
    cancelPlanUrl = `${apiEndPoint}/${this.state.getPlanData&&this.state.getPlanData.subscription_id}/cancel_subscription`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelPlanApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      cancelPlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  renewPlan = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.updatePlanAPIEndPoint
    this.setState({ loading: true })
    let renewPlanUrl
    renewPlanUrl = `${apiEndPoint}/${this.state.getPlanData&&this.state.getPlanData.subscription_id}/renew_subscription`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.renewPlanApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      renewPlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFeatures = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.getFeatures
    this.setState({ loading: true })
    let getFeaturesUrl
    getFeaturesUrl = `${apiEndPoint}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeaturesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getFeaturesUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCreateCharge = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const notUsedAmount = (this.state.updatePlanData as UpdatePlan)?.not_used_amount ?? '';
    this.setState({ loading: true })
    let formData = new FormData();
    formData.append("[token]", 'src_card');
    formData.append("[redirect_url]", this.state.redirectUrl);
    formData.append("[currency_code]", this.state.currency);
    formData.append("[pricing_plan_id]", this.state.selectPlan);
    formData.append("[not_used_amount]", notUsedAmount.toString());
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createChargedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChargeAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  newSliderAmount = (currentPlanPrice:number | null | undefined,sliderAmount:number) =>{
    if(currentPlanPrice){
      return (sliderAmount * currentPlanPrice)
    } else{
      return ''
    }
  }
  createChargeForSlider = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const sliderAmount = (this.state.sliderAmount - this.state.amountOfSlider);
    const currentPlan = this.state.getPlanData && this.state.getPlanData.current_plan
    const planItem = this.state.getPlanData && this.state.getPlanData.plans.data.find(plan => plan.attributes.plan_type === currentPlan);
    const currentPlanPrice= planItem&&planItem.attributes.amount_of_slider
    const newSliderAmount1 = this.newSliderAmount(currentPlanPrice,sliderAmount)
    const subscriptionId= this.state.getPlanData&&this.state.getPlanData.subscription_id
    this.setState({ loading: true })
    let formData = new FormData();
    formData.append("[redirect_url]", this.state.redirectUrl);
    formData.append("[currency_code]", this.state.currency);
    formData.append("[pricing_plan_id]", this.state.pricingPlanId);
    formData.append("[slider_amount]" , newSliderAmount1.toString())
    formData.append("[subscription_id]" , subscriptionId ? subscriptionId.toString() : '')
    formData.append("[user_count]", sliderAmount.toString());
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createChargeForSliderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChargeForSliderAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCharge= (tap_id: string) => {
    const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem(configJSON.authToken),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChargeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChargeAPIEndPoint}?tap_id=${tap_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.getAPIMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getChargeSlider=(tap_id: string)=>{
   const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem(configJSON.authToken),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChargeSliderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChargeSliderAPIEndPoint}?tap_id=${tap_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.getAPIMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
// Customizable Area End