import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  InputBase,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Divider,
  Popover
} from "@material-ui/core";
import {
  styled,
  withStyles,Theme
} from "@material-ui/core/styles";
import { dropDownIcon } from "../assets/assets";
import { deleteLogoIcon } from "../../Settings/src/assets";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
export const configJSON = require("./config");
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme";
import { StyleGrid } from "./CreateSupplier.web";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withTranslation } from "react-i18next";
import Select, {  OptionsType  } from "react-select";
import Radio, { RadioProps } from '@material-ui/core/Radio';
// Customizable Area End

import PurchasesController, {
  Props,
} from "./PurchasesController.web";

// Customizable Area Start
 interface TaxRateResponse {
  id: number;
  company_information_id: number;
  name: string;
  rate: number;
  description: string;
  created_at: string;
  updated_at: string;
}
const customSelectStyles = {
  control: (styles: object) => ({
    ...styles, border: "none", boxShadow: 'none',
    '&:hover': { borderColor: 'none', boxShadow: 'none' },
  }),
  placeholder: (styles: object) => ({ ...styles, display: 'none' }),
  indicatorSeparator: (styles: object) => ({ ...styles, backgroundColor: "none" }),
};
interface StyledRadioProps extends RadioProps {
  classes: {
    root: string;
    checked: string;
  };
};
const stylesRadio = (theme: Theme) => ({
  root: {
    color: '#1C18AF',
    '&$checked': {
      color: '#1C18AF',
    },
  },
  checked: {},
});
const StyledRadio = withStyles(stylesRadio)((props: StyledRadioProps) => (
  <Radio color="default" {...props} />
));
const StyledTextFieldDiscount = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: CONSTANTS.color.black,
      fontFamily: "Rubik",
    },
    "& .css-1uccc91-singleValue": {
      color: CONSTANTS.color.black,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",

    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .css-6q0nyr-Svg": {
      height: "25px",
      width: "25px"
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"

    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px"

    },
    '& .MuiOutlinedInput-input': {
      padding: '0px',
      paddingLeft: '8px',
      paddingRight: "8px"
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingLeft: "20px"
    }
  }
})(TextField);
export const StyledTextFields = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      color: CONSTANTS.color.lightBlack,
    },
    "& .MuiFormHelperText-root": {
      textAlign: "end"
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px"
    },
    "& .MuiTypography-colorTextSecondary": {
      fontWeight: "700",
      color: CONSTANTS.color.lightBlack,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: CONSTANTS.color.lightBlack,
    }
  }
})(TextField);

export const StyledInputBase = withStyles({
  root: {
    "& .MuiInputBase-input": {
      cursor: "pointer",
      color: CONSTANTS.color.lightBlack,
    },
    "& .MuiTypography-colorTextSecondary": {
      color: CONSTANTS.color.lightBlack,
    }
  }
})(InputBase);

export const StyledSubtotalDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: "20.5% !important",
  },
}));

export const StyledTaxDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    top:"auto !important",
    left: "auto !important",
  },
}));

export const StyledDiscountDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: (props) =>  props.languageSelected !== "ar" ? "41.5% !important" : "81.5% !important",
  },
  [theme.breakpoints.down(700)]: {
    top: "64% !important",
    left: "50.5% !important",
  },
}));

export const StyledDepositDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: (props) => props.languageSelected !== "ar" ? "68.5% !important" : "33.5% !important",
  },
  [theme.breakpoints.down(700)]: {
    left: "50.5% !important",
  },
}));

const getConditionalStyle = (isError: any) => {
  return isError && webStyle.errorBackgroundStyle;
};
const getConditionalStyleModal = (isError: any) => {
  return isError && webStyle.errorStyle;
};

const renderErrorMessage = (errorText: string) => {
  return (
    <Typography variant="body2" style={webStyle.errorText}>
      {errorText}
    </Typography>
  );
};

const purchaseFormContainer = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? classes?.purchaseFormContainerAr : classes?.purchaseFormContainer
}

const rateContainer = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? classes?.rateLabelContainerAr : classes?.rateLabelContainer
}

const transactionDateContainer = (languageSelected: string) => {
  return languageSelected === "ar" ? { ...webStyle.depositContainer, ...webStyle.transactionDateStyle, gap: "2.5rem" } : { ...webStyle.depositContainer, ...webStyle.transactionDateStyle }
}

const requiredDepositModalText = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.modalTextsContent, ...webStyle.modalDepositBackDrop, right: "45%"} : {...webStyle.modalTextsContent, ...webStyle.modalDepositBackDrop}
}

interface MockDeposit {
  id: number;
  value: string;
}
// Customizable Area End

export class CreatePurchase extends PurchasesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  methodDialog = (depositMethodMock: MockDeposit[]) => {
    const { method } = this.state.depositFormData;
    const { classes }: any = this.props;
    return (
      <Box style={this.state.languageSelected === "ar" ? {transform: "translate(-74.3%, 5%)"} : {}} className={classes?.selectMethodDialog}>
        {depositMethodMock.map((item) => (
          <Typography
            data-test-id="handleSelectMethod"
            style={
              method === item.value ?
              {...webStyle.selectMethod, ...webStyle.selectedMethod} :
              webStyle.selectMethod
            }
            key={item.id}
            onClick={() => this.handleSelectMethod(item)}
          >
            {item?.value === 'bank_transfar' ?
              'Bank Transfer' :
              item?.value?.split('_')?.map((word: string) => word?.charAt(0).toUpperCase() + word?.slice(1))?.join(' ')
            }
          </Typography>
        ))}
      </Box>
    );
  }

  taxDialog = () => {
    const { t } = this.props;
    const { taxName, taxRate } = this.state.taxFormData;
    const { classes }: any = this.props;
    return (
      <Popover
        id={this.state.taxAnchorId}
        open={Boolean(this.state.taxDialog)}
        anchorEl={this.state.taxAnchorEl}
        onClose={this.handleCancelTaxDialog}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledTaxDiv
          languageSelected={this.state.languageSelected}
          style={{ ...webStyle.discountDialog, ...webStyle.taxDialog }}
          ref={this.wrapperRef}
        >
          <Typography variant="body2" style={{ ...webStyle.modalTextsContent, ...webStyle.modalTaxBackDrop }}>
            {`+ ${t('suppliers.tax')}`}
          </Typography>
          <Typography component={'div'} className={classes?.discountText}>{t('suppliers.createTaxRate')}</Typography>
          <span className={classes?.horizontalHr}></span>
          <Box
            style={(this.state.isErrorTaxFormData.taxName || this.state.isErrorTaxFormData.taxRate) ? { ...webStyle.taxContainerInput, margin: "22px 0px 5px" } : webStyle.taxContainerInput}
          >
            <Box
              style={{
                ...getConditionalStyleModal(this.state.isErrorTaxFormData.taxName),
                ...{ display: "flex", border: !this.state.isErrorTaxFormData.taxName ? `1px solid ${CONSTANTS.color.beigeGrey}` : `1px solid ${CONSTANTS.color.red}` },
                ...webStyle.inputBaseTextfield
              }}
            >
              <StyledInputBase
                data-test-id="formDataTax"
                style={webStyle.inputBaseTextfield}
                placeholder={t('suppliers.taxName')}
                name="taxName"
                value={taxName}
                onChange={this.handleChangeTax}
                error={Boolean(this.state.isErrorTaxFormData.taxName)}
              />
            </Box>
            <StyledInputBase
              data-test-id="formDataTax"
              style={{
                ...getConditionalStyleModal(this.state.isErrorTaxFormData.taxRate),
                ...{
                  border: !this.state.isErrorTaxFormData.taxRate ?
                    `1px solid ${CONSTANTS.color.beigeGrey}` :
                    `1px solid ${CONSTANTS.color.red}`
                },
                ...webStyle.inputBaseTextfield
              }}
              placeholder={t('suppliers.taxRate')}
              name="taxRate"
              value={taxRate}
              onChange={this.handleChangeTax}
              error={Boolean(this.state.isErrorTaxFormData.taxRate)}
            />
          </Box>
          <Box style={webStyle.taxErrorStyleContainer}>
            {renderErrorMessage(this.state.isErrorTaxFormData.taxName)}
            {renderErrorMessage(this.state.isErrorTaxFormData.taxRate)}
          </Box>
          <Box style={webStyle.dialogButtonsContainer} className={classes?.dialogButtonsContainer}>
            <Button
              className={`${classes?.buttonStyleCancel} ${classes?.buttonStylePurchases}`}
              onClick={this.handleCancelTaxDialog}
            >
              <Typography variant="caption" style={webStyle.cancelButtonText}>
                {t('suppliers.cancelUpper')}
              </Typography>
            </Button>
            <Button
              style={{ maxWidth: "147px" }}
              className={`${classes?.buttonStyle} ${classes.addBtnContainer}  ${classes?.buttonStylePurchase}`}
              onClick={this.handleCreateTaxRate}
            >
              <Typography variant="caption">
                {t('suppliers.createTaxRate')}
              </Typography>
            </Button>
          </Box>
        </StyledTaxDiv>
      </Popover>   
    );
  }
  discountDialog = (classes:ClassNameMap, currency: { label: string; value: string; }[]) => {
    return <>
      {this.state.discountDialog ?
        <Grid container justifyContent="center" style={{ maxWidth: '200px' }}>
          <StyledTextFieldDiscount
            variant="outlined"
            data-test-id="formDataDiscount"
            name="discount"
            placeholder="0.00"
            type="number"
            onKeyPress={this.preventMinus}
            onChange={this.handleChangeDiscount}
            value={this.state.discount}
            className={classes.quantityInput}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <div className={classes.endAdornment}>
                <Select
                  onChange={this.selectCurrency}
                  styles={customSelectStyles}
                
                  options={currency as unknown as OptionsType<{ label: string; value: string; }>} 
                  />
              </div>
            }}
          />
        </Grid> : <Typography variant="body2" style={webStyle.modalTexts} onClick={this.handleDiscountDialogOpen}>
          {!this.state.discount ? `${'+'} ${this.props.t('price.discount')}` : `${this.state.discount}%`}
        </Typography>
      }
    </>
  }
  taxPopover = (classes: ClassNameMap) => {
    return <Popover
      id={this.state.anchorId}
      open={Boolean(this.state.anchorEl)}
      anchorEl={this.state.anchorEl}
      onClose={this.closeTaxPopover}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid container className={classes.taxPopoverContainer}>
        <Grid item>
          <Box p={"1rem"} className={classes.selectTaxRate}>
            <Typography variant="body2" style={webStyle.blackColor}>
              {this.props.t("quote.selectTaxRate")}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box p={"1rem"} height={"110px"} overflow={"scroll"} display={"flex"} alignItems={"center"} flexDirection={"column"}>
            {this.state.taxRates && this.state.taxRates.length > 0 ?
              <>
                {this.state.taxRates && this.state.taxRates.map((taxRate: TaxRateResponse, index: number) => {
                  return (
                    <Box display={"flex"} alignItems={"center"} width={"100%"} >
                      <StyledRadio
                        value={this.state.data.taxRate}
                        name={index === 0 ? "tax_name" : "tax"}
                        checked={this.state.data.taxRate === taxRate.rate}
                        onChange={this.selectTaxRate.bind(this, taxRate)}
                      />
                      <Typography variant="body2" color="textPrimary">
                        {taxRate.name} {taxRate.rate}%
                      </Typography>
                    </Box>
                  )
                })}
              </>
              : ""}
          </Box>
        </Grid>
        <Divider className={classes.taxDivider} />
        <Grid item>
          <Box p={"1rem"} display={"flex"} justifyContent={"center"}>
            <Typography variant="body2" data-test-id="handleTaxDialogOpen" onClick={this.handleTaxDialogOpen} style={webStyle.modalTexts}>
              {`${'+'}  ${this.props.t("quote.createTaxRate")}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  }
  subTotalContainer = (classes: ClassNameMap) => {
    const { price, quantity } = this.state.formData;
    return <Box className={classes.subTotalBox}>
      <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{this.props.t('price.subtotal')}</Typography>
      <Typography variant="body2" className={classes.total}>
        {(price && quantity) ? `${(price * quantity).toFixed(2)} ${this.props.currency}` : `0 ${this.props.currency}`}
      </Typography>
    </Box>
  }
  taxContainer=(classes: ClassNameMap)=>{
    return <Box className={classes.subTotalBox}>
    <Typography variant="body2" color="textPrimary" className={`${classes.subTitle20} ${classes.taxLabel}`}>{this.props.t('price.tax')}</Typography>
    <Typography data-test-id="openTaxPopover" component={"div"} className={classes.addTax} style={webStyle.modalTexts} onClick={this.openTaxPopover}>
      {!this.state.data.taxName && !this.state.data.taxRate ? `+ ${this.props.t('suppliers.tax')}` : `${this.state.data.taxName || ""} ${this.state.data.taxRate || ""}%`}
    </Typography>
  </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const currency = [
      { value: "%", label: '%' },
      { value: this.props.currency, label: this.props.currency },
    ];
    const depositMethodMock = [
      { id: 1, value: t('suppliers.cash') },
      { id: 2, value: t('suppliers.cheque') },
      { id: 3, value: t('suppliers.creditCard') },
      { id: 4, value: t('suppliers.bankTransfer') },
      { id: 5, value: t('suppliers.moneyOrder') },
      { id: 6, value: t('suppliers.other') },
    ]
    const { languageSelected } = this.state;
    const { purchaseDate, price, quantity, itemDescription } = this.state.formData;
    const { method, amount, transactionDate, details } = this.state.depositFormData;
    const { classes }: any = this.props;
    return (
      <>
        <Box className={classes?.purchasesContainer}>
          <Typography style={webStyle.headingText} variant="subtitle1" className={classes?.headingText}>{t('suppliers.purchaseOrder')}</Typography>
          <Box className={purchaseFormContainer(languageSelected, classes)}>
            <Grid item lg={9} md={12} xs={12}>
              <StyleGrid container spacing={10}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.formDataText} color="textPrimary" variant="body1" gutterBottom>
                    {t('suppliers.item')}
                  </Typography>
                  <StyledTextFields
                    style={{ backgroundColor: CONSTANTS.color.offWhiteGrey }}
                    variant="outlined"
                    value={""}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                    {t('suppliers.purchaseDate')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                  </Typography>
                  <CustomDatePicker
                    data-test-id="handleDateChange"
                    selectedDate={purchaseDate}
                    dateChange={this.handleDateChange}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.purchaseDate)}
                </Grid>
              </StyleGrid>
            </Grid>
            <Box className={classes?.gridItems}>
              <Grid item lg={9} md={12} xs={12}>
                <StyleGrid container spacing={10}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes?.formDataText} color="textPrimary" variant="body1" gutterBottom>
                      {t('suppliers.price')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.price)}
                      fullWidth
                      value={price}
                      variant="outlined"
                      name="price"
                      onChange={this.handleChangeFormData}
                      error={Boolean(this.state.isErrorFormData.price)}
                    />
                    {renderErrorMessage(
                      this.state.isErrorFormData.price
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography
                      className={classes?.formDataText}
                      color="textPrimary" variant="body1"
                      gutterBottom
                    >
                      {t('suppliers.quantity')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(
                        this.state.isErrorFormData.quantity
                      )}
                      variant="outlined"
                      fullWidth
                      value={quantity}
                      onChange={this.handleChangeFormData}
                      name="quantity"
                      error={Boolean(this.state.isErrorFormData.quantity)}
                    />
                    {renderErrorMessage(
                      this.state.isErrorFormData.quantity
                    )}
                  </Grid>
                </StyleGrid>
              </Grid>
            </Box>
            <Box
              className={classes?.gridItems}>
              <Grid item lg={9} md={12} xs={12}>
                <StyleGrid
                  container
                  spacing={10}
                  >
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography
                      className={classes?.formDataText}
                      variant="body1"
                      color="textPrimary"
                      gutterBottom
                    >
                      {t('suppliers.itemDescription')}
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.itemDescription)}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      helperText={
                        <div className={classes?.helperText}>
                          {renderErrorMessage(this.state.isErrorFormData.itemDescription)}
                          <span style={itemDescription?.length > 500 ? {color: CONSTANTS.color.red } : {}}>
                            {`${itemDescription.length}/500`}
                          </span>
                        </div>
                      }
                      name="itemDescription"
                      value={itemDescription}
                      onChange={this.handleChangeFormData}
                      error={Boolean(this.state.isErrorFormData.itemDescription || itemDescription.length > 500)}
                    />
                  </Grid>
                </StyleGrid>
              </Grid>
            </Box>
          </Box>

          <Box className={rateContainer(languageSelected, classes)}>
            <Typography style={{...webStyle.headingText, padding: "1rem 0rem 0rem"}} className={classes?.rateLabelMobile} variant="subtitle1">
              {t('suppliers.rate')}
            </Typography>
                {this.taxDialog()}
                {this.state.depositDialog && (
                  <div>
                    <div className={classes?.backDropStyles} />
                    <ClickAwayListener onClickAway={this.handleCancelDepositDialog}>
                      <StyledDepositDiv
                        languageSelected={this.state.languageSelected}
                        style={
                          languageSelected === "ar" ?
                          {...webStyle.discountDialogStyle, ...webStyle.depositDialogContainer, left: "24%"} :
                          {...webStyle.discountDialogStyle, ...webStyle.depositDialogContainer}
                        }
                        ref={this.wrapperRef}
                      >
                        <Typography
                          variant="body2"
                          style={requiredDepositModalText(languageSelected)}
                          className={languageSelected === "ar" && classes.depositModalAr}
                        >
                          {`+ ${t('suppliers.requiredDeposit')}`}
                        </Typography>
                        <Typography className={classes?.discountText} component={'div'}>
                          {t('suppliers.deposit')}
                        </Typography>
                        <span className={classes?.horizontalHr}></span>
                        <Box style={{ ...webStyle.depositContainer, padding: "10px 0 6px" }} className={classes?.depositContainer}>
                          <Typography style={webStyle.depositTexts} variant="caption">{t('suppliers.method')}</Typography>
                          <StyledInputBase
                            className={classes?.inputDepositMethod}
                            readOnly={true}
                            endAdornment={
                              <IconButton disableTouchRipple={true} disableRipple={true} disableFocusRipple={true}>
                                <img style={webStyle.dropDownIcon} src={dropDownIcon} alt="" />
                              </IconButton>
                            }
                            defaultValue={method}
                            value={method ==='bank_transfar' ?
                              'Bank Transfer' :
                              method?.split('_')?.map((word: string) => word?.charAt(0).toUpperCase() + word?.slice(1))?.join(' ')
                            }
                            onClick={this.handleOpenMethodDialog}
                          />
                          {this.state.methodDialog &&(
                            this.methodDialog(depositMethodMock)
                          )}
                        </Box>
                        <Box
                          className={classes?.depositContainer}
                          style={webStyle.depositContainer}>
                          <Typography style={webStyle.depositTexts} variant="caption">{t('suppliers.amount')}
                          </Typography>
                          <StyledInputBase
                            data-test-id="formDataDeposit"
                            style={{
                              ...getConditionalStyleModal(this.state.isErrorDepositFormData.amount),
                              ...{border: !this.state.isErrorDepositFormData.amount ? `1px solid ${CONSTANTS.color.paleCement}` : `1px solid ${CONSTANTS.color.red}`},
                            }}
                            className={classes?.inputBaseDeposit}
                            value={amount}
                            name="amount"
                            onChange={this.handleChangeDeposit}
                            error={Boolean(this.state.isErrorDepositFormData.amount)}
                            startAdornment={
                              <InputAdornment position="start">{this.props.currency}</InputAdornment>
                            }
                          />
                        </Box>
                        <Box style={webStyle.textAlignEnd}>
                          {renderErrorMessage(this.state.isErrorDepositFormData.amount)}
                        </Box>
                        <Box style={transactionDateContainer(this.state.languageSelected)} className={`${classes?.depositContainer} ${classes?.transactionDateMobile}`}>
                          <Typography variant="caption" style={{...webStyle.depositTexts, textAlign: "justify"}} className={classes?.transactionDateLabelMobile}>
                            {t('suppliers.transactionDate')}
                          </Typography>
                          <CustomDatePicker
                            data-test-id="handleTransactionDateChange"
                            selectedDate={transactionDate}
                            dateChange={this.handleTransactionDateChange}
                          />
                        </Box>
                        <Box style={{...webStyle.depositContainer, alignItems: "baseline"}} className={classes?.depositContainer}>
                          <Typography style={webStyle.depositTexts} variant="caption">
                            {t('suppliers.details')}
                          </Typography>
                          <StyledInputBase
                            data-test-id="formDataDeposit"
                            style={{
                              ...getConditionalStyleModal(this.state.isErrorDepositFormData.details),
                              ...{border: !this.state.isErrorDepositFormData.details ? `1px solid ${CONSTANTS.color.paleCement}` : `1px solid ${CONSTANTS.color.red}`},
                            }}
                            className={classes?.inputBaseDepositDetails}
                            multiline
                            minRows={2}
                            maxRows={2}
                            value={details}
                            name="details"
                            onChange={this.handleChangeDeposit}
                            error={Boolean(this.state.isErrorDepositFormData.details)}
                          />
                        </Box>
                        <Box className={classes?.errorDepositStyle}>
                          {renderErrorMessage(this.state.isErrorDepositFormData.details)}
                        </Box>
                        <Box style={{...webStyle.dialogButtonsContainer, paddingTop: "1rem"}}>
                          <Button
                            className={`${classes?.buttonStyle} ${classes?.buttonStylePurchases}`}
                            onClick={this.handleCancelDepositDialog}
                          >
                            <Typography variant="caption" style={webStyle.cancelButtonText}>
                              {t('suppliers.cancelUpper')}
                            </Typography>
                          </Button>
                          <Button
                            style={{ maxWidth: "65px"}}
                            className={`${classes?.buttonStyle} ${classes.addBtnContainer}  ${classes?.buttonStylePurchase}`}
                            onClick={this.handleSaveDeposit}
                          >
                            <Typography variant="caption">
                              {t('suppliers.saveUpper')}
                            </Typography>
                          </Button>
                        </Box>
                      </StyledDepositDiv>
                    </ClickAwayListener>
                  </div>
                )}
            
            {this.taxPopover(classes)}
            <Box className={classes.rateContainer}>
              {this.subTotalContainer(classes)}
              <Divider className={classes.divider} />
              <Box className={classes.subTotalBox}>
                <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{t('price.discount')}</Typography>
                {this.discountDialog(classes,currency)}
                <Box>
                  <Box style={webStyle.taxDeleteBox}>
                    <Typography variant="body2" style={webStyle.blackColor}>
                      {this.state.discount}{this.state.selectCurrency}
                    </Typography>
                    <img
                      style={webStyle.deleteIcon}
                      src={deleteLogoIcon}
                      alt=""
                      onClick={this.emptyPriceSectionData.bind(this, "discount")}
                    />
                  </Box>
                </Box>
              </Box>
              <Divider className={classes.divider} />
              {this.taxContainer(classes)}
              <Divider className={classes.divider} />
              <Box className={classes.subTotalBox}>
                <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>
                  {t('ProductService.total')}
                </Typography>
                <Typography variant="body2" className={classes.total}>
                  {(this.state.discount || this.state.data.taxRate) ? `${Number(this.calculateTotal())}${this.props.currency}` :`${(price * quantity).toFixed(2)} ${this.props.currency}` }
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box className={classes.subTotalBox}>
                <Typography variant="body2" color="textPrimary" className={classes.subTitle20}>{t('price.requiredDeposit')}</Typography>
                <Typography variant="body2" style={webStyle.modalTexts} onClick={this.handleDepositDialogOpen}>
                  {!this.state.data.amount ? `+ ${t('suppliers.requiredDeposit')}` : `${this.props.currency}${this.state.data.amount}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        
        <Box style={webStyle.buttons} className={`${classes?.buttons} ${classes?.dialogButtonsContainer}`}>
          <Box className={classes?.buttonsInnerContainer}>
            <Button
              className={`${classes?.buttonStyleMobile}  ${classes.btnBoxCancel} ${classes?.buttonStyleCommon}`}
              onClick={this.handleCancelPurchase}
            >
              <Typography
                style={webStyle.cancelText}
                className={classes?.buttonTextMobile}
                variant="subtitle2"
              >
                {t('suppliers.cancel')}
              </Typography>
            </Button>
          </Box>
          <Button
            className={`${classes?.buttonMobile} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
            onClick={this.handleSavePurchase}
          >
            <Typography
              style={webStyle.buttonSaveText}
              className={classes?.buttonTextMobile}
              variant="subtitle2"
            >
              {t('suppliers.save')}
            </Typography>
          </Button>
        </Box>
        <LoadingBackdrop
          loading={this.state.createPurchasesLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  deleteIcon:{
    width: "30px",
    height: "30px",
    marginBottom:"3px",
    cursor:"pointer"
  },
  blackColor:{
    color: CONSTANTS.color.black,
  },
  taxDeleteBox :{
    display:"flex",
    gap:"1rem",
    alignItems:"center",
  },
  headingText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    padding: "2.5rem 4rem 0rem"
  },
  buttons: {
    padding: "3rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  modalTexts: {
    color: CONSTANTS.color.blueViolet,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    cursor: "pointer",
  },
  buttonSaveText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorText: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  subTotal: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "5px"
  },
  rateStyle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  totalRate: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  modalTextsContent: {
    color: CONSTANTS.color.blueViolet,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    cursor: "pointer",
  },
  discountDialogStyle: {
    padding: "1.5rem",
    width: "100%",
    maxWidth: "240px",
    minHeight: "183px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    position: "absolute" as const,
    top: "171%",
    left: "35%",
    transform: "translate(-50%, -50%)",
    zIndex: 6,
  },
  modalTextBackDrop: {
    position: "absolute" as const,
    top: "-5%",
    left: "19%",
    transform: "translate(-50%, -50%)"
  },
  modalTaxBackDrop: {
    position: "absolute" as const,
    top: "-4%",
    left: "7%",
    transform: "translate(-50%, -50%)"
  },
  taxDialog: {
    maxWidth: "351px",
    minHeight: "214px",
    top: "170%",
    left: "59.5%"
  },
  discountDialog: {
    padding: "1.5rem",
    width: "100%",
    maxWidth: "351px",
    minHeight: "183px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  modalDepositBackDrop: {
    position: "absolute" as const,
    top: "-3%",
    right: "-21%",
    transform: "translate(-50%, -50%)"
  },
  depositContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative" as const,
  },
  transactionDateStyle: {
    padding: "1px 0px 6px",
    gap: "1rem"
  },
  textAlignEnd: {
    textAlign: "end" as const
  },
  depositTexts: {
    color: CONSTANTS.color.neutralGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  dropDownIcon: {
    width: "12px",
    height: "7px"
  },
  paddingBottomStyle: {
    paddingBottom: "5px"
  },
  dialogButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  taxErrorStyleContainer: {
    display: "flex",
    paddingBottom: "5px"
  },
  currencyContainer: {
    margin: "22px 0 22px",
    maxWidth: "194px",
    width: "100%",
    minHeight: "48px",
    borderRadius: "6px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex",
  },
  taxContainerInput: {
    margin: "22px 0 22px",
    maxWidth: "305px",
    minHeight: "56px",
    width: "100%",
    borderRadius: "6px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex"
  },
  cancelButtonText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  selectBoxContainerCurrency: {
    borderRight: `1px solid ${CONSTANTS.color.paleCement}`,
    width: "96px"
  },
  selectMethod: {
    color: CONSTANTS.color.neutralGrey,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "justify" as const,
    padding: "2px 15px",
    cursor: "pointer"
  },
  selectedMethod: {
    padding: "5px 15px",
    backgroundColor: "rgba(28,24,175,0.16)",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
    color: CONSTANTS.color.darkBlue,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  inputBaseTextfield: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px"
  },
  taxContainer: {
    display: "flex",
    border: "0.5px solid rgb(216, 216, 216)"
  },
  taxDialogContainer: {
    maxWidth: "351px",
    minHeight: "214px",
    top: "173%",
    left: "61%"
  },
  depositDialogContainer: {
    maxWidth: "338px",
    minHeight: "335px",
    top: "250%",
    left: "75%"
  },
  redColor: {
    color: CONSTANTS.color.red,
  },
  errorBackgroundStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px"
  },
  errorStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    border: `1px solid ${CONSTANTS.color.red}`,
    borderRadius: "5px"
  }
}

export default withTranslation()(withStyles(styles)(CreatePurchase));
// Customizable Area End