Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start


exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.btnExampleTitle = "CLICK ME";
exports.countryList="/account_block/list_of_country"
exports.signup="/account_block/accounts"
exports.industries="/account_block/industries"
exports.profileAnswes="/account_block/profile_questions"
exports.countryCodeList="/account_block/contry_code_listing";
exports.getTaxRates = "bx_block_settings/tax_rates"

exports.storageToken="authToken"
exports.emailRegex=/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
exports.charactersregex=/^[A-Za-z]+$/;
exports.numRegex=/^\d*$/;

exports.account_id = "account_id";
exports.authToken = "authToken";

// Suppliers
exports.dashboardContentType = "application/json";
exports.createSuppliersAPIMethod = "POST";
exports.suppliersAPIEndPoint = "account_block/suppliers/";
exports.getSuppliersAPIMethod = "GET";
exports.getAllSuppliersAPIEndPoint = "account_block/suppliers";
exports.deleteSuppliersAPIEndPoint = "account_block/delete_supplier?id=";
exports.deleteSuppliersAPIMethod = "DELETE";
exports.editSuppliersAPIEndPoint = "account_block/update_supplier";
exports.getCurrency='bx_block_settings/region_selection'
exports.getCountryLocation='bx_block_settings/company_informations/country_lat_long'
exports.editSuppliersAPIMethod = "PATCH";
exports.supplier_id = "supplier_id";

exports.createSupplierToaster = "createSupplierToaster";
exports.updateSupplierToaster = "updateSupplierToaster";
exports.deleteSupplierToaster = "deleteSupplierToaster";
exports.archiveSupplierToaster = "archiveSupplierToaster";
exports.UnarchiveSupplierToaster = "UnarchiveSupplierToaster";
exports.createSupplierValue = "createSupplier";
exports.updateSupplierValue = "updateSupplier";
exports.deleteSupplierValue = "deleteSupplier";
exports.archiveSupplierValue = "archiveSupplier";
exports.unarchiveSupplierValue = "unarchiveSupplier";

exports.createSupplierMessage = "Supplier created";
exports.updateSupplierMessage = "Supplier updated";
exports.deleteSupplierMessage = "Supplier deleted";

exports.createPurchaseToaster = "createPurchaseToaster";
exports.updatePurchaseToaster = "updatePurchaseToaster";
exports.deletePurchaseToaster = "deletePurchaseToaster";

exports.createPurchaseValue = "createPurchase";
exports.updatePurchaseValue = "updatePurchase";
exports.deletePurchaseValue = "deletePurchase";

exports.createPurchaseMessage = "Purchase created";
exports.updatePurchaseMessage = "Purchase updated";
exports.deletePurchaseMessage = "Purchase deleted";

exports.notFoundSupplierLabel = "Not found any supplier.";

// Purchases
exports.purchasesAPIEndPoint = "account_block/supplier_purchases/";
exports.createPurchasesAPIMethod = "POST";
exports.getPurchasesAPIMethod = "GET";
exports.getPurchasesAPIEndPoint = "account_block/supplier_purchases?supplier_id=";
exports.deletePurchasesAPIMethod = "DELETE";
exports.patchPurchasesAPIMethod = "PATCH";
exports.purchase_id = "purchase_id";
exports.getPurchasesPdfAPIEndPoint = "account_block/pdf_details?purchase_id=";

// BO Employees
exports.employeesEndPoint = "account_block/employees";
exports.createEmployeeAPIMethod = "POST";
exports.getEmployeesAPIMethod = "GET";
exports.deleteEmployeesAPIMethod = "DELETE";
exports.searchEmployeesAPIEndPoint = "account_block/employee_search?query=";
exports.putEmployeesAPIEndPoint = "account_block/update_profile?id=";
exports.putEmployeesAPIMethod = "PUT";
exports.patchEmployeesAPIMethod = "PATCH";
exports.employeesBillingRatesAPIEndPoint = "/billing_rates";
exports.retrieveAllPermisionsAPIEndPoint = "/account_block/retrieve_all_permissions";
exports.employee_id = "employee_id";

// Customers
exports.dashboardContentType = "application/json";
exports.createCustomersAPIEndPoint = "/account_block/customers";
exports.createCustomersAPIMethod = "POST";
exports.getCustomersAPIEndPoint = "/account_block/customers";
exports.getCustomersAPIMethod = "GET";
exports.editCustomersAPIEndPoint = "/account_block/customers/id";
exports.editCustomersAPIMethod = "PATCH";
exports.deleteCustomersAPIEndPoint = "/account_block/customers/:id";
exports.unArchiveCustomerAPIEndPoint = '/account_block/customers/unarchived_customer'
exports.deleteCustomersAPIMethod = "DELETE";
exports.searchCustomersAPIEndPoint = "/account_block/customer_search";
exports.searchCustomersAPIMethod = "GET";
exports.getCustomersIdAPIEndPoint = "account_block/customers/:id";

// Sites
exports.dashboardContentType = "application/json";
exports.createCustomerSiteAPIEndPoint = "/bx_block_location/site_informations";
exports.createCustomerSiteAPIMethod = "POST";
exports.getCustomerSiteAPIEndPoint = "/bx_block_location/site_informations";
exports.getCustomerSiteAPIMethod = "GET";

// Customer Requests
exports.dashboardContentType = "application/json";
exports.getCustomerRequestsAPIEndPoint = "/bx_block_request_management/requests";
exports.getCustomerRequestsAPIMethod = "GET";

// Customer Invoices
exports.getInvoices="account_block/customer_data";
//regex
exports.regexName = /^(?=.{1,23}$)(?!.*(?: [^a-zA-Z']|[a-zA-Z'] [^a-zA-Z']) )[a-zA-Z']+(?: [a-zA-Z']+)*/;
exports.regex = {
  // email:/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  // phone:/^\d{10}$/,
  phone:/^\s*\d+\s*$/,
  email:/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
  zipcode:/^\s*\d+\s*$/,
  number:/^-?\d+(\.\d+)?$/, //check only numbers
  spaces:/^[a-zA-Z\s]*$/, // check alphabetical, allow spaces anywhere
  spaces_numbers:/^[a-zA-Z0-9\s]*$/, //allow spaces numbers also
  address:/^[a-zA-Z0-9,.]+(?:\s[a-zA-Z0-9,.]+)*$/,//check address with charactrs and symbols
  alphaNumeric:/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, //check charact numbers
}

exports.regexAr = {
  // email: /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
  // phone: /^\s*\d+\s*$/,
  lengthTypo:/[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]{23}/g,
  phone: /^\s*[\d٠١٢٣٤٥٦٧٨٩]+\s*$/, // Arabic numerals
  email: /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
  zipcode: /^\s*\d+\s*$/,
  number: /^-?[\d٠١٢٣٤٥٦٧٨٩]+(?:\.\d+)?$/, // Arabic numerals
  spaces: /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFa-zA-Z\s]*$/, // check alphabetical, allow spaces anywhere
  spaces_numbers: /^[\u0600-\u06FFa-zA-Z0-9\s]*$/, // allow spaces numbers also
  address: /^[a-zA-Z0-9,.]+(?:\s[a-zA-Z0-9,.]+)*$/, // check address with characters and symbols
  alphaNumeric: /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, // check characters and numbers
}



exports.getAllRequest="/bx_block_request_management/requests"

// Suppliers labels
exports.createASupplierLabel = "Create A Supplier";
exports.supplierNameLabel = "Supplier Name";
exports.startSymbol = "*";
exports.contactNameLabel = "Contact Name";
exports.contactInformationLabel = "Contact Information";
exports.mobileNumberLabel = "Mobile Number";
exports.emailAddressLabel = "Email Address";
exports.addressLabel = "Address";
exports.cityLabel = "City";
exports.stateLabel = "State";
exports.zipCodeLabel = "Zip Code";
exports.currencyLabel = "Currency";
exports.editSupplierLabel = "Edit Supplier";
exports.homeLabel = "Home";
exports.supplierLabel = "Supplier";

// Purchases labels
exports.purchaseOrderLabel = "Purchase Order";
exports.purchaseIdLabel = "Purchase ID #";
exports.downloadAsPdfLabel = "Download As PDF";
exports.createNewPurchaseLabel = "Create New Purchase";
exports.itemLabel = "Item #";
exports.purchaseDateLabel = "Purchase Date";
exports.quantityLabel = "Quantity";
exports.quantityPdfLabel = "Quantity";
exports.priceLabel = "Price";
exports.itemDescriptionLabel = "Item Description";
exports.rateLabel = "Rate";
exports.subTotalLabel = "Subtotal";
exports.plusSubTotalLabel = "+ Subtotal";
exports.cancelUpperLabel = "Cancel";
exports.addUpperLabel = "Add";
exports.discountLabel = "Discount";
exports.plusDiscountLabel = "+ Discount";
exports.taxLabel = "Tax";
exports.plusTaxLabel = "+ Tax";
exports.createTaxRateLabel = "Create Tax Rate";
exports.totalLabel = "Total";
exports.requiredDepositLabel = "Required Deposit";
exports.plusRequiredDepositLabel = "+ Required Deposit";
exports.depositLabel = "Deposit";
exports.methodLabel = "Method";
exports.amountLabel = "Amount";
exports.transactionDateLabel = "Transaction Date";
exports.detailsLabel = "Details";
exports.downloadLabel = "Download";
exports.POLabel = "PO# :";
exports.pageNumberLabel = "Page 1 of 1";
exports.vendorInformationLabel = "Vendor Information";
exports.companyLabel = "Company";
exports.zipLabel = "Zip";
exports.shippingToLabel = "Shipping To";
exports.itemHashLabel = "Item#";
exports.descriptionLabel = "Description";
exports.priceLabel = "Price";
exports.paymentAdviceLabel = "Payment Advice";
exports.grandTotalLabel = "Grand Total";

// Employees labels
exports.applyLabel = "Apply";
exports.activeLabel = "Active";
exports.noDataFoundLabel = "No Data Found";
exports.createAEmployeeLabel = "Create An Employee";
exports.personalInformationLabel = "Personal Information";
exports.fullNameLabel = "Full Name";
exports.useEmailAddressAsUserNameLabel = "Use Email Address as Username";
exports.emailAddressLabel = "Email Address";
exports.emailIIconHoverLabel = "All updates relevant to the staff including password will be sent to the email id you mention here";
exports.resendEmailInvitationLabel = "Resend Email Invitation";
exports.phoneNumberLabel = "Phone Number";
exports.defaultBillingRateLabel = "Default Billing Rate";
exports.plusIcon = "+";
exports.standardPayRateLabel = "Standard Pay Rate";
exports.standardPayRateIIconHoverLabel = "What this employee cost the company per hour";
exports.deleteBillingRateLabel = "Delete Billing Rate?";
exports.deleteBillingRateConfirmationLabel = "Are you sure you want to delete this Billing Rate?";
exports.nameLabel = "Name";
exports.hourlyRateLabel = "Hourly Rate";
exports.taxRateLabel = "Tax Rate";
exports.staffPermissionLabel = "Staff Permission";
exports.cancelLabel = "Cancel";
exports.deleteLabel = "Delete";
exports.saveLabel = "Save";
exports.updateLabel = "Update";
exports.editLabel = "Edit";
exports.deleteEmployeeLabel = "Delete Employee";
exports.deleteConfirmationLabel = "Are you sure you want to delete this Employee?";
exports.uploadFilesLabel = "Upload Files";
exports.changeLogoImageLabel = "Change Logo Image";
exports.removeImageLabel = "Remove Image";

exports.noDataFoundEmployeeLabel = "No data found for employee.";
exports.employeeNotFoundLabel = "Employee Not Found";
exports.deleteEmployeeToaster = "deleteEmployeeToaster";
exports.deleteEmployeeValue = "deleteEmployee";
exports.employeeCreatedLabel = "Successfully created employee record";
exports.employeeDeletedLabel = "Successfully deleted employee record";
exports.employeeUpdatedLabel = "Successfully updated employee record";
exports.billingRateCreatedLabel = "Billing rate created successfully";
exports.billingRateDeletedLabel = "Billing rate deleted successfully";
exports.billingRateUpdatedLabel = "Billing rate updated successfully";

exports.employeeName = "Employee Name";

//Customers error text
exports.customersErrorText = {
  requiredTypo: "Name is required",
  addressRequiredTypo: "Address is required",
  lengthTypo:"Name should be less than 23 characters",
  lengthTypoV1:"First Name should be less than 23 characters",
  lengthTypoV2:"Last Name should be less than 23 characters",
  alphabeticTypo:"Please enter in alphabetical characters",
  phoneTypo:"This is not a valid Phone Number",
  emailTypo:"Please enter a valid Email Address",
  numbersTypo:"Please enter in numbers",
  stateLengthTypo:"State should be less than 50 characters",
  phoneTypoV1:"This is not a valid Mobile Number",
  alphaNumericTypo:"Please enter in alphanumeric characters"
}

// Regex
exports.regexNameValidation = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
exports.regexDescValidation = /^(?:[a-zA-Z][a-zA-Z.,]*)(?:\s[a-zA-Z.,]+)*$/;
exports.regexWebsite = /^[a-zA-Z.]+(?:\s[a-zA-Z.]+)*$/;
exports.regexPhone = /^\d+$/;
exports.zipCodeRegex = /^[a-zA-Z0-9]+$/;
exports.regexEmail = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
exports.regexAddressField = /^(?!\s*$).+/;
exports.regexBillingRateField = /^(?!\s*$)(?!\s ).+(?<!\s)$/;
exports.regexNumbers = /^(\d+|\d*\.\d+)$/;
exports.regexDecimalNumbers = /^[\d.]*$/;

// Suppliers Error Messages
exports.suppliersErrorText = {
  alphabeticErrorMessage: "Please enter in alphabetical characters",
  numericalErrorMessage: "Please enter in numerical characters",
  alphanumericErrorMessage: "Please enter in alphanumeric characters",
  invalidPhoneErrorMessage: "This is not a valid Phone Number",
  invalidMobileErrorMessage: "This is not a valid Mobile Number",
  invalidEmailErrorMessage: "Please enter a valid Email Address",
  supplierNameErrorMessage: "Supplier Name is required",
  supplierNameLengthErrorMessage: "Supplier Name should be less than 23 characters",
  contactNameLengthErrorMessage: "Contact Name should be less than 23 characters",
  purchaseDateErrorMessage: "Purchase Date is required",
  quantityErrorMessage: "Quantity is required",
  priceErrorMessage: "Price is required",
  itemDescriptionLengthErrorMessage: "Item description should be less than 500 characters",
  alphabeticalErrorMessage: "Please enter in alphabetical",
  numericErrorMessage: "Please enter in numerical",
}

// Employees Error Messages
exports.employeesErrorText = {
  alphaNumericErrorMessage: "Please enter in alphanumeric characters",
  alphabeticErrorMessage: "Please enter in alphabetical characters",
  numericalErrorMessage: "Please enter in numerical characters",
  invalidPhoneErrorMessage: "This is not a valid Phone Number",
  fullNameErrorMessage: "Full Name is required",
  fullNameLengthErrorMessage: "Full Name should be less than 23 characters",
  emailAddressErrorMessage: "Email Address is required",
  invalidEmailErrorMessage: "This is not a valid email format",
  nameErrorMessage: "Please enter Name",
  nameLengthErrorMessage: "Name should be less than 23 characters",
  houlyRateErrorMessage: "Please enter the Hourly Rate",
  houlyRateLengthErrorMessage: "Hourly Rate should be less than 10 digits",
  taxRateErrorMessage: "Please enter the Tax Rate",
  taxRateLengthErrorMessage: "Tax Rate should be less than 10 digits",
}

// Response Error Messages
exports.emailAlreadyTakenlabel = "Email has already been taken";
exports.tokenExpired = "Token has Expired";
exports.invalidToken = "Invalid token";
exports.errorLabel = "error";

// Navigation labels
exports.Login = "Login";
exports.LoginNavigation = "/Login";
exports.Suppliers = "Suppliers";
exports.CreateSupplier = "/CreateSupplier";
exports.CreateSuppliers = "CreateSupplier";
exports.SupplierData = "/SupplierData";
exports.SuppliersData = "SupplierData";
exports.EditSupplier = "/EditSupplier";
exports.EditSuppliers = "EditSupplier";
exports.PurchaseOrders = "/PurchaseOrders";
exports.PurchaseOrder = "PurchaseOrders";
exports.CreatePurchase = "/CreatePurchase";
exports.CreatePurchases = "CreatePurchase";
exports.PurchaseData = "/PurchaseData";
exports.PurchasesData = "PurchaseData";
exports.Employees = "/Employees";
exports.EmployeesNavigation = "Employees";
exports.CreateEmployee = "CreateEmployee";

exports.secretKey = "eD%TN@3bc%l},F5eplIMCyd9h!Y2"

// Employee dashboard
exports.getDashboardDataAPIEndPoint = "bx_block_dashboard/dashboards";
exports.getDashboardDataAPIMethod = "GET";
// Customizable Area End
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
// Customizable Area End

