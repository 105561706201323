import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  userIcon,
} from "./assets";
import { checkLoggedInUserDashboard, getAuthTokenDashboard,} from "./utility.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleViewData } from "../../CustomisableUserProfiles/src/utility.web";
//@ts-ignore
import CryptoJS from "crypto-js";
interface ClassesType  {
  boxContainer: string;
  iconButton: string;
  userName: string;
  dropDownIcon: string;
  navItemName: string;
  dashhideLarge: string;
  dashhideSmall: string;
  dashdialogBoxIcon: string;
  dashnotifyHeadingBox: string;
  dashcreatePopup: string;
  dashimgStyles: string;
  dashnotifyheading: string;
  dashmarkRead: string;
  dashMoreIcon: string;
  dashMoreIconxs: string;
  dashReadLess: string;
  dashCheckIcon: string;
  dashDeleteIcon: string;
  dashMarkReadColor: string;
  dashDeleteColor: string;
  dashHr: string;
  dashtodayDate: string;
  dashnotifyBody: string;
  dashnotifyTitleWidth: string;
  dashnoNotifyText: string;
  dashdeleteText: string;
  dashnotifyTitle: string;
  dashnotifyContainer: string;
  dashnotifyImageParent: string;
  dashbadgeIcon: string;
  dashnotifyBodyContainer: string;
  dashnotifyBodyParent: string;
  dashpopoverItems: string;
  dashsmallPopup: string;
  notifications: string;
  menuIconContainer: string;
}

interface BoPermissions {
  id: number;
  section: string;
  feature_name: string;
  created_at: string;
  updated_at: string;
  standard: boolean;
}

interface BoAccountAttributes {
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  country_name: string;
  company_name: string;
  company_size: string;
  post_code: string | null;
  street_address: string | null;
  activated: boolean;
  city: string | null;
  language: string | null;
  terms: boolean;
  local: string | null;
  tap_account_id: string;
  industrie: string | null;
  other_industry: string | null;
  other_profile_question: string | null;
  heard_from: string | null;
  company_logo: string | null;
  profile_image: string | null;
  subscription_plan: string;
  subscription_plan_id: number;
  permissions: BoPermissions[];
}

interface BoData {
  id: string;
  type: string;
  attributes: BoAccountAttributes;
}

interface BoResponse {
  data: BoData;
  message?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  navMenu: any;
  navMenuItemProps: string;
  width: any;
  permissions: any;
  openToastHandler: any;
  setPermissions: any;
  history:any
  classes:ClassesType
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  languageSelected: string;
  loggedInUser: any;
  notifyModal:any;
  mockData:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileDataCallApiId: any = "";
  userPermissionDataCallApiId: any = "";
  interval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];


    this.state = {
      languageSelected: "",
      loggedInUser: {
        first_name: "",
        profile_image: "",
        last_name: "",
      },
      notifyModal: false,
      mockData: [{
        icon: userIcon,
        title: "Anupreet created a task for you.",
        body: "Task: Lamsa user interface designing",
        time: "a day ago"
      }, {
        icon: userIcon,
        title: "Anupreet created a task for you.",
        body: "Task: Lamsa user interface designing",
        time: "a day ago"
      },
      {
        icon: userIcon,
        title: "Anupreet created a task for you.",
        body: "Task: Lamsa user interface designing",
        time: "a day ago"
      }
   ]
     
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
   this.initialzeCall()
    const selectedlanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedlanguage });
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmont(){
    clearInterval(this.interval)
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.userProfileDataCallApiId) {
          this.userhandleFetchApi(responseJson);

          this.parseApiCatchErrorResponse(errorResponse);
          this.receiveBOEndCall(responseJson,apiRequestCallId,this.userProfileDataCallApiId)
        } 
        this.receiveEndCall(responseJson,apiRequestCallId,this.userPermissionDataCallApiId,errorResponse)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  initialzeCall = async () =>{
    const loggedInUser = await checkLoggedInUserDashboard();
    const authToken = await getAuthTokenDashboard();
    const employee_id = localStorage.getItem("employeeId");
    this.permissionApiIntervalCall(loggedInUser,authToken,employee_id)
    const data = localStorage.getItem("profileDataFetched");
    const profileData = JSON.parse(`${localStorage.getItem("profileData")}`);
    this.profileDataFetchCall(data,profileData)
  }

  profileDataFetchCall = (data:any, profileData:any)=>{
  if (data !== "true") {
    setTimeout(this.userfetchAccountData, 1000);
  } else {
    this.setState({
      loggedInUser: {
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        profile_image: profileData.profile_image,
      },
    });
  }
}
  permissionApiIntervalCall = (loggedInUser:any,authToken:any,employee_id:any)=>{
 
    if (loggedInUser && authToken && employee_id) {
      this.interval =   setInterval(this.setintervalCallback, 900000);
    }
  }
  setintervalCallback = () => this.userfetchAccountPermissionData();

  receiveEndCall = (responseJson:any,apiRequestCallId:any,userPermissionDataCallApiId:any,errorResponse:any)=>{
   if(apiRequestCallId === userPermissionDataCallApiId){
    this.userhandleFetchPermissionApi(responseJson);

    this.parseApiCatchErrorResponse(errorResponse);
   }
   
  }

  userhandleFetchApiErrResponse = async (responseJson: any) => {
    if (
      responseJson.errors[0].token == "Token has Expired" ||
      responseJson.errors[0].token == "Invalid token"
    ) {
      
      const isEmployee = await checkLoggedInUserDashboard()
      this.userhandleFetchApiErrResponseCallback(isEmployee)
    }
    //Check Error Response
    this.parseApiErrorResponse(responseJson);
  };

  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  userhandleFetchApiErrResponseCallback = (isEmployee:boolean)=>{
    if (isEmployee) {
      this.handleNavigate("EmployeeLogin");
    } else {
      this.handleNavigate("Login");
    }
  }

  userhandleFetchApi = (responseJson: any) => {
    const isEmp = localStorage.getItem("isEmployee");
    const isEmployee = JSON.parse(`${isEmp}`);
   this.userhandleFetchApiCallback (responseJson,isEmployee)
  };

  userhandleFetchApiCallback = (responseJson:any, isEmployee:boolean)=>{
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.props.openToastHandler("You are not authorised.", "error");
      } else {
        const {
          first_name,
          last_name,
          profile_image,
          employee_image,
        } = responseJson?.data.attributes;
        this.setState({
          loggedInUser: {
            ...this.state.loggedInUser,
            first_name: first_name || "",
            last_name: last_name || "",
            profile_image: isEmployee ? employee_image?.url : profile_image,
          },
        });
        localStorage.setItem("profileDataFetched", "true");
        localStorage.setItem(
          "profileData",
          JSON.stringify({
            first_name: first_name || "",
            last_name: last_name || "",
            profile_image: isEmployee ? employee_image?.url : profile_image,
          })
        );
      }
    } else {
      this.userhandleFetchApiErrResponse(responseJson);
    }
  }

  formatAndSetPermissionsNavbar = async (permissions: any) => {
    interface Permission {
      id: string;
      type: string;
      attributes: {
        category: string;
        action: string;
        active: boolean;
        employee_id: any;
      };
    }
    let simplePermissionsArray: Permission[] = permissions;

    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(simplePermissionsArray),
      configJSON.secretKey
    ).toString();
    await setStorageData("permissions", ciphertext);
    this.props.setPermissions(simplePermissionsArray);
  };

  userhandleFetchPermissionApi = async (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.props.openToastHandler("You are not authorised.", "error");
      }else{
       await this.formatAndSetPermissionsNavbar(
        responseJson.data.attributes.staff_permissions.data
      )
      }
    } else {
      this.userhandleFetchApiErrResponse(responseJson);
    }
  };

  userfetchAccountData = async () => {
    const token = localStorage.getItem("authToken");
    const account_id = localStorage.getItem("account_id");
    const employee_id = localStorage.getItem("employeeId");
    

    const isEmployee = await checkLoggedInUserDashboard()

    const headers = {
      "Content-Type": "application/json",
      token,
    };
    const url = !isEmployee
      ? `account_block/accounts/${account_id}`
      : `account_block/employees/${employee_id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userfetchAccountPermissionData = () => {
    const token = localStorage.getItem("authToken");
    const employee_id = localStorage.getItem("employeeId");
    const headers = {
      "Content-Type": "application/json",
      token,
    };
    const url = `account_block/employees/${employee_id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userPermissionDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleProfile = async () => {
    const isEmployee = await checkLoggedInUserDashboard()
    this.handleCheckProfile(isEmployee)
  };
  handleCheckProfile = async (isEmployee: any) => {
    if (isEmployee) {
      this.handleNavigate("EmployeeProfile");
    } else {
      this.handleNavigate("Profile");
    }
  }

  handleProfileCallback = (isAuthorized: any) => {
    if (isAuthorized.includes("VIEW") || isAuthorized.includes("EDIT")) {
      this.handleNavigate("EmployeeProfile");
    } else {
      this.props.openToastHandler("You are not authorised.", "error")
    }
  };

  handleSearchButton = () => {
    this.handleNavigate("Search");
  }

  handleFAQButton = () => {
    this.handleNavigate("FAQs");
  }

  openNotifyModal = () => {
    this.setState({ notifyModal: true })
  }
  
  closeNotifyModal = ()=>{
    this.setState({notifyModal:false})
  }

receiveBOEndCall = (responseJson:BoResponse,apiRequestCallId:string,userProfileDataCallApiId:string)=>{
  if(apiRequestCallId === userProfileDataCallApiId){
   this.handleBoPermissionApi(responseJson);
  }
  
 }

 BOformatAndSetPermissionsNavbar = async (permissions: BoPermissions[]) => {
 
  let simplePermissionsArray: BoPermissions[] = permissions;

  // Encrypt
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(simplePermissionsArray),
    configJSON.secretKey
  ).toString();
  await setStorageData("Bo permissions", ciphertext);
  this.props.setPermissions(simplePermissionsArray);
};
 
handleBoPermissionApi = async (responseJson : BoResponse) => {
    if (responseJson.message === "you are not authorized") {
      this.props.openToastHandler("You are not authorised.", "error");
    }else{
     await this.BOformatAndSetPermissionsNavbar(
      responseJson.data.attributes.permissions
    )
    }
  }
}
// Customizable Area End
