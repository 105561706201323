import React from 'react';
import { Dialog, DialogTitle, withStyles, Typography, Divider, Grid, Button, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StyledTextFieldRequest } from './Utility.web';
import CustomerSitesController, { Props } from './CustomerSitesController.web';
import SiteModal from './SiteModal.web';
import { CustomerText } from '../../../components/src/CustomizableComponents.web';
import { arrowRight } from '../../CustomisableUserProfiles/src/assets';
import { createStyles,Theme } from "@material-ui/core/styles"
import { CONSTANTS } from '../../../components/src/theme';
export const style = (theme: Theme) =>
    createStyles({
        closeButtonPopup: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.text.primary,
            width: "15px",
            height: "16px",
        },
        display_flex:{
            display:"flex"
        },
        createBtnCustomer: {
            borderRadius: "8px",
            backgroundBlendMode: "normal",
            background:CONSTANTS.color.darkBlue,
            height: "56px",
            color:CONSTANTS.color.white,
            fontWeight: CONSTANTS.fontWeight.fontWeight600,
            fontSize: "14px",
            "&:hover": {
                background: CONSTANTS.color.darkBlue,
                color: CONSTANTS.color.white,
            }

        },
        orTextCustomer: {
            width: "17px",
            height: "16px",
            color:theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: "16px"
        },
        'input': {
            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color:CONSTANTS.color.darkBlue,
                opacity: 10
            }
        },
        searchLayoutCustomer: {
            paddingTop: "2rem",
            paddingBottom: "2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            flexBasis: 0
        },
        searchLayoutCustomerChild:{
            [theme.breakpoints.down("sm")]: {
                gap:"1rem"
            }
           
        },
        activeTextCustomer: {
            color: CONSTANTS.color.darkBlue,
            fontWeight: theme.typography.fontWeightRegular,
        },
        activeDividerCustomer: {
            background:  CONSTANTS.color.darkBlue
        },
        titleTextCustomer: {
            color:CONSTANTS.color.lightBrown,
            fontWeight: theme.typography.fontWeightMedium
        },
        nameCustomer: {
            color: CONSTANTS.color.darkBlack,
            fontWeight: theme.typography.fontWeightMedium,
            cursor: "pointer",
            textTransform: "capitalize",
        },
        paper: {
            width: "530px",
            height: "600px",
            borderRadius: "10px",
            [theme.breakpoints.up("xl")]: {
                width: "634px",
                height: "678px",
                borderRadius: "10px",
            }
        },
        siteNameLayout: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "15px",
            cursor: "pointer"
        },
        editIcon: {
            width: "15px",
            height: "15px"
        },
        arrowBackIcon: {
            width: "14px",
            height: "17px",
        },
        transformStyle: {
            transform: "rotate(180deg)"
        }
    });

// Customizable Area Start

export class CustomerModal extends CustomerSitesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    arrowBackIcon = () => {
        const { classes } = this.props;
        return this.props.languageSelected === "ar" ? `${classes.arrowBackIcon} ${classes.transformStyle}` : classes.arrowBackIcon
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes ,t} = this.props;
        return (
            <>
                <Dialog
                    maxWidth="lg"
                    open={this.state.customerDailog}
                    onClose={this.handleCloseDialog}
                    PaperProps={{ classes: { root: classes.paper } }}

                >
                    <DialogTitle classes={{ root: classes.dialogTitle }}>
                        <Typography variant='subtitle1' className={classes.titleTextCustomer}>{t ? t('customerModal.title'):"Select Or Create A Customer"}</Typography>
                        <IconButton aria-label="close" data-test-id="closeDailog" className={classes.closeButtonPopup} onClick={this.handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <Grid item xs={12} className={classes.searchLayoutCustomer}>
                        <Grid container direction='row' justifyContent='center' alignItems='center' className={classes.searchLayoutCustomerChild}>
                            <Grid item xs={12} md={5}>
                                <StyledTextFieldRequest
                                    fullWidth
                                    data-test-id="search_customers"
                                    variant='outlined'
                                    placeholder={t ? `${t('customerModal.searchCus')}`:'Search Customer...'}
                                    InputProps={{ classes: { input: classes['input'] } }}
                                    onChange={this.searchCustomers}
                                />

                            </Grid>
                            <Grid item xs={12} md={2} container justifyContent='center'>
                                <Typography variant='body1' className={classes.orTextCustomer} color='textPrimary' >{t? t('customerModal.or'):"or"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Button data-test-id="create_customer" className={classes.createBtnCustomer} fullWidth onClick={this.createCustomer}>
                                    {t ? t('customerModal.createNewCus'):"CREATE NEW CUSTOMER"}
                                </Button>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingRight: "0.7rem", paddingLeft: "0.7rem" }}>
                                <Typography variant="body2" className={classes.activeTextCustomer}>{t ? t('customerModal.active') :'Active'}</Typography>
                                <Divider className={classes.activeDividerCustomer} />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.customersList && this.state.customersList.length > 0 ? this.state.customersList.map((_list: any, index: number) => {
                                    return (

                                        <>
                                            <CustomerText key={_list.id} selected={this.props.customerDetails?.name === _list.attributes.name}>
                                                <ListItemText
                                                    className={classes.display_flex}
                                                    data-test-id="selectCustomer"
                                                    onClick={() => { this.selectCustomer(_list) }}
                                                    primary={_list.attributes.name}/>
                                                <ListItemIcon>
                                                    <img className={this.arrowBackIcon()} src={arrowRight}/>
                                                </ListItemIcon>
                                            </CustomerText>
                                        </>
                                    )
                                }) :
                                    <>
                                        <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center' style={{ marginTop: "25%" }}>
                                            <Typography variant='subtitle1' className={classes.nameCustomer}>{t ? t('customerModal.noCusText'): 'No Customers Found'}</Typography>
                                        </Grid>
                                    </>}
                            </Grid >
                        </Grid>
                    </Grid>
                </Dialog>

                {
                    this.state.siteDailog &&
                    <SiteModal
                        siteDailog={this.state.siteDailog}
                        customerSites={this.state.customerSitesList}
                        handleSiteClose={this.handleSiteClose}
                        searchSites={this.searchSites}
                        selectedSiteCustomer={this.selectedSiteCustomer}
                        data-test-id="search_sites"
                        createSite={this.createSite}
                        siteDetails={this.props.siteDetails}
                        t={this.props.t}
                        handleBack={this.handleBack}
                        languageSelected={this.props.languageSelected}
                    />
                }

            </>

        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(style)(CustomerModal);
 // Customizable Area End
