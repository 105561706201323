// @ts-nocheck
import React from "react";

import {
    // Customizable Area Start
    Box, Typography, Grid, Container, 
    Button, MenuItem, ListItemIcon, Paper, Drawer,Hidden
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withSnackbar } from "../../../components/src/toastweb";
import QuoteActionsController, { Props, configJSON } from "./QuoteActionsController.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { withRouter } from "react-router-dom"
import withRequestStyles from "../../RequestManagement/src/RequestStyles.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import CreationProcess from "../../RequestManagement/src/CreationProcess.web";
import { convertToJob, leftArrow, rightArrow } from "../../RequestManagement/src/assets";
import CustomerDetails from "../../RequestManagement/src/CustomerDetails.web";
import CustomerModal from "../../RequestManagement/src/CustomerModal.web";
import InternalNotesAttachements from "../../RequestManagement/src/InternalNotesAttachements.web";
import { GoogleMap, Marker } from "@react-google-maps/api";
import ProductServices from './ProductService.web'
import PriceSectionWeb from "./PriceSection.web";
import { backIconMobile } from '../../CustomisableUserProfiles/src/assets';
import { JobsStyledMenu } from "../../JobListing2/src/JobsUtility.web";
import ClearIcon from '@material-ui/icons/Clear';
import { downloadIcon, emailIcon } from "../../JobListing2/src/assets";
import EmailModal from "../../JobListing2/src/EmailModal.web";
import DeleteModel from "../../RequestManagement/src/DeleteModal.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { dropDownArrow } from "../../ProjectReporting/src/assets";
import QuotePdf from "../../saveaspdf/src/QuotePdf.web";
import { ArcheiveButton, CancelButton,SelectCustomerButton, ContainedButton, CustomizedTextField, OutlinedButton } from "../../../components/src/CustomizableComponents.web";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import ContentDrawer from "../../JobListing2/src/ContentDrawer.web";
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme"
import { withTranslation } from "react-i18next";
const selectedLanguage =  localStorage.getItem("lang");
// Customizable Area End


export class QuoteCreate extends QuoteActionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    errorMessage = () => {
        return this.state.quoteForm.quoteTitle === '' || (this.state.quoteForm.quoteTitle && this.state.quoteForm.quoteTitle.length > 40) ? this.state.isQuoteErrorformData.quoteTitle : ''
    }
    mobileBackIcon = () => {
        return selectedLanguage === "ar" ? {...webStyle.backIcon, ...webStyle.transformStyle} : webStyle.backIcon
    }
    manageLocation = (classes, webStyle, type,t) => {

        return (
            <>
                {type === "RequestTo" &&
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item md={1}>
                            {this.state.drawerStatus ?
                                <Paper elevation={2} data-test-id="drawer_close" onClick={this.handleQuoteDrawerClose} className={classes.paperQuote}>
                                    <img src={rightArrow} width="12px" height={"10px"} />
                                </Paper>
                                :
                                <Paper elevation={2} data-test-id="drawer_open" onClick={this.handleQuoteDrawerOpen} className={classes.paperQuote}>
                                    <img src={leftArrow} width="12px" height={"10px"} />
                                </Paper>
                            }
                        </Grid>
                        {this.state.drawerStatus &&
                            <Grid item md={11} className={classes.quoteDrawerLayout}>
                                <Drawer
                                    elevation={2}
                                    className={classes.drawer}
                                    variant="persistent"
                                    anchor="right"
                                    open={this.state.drawerStatus}
                                    classes={{
                                        paper: classes.quoteDrawerPaper,
                                    }}
                                >
                                    <Grid container direction="row">
                                        <ContentDrawer
                                            details={this.state.requestDetails}
                                            modeType={"RequestTo"}
                                            t={t}
                                        />
                                    </Grid>
                                </Drawer>
                            </Grid>
                        }
                    </Grid>
                    }
                    <>
                        <Typography variant="subtitle1" 
                            className={ this.state.drawerStatus && type ? 
                                `${classes.titleHeading26} ${classes.mb_10} ${classes.mt_141}`
                            : `${classes.titleHeading26} ${classes.mb_10} ${classes.mt_38}`}
                        >
                            {t('invoice.location')}
                        </Typography>
                        <GoogleMap
                            mapContainerStyle={webStyle.selectedCustomerMapStyles}
                            center={this.state.defaultLocation}
                            zoom={5}
                            data-test-id="mapComponent"
                            onLoad={(map) => {
                                this.onMapLoad(map);
                            }}
                            options={{
                                zoomControl:false,
                                disableDefaultUI: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false
                            }}
                        >
                            {this.state.getCustomerPlace && (
                                <Marker position={this.state.getCustomerPlace} />
                            )}
                        </GoogleMap>
                    </>
            </>
        )
    }
    cancelArchiveButtons = () => {
        const { classes,t } = this.props
        const quote_id = localStorage.getItem("quote_id");
        return (
             <Box style={webStyle.cancelBtnContainer}>
                {!quote_id &&
                    <CancelButton
                        data-test-id="handleCancel"
                        variant="outlined"
                        onClick={this.handleCancel}
                    >
                        <Typography variant="subtitle2" className={classes.buttonText}>{t('invoice.button.cancel')}</Typography>
                    </CancelButton>
                }
                {(this.state.isEditQuote && quote_id) &&
                    <CancelButton
                        data-test-id="cancel_btn"
                        variant="outlined"
                        onClick={this.handleCancelQuote}
                    >
                       <Typography variant="subtitle2" className={classes.buttonText}>{t('invoice.button.cancel')}</Typography>
                    </CancelButton>
                }
                {(quote_id && this.state.singleQuoteDetails?.attributes?.status != "cancelled" && this.state.isEditQuote) &&
                    <ArcheiveButton
                        data-test-id="archeive_button"
                        variant="outlined"
                        onClick={this.handleQuoteDeleteModal}
                    >
                        <Typography variant="subtitle2" className={`${classes.buttonText} ${classes.lineHeight}`}>{t('quote.button.cancelQuote')}</Typography>
                    </ArcheiveButton>
                }
             </Box>
        );
    }

    saveQuoteMoreActions = (type) => {
        const { classes,t } = this.props
        const quote_id = localStorage.getItem("quote_id");
        return (
            <>
                {!quote_id ?
                    <OutlinedButton
                        data-test-id="saveQuoteButton"
                        variant="outlined"
                        onClick={() => this.saveQuoteButton("save")}
                    >
                        <Typography variant="subtitle2" className={`${classes.lineHeight} ${classes.buttonText}`}>{type === "RequestTo" ? `${t('quote.button.convertQuote')}` : `${t('quote.button.saveQuote')}`}</Typography>
                    </OutlinedButton>
                    :
                    <>
                        {!this.state.isEditQuote ?
                            <>
                                {this.state.singleQuoteDetails?.attributes?.status != "cancelled" &&
                                    <>
                                            <OutlinedButton
                                                data-test-id="enable_edit"
                                                variant="outlined"
                                                onClick={this.editQuoteButton}
                                            >
                                                <Typography variant="subtitle2" className={classes.buttonText}>{t('invoice.button.edit')}</Typography>
                                            </OutlinedButton>
                                    </>
                                }
                            </>
                            :
                            <OutlinedButton
                                data-test-id="updateQuoteButton"
                                variant="outlined"
                                onClick={this.updateQuoteButton}
                            >
                                <Typography variant="subtitle2" className={`${classes.buttonText} ${classes.lineHeight}`}>{t('quote.button.updateQuote')}</Typography>
                            </OutlinedButton>
                        }
                    </>
                }
                {this.moreActionsButton()}
                <JobsStyledMenu
                    id="customized-menu"
                    data-test-id="moreinfo"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleCloseSaveMore}
                >
                    <div className={!this.state.isEditQuote ? classes.quoteIconLayout : classes.flex_start}>
                        {this.closeIcon()}
                    </div>
                    {type != "RequestTo" && <MenuItem>
                        <ListItemIcon style={webStyle.width35}>
                            <img src={emailIcon} width="20px" height="20px" />
                        </ListItemIcon>
                        <Typography component={'div'} className={classes.popupBtnText} onClick={() => { !quote_id ? this.saveQuoteButton("saveMoreEmail") : this.handleEmailQuoteOpen() }}>{t('invoice.menuLabel.Email')}</Typography>
                    </MenuItem>}
                    {this.handleConvertToJobButton(classes, type,t)}

                    <MenuItem>
                        <ListItemIcon style={webStyle.width35}>
                            <img src={downloadIcon} width="20px" height="20px" />
                        </ListItemIcon>
                        <Typography data-test-id="saveMOrePdf"

                            component={'div'} className={classes.popupBtnText} onClick={() => { !quote_id ? this.saveQuoteButton("saveMorePdf") : this.openpdf() }}>{t('request.popupBtn.download')}</Typography>
                    </MenuItem>
                </JobsStyledMenu>
            </>
        );
    }

    handleConvertToJobButton = (classes, type,t) => {
        const quote_id = localStorage.getItem("quote_id");
        return (
            <>
                {type === "RequestTo" ?
                    <>
                        {!this.state?.requestDetails?.attributes?.converted_to_job &&
                            <MenuItem>
                                <ListItemIcon style={webStyle.width35}>
                                    <img src={convertToJob} width="20px" height="20px" />
                                </ListItemIcon>
                                <Typography component={'div'}
                                    data-test-id="convertToJob"
                                    onClick={() => { quote_id ? this.convertQuoteToJob() : this.saveQuoteButton("saveMoreJob") }}
                                    className={classes.popupBtnText}>{t('request.popupBtn.job')}</Typography>
                            </MenuItem>
                        }
                    </>
                    :
                    <>

                        {!this.state?.singleQuoteDetails?.attributes?.converted_to_job && <MenuItem>
                            <ListItemIcon style={webStyle.width35}>
                                <img src={convertToJob} width="20px" height="20px" />
                            </ListItemIcon>
                            <Typography component={'div'}
                                data-test-id="convertToJob"
                                onClick={() => { quote_id ? this.convertQuoteToJob() : this.saveQuoteButton("saveMoreJob") }}
                                className={classes.popupBtnText}>{t('request.popupBtn.job')}</Typography>
                        </MenuItem>}
                    </>
                }
            </>
        )
    }

    moreActionsButton = () => {
        const { classes,t } = this.props
        const quote_id = localStorage.getItem("quote_id");
        const actionText = this.state.isEditQuote ? `${t('invoice.button.updateAnd')}` : `${t('invoice.button.moreAction')}`
        return (
            <ContainedButton
                data-test-id="openMoreInfo"
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                onClick={this.handleOpenSaveMore}
            >
                <Typography variant="subtitle2" className={`${classes.buttonText} ${classes.lineHeight}`}>{!quote_id ? `${t('invoice.button.saveAnd')}` : actionText}</Typography>
                <img style={
                    this.state.anchorEl ?
                        { ...webStyle.dropDownArrow, transform: "rotate(180deg)" } :
                        webStyle.dropDownArrow}
                    src={dropDownArrow} alt=""
                />
            </ContainedButton>
        );
    }

    closeIcon = () => {
        const { classes,t } = this.props
        const quote_id = localStorage.getItem("quote_id");
        return (
            <>
                <ListItemIcon style={webStyle.width35} className={!this.state.isEditQuote && classes.flex_end} >
                    {this.state.isEditQuote ?
                        <Typography component={'div'} className={`${classes.popupBtnText} ${classes.ml_16} ${classes.mr_16} ${classes.p_10} ${classes.lineHeight}`}>
                            {quote_id ? `${t('invoice.button.updateAnd')}` : `${t('invoice.button.saveAnd')}`}
                        </Typography>
                        :
                        <ClearIcon onClick={this.handleCloseSaveMore} />
                    }
                </ListItemIcon>
            </>
        );
    }

    emailModal = (t) => {
        return (
            <>
                {this.state.quoteSendEmailDailog &&
                    <EmailModal
                        type="Quote"
                        customerEmail={this.state.selectedQuoteCustomer.attributes?.email}
                        open={this.state.quoteSendEmailDailog}
                        jobDetails={this.state.singleQuoteDetails}
                        closeEmailModal={this.handleCloseQuoteEmailDailog}
                        t={t}
                    />
                }
            </>
        );
    }

    deleteModal = (t) => {
        return (
            <>
                {this.state.quoteDeletedailog &&
                    <DeleteModel
                        deleteType={`${t('quote.deleteQuote')}`}
                        subType={`${t('quote.deleteQuoteDes')}`}
                        open={this.state.quoteDeletedailog}
                        onClose={this.handleCloseQuoteDeleteDailog}
                        handleDelete={this.handleSoftDeleteQuote}
                        loader={
                            this.state.softDeleteQuoteLoading &&
                            <LoadingBackdrop loading={this.state.softDeleteQuoteLoading} />
                        }
                        t={t}
                    />
                }
            </>
        );
    }

    quotePdf = (t) => {
        return (
            <>
                {this.state.openPdfStatus &&
                    <QuotePdf
                        type="Quote"
                        quoteId={this.state.singleQuoteDetails?.id}
                        openPdf={this.state.openPdfStatus}
                        closePdf={this.closePdf}
                        t={t}
                        currency={this.state.currency}
                    />
                }
            </>
        );
    }

    loadingBackdrop = () => {
        return (
            <LoadingBackdrop
                loading={
                    this.state.createQuoteLoading ||
                    this.state.getQuoteLoading ||
                    this.state.updateQuoteLoading
                }
            />
        );
    }

    manageQuoteDetails = (classes,t) => {
        return (
            <>
                <Grid container justifyContent="flex-start" spacing={1} >
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.titleHeading26}>{t('quote.quoteText')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container justifyContent="flex-start" spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="body1"
                                    color="textPrimary"
                                    className={classes.subTitle20}
                                >
                                    {t('quote.quoteDate')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomDatePicker
                                    disabled={!this.state.isEditQuote}
                                    selectedDate={this.state.quoteForm.quoteDate}
                                    dateChange={this.handleQuoteDateChange}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container justifyContent="flex-start" spacing={1}>

                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    className={classes.subTitle20}>
                                    {t('quote.expirationDate')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomDatePicker
                                    disabled={!this.state.isEditQuote}
                                    minDate={this.state.quoteForm.quoteDate}
                                    selectedDate={this.state.quoteForm.dueDate}
                                    dateChange={this.handleDueDateChange}

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
    customerLocation = (classes, webStyle, type, t) => {
        return <> 
            {type === "RequestTo" &&
                <Hidden smDown>
                    <Grid item xs={12} md={4}>
                        {this.manageLocation(classes, webStyle, type, t)}
                    </Grid>
                </Hidden>
            }
        </>
    }
    quoteDetails = (classes, t, type) =>{
        return <>
            {type === "RequestTo" && 
                <Grid item xs={12}>
                    {this.manageQuoteDetails(classes,t)}
                </Grid>
            }
        </>
    }
    // Customizable Area End

    render() {
        const { classes,t } = this.props
        const quote_id = localStorage.getItem("quote_id");
        const type = this.props.navigation?.history?.location?.state?.convertedType

        return (
            // Customizable Area Start
            <>
                <DashboardSideBar oldProps={undefined} permissions={undefined} {...this.props} navMenuItem={`${t('quote.header')}`} active={4} active1={false} active2={false} />
                <StyledRoot languageSelected={this.state.selectLanguage}>
                    <Box sx={{ marginTop: "8rem" }}>
                        <Container maxWidth={false}>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} className={classes.mb_25}>
                                        <CreationProcess active={1} t={t} selectLanguage={this.state.selectLanguage}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="flex-start" spacing={3}>
                                            <Grid item xs={12} md={8}>
                                                <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid item xs={12} className={classes.mb_10}>
                                                            <Grid item className={classes.backIconContainer}>
                                                            <Hidden smUp>
                                                                <img style={this.mobileBackIcon()} src={backIconMobile} onClick={this.handleBack}  alt="" />
                                                            </Hidden>
                                                                {type === "RequestTo" &&
                                                                    <Hidden mdUp>
                                                                        <Grid container direction="row">
                                                                            <Grid item xs={12}>
                                                                                {this.manageLocation(classes, webStyle, type,t)}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Hidden>}
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} md={8}>
                                                            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                                <Grid item xs={12} className={`${classes.mt_10} ${classes.mb_12}`}>
                                                                    <Typography 
                                                                        variant="subtitle1"
                                                                        className={classes.titleHeading26}
                                                                    >
                                                                        {t('quote.title')}
                                                                        <span style={webStyle.errorText}>*</span>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <CustomizedTextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        name={"quoteTitle"}
                                                                        onChange={this.handleQuoteChange}
                                                                        onBlur={this.handleOnBlur}
                                                                        disabled={!this.state.isEditQuote}
                                                                        value={this.state.quoteForm.quoteTitle}
                                                                        data-test-id="handleQuoteChange" />
                                                                </Grid>
                                                                <Grid>
                                                                    <ErrorMessage message= {this.errorMessage()}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    </Grid>
                                    </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction='row' spacing={2}>
                                                    <Grid item xs={12} md={8}>
                                                        {!quote_id ?
                                                            <>
                                                                {this.state.selectCustomerButton ?
                                                                    <>
                                                                        <CustomerDetails
                                                                            crudStatus={'create'}
                                                                            modeType={t('quote.quoteFor')}
                                                                            details={this.state.selectedQuoteCustomer.attributes}
                                                                            siteDetails={this.state.siteDetails}
                                                                            customerSelectedStatus={this.getCustomerDailogStatus1}
                                                                            dailogOpen={this.handleOpenCustomerDailog}
                                                                            clearCustomer={this.clearCustomerSite}
                                                                            t={t}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <SelectCustomerButton
                                                                        onClick={this.handleOpenCustomerDailog}
                                                                        data-test-id="select_customer"
                                                                    >
                                                                        {t('invoice.button.selectCus')}
                                                                    </SelectCustomerButton>
                                                                }
                                                            </>
                                                            :
                                                            <CustomerDetails
                                                                crudStatus={'edit'}
                                                                modeType={t('quote.quoteFor')}
                                                                editStatus={this.state.isEditQuote}
                                                                details={this.state.selectedQuoteCustomer.attributes}
                                                                siteDetails={this.state.siteDetails}
                                                                customerSelectedStatus={this.getCustomerDailogStatus1}
                                                                dailogOpen={this.handleOpenCustomerDailog}
                                                                clearCustomer={this.clearCustomerSite}
                                                                t={t}
                                                            />

                                                        }
                                                        <Grid item xs={12}>
                                                            <ErrorMessage message={this.state.siteDetails && this.state.selectedQuoteCustomer.attributes ? '' : this.state.isQuoteErrorformData.customer} />
                                                        </Grid>
                                                        
                                                    </Grid>
                                                     {type !== "RequestTo" && !quote_id &&
                                                        <Grid item xs={12} md={4}>
                                                            {this.state.selectCustomerButton &&
                                                                this.manageLocation(classes, webStyle, type, t)}
                                                        </Grid>
                                                    }
                                                     {type !== "RequestTo" && quote_id &&
                                                        <Grid item xs={12} md={4}>
                                                            {this.manageLocation(classes, webStyle, type, t)}
                                                        </Grid>
                                                    } 
                                                    {this.customerLocation(classes, webStyle, type, t)}
                                                </Grid>
                                            </Grid>
                                               
                                                    <Hidden smDown>
                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justifyContent="flex-start" spacing={2} style={webStyle.serviceBoxText}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="subtitle1" className={classes.titleHeading26}>{t('request.serviceText')}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.serviceText')}</Typography>
                                                                </Grid>
                                                                <Grid item
                                                                    xs={12}
                                                                    md={8}>
                                                                    <CustomizedTextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        multiline
                                                                        minRows={3}
                                                                        maxRows={3}
                                                                        name={"serviceDetails"}
                                                                        value={this.state.quoteForm.serviceDetails}
                                                                        onChange={this.handleQuoteChange}
                                                                        data-test-id="handleQuoteChange"
                                                                        disabled={!this.state.isEditQuote}
                                                                    />
                                                                    <ErrorMessage message={this.state.isQuoteErrorformData.serviceDetails} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Hidden>
                                                  {this.quoteDetails(classes,t,type)}
                                                </Grid>
                                            </Grid>
                                           
                                        
                                    <Hidden mdUp>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.titleHeading26}>
                                                        {t('request.serviceText')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>
                                                        {t('request.subTitles.serviceText')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <CustomizedTextField
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        minRows={3}
                                                        maxRows={3}
                                                        name={"serviceDetails"}
                                                        value={this.state.quoteForm.serviceDetails}
                                                        onChange={this.handleQuoteChange}
                                                        data-test-id="handleQuoteChange"
                                                        disabled={!this.state.isEditQuote}
                                                    />
                                                    <ErrorMessage message={this.state.isQuoteErrorformData.serviceDetails} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                    {type != "RequestTo" &&
                                        <Grid item xs={12} md={8}>{this.manageQuoteDetails(classes,t)}</Grid>}
                                    <Grid item xs={12} md={9}>
                                        <ProductServices
                                            productServices={this.state.productServices}
                                            productData={this.state.productData}
                                            handleProductDocument={this.handleProductDocument}
                                            handleProductChange={this.handleChangeInput}
                                            addField={this.addQuoteField}
                                            completed={this.state.completed}
                                            removeField={this.removeField}
                                            files={this.state.files}
                                            handleRemoveFile={this.handleRemoveFile}
                                            progress={this.state.progress}
                                            value={this.state.productServices}
                                            history={this.props.history}
                                            handleChangeProductFiles={this.handleChangeProductFiles}
                                            isEditable={this.state.isEditQuote ? true : false}
                                            isErrorFormData={this.state.isQuoteErrorformData}
                                            currency={this.state.currency}
                                            productOnBlur={this.productOnBlur}
                                            languageSelected={this.state.selectLanguage}
                                            t={t}
                                        />
                                    </Grid>
                                    <Grid item
                                        xs={12}
                                        md={9}
                                    >
                                        <Typography
                                            className={`${classes.fontWeight_600} ${classes.font_lightBrown} ${classes.headingText}`}
                                            variant="subtitle1">Price</Typography>
                                        {/* PriceSection */}
                                    <PriceSectionWeb
                                            data={this.state.productServices}
                                            priceSectionData={this.state.priceSectionData}
                                            handlePriceSection={this.handlePriceSection}
                                            emptyPriceSectionData={this.emptyPriceSectionData}
                                            selectTaxRate={this.selectTaxRate}
                                            isEditable={this.state.isEditQuote ? this.state.isEditable : false}
                                            calculateTotal={this.calculateTotal}
                                            currency={this.state.currency}
                                            getSubTotal={this.getSubTotal}
                                            invoicePayment={this.invoicePayment}
                                            selectCurrency={this.selectCurrency}
                                            selectedCurrency={this.state.selectCurrency}
                                            handleChangeCurrencyRef={this.handleChangeCurrencyRef}
                                            handleCollectionPaymentSave={this.handleCollectionPaymentSave}
                                            handleChange={this.handleChange}
                                            onClose={this.handlePaymentModalClose}
                                            discountDialog={this.state.discountDialog}
                                            handleDiscountDialogOpen={this.quoteDiscountDailog}
                                            collectedPaymentForm={this.state.collectedPaymentForm}
                                            handleChangePaymentMethodRef={this.handleChangePaymentMethodRef}
                                            handleCollectionPaymentModal={this.handleCollectionPaymentModal}
                                            handleCollectionPaymentClose={this.handleCollectionPaymentClose}
                                            handleTransactionDate={this.handleTransactionDate}
                                            handlePay_balence={this.handlePayBalance}
                                            handleCollected={this.handleCollected}
                                        />
                                    </Grid>
                                    
                                        
                                    <Grid item xs={12} style={webStyle.mt_30} md={9}>
                                        <InternalNotesAttachements
                                            t={t}
                                            editStatus={this.state.isEditQuote ? true : false}
                                            type={quote_id && "edit"}
                                            internalNotes={this.state.internalNotes}
                                            internalAttachments={this.state.internalAttachments}
                                            editedinternalAttachements={this.state.jobEditedFiles}
                                            convertedFiles={this.state.jobConvertedFiles}
                                            getConvertedFiles={this.getJobConvertedFiles}
                                            getEditedFiles={this.getJobEditedFiles}
                                            updateInternalNotes={this.updateInternalNotes}
                                            languageSelected={this.state.selectLanguage}
                                            updateInternalAttachments={this.updateInternalAttachments} />
                                        <Typography variant="caption" color="error">{this.state.isQuoteErrorformData.internalNotes}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={`${classes.bottomBtnsLayout} ${classes.margin}`}>
                                        <Grid container justifyContent="flex-end" direction="row" className={`${classes.smLayout} ${classes.flexDirection}`}>
                                            <Grid item xs={12} sm={6} className={`${classes.mt_1} ${classes.direction}`}>
                                                    {this.cancelArchiveButtons()}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container className={classes.flexDirection} direction="row" style={webStyle.saveBtnContainer}>
                                                    {this.saveQuoteMoreActions(type)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>

                    </Box>
                </StyledRoot >
                {
                    this.state.cutstomerDailogOpen &&
                    <CustomerModal
                        open={this.state.cutstomerDailogOpen}
                        languageSelected={this.state.selectLanguage}
                        saveMapData={this.getCustomerLocationData}
                        getCustomerDetails={this.getCustomerDetails}
                        getSiteDetails={this.getSiteDetails}
                        selectedCustomerButtonStatus={this.getCustomerDailogStatus1}
                        getCustomerSelectedStatus={this.getCustomerSelectedStatus}
                        customerDetails={this.state.selectedQuoteCustomer?.attributes}
                        siteDetails={this.state.siteDetails}
                        navigation={this.props.navigation}
                        history={this.props.navigation.history}
                        location={this.props.navigation.history.location}
                        t={t}
                        handleCusValidation={this.handleCustomerValidation}
                    />
                }
                {this.emailModal(t)}
                {this.deleteModal(t)}
                {this.quotePdf(t)}
                {this.loadingBackdrop()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    dropDownArrow: {
        width: "16px",
        height: "8px",
        marginLeft:"15px"
    },
    errorText:{
        fontWeight: 400,
        fontSize: "20px",
        color: CONSTANTS.color.crimsonRed
    },
    selectedCustomerMapStyles: {
        width: "100%",
        minHeight: "320px",
        borderRadius: "10px",
    },
    mt_30:{
        marginTop:"30px"
    },
    width35:{
        minWidth:"35px"
    },
    backIcon: {
        width: "12px",
        height: "24px",
    },
    transformStyle: {
        transform: "rotate(180deg)"
    },
    cancelBtnContainer: {
        display: "flex",
        gap: "1rem"
    },
   
    saveBtnContainer: {
        gap: "1rem",
        justifyContent:'end'
    },
    serviceBoxText:{
        width:"100%"
    }

}
const QuoteCreateToaster = withSnackbar(QuoteCreate);
const QuoteCreateRouter = withRouter(QuoteCreateToaster);
const QuoteCreateCOmmonStyles = withCommonStyles(QuoteCreateRouter)
const QuoteRequestStyles =  withRequestStyles(QuoteCreateCOmmonStyles)
const CreateJobToaster = QuoteRequestStyles
export default withTranslation()(CreateJobToaster)
// Customizable Area End