import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {WithStyles} from "@material-ui/core"
import { createCustData } from "./CreateCustomerController.web";
import {Customer,SiteProp,ToggleDataProp} from "./Customers.web"
import {
  checkLoggedInUser,
  checkPermission,
  performAuthorizationCheck,
  handleTooltipOpenSites,
  handleTooltipClose
} from "./utility.web";
import { getStorageData } from "../../../framework/src/Utilities";

interface CustomerStatusData {
  id:number,
  title:string,
  statusName:string
}

interface ApiResponse {
  active: {
    data: Customer[];
  };
  archived: {
    data: Customer[];
  };
  message:string,
  errors:{token:string}[]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  oldProps: any;
  text: any;
  toaster: any;
  handleCloseToaster: any;
  openToastHandler: any;
  permission: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusModal: boolean;
  toggleModal: boolean;
  status: string;
  checkedItems:string[]
  radioQuote: boolean;
  jobQuote: boolean;
  invoiceQuote: boolean;
  mockAutomaticRemindersData: any;
  countryCode: any;
  remindersDialogOpen: boolean;
  customersData: any;
  getCustomersLoading: boolean;
  isErrorFormData: any;
  toaster: any;
  active: any;
  showUpdateButton: boolean;
  deleteDialogOpen: boolean;
  toggleData: any;
  sortDirection: any;
  sortColumn: any;
  toasterText: string;
  searchCustomer: string;
  timer: any;
  sortingOrder: string;
  lastClickedProperty: any;
  sortingProperty: any;
  sortConfig: {
    key: any;
    direction: string;
  };
  checkedColumn: number[]
  customerName: any;
  lang:string,
  anchorEl:Element | null,
  anchorId:string;
  listData:SiteProp[] | undefined,
  popoverHeading:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getCustomersLoading: string = "";
  searchCustomersApiCallId: string = "";
  getCustomersApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      statusModal: false,
      toggleModal: false,
      status: "all",
      checkedItems:['all','active','archived'],
      radioQuote: true,
      jobQuote: true,
      invoiceQuote: true,
      mockAutomaticRemindersData: createCustData,
      countryCode: "+91",
      remindersDialogOpen: false,
      getCustomersLoading: false,
      isErrorFormData: {},
      toaster: false,
      active: 0,
      showUpdateButton: false,
      deleteDialogOpen: false,
      customersData: [],
      sortDirection: "asc",
      sortColumn: "",
      toggleData: [
        { id: 1, title: this.props.t(`customers.mockTableRow.${0}.title`), hidden: false, },
        { id: 2, title: this.props.t(`customers.mockTableRow.${1}.title`), hidden: false, },
        { id: 3, title: this.props.t(`customers.mockTableRow.${2}.title`), hidden: false, },
        { id: 4, title: this.props.t(`customers.mockTableRow.${3}.title`), hidden: false },
      ],
      toasterText: "",
      searchCustomer: "",
      timer: "",
      lastClickedProperty: null,
      sortingProperty: null,
      sortingOrder: "",
      sortConfig: {
        key: "",
        direction: "",
      },
      checkedColumn: [1,2,3,4],
      customerName: "",
      lang: "en",
      popoverHeading: "",
      listData: [],
      anchorEl: null,
      anchorId: "",
    };

    this.executeInputChange = this.executeInputChange.bind(this);

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const token = localStorage.getItem("authToken");
    const lang = await getStorageData("lang")
    this.setState({ lang });
    this.checkAuthToken(token);
    const pathName = this.props.location.pathname;

    if (pathName == "/CreateCustomer") {
      this.setState({ active: 0 });
    } else if (pathName == "/CustomerSites") {
      this.setState({ active: 1 });
    } else if (pathName == "/CustomerRequests") {
      this.setState({ active: 2 });
    } else if (pathName == "/CustomerQuotes") {
      this.setState({ active: 3 });
    } else if (pathName == "/CustomerJobs") {
      this.setState({ active: 4 });
    } else if (pathName == "/CustomerInvoices") {
      this.setState({ active: 5 });
    } else if (pathName == "/EditCustomer") {
      this.setState({ active: 6 });
    } else {
      this.getCustomers();
      this.setState({ getCustomersLoading: true });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkAuthToken = (token: any) => {
    if (!token) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }
  };
  handleChange = async (event: any, newValue: any) => {
    const urlMap: any = {
      0: "/CreateCustomer",
      1: "/CustomerSites",
      2: "/CustomerRequests",
      3: "/CustomerQuotes",
      4: "/CustomerJobs",
      5: "/CustomerInvoices",
      6: "/EditCustomer",
    };

    const url = urlMap[newValue];
    if (!url) return;

    const editedCustomerId = localStorage.getItem("editedCustomerId");
    const isEmployee = await checkLoggedInUser();
    const isPermit = await checkPermission("customers_data");
    if (newValue === 0) {
      await this.handleChangeCallback(editedCustomerId, isEmployee, isPermit);
    } else {
      await this.checkAuthorization(url, isEmployee, isPermit);
    }
  };

  handleChangeCallback = async (
    editedCustomerId: any,
    isEmployee: any,
    isPermit: any
  ) => {
    if (editedCustomerId) {
      await this.checkAuthorization("/EditCustomer", isEmployee, isPermit);
    } else {
      localStorage.removeItem("customerName");
      await this.checkAuthorization("/CreateCustomer", isEmployee, isPermit);
    }
  };
  checkAuthorization = async (path: string, isEmployee: any, isPermit: any) => {
    const isCreateCustomer = path === "/CreateCustomer";
    const isViewClientDetails =
      isPermit && isPermit[0] === "View client's details";
    let conditions;
    if (isEmployee) {
      conditions =
        isPermit &&
        (!isPermit[1] ||
          isPermit[0] === "None" ||
          (isCreateCustomer && isViewClientDetails));
    } else {
      conditions = false;
    }
    this.checkAuthCallback(conditions, path);
  };

  checkAuthCallback = (conditions: any, path: any) => {
    if (conditions) {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    } else {
      this.props.history.push(path);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  tokenExpired = async () => {
    const isEmployee = await checkLoggedInUser();
    this.tokenExpiredCallbackCustomers(isEmployee);
  };

  tokenExpiredCallbackCustomers = (isEmployee: any) => {
    const redirectUrl = isEmployee ? "/Employee/Login" : "/Login";
    setTimeout(
      this.tokenExpiredTimeCallbackCustomers.bind(this, redirectUrl),
      1000
    );
  };

  tokenExpiredTimeCallbackCustomers = (url: any) => {
    localStorage.clear();
    this.props.history.push(url);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getCustomersApiCallId) {
       this.handleGetCustomers(responseJson)
      }
    }

    this.handleSearchCustomers(message);
    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetCustomers = (responseJson:ApiResponse)=>{
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.props?.openToastHandler(this.props.t("customers.unAuth"), "error");
        this.setState({ customersData: [] });
        this.setState({ getCustomersLoading: false });
      } else {
     
        this.setState({ customersData: Object.keys(responseJson).length > 0 ? responseJson : [] });
        this.setState({ getCustomersLoading: false });
      }
    } else if (
      responseJson.errors[0].token == "Token has Expired" ||
      responseJson.errors[0].token == "Invalid token"
    ) {
      this.setState({ customersData: [] });
      this.setState({ getCustomersLoading: false });
      this.props?.openToastHandler(responseJson?.errors[0].token, "error");
      this.tokenExpired();
    } else {
      //Check Error Response
      this.setState({ customersData: [] });
      this.setState({ getCustomersLoading: false });
      
    }
    
  }

  getCustomersUrl = (direction: string) => {
    return direction === ""
      ? `${configJSON.getCustomersAPIEndPoint}?status=${this.state.status}&query=${this.state.searchCustomer}&lang=${this.state.lang}`
      : `${configJSON.getCustomersAPIEndPoint}?status=${this.state.status}&order=${this.state.sortConfig.direction}&field=${this.state.sortConfig.key}&query=${this.state.searchCustomer}&lang=${this.state.lang}`;
  };
  getCustomers = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const url = this.getCustomersUrl(this.state.sortConfig.direction);

    this.getCustomersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSearchCustomers = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.searchCustomersApiCallId) {
        this.handleSearchCustomersCallback(responseJson, errorResponse);
      }
    }
  };

  handleSearchCustomersCallback = (responseJson: any, errorResponse: any) => {
    if (responseJson.errors) {
      if (
        responseJson.errors[0].token == "Token has Expired" ||
        responseJson.errors[0].token == "Invalid token"
      ) {
        this.setState({
          customersData: [],
        });
        this.props?.openToastHandler(responseJson?.errors[0].token, "error");
        this.tokenExpired();
      } else {
        this.setState({
          customersData: [],
        });
        this.parseApiErrorResponse(responseJson);
      }
    } else {
      if (responseJson.message === "you are not authorized") {
        this.setState({ getCustomersLoading: false });
        this.setState({ customersData: [] });
        return this.props?.openToastHandler(this.props.t("customers.unAuth"), "error");
      }
      this.setState({ getCustomersLoading: false });
      this.setState({ customersData: responseJson|| []});
    }

    this.parseApiCatchErrorResponse(errorResponse);
  };
  searchCustomers = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const url = this.getCustomersUrl(this.state.sortConfig.direction);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchCustomersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.searchCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangeSearchCustomer = (e: any) => {
    const inputValue = e.target.value;
    clearTimeout(this.state.timer);
    const timer = setTimeout(this.changeTime, 500);
    this.setState({ searchCustomer: inputValue, timer });
  };

  changeTime = () => {
    this.executeInputChange();
  };

  executeInputChange() {
    // Perform the input change logic here, e.g., call an API with the new input value.
    this.getCustomers();
  }

  handleEditButton = () => {
    this.setState({ showUpdateButton: true });
  };

  handleUpdateButton = () => {
    this.setState({ showUpdateButton: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleCloseToaster = () => {
    this.setState({ toaster: false });
  };

  sortCallbackCustomer = () => {
    this.setState({ getCustomersLoading: true });
    this.getCustomers();
  };

  onSort = (key: string) => {
    if (key === "name" || key === "created_at" || key === "phone_number") {
      let direction = "asc";
      const { sortConfig } = this.state;
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      this.setState(
        { sortConfig: { key, direction } },
        this.sortCallbackCustomer
      );
    }
  };

  handleStatusModalOpen = () => {
    this.setState({ statusModal: !this.state.statusModal });
    this.setState({ toggleModal: false });
  };

  handleStatusModalClose = () => {
    this.setState({ statusModal: false });
  };

  handleToggleModalOpen = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
    this.setState({ statusModal: false });
  };

  handleToggleModalClose = () => {
    this.setState({ toggleModal: false });
  };

  handleSelectStatus = (item: CustomerStatusData, customerStatusData: CustomerStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.checked) {
        if (item.statusName === "all") {
            this.setState({
                checkedItems: customerStatusData.map((subItem: CustomerStatusData) => subItem.statusName),
                status: "all"
            }, this.getCustomers)
        } else {
            this.setState({
                checkedItems: [...this.state.checkedItems,
                item.statusName
                ],
                status: [...this.state.checkedItems,
                item.statusName
                ].join(",")
            }, this.getCustomers)
        }
    } else {
        if (item.statusName === "all") {
            this.setState({
                checkedItems: [],
                status: ""
            }, this.getCustomers)
        } else {
            this.setState({
                checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
                status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
            }, this.getCustomers)
        }
    }
}

  handleCreateCustomer = async () => {
    localStorage.removeItem("editedCustomerId");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("customerName");
    localStorage.removeItem("customerPage");

    const isAuthorized = await performAuthorizationCheck("customers_data"); //true = not authorized , false = authorized
    this.handleCreateCustomerCallback(isAuthorized);
  };

  handleCreateCustomerCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.props.navigation.navigate("CreateCustomer");
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  handleCheckedColumn = (item: ToggleDataProp, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      this.setState({ checkedColumn: [...this.state.checkedColumn, item.id].sort((a, b) => a - b) });

    } else {
      this.setState({ checkedColumn: this.state.checkedColumn.filter((id: number) => id !== item.id).sort((a, b) => a - b) });
    }
  };

  handleCloseRemindersDialog = () => {
    this.setState({ remindersDialogOpen: false });
  };


  handleViewCustomerData = async (item: Customer) => {
    const isEmployee = await checkLoggedInUser();
    const isPermit = await checkPermission("customers_data");
    localStorage.setItem("customer_id", item.id);
    localStorage.setItem("editedCustomerId", item.id);
    localStorage.setItem("customerName", item.attributes?.name);
    localStorage.setItem("site_id",String(item.attributes?.sites[0]?.id));
    this.handleViewCustomerDataCallback(isEmployee, isPermit);
  };

  handleViewCustomerDataCallback = async (isEmployee: any, isPermit: any) => {
    if (isEmployee) {
      if (isPermit && !isPermit[1]) {
        this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
      } else if (isPermit && isPermit[0] === "None") {
        this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
      } else {
        this.props.navigation.navigate("EditCustomer");
      }
    } else {
      this.props.navigation.navigate("EditCustomer");
    }
  };
  handleTooltipOpen = (item: SiteProp[] | [],event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const {t} = this.props;
    const { anchorEl, anchorId, listData, popoverHeading } = handleTooltipOpenSites(item, t("customers.sites"), event)
    this.setState({
      anchorEl, anchorId, listData, popoverHeading
    })
  }
  handleTooltipClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { anchorEl, anchorId, listData, popoverHeading } = handleTooltipClose();
    this.setState({
      anchorEl, anchorId, listData, popoverHeading
    })
  }
}

// Customizable Area End
